import { isPlatformBrowser } from "@angular/common";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject, Injectable, PLATFORM_ID } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { CookieService } from "ngx-cookie-service";
import { BehaviorSubject } from "rxjs";
import { environment } from "src/environments/environment";
import { SuccessComponent } from "../shared/success/success.component";
import { NavigationService } from "./navigation.service";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  /** API Access - ENV */
  public baseUrl: string = "";

  /** Variable that holds the http headers */
  private headers: HttpHeaders;

  /** Variable that holds the login status */
  loggedIn = new BehaviorSubject(false);

  /** Variable that holds the intended url when the user tries to log in but is unauthorized */
  private intendedUrl: string;

  constructor(
    private cookieService: CookieService,
    @Inject(PLATFORM_ID) private platformId,
    private http: HttpClient,
    private navigationService: NavigationService,
    private dialog: MatDialog
  ) {
    this.headers = new HttpHeaders();
    this.headers.set("Content-Type", "application/json");
    this.baseUrl = environment.baseUrl;
    if (isPlatformBrowser(this.platformId)) {
      const user = this.cookieService.get("flexpot-user");
      if (user !== undefined && user !== null && user !== "") {
        this.loggedIn.next(true);
      } else {
        this.loggedIn.next(false);
      }
    }
  }

  /** Registers a new user (natural person) user in the application */
  registerNaturalUser({
    nombre,
    apellido,
    email,
    tipoDocumento,
    numeroDocumento,
    documentFile,
    ciudad,
    direccion,
    celular,
    password,
    banco,
    rut,
  }: {
    nombre: string;
    apellido: string;
    email: string;
    tipoDocumento: string;
    numeroDocumento: string;
    documentFile: string;
    ciudad: string;
    direccion: string;
    celular: string;
    password: string;
    banco: string;
    rut: string;
  }): void {
    const user = {
      nombre,
      apellido,
      email,
      tipoDocumento,
      numeroDocumento,
      documentFile,
      ciudad,
      direccion,
      celular,
      password,
      banco,
      rut,
      tipo: "usuario",
    };
    this.http.post(`${this.baseUrl}api/user`, user).subscribe(
      (response: any) => {
        if (response.success) {
          // Show dialog of waiting for approval
          this.dialog.open(SuccessComponent, {
            data: {
              title: "Cuenta creada y en revisión",
              message:
                "Pronto recibirás una notificación para que puedas acceder a tu cuenta en cuanto el administrador la haya aprobado",
              onOk: () => {
                this.navigationService.navigateTo({ path: "home" });
              },
            },
            disableClose: true,
          });
        }
      },
      (err) => {
        // Handle register error
        alert(JSON.stringify(err.error.data));
      }
    );
  }

  /**
   * Registers a new user (legal person) in the application
   */
  registerLegalUser({
    nombreEmpresa,
    razonSocial,
    email,
    tipoDocumento,
    numeroDocumento,
    documentFile,
    nombreRepresentante,
    apellidoRepresentante,
    tipoDocumentoRepresentante,
    numeroDocumentoRepresentante,
    ciudad,
    direccion,
    celular,
    password,
    banco,
    rut,
  }: {
    nombreEmpresa: string;
    razonSocial: string;
    email: string;
    tipoDocumento: string;
    numeroDocumento: string;
    documentFile: string;
    nombreRepresentante: string;
    apellidoRepresentante: string;
    tipoDocumentoRepresentante: string;
    numeroDocumentoRepresentante: string;
    ciudad: string;
    direccion: string;
    celular: string;
    password: string;
    banco: string;
    rut: string;
  }): void {
    const user = {
      nombreEmpresa,
      razonSocial,
      email,
      tipoDocumento,
      documentFile,
      numeroDocumento,
      nombreRepresentante,
      apellidoRepresentante,
      tipoDocumentoRepresentante,
      numeroDocumentoRepresentante,
      ciudad,
      direccion,
      celular,
      password,
      banco,
      rut,
      tipo: "usuario",
      juridica: true,
    };

    this.http.post(`${this.baseUrl}api/user`, user).subscribe(
      (response: any) => {
        if (response.success) {
          // Show dialog of waiting for approval
          this.dialog.open(SuccessComponent, {
            data: {
              title: "Cuenta creada y en revisión",
              message:
                "Pronto recibirás una notificación para que puedas acceder a tu cuenta en cuanto el administrador la haya aprobado",
              onOk: () => {
                this.navigationService.navigateTo({ path: "home" });
              },
            },
            disableClose: true,
          });
        }
      },
      (err) => {
        // Handle register error
        alert(err);
      }
    );
  }

  /**
   * Authenticates a user in the backend, given that the user email-password combination is ok
   * @param email user email
   * @param password user password
   */
  authenticateUser({
    email,
    password,
    dialogRef,
  }: {
    email: string;
    password: string;
    dialogRef?: any;
  }): void {
    this.http
      .post(
        `${this.baseUrl}api/user/authenticate`,
        {
          email,
          password,
        },
        { headers: this.headers }
      )
      .subscribe(
        (response: any) => {
          this.saveCredentials({
            user: JSON.stringify(response.data.user),
            token: response.data.token,
          });
          this.loggedIn.next(true);
          if (this.intendedUrl !== null && this.intendedUrl !== undefined) {
            this.navigationService.navigateTo({ path: this.intendedUrl });
          } else {
            this.navigationService.navigateTo({ path: "cuenta" });
          }

          if (dialogRef) {
            dialogRef.close();
          }
        },
        (err) => {
          alert(err.error.mensaje);
        }
      );
  }

  /**
   * Authenticates an admin in the backend, given that the user email-password combination is ok
   * @param email user email
   * @param password user password
   */
  authenticateAdmin({
    email,
    password,
  }: {
    email: string;
    password: string;
  }): void {
    this.http.post(
      `${this.baseUrl}api/user/authenticate`,
      {
        email,
        password,
      },
      { headers: this.headers }
    );
  }

  /**
   * Authenticates an admin in the backend, given that the user email-password combination is ok
   * @param email user email
   * @param oldPassword old password
   * @param newPassword new password
   */
  changePasswordPromise({
    email,
    oldPassword,
    newPassword,
  }: {
    email: string;
    oldPassword: string;
    newPassword: string;
  }): Promise<any> {
    return this.http
      .post(
        `${this.baseUrl}api/user/password/reset`,
        {
          email: email,
          old_password: oldPassword,
          new_password: newPassword,
        },
        { headers: this.headers }
      )
      .toPromise();
  }

  /**
   *
   * @param email user email
   */
  forgotPasswordPromise({ email }: { email: string }): Promise<any> {
    return this.http
      .post(
        `${this.baseUrl}api/user/password/forgot`,
        {
          email: email,
        },
        { headers: this.headers }
      )
      .toPromise();
  }

  /**
   * Logs the user out from the application
   */
  logout(): void {
    this.cookieService.delete("flexpot-user");
    this.cookieService.delete("flexpot-token");
    localStorage.removeItem("flexpot-user");
    localStorage.removeItem("flexpot-token");
  }

  /** Stores the user credentials in the cookies and in the localStorage */
  public saveCredentials({
    user,
    token,
  }: {
    user: string;
    token: string;
  }): void {
    this.cookieService.set("flexpot-user", user);
    this.cookieService.set("flexpot-token", token);
    localStorage.setItem("flexpot-user", user);
    localStorage.setItem("flexpot-token", token);
  }

  /** Returns the user that is currently logged in or null if there isn't a user logged in */
  getUser(): any {
    if (isPlatformBrowser(this.platformId) && this.loggedIn.value) {
      return JSON.parse(localStorage.getItem("flexpot-user"));
    } else {
      return null;
    }
  }

  /** Returns the token that is currently logged in or null if there isn't a user logged in */
  getToken(): any {
    if (isPlatformBrowser(this.platformId) && this.loggedIn.value) {
      return localStorage.getItem("flexpot-token");
    } else {
      return null;
    }
  }

  public storeUserData(token, user) {
    this.cookieService.set("flexpot-user", JSON.stringify(user));
    this.cookieService.set("flexpot-token", token);
    localStorage.setItem("flexpot-user", JSON.stringify(user));
    localStorage.setItem("flexpot-token", token);
  }

  public validateJwtExpired(JWT) {
    this.headers = new HttpHeaders({ "Content-Type": "application/json" });
    return this.http.post(
      `${this.baseUrl}api/user/validate-JWT-expired`,
      { JWT: JWT },
      {
        headers: this.headers,
      }
    );
  }
}
