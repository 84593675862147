import { StatsComponent } from "./public/stats/stats.component";
import { ComponentsModule } from "./components/components.module";
import { BrowserModule } from "@angular/platform-browser";
import { LOCALE_ID, NgModule } from "@angular/core";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatCardModule } from "@angular/material/card";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatDialogModule } from "@angular/material/dialog";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import { HomeComponent } from "./public/home/home.component";
import { NavbarComponent } from "./public/shared/navbar/navbar.component";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { MatTableModule } from "@angular/material/table";
import { FooterComponent } from "./public/shared/footer/footer.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { LoginComponent } from "./public/auth/login/login.component";
import { SuccessComponent } from "./public/shared/success/success.component";
import { MyAccountComponent } from "./public/my-account/my-account.component";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { ContactComponent } from "./public/contact/contact.component";
import { WarehouseCreateComponent } from "./public/warehouse/warehouse-create/warehouse-create.component";
import { AgmCoreModule } from "@agm/core";
import { environment } from "src/environments/environment";
import { DeleteConfirmComponent } from "./public/shared/delete-confirm/delete-confirm.component";
import { TokenInterceptor } from "./public/interceptors/token.interceptor";
import { SearchComponent } from "./public/search/search.component";
import {
  NbThemeModule,
  NbLayoutModule,
  NbSidebarModule,
  NbDialogModule,
  NbCardModule,
  NbIconModule,
  NbSpinnerModule,
} from "@nebular/theme";
import { AgmOverlays } from "agm-overlays";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatSortModule } from "@angular/material/sort";
import { CalculaEspacioComponent } from "./calcula-espacio/calcula-espacio.component";
import { CommonModule, registerLocaleData, SlicePipe } from "@angular/common";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { MatSliderModule } from "@angular/material/slider";
import { NgxSliderModule } from "@angular-slider/ngx-slider";
import { MyWarehousesComponent } from "./public/warehouse/my-warehouses/my-warehouses.component";
import { ChartsModule } from "ng2-charts";
import { BarChartComponent } from "./public/shared/charts/bar-chart/bar-chart.component";
import { LineChartComponent } from "./public/shared/charts/line-chart/line-chart.component";
import { PieChartComponent } from "./public/shared/charts/pie-chart/pie-chart.component";
import { NgCircleProgressModule } from "ng-circle-progress";
import { SuccessReservaComponent } from "./public/success-reserva/success-reserva.component";
import { ReservasComponent } from "./public/reservas/reservas.component";
import { DatePipe } from "@angular/common";
import { NgxDaterangepickerMd } from "ngx-daterangepicker-material";
import { ReservasDetailComponent } from "./reservas-detail/reservas-detail.component";
import { CreditCardDirectivesModule } from "angular-cc-library";
import { Ng2SmartTableModule, LocalDataSource } from "ng2-smart-table";
import {
  NbDatepickerModule,
  NbMenuModule,
  NbToastrModule,
  NbWindowModule,
} from "@nebular/theme";
import { ToastrModule } from "ngx-toastr";
import { NbEvaIconsModule } from "@nebular/eva-icons";
import { ReservationAcceptedComponent } from "./public/reservation-accepted/reservation-accepted.component";
import { NotFoundComponent } from "./public/not-found/not-found.component";
import { PagosHistorialComponent } from "./pagos-historial/pagos-historial.component";
import { InventoryReserveComponent } from "./components/reserve/inventory-reserve/inventory-reserve.component";
import { ReservasMineComponent } from "./public/reservas-mine/reservas-mine.component";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MisBodegasDetailComponent } from "./public/mis-bodegas-detail/mis-bodegas-detail.component";
import { UrlService } from "./public/services/url.service";
import { NgxPaginationModule } from "ngx-pagination";
import { ForgotPasswordComponent } from "./public/auth/forgot-password/forgot-password.component";
import { EditWarehouseComponent } from "./edit-warehouse/edit-warehouse.component";
import { ReservasHistoryComponent } from "./reservas-history/reservas-history.component";
import { NgxLoadingModule } from "ngx-loading";
import { MyMessagesComponent } from "./public/my-messages/my-messages.component";
import { MessageItemComponent } from "./public/my-messages/message-item/message-item.component";
import { PaymentReservationsComponent } from "./payment-reservations/payment-reservations.component";
import localeZh from "@angular/common/locales/es-CO";
import { SharedModule } from "./shared/shared.module";

registerLocaleData(localeZh);
@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    NavbarComponent,
    FooterComponent,
    LoginComponent,
    SuccessComponent,
    MyAccountComponent,
    ContactComponent,
    WarehouseCreateComponent,
    DeleteConfirmComponent,
    SearchComponent,
    CalculaEspacioComponent,
    MyWarehousesComponent,
    BarChartComponent,
    PieChartComponent,
    LineChartComponent,
    SuccessReservaComponent,
    ReservasComponent,
    PaymentReservationsComponent,
    ReservasDetailComponent,
    InventoryReserveComponent,
    ReservationAcceptedComponent,
    NotFoundComponent,
    PagosHistorialComponent,
    ReservasMineComponent,
    MisBodegasDetailComponent,
    ForgotPasswordComponent,
    EditWarehouseComponent,
    ReservasHistoryComponent,
    MyMessagesComponent,
    MessageItemComponent,
    StatsComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: "serverApp" }),
    AppRoutingModule,
    ChartsModule,
    SharedModule,
    FontAwesomeModule,
    NbIconModule,
    NbEvaIconsModule,
    BrowserAnimationsModule,
    MatProgressBarModule,
    MatCardModule,
    MatTableModule,
    NgxLoadingModule.forRoot({}),
    ReactiveFormsModule,
    Ng2SmartTableModule,
    MatExpansionModule,
    MatCheckboxModule,
    MatDialogModule,
    NgbModule,
    HttpClientModule,
    MatProgressSpinnerModule,
    NbSpinnerModule,
    FormsModule,
    CommonModule,
    NbLayoutModule,
    AgmOverlays,
    NbCardModule,
    MatFormFieldModule,
    MatDatepickerModule,
    NgxPaginationModule,
    NbMenuModule.forRoot(),
    NgxSliderModule,
    NbSidebarModule,
    MatSliderModule,
    AgmCoreModule.forRoot({
      apiKey: environment.MAPS_KEY,
    }),
    MatPaginatorModule,
    MatSortModule,
    CreditCardDirectivesModule,
    NgxDaterangepickerMd.forRoot(),
    // NbDialogModule.forRoot(),
    // NbThemeModule.forRoot()
    ToastrModule.forRoot(),
    // Specify ng-circle-progress as an import
    NgCircleProgressModule.forRoot({
      // set defaults here
      backgroundOpacity: 1,
      backgroundColor: "transparent",
      radius: 30,
      outerStrokeColor: "#271252",
      innerStrokeColor: "#FFD772",
      space: -9,
      outerStrokeWidth: 16,
      innerStrokeWidth: 8,
      imageHeight: 50,
      imageWidth: 50,
      showSubtitle: false,
      showInnerStroke: false,
      responsive: true,
    }),
    ComponentsModule,
  ],
  providers: [
    // NbThemeModule.forRoot().providers,
    DatePipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    UrlService,
    { provide: LOCALE_ID, useValue: "es-CO" },
  ],
  exports: [MatExpansionModule],
  bootstrap: [AppComponent],
})
export class AppModule {}
