import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import {
  faChevronLeft,
  faSearch,
  faCalendarAlt,
  faCreditCard,
} from "@fortawesome/free-solid-svg-icons";
import { NbIconLibraries } from "@nebular/theme";
import { NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";
import { LocalDataSource, Ng2SmartTableModule } from "ng2-smart-table";
import { ApiService } from "../public/services/api.service";
@Component({
  selector: "app-pagos-historial",
  templateUrl: "./pagos-historial.component.html",
  styleUrls: ["./pagos-historial.component.scss"],
})
export class PagosHistorialComponent implements OnInit {
  /**FONT AWESOME ICONS */
  faCreditCard = faCreditCard;
  faChevronLeft = faChevronLeft;
  faCalendarAlt = faCalendarAlt;
  faSearch = faSearch;

  /**SmartTable settings and values */
  public settings: any = {};
  public source: LocalDataSource;
  public selectedIds: any = {};

  closeResult = "";
  API_PAGOS = "api/pago/reservas";
  pagos: any = {};
  searchBar: any = "";

  pagosValor = [];

  constructor(
    private activatedRoute: ActivatedRoute,
    private iconLibraries: NbIconLibraries,
    private apiService: ApiService,
    private router: Router,
    private modalService: NgbModal
  ) {
    this.iconLibraries.registerFontPack("font-awesome", {
      iconClassPrefix: "fa",
      packClass: "fas",
    });
    let id = this.activatedRoute.snapshot.params["id"];
    this.getPagos({ id });
  }

  ngOnInit(): void {
    this.configSmartTable();
  }

  /**
   * Function to format the column Monto as currency
   * @param currencyWithOutFormat //Data without currency format
   * @returns //Currency-formatted data
   */
  public currencyFormatCOP(currencyWithOutFormat: any) {
    return currencyWithOutFormat.toLocaleString("en-US", {
      style: "currency",
      currency: "COP",
    });
  }

  /**
   * Handles closing the modals with different options
   * @param reason how I am closing the modals
   */
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }
  /**Handles opening modals */
  openModal(content) {
    this.modalService
      .open(content, {
        windowClass: "gr-modal-full",
        ariaLabelledBy: "modal-basic-title",
      })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }
  /**
   * Method that checks if the ususario is aprobado, to use the method that changes the status of the user.
   * @param event Event of action in the table
   */
  public onCustom(event: any): void {
    if (event.action === "look") {
      // this.changeWarehouseStatus(event);
    } else {
      // this.changeWarehouseStatusRechazado(event);
    }
  }
  public getPagos({ id }: { id: string }) {
    this.apiService
      .get({ api: `${this.API_PAGOS}/${id}` })
      .subscribe((pagos) => {
        this.pagos = pagos;
        if (pagos) {
          this.source = new LocalDataSource(pagos);
          let selectedPagos = this.pagos.map(({ totalPago }) => totalPago);
          this.pagosValor = Object.values(selectedPagos);
        }
      });
  }
  private configSmartTable(): void {
    this.settings = {
      mode: "inline",
      pager: {
        display: true,
        perPage: 8,
      },
      hideSubHeader: false,
      actions: {
        columnWidth: "50px",
        columnTitle: "Acciones",
        add: false,
        edit: false,
        delete: false,
        custom: [
          {
            name: "look",
            title: '<i class="fa fa-eye" aria-hidden="true"></i>',
          },
        ],
      },
      columns: {
        fechaCobro: {
          title: "Fecha de pago",
          editable: true,
          filter: true,
        },
        estado: {
          title: "Estado",
          editable: true,
          filter: true,
        },
        totalPago: {
          title: "Monto",
          valuePrepareFunction: (data) => {
            return this.currencyFormatCOP(data);
          },
        },
      },
      rowClassFunction: (row) => {
        if (row.data.estado == "aprobada") {
          //aprobado
          return "active";
        }
      },
    };
  }
  public onGoingBack(id) {
    this.router.navigate(["otras-reservas/" + id]);
  }
}
