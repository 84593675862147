import { Location } from "@angular/common";
import { InventoryService } from "src/app/public/services/inventory.service";
import { Component, OnInit } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { ActivatedRoute, Router } from "@angular/router";
import { MovementService } from "src/app/public/services/movement.service";
import { ModalDismissReasons, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { faQuestion } from "@fortawesome/free-solid-svg-icons";
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
} from "@angular/forms";
import { MyWarehousesService } from "../../public/services/my-warehouses.service";
import { ApiService } from "src/app/public/services/api.service";
import { HttpClient, HttpParams } from "@angular/common/http";
import { NavigationService } from "src/app/public/services/navigation.service";
@Component({
  selector: "app-movement",
  templateUrl: "./movement.component.html",
  styleUrls: ["./movement.component.scss"],
})
export class MovementComponent implements OnInit {
  /**Reservation */
  public reservation: any = [];
  // This is a dictionary with element to create a flow to remove
  public inventoryToRemove: any = {};
  // This is a var with selection of management
  public managementOption: string = "flexpot";
  // this variable is associated with the city entered by the user
  public cityOption: string = "";
  // this variable is associated with the adress entered by the user
  public address: string = "";
  // this variable is associated with the full name entered by the user
  public fullName: string = "";
  // this variable is associated with the mobile entered by the user
  public mobile: string = "";
  /**KG price */
  public costoKg: number = 0;
  // This is a var with reserve id
  public reserveId: string = "";
  // The number of elements of each product
  public itemsToMoveTotal: number = 0;
  // Variable that signals if the component is loading
  public loading: boolean = false;
  public faQuestion = faQuestion;
  public errorToSave: boolean = false;
  public errorMessage: string = "";
  /**Variables for locations */
  public paises: any = [];
  public ciudadOrigen: any = "";
  /**Variables to calculate the transportation costs */
  public factorConversionValue: any = "";
  public porcentajeSeguroValue: any = "";
  /**API VARIABLES */
  public PRICE_API = "api/costo-transporte/origen/destinos";
  // FORMS
  public dataInformation: FormGroup;
  public dataInformationExt: FormGroup;
  public shippingCost: FormGroup;
  public totalTransportation: number = 0;
  public totalTransportationValidation = false;
  public pesoVolumetrico = 0;
  public citiesList: any = "";
  /** Function to remplace especial charaters and letters of string */
  formatterInputNumbers = (baseText) =>
    baseText.replace(/[^\w\s]/gi, "").replace(/\D/g, "");

  public wompiError: boolean = false;

  constructor(
    private inventoryService: InventoryService,
    private movementService: MovementService,
    private location: Location,
    private toastr: ToastrService,
    private navigationService: NavigationService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private mywarehouseService: MyWarehousesService,
    private apiService: ApiService
  ) {
    this.reserveId = this.activatedRoute.snapshot.params["id"];
    this.getReservationById({ id: this.reserveId });
    this.inventoryToRemove = inventoryService.inventoryToRemove.slice(); // Clone object
    this.itemsToMoveTotal = inventoryService.itemsToMoveTotal;
    this.getOtrosByTipo();
    this.dataInformation = this.formBuilder.group({
      cityOption: ["", Validators.required],
      address: ["", Validators.required],
      fullName: ["", Validators.required],
      mobile: ["", Validators.required],
    });
    this.dataInformationExt = this.formBuilder.group({
      date: ["", Validators.required],
      hour: ["", Validators.required],
      fullName: ["", Validators.required],
      mobile: ["", Validators.required],
    });
    this.shippingCost = this.formBuilder.group({
      peso: ["", Validators.required],
      volumen: ["", Validators.required],
      valorMercancia: ["", Validators.required],
    });
  }

  ngOnInit(): void {}

  /** On Funtions */

  /**
   * This method is in charge of opening the confirmation modal to send the data to the back
   * @param content modal
   */
  public onClick({ content }: { content: any }): void {
    if (
      this.dataInformation.valid &&
      this.inventoryToRemove.length !== 0 &&
      this.shippingCost.valid
    ) {
      if (this.totalTransportation === 0) {
        alert("Por favor calcula el costo de transporte");
      } else {
        this.loading = true;
        this.onOpenModal({ content: content, type: "checkout" });
      }
    } else {
      if (this.inventoryToRemove.length === 0) {
        alert("Por favor agregue inventario a retirar.");
      }
      this.dataInformation.markAllAsTouched();
      this.shippingCost.markAllAsTouched();
    }
  }

  public async getReservationById({ id }) {
    this.reservation = await this.mywarehouseService.getReservationById({
      id: id,
    });
    this.ciudadOrigen = this.reservation.bodega.ciudad;
    let params = new HttpParams().set("origen", this.ciudadOrigen);
    // .set("destino", ciudadDestino);
    this.apiService
      .getWithParams({ api: `${this.PRICE_API}`, queryParams: params })
      .subscribe(
        (ciudades) => {
          console.log(ciudades);
          this.citiesList = ciudades.filter((c) => c.costo != ("" || 0));
          console.log(this.citiesList);
        },
        (err) => {
          this.citiesList = [];
        }
      );
  }

  public async getOtrosByTipo() {
    let factorConversion = await this.mywarehouseService.getOtrosByTipo({
      tipo: "factorConversion",
    });
    this.factorConversionValue = factorConversion.valor;
    let porcentajeSeguro = await this.mywarehouseService.getOtrosByTipo({
      tipo: "seguroInventario",
    });
    this.porcentajeSeguroValue = porcentajeSeguro.valor;
    // this.warehouseService.getS
  }

  public getPriceBodega(event) {
    let costo = event.target.value;
    this.costoKg = costo;
    this.onCalculateCosto();
  }
  /**
   * To go to the previous view
   */
  public onBackClicked(): void {
    this.inventoryService.inventoryToRemove = [];

    this.router.navigate(["inventario/" + this.reserveId]);
  }

  /**
   * To go to the previous view
   */
  public onModClicked(): void {
    this.inventoryService.inventoryToRemove = this.inventoryToRemove;
    this.router.navigate(["inventario/" + this.reserveId]);
  }

  /**
   * This method removes the order element from the dictionary.
   * @param key item identifier
   */
  public onRemoveItemDelete({ index }: { index: number }): void {
    this.itemsToMoveTotal -= this.inventoryToRemove[index].cantidadAgregada;
    this.inventoryToRemove.splice(index, 1);
  }

  /**
   * This method takes care of increasing the quantity to add
   * @param key item identifier
   */
  public onRemoveItemAdd({ index }: { index: number }): void {
    this.inventoryToRemove[index].cantidadAgregada += 1;
    this.itemsToMoveTotal++;
  }

  /**
   * This method takes care of decreasing the quantity to add
   * @param key item identifier
   */
  public onRemoveItemLess({ index }: { index: number }): void {
    this.inventoryToRemove[index].cantidadAgregada -= 1;
    this.itemsToMoveTotal--;
  }

  public onChangeSelect() {
    if (this.managementOption === "flexpot") {
      this.toastr.info(
        "Será el tiempo que estará tu bodega habilitada en el sistema inicialmente, luego podras irla actualizando segun como se vaya moviendo en el sistema.",
        "Gestion Flexpot"
      );
    }
  }

  /**Handles opening the modal */
  public onOpenModal({ content, type }: { content: any; type: string }) {
    this.modalService
      .open(content, { ariaLabelledBy: "modal-basic-title" })
      .result.then(
        (result) => {},
        (reason) => {
          this.dismissReasonAction({ reason: reason });

          if (type === "succesOrFail") {
            this.onDecision();
          }
        }
      );
  }

  public onDecision() {
    if (!this.errorToSave) {
      this.router.navigate(["otras-reservas/" + this.reserveId]);
      // Clear data inventory
      this.inventoryService.inventoryToRemove = [];
    }
    this.loading = false;
  }

  /** Main Funtions */

  /**Handles closing the modal */
  private dismissReasonAction({ reason }: { reason: any }): string {
    if (
      reason === ModalDismissReasons.ESC ||
      reason === ModalDismissReasons.BACKDROP_CLICK ||
      reason === "Cross cancel" ||
      reason === "Cross exit"
    ) {
      this.loading = false;
      return `with: ${reason}`;
    } else {
      return `with: ${reason}`;
    }
  }

  public fieldIsInvalid({ field }: { field: string }) {
    return (
      this.dataInformation.get(field)?.touched &&
      this.dataInformation.get(field)?.invalid
    );
  }

  public fieldExIsInvalid({ field }: { field: string }) {
    return (
      this.dataInformationExt.get(field)?.touched &&
      this.dataInformationExt.get(field)?.invalid
    );
  }

  public costIsInvalid({ field }: { field: string }) {
    return (
      this.shippingCost.get(field)?.touched &&
      this.shippingCost.get(field)?.invalid
    );
  }

  public onValidateSpecialCharacters({ type }) {
    if (type == "phone") {
      let format = this.dataInformation.get("mobile").value;
      format = this.formatterInputNumbers(format);
      this.dataInformation.patchValue({ mobile: format });
    } else if (type == "peso") {
      let format = this.shippingCost.get("peso").value;
      format = this.formatterInputNumbers(format);
      this.shippingCost.patchValue({ peso: format });
      format = this.formatterInputNumbers(format);
    } else if (type == "volumen") {
      let format = this.shippingCost.get("volumen").value;
      format = this.formatterInputNumbers(format);
      this.shippingCost.patchValue({ volumen: format });
    } else if (type == "valor") {
      let format = this.shippingCost.get("valorMercancia").value;
      format = this.formatterInputNumbers(format);
      this.shippingCost.patchValue({ valorMercancia: format });
    }
  }

  public onCalculateCosto() {
    let form = this.shippingCost.value;
    if (this.shippingCost.valid && this.costoKg) {
      // TODO ajustar parametros en el admin para poder editarlos
      let factorConversion = this.factorConversionValue;
      // peso volumetrico = volumen * factor de conversion (400)
      this.pesoVolumetrico = form.volumen * factorConversion;
      let peso = form.peso;
      let valorMercancia = form.valorMercancia;
      let valorSeguro = this.porcentajeSeguroValue / 100;
      //Peso volumetrico
      let max = Math.max(this.pesoVolumetrico, peso);
      let costoKg = this.costoKg;
      let parcialTotal = max * costoKg + valorMercancia * valorSeguro;
      this.totalTransportation = parcialTotal > 20000 ? parcialTotal : 20000;
      this.totalTransportationValidation = true;
    } else if (this.costoKg === 0) {
      alert(
        "No es posible realizar el calculo, por favor selecciona un departamento"
      );
      this.totalTransportation = 0;
    }
  }
  /**
   * This method is in charge of calling the service and making the request to the back
   * @param content modal
   */
  public async putDataInBack({ content }: { content: any }): Promise<void> {
    let valuesForm = this.dataInformation.value;
    let data = {
      productos: this.inventoryToRemove,
      city: valuesForm.cityOption,
      address: valuesForm.address,
      destinatario: valuesForm.fullName,
      mobile: valuesForm.mobile,
      costoTransporte: this.totalTransportation,
      pago: true,
    };
    this.apiService
      .post({
        api: `api/ordenRetiro/reserva/${this.reserveId}`,
        data: data,
      })
      .subscribe(
        (response) => {
          this.errorToSave = false;
          this.inventoryService.inventoryToRemove = [];
          this.inventoryService.itemsToMoveTotal = 0;
          this.onOpenModal({ content: content, type: "succesOrFail" });
        },
        (err) => {
          this.wompiError = err?.error?.type && err?.error?.type == "Wompi";
          this.errorToSave = true;
          this.inventoryService.inventoryToRemove = [];
          this.inventoryService.itemsToMoveTotal = 0;
          this.errorMessage = err.error.mensaje;
          this.onOpenModal({ content: content, type: "succesOrFail" });
        }
      );
  }

  public onGoToProfile() {
    this.navigationService.navigateTo({ path: "cuenta" });
  }
}
