import {Injectable} from "@angular/core";
import {ApiService} from "./api.service";

@Injectable({
  providedIn: "root"
})
export class ReservationAcceptedService {
  RESERVE_API = "api/reserva/";
  INVETORY_API = "api/inventario/reserva/";
  MOVEMENT_ADD_API = "api/movimientoAgregar/reserva/";
  MOVEMENT_REMOVE_API = "api/ordenRetiro/reserva/";
  MOVEMENT_API = "api/movimientoAgregar/";
  REMOVE_API = "api/ordenRetiro/";
  SPACES_API = "api/espacio/bodega/";
  ORDERS_API = "api/ordenRetiro/reserva/";
  POSITIONS_CHANGE_API = "api/reserva/posiciones/";
  constructor(private apiService: ApiService) {}

  /**
   * It is in charge of calling the api service to make the get request to obtain the reserve.
   * @param param0 Receives a string is id of reserve
   * @returns The reserve obtained from the back
   */
  getReserve({id}: {id: string}): any {
    return this.apiService.getAsPromise({api: `${this.RESERVE_API}${id}`});
  }

  /**
   * It is in charge of calling the api service to make the get request to obtain the inventory of reserve.
   * @param param0 Receives a string is id of reserve
   * @returns The iventory obtained from the back
   */
  getInventory({id}: {id: string}): any {
    return this.apiService.getAsPromise({api: `${this.INVETORY_API}${id}`});
  }

  /**
   * It is in charge of calling the api service to make the get request to obtain the add movements of reserve.
   * @param param0 Receives a string is id of reserve
   * @returns The add movements obtained from the back
   */
  getMovement({id}: {id: string}): any {
    return this.apiService.getAsPromise({api: `${this.MOVEMENT_API}${id}`});
  }
  /**
   * It is in charge of calling the api service to make the get request to obtain the add movements of reserve.
   * @param param0 Receives a string is id of reserve
   * @returns The add movements obtained from the back
   */
  getMovementRetiro({id}: {id: string}): any {
    return this.apiService.getAsPromise({api: `${this.REMOVE_API}${id}`});
  }
  /**
   * It is in charge of calling the api service to make the get request to obtain the add movements of reserve.
   * @param param0 Receives a string is id of reserve
   * @returns The add movements obtained from the back
   */
  getRemovebyId({id}: {id: string}): any {
    return this.apiService.getAsPromise({api: `${this.REMOVE_API}${id}`});
  }
  /**
   * It is in charge of calling the api service to make the get request to obtain the add movements of reserve.
   * @param param0 Receives a string is id of reserve
   * @returns The add movements obtained from the back
   */
  getMovementsAdd({id}: {id: string}): any {
    return this.apiService.getAsPromise({api: `${this.MOVEMENT_ADD_API}${id}`});
  }

  /**
   * It is in charge of calling the api service to make the get request to obtain the remove movements of reserve.
   * @param param0 Receives a string is id of reserve
   * @returns The remove movements obtained from the back
   */
  getMovementsRemove({id}: {id: string}): any {
    return this.apiService.getAsPromise({api: `${this.MOVEMENT_REMOVE_API}${id}`});
  }
  /**
   * It is in charge of calling the api and getting the spaces by warehouse ID
   * @param param0 Receives a string is id of reservation
   * @param any
   * @returns
   */
  getSpacesByWarehouseId({id}: {id: string}): any {
    return this.apiService.getAsPromise({api: `${this.SPACES_API}${id}`});
  }
  /**
   * It is in charge of calling the api and getting the order by reservation ID
   * @param param0 Receives a string is id of reservation
   * @param any
   * @returns
   */
  getOrderByReservationId({id}: {id: string}): any {
    return this.apiService.getAsPromise({api: `${this.ORDERS_API}${id}`});
  }
  /**Change ther #of positions using an reservation id  */
  public changeNumberPositions({id,data}: {id:any,data: any}): Promise<any> {
    return this.apiService.putPromise({api: `${this.POSITIONS_CHANGE_API}${id}`, data: data});
  }
}
