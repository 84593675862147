<div class="reservas">
  <!-- OTROS ESPACIOS SECTION -->
  <div class="reservasOtros" *ngIf="otros">
    <h1 class="main__title">Reservas</h1>
    <div class="reservas--navbar">
      <button
        (click)="onClickMios()"
        class="reservas--navbar__mine"
        [style]="mios ? 'color: white' : ''"
      >
        <p>De mis espacios</p>
      </button>
      <button
        (click)="onClickOtros()"
        class="reservas--navbar__other"
        [style]="otros ? 'color: white' : ''"
      >
        <p>A otros espacios</p>
      </button>
    </div>
    <div class="reservas--main">
      <div class="reservas--main--left">
        <div class="reservas--main--left--navbar">
          <div class="reservas--main--left--navbar--search--flex no-space">
            <fa-icon class="searchIcon" [icon]="faSearch"></fa-icon>
            <input
              class="reservas--main--left--navbar--search"
              type="text"
              placeholder="Buscar"
              [(ngModel)]="searchBar"
            />
          </div>
          <div class="reservas--main--left--navbar--select--flex left-space">
            <input
              placeholder="Fecha"
              type="text"
              ngxDaterangepickerMd
              [(ngModel)]="selectedDate"
              (change)="onChangeDate($event)"
              class="form-control"
            />
          </div>
          <div class="reservas--main--left--navbar--select--flex left-space">
            <select
              (change)="onEstadoSelected($event)"
              name="Estado"
              id=""
              class="reservas--main--left--navbar--select"
            >
              <option value="" selected disabled>Estado</option>
              <option value="aceptado">Aceptadas</option>
              <option value="pendiente">Pendientes</option>
              <option value="anticipado">Anticipadas</option>
              <option value="rechazado">Rechazadas</option>
              <option value="pagado">Pagado</option>
            </select>
          </div>
        </div>
        <div class="reservas--main--left--reservas">
          <div
            *ngIf="viewReservasTenant.length === 0"
            class="reservas--main--left--reservas--empty right space-left"
          >
            <div class="box--empty">
              <fa-icon [icon]="faBoxOpen" class="icon-caja"></fa-icon>
            </div>
            <p class="nothing">Nada por aquí</p>
          </div>
          <mat-card
            *ngFor="
              let reserva of viewReservasTenant | search: searchBar;
              let i = index
            "
            (click)="onReservation({ id: reserva._id })"
          >
            <div class="reservas--main--left--reservas--title">
              <mat-card-header>
                <div>
                  <mat-card-title>
                    <!-- <p>{{reserva.estado}}</p> -->
                    <div class="reservas--main--left--reservas--title--space">
                      <div
                        class="reservas--main--left--reservas--title--space--flex"
                      >
                        <p>Espacio {{ reserva.bodega.nombre }}</p>
                        <fa-icon
                          (click)="onReservation({ id: reserva._id })"
                          [icon]="faChevronRight"
                          class="right--arrow"
                        ></fa-icon>
                      </div>
                      <div
                        class="reservas--main--left--reservas--title--space--status positions"
                      >
                        <div
                          *ngIf="reserva.estado !== 'aceptado'"
                          class="reservas--main--left--reservas--title--space--status--number__positions"
                        >
                          <p>{{ reserva.espacios[0].cantidad }} posiciones</p>
                        </div>
                        <div
                          *ngIf="reserva.estado == 'aceptado'"
                          class="reservas--main--left--reservas--title--space--status--number__positions--red"
                        >
                          <p>Pago pendiente</p>
                        </div>

                        <div
                          class="reservas--main--left--reservas--title--space--status--movimientos"
                        >
                          <!-- <fa-icon [icon]="faSignInAlt"></fa-icon> -->
                          <img
                            src="../../../assets/Utils/movimiento.png"
                            class="upload-image"
                          />
                          <p>0 movimientos</p>
                        </div>
                      </div>
                    </div>
                  </mat-card-title>
                </div>
              </mat-card-header>
            </div>
            <div class="reservas--main--left--reservas--content">
              <mat-card-content>
                <div class="reservas--main--left--reservas--content--fecha">
                  <div
                    class="reservas--main--left--reservas--content--fecha--inicio"
                  >
                    <p class="title">Desde:</p>
                    <p>{{ reserva.fechaInicio }}</p>
                  </div>
                  <div
                    class="reservas--main--left--reservas--content--fecha--line"
                  ></div>
                  <div
                    class="reservas--main--left--reservas--content--fecha--inicio"
                  >
                    <p class="title">Hasta:</p>
                    <p>{{ reserva.fechaFinal }}</p>
                  </div>
                </div>
              </mat-card-content>
            </div>
          </mat-card>
        </div>
      </div>
      <div class="reservas--main--right">
        <div class="reservas--main--right--title">
          <h1>Solicitudes</h1>
          <p>{{ counterActive }} pendientes</p>
        </div>
        <div class="reservas--main--right--main">
          <div
            *ngIf="viewReservasTenant.length === 0"
            class="reservas--main--left--reservas--empty right"
          >
            <div class="box--empty">
              <fa-icon [icon]="faBoxOpen" class="icon-caja"></fa-icon>
            </div>
            <p class="nothing">Nada por aquí</p>
          </div>
          <mat-card
            *ngFor="let reserva of viewReservasTenant; let i = index"
            class="border--right--main no-border"
          >
            <div>
              <div class="reservas--main--left--reservas--title">
                <mat-card-header>
                  <div>
                    <mat-card-title>
                      <div class="reservas--main--left--reservas--title--space">
                        <p>Espacio {{ reserva.bodega.nombre }}</p>

                        <div
                          class="reservas--main--left--reservas--title--space--status"
                        >
                          <div
                            *ngIf="reserva.estado !== 'aceptado'"
                            class="reservas--main--left--reservas--title--space--status--number__positions"
                          >
                            <p>{{ reserva.espacios[0].cantidad }} posiciones</p>
                          </div>
                          <div
                            *ngIf="reserva.estado == 'aceptado'"
                            class="reservas--main--left--reservas--title--space--status--number__positions--red"
                          >
                            <p>Pago pendiente</p>
                          </div>
                          <div
                            *ngIf="reserva.estado == 'pendiente'"
                            class="reservas--main--left--reservas--title--space--status--status"
                          >
                            <fa-icon
                              [icon]="faCircle"
                              class="red--circle"
                            ></fa-icon>
                            <p>Pendiente</p>
                          </div>
                          <div
                            *ngIf="reserva.estado == 'rechazado'"
                            class="reservas--main--left--reservas--title--space--status--status"
                          >
                            <fa-icon
                              [icon]="faCircle"
                              class="red--circle"
                            ></fa-icon>
                            <p>Rechazado</p>
                          </div>
                          <div
                            *ngIf="reserva.estado == 'aceptado'"
                            class="reservas--main--left--reservas--title--space--status--status"
                          >
                            <fa-icon
                              [icon]="faCheckCircle"
                              class="green--circle"
                            ></fa-icon>
                            <p class="green--circle aprobado">Aprobado</p>
                          </div>
                          <div
                            *ngIf="reserva.estado == 'pagado'"
                            class="reservas--main--left--reservas--title--space--status--status"
                          >
                            <fa-icon
                              [icon]="faCheckCircle"
                              class="green--circle"
                            ></fa-icon>
                            <p class="green--circle aprobado">Pagado</p>
                          </div>
                          <div
                            *ngIf="reserva.estado == 'anticipado'"
                            class="reservas--main--left--reservas--title--space--status--status"
                          >
                            <fa-icon
                              [icon]="faCheckCircle"
                              class="green--circle"
                            ></fa-icon>
                            <p class="green--circle aprobado">Anticipado</p>
                          </div>
                        </div>
                      </div>
                    </mat-card-title>
                  </div>
                </mat-card-header>
              </div>
              <div class="reservas--main--left--reservas--content">
                <mat-card-content>
                  <div
                    class="reservas--main--left--reservas--content--fecha reduced"
                  >
                    <div
                      class="reservas--main--left--reservas--content--fecha--inicio"
                    >
                      <p class="title">Desde:</p>
                      <p>{{ reserva.fechaInicio }}</p>
                    </div>
                    <div
                      class="reservas--main--left--reservas--content--fecha--line"
                    ></div>
                    <div
                      class="reservas--main--left--reservas--content--fecha--inicio"
                    >
                      <p class="title">Hasta:</p>
                      <p>{{ reserva.fechaFinal }}</p>
                    </div>
                  </div>
                  <div
                    class="reservas--main--left--reservas--footer"
                    *ngIf="reserva.estado == 'aceptado'"
                  >
                    <button
                      class="reservas--main--left--reservas--footer__button"
                      (click)="onOpenModalPago(pago, i)"
                    >
                      Pagar y alquilar
                    </button>
                  </div>
                </mat-card-content>
              </div>
            </div>
          </mat-card>
        </div>
      </div>
    </div>
  </div>
  <!-- MIS ESPACIOS SECTION -->
  <div class="reservasMios" *ngIf="mios">
    <h1 class="main__title">Reservas</h1>
    <div class="reservasMine--navbar">
      <div class="reservas--navbar">
        <button (click)="onClickMios()" class="reservas--navbar__other">
          <p>De mis espacios</p>
        </button>
        <button (click)="onClickOtros()" class="reservas--navbar__mine">
          <p>A otros espacios</p>
        </button>
      </div>
      <div class="reservas--navbar--history">
        <fa-icon [icon]="faFilm"></fa-icon>
        <button (click)="onReservas()" class="historyCheck">
          Historial de reservas pasadas
        </button>
      </div>
    </div>
    <div class="reservas--main">
      <div class="reservas--main--left">
        <div class="reservas--main--left--navbar">
          <div class="reservas--main--left--navbar--search--flex no-space">
            <fa-icon class="searchIcon" [icon]="faSearch"></fa-icon>
            <input
              class="reservas--main--left--navbar--search"
              type="text"
              placeholder="Buscar"
              [(ngModel)]="searchBarOwner"
            />
          </div>
          <div class="reservas--main--left--navbar--select--flex left-space">
            <select
              (change)="onBodegaSelected($event)"
              name="fechas"
              class="reservas--main--left--navbar--select"
            >
              <option value="" selected disabled>Bodegas</option>
              <option
                *ngFor="let reserva of reservasOwner"
                value="{{ reserva.bodega.nombre }}"
              >
                {{ reserva.bodega.nombre }}
              </option>
            </select>
          </div>
          <div class="reservas--main--left--navbar--select--flex left-space">
            <input
              placeholder="Fecha"
              type="text"
              ngxDaterangepickerMd
              [(ngModel)]="selectedDate"
              (change)="onChangeDateOwner($event)"
              class="form-control"
            />
          </div>
        </div>
        <div class="reservas--main--left--navbar--select--flex--title">
          <p>Reservas activas</p>
        </div>
        <div
          *ngIf="viewReservasTenant.length === 0"
          class="reservas--main--left--reservas--empty right"
        >
          <div class="box--empty">
            <fa-icon [icon]="faBoxOpen" class="icon-caja"></fa-icon>
          </div>
          <p class="nothing">Nada por aquí</p>
        </div>
        <div
          (click)="onMine(reserva._id)"
          class="reservas--main--left--reservas"
        >
          <mat-card
            (click)="onMine(reserva._id)"
            class="border--right--main height"
            *ngFor="
              let reserva of viewReservasOwner | search: searchBarOwner;
              let i = index
            "
          >
            <div
              (click)="onMine(reserva._id)"
              class="reservas--main--left--reservas--title"
            >
              <mat-card-header>
                <div>
                  <mat-card-title>
                    <!-- <p>{{reserva.estado}}</p> -->
                    <div
                      (click)="onMine(reserva._id)"
                      class="reservas--main--left--reservas--title--space"
                    >
                      <p>Espacio {{ reserva.bodega.nombre }}</p>

                      <div
                        class="reservas--main--left--reservas--title--space--status positions"
                      >
                        <div
                          class="reservas--main--left--reservas--title--space--status--number__positions"
                        >
                          <p>{{ reserva.espacios[0].cantidad }} posiciones</p>
                        </div>
                        <div
                          class="reservas--main--left--reservas--title--space--status--movimientos"
                        >
                          <img
                            src="../../../assets/Utils/movimiento.png"
                            class="upload-image"
                          />
                          <p>0 movimientos</p>
                        </div>
                      </div>
                    </div>
                  </mat-card-title>
                </div>
              </mat-card-header>
            </div>
            <div class="reservas--main--left--reservas--content--mine medium">
              <mat-card-content>
                <div
                  (click)="onMine(reserva._id)"
                  class="reservas--main--left--reservas--content--finaliza"
                >
                  <div class="reservas--main--left--reservas--content--fechas">
                    <p class="title">Finaliza:</p>
                    <p class="sub-title">{{ reserva.fechaFinal }}</p>
                  </div>
                  <!-- <div class="reservas--main--left--reservas--content--fecha--line"></div> -->
                  <div class="reservas--main--left--reservas--content--fechas">
                    <p class="title">{{ reserva.duracion }}</p>
                    <p class="sub-title">semanas</p>
                  </div>
                </div>
              </mat-card-content>
            </div>
            <div class="reservas_main--info--solicitud">
              <div class="reservas_main--info--solicitud--circle">
                <p>
                  {{
                    reserva.arrendatario.nombre.charAt(0) +
                      reserva.arrendatario.apellido.charAt(0)
                  }}
                </p>
              </div>
              <div class="reservas_main--info--solicitud--info">
                <p>Alquila</p>
                <p class="name">{{ reserva.arrendatario.nombre }}</p>
              </div>
            </div>
          </mat-card>
        </div>
      </div>
      <div class="reservas--main--right">
        <div class="reservas--main--right--title">
          <h1>Solicitudes</h1>
          <p>{{ reservasOwnerPending.length }} pendientes</p>
        </div>
        <div
          *ngIf="reservasOwnerPending.length === 0"
          class="reservas--main--left--reservas--empty right"
        >
          <div class="box--empty">
            <fa-icon [icon]="faBoxOpen" class="icon-caja"></fa-icon>
          </div>
          <p class="nothing">Nada por aquí</p>
        </div>
        <div class="reservas--main--right--main">
          <ng-container *ngFor="let reserva of reservasOwnerPending">
            <mat-card
              class="border--right--main"
              (click)="onDetailSpaces(reserva._id)"
            >
              <div class="reservas--main--left--reservas--title">
                <mat-card-header>
                  <div>
                    <mat-card-title>
                      <!-- <p>{{reserva.estado}}</p> -->
                      <div class="reservas--main--left--reservas--title--space">
                        <div
                          class="reservas--main--left--reservas--title--space--title"
                        >
                          <p>Espacio {{ reserva.bodega.nombre }}</p>
                          <fa-icon
                            (click)="onDetailSpaces(reserva._id)"
                            [icon]="faChevronRight"
                            class="right--arrow"
                          >
                          </fa-icon>
                        </div>
                        <div
                          class="reservas--main--left--reservas--title--space--status"
                        >
                          <div
                            class="reservas--main--left--reservas--title--space--status--number__positions"
                          >
                            <p>{{ reserva.espacios[0].cantidad }} posiciones</p>
                          </div>
                        </div>
                      </div>
                    </mat-card-title>
                  </div>
                </mat-card-header>
              </div>
              <div class="reservas--main--left--reservas--content">
                <mat-card-content>
                  <!-- Section of pagos -->
                  <!-- *ngIf="reserva; then thenBlock else elseBlock" -->
                  <!-- <ng-template #thenBlock>
                    <p>Working pa</p>
                  </ng-template>
                  <ng-template #elseBlock>Desde:</ng-template> -->
                  <div class="reservas--main--left--reservas--content--fecha">
                    <div
                      class="reservas--main--left--reservas--content--fecha--inicio"
                    >
                      <p class="title">Desde:</p>
                      <p>{{ reserva.fechaInicio }}</p>
                    </div>
                    <div
                      class="reservas--main--left--reservas--content--fecha--line"
                    ></div>
                    <div
                      class="reservas--main--left--reservas--content--fecha--inicio"
                    >
                      <p class="title">Hasta:</p>
                      <p>{{ reserva.fechaFinal }}</p>
                    </div>
                  </div>
                </mat-card-content>
              </div>
            </mat-card>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
  <!-- Pago modal -->
  <div class="pago__modal">
    <ng-template #pago let-modal>
      <div class="modal-header">
        <button
          type="button"
          class="close"
          aria-label="Close"
          (click)="modal.dismiss('Cross click')"
        >
          <span aria-hidden="true">&times;</span>
        </button>
        <a (click)="modal.dismiss('Cross click')" class="close">Cerrar</a>
        <p class="reserva">Activa tu reserva</p>
      </div>
      <div class="modal-body">
        <div class="pago--section">
          <div class="pago--section--title">
            <fa-icon class="warehouseIcon" [icon]="faWarehouse"></fa-icon>
            <p>Bodega {{ choosenReservaTenat.bodega.nombre }}</p>
          </div>
          <div class="pago--section--main">
            <div class="reservas--main--left--reservas--content--fecha payment">
              <div
                class="reservas--main--left--reservas--content--fecha--inicio"
              >
                <p class="title">Desde:</p>
                <p>{{ choosenReservaTenat.fechaInicio }}</p>
              </div>
              <div
                class="reservas--main--left--reservas--content--fecha--line"
              ></div>
              <div
                class="reservas--main--left--reservas--content--fecha--inicio"
              >
                <p class="title">Hasta:</p>
                <p>{{ choosenReservaTenat.fechaFinal }}</p>
              </div>
            </div>
          </div>
          <div class="pago--tipo--pago">
            <!-- <select class="payment--type" (change)="onCotiza($event)" name="" id="">
                          <option value="" selected disabled>Tipo de pago</option>
                          <option value="week">Pago por costes semanal</option>
                          <option value="fullPago">Un solo pago</option>
                      </select> -->
            <fa-icon class="infoIcon--select" [icon]="faInfoCircle"></fa-icon>
          </div>
          <!-- <div *ngIf="cotizaNada" class="pago--label">
                      <p class="return--payment">Volver a pagar</p>
                      <strong>
                          <p class="paymet--method">Selecciona un tipo de pago</p>
                      </strong>
                  </div> -->
          <div *ngIf="choosenReservaTenat.unPago == true" class="pago--mes">
            <p>Resumen de pago único</p>
            <div
              *ngFor="
                let reserva of choosenReservaTenat.espacios;
                let i = index
              "
              class="pago--mes--summary"
            >
              <p>
                {{ reserva.espacio.nombre }} x {{ reserva.cantidad }} ({{
                  choosenReservaTenat.duracion
                }}sem)
              </p>
              <p>
                {{
                  reserva.espacio.costoPosicion *
                    reserva.cantidad *
                    choosenReservaTenat.duracion *
                    7 | currency: "COP ":"code"
                }}
              </p>
            </div>
            <div
              *ngFor="let service of choosenReservaTenat.otros; let i = index"
              class="pago--mes--summary"
            >
              <p>{{ service.otro.nombre }} x {{ service.cantidad }} unidades</p>
              <p>
                {{
                  servicesSelected[i].costo * service.cantidad
                    | currency: "COP ":"code"
                }}
              </p>
            </div>
            <div class="pago--mes--total">
              <p class="total--payment"><strong>Total:</strong></p>
              <p class="total--payment">
                <strong>{{ finalPrice | currency: "COP ":"code" }}</strong>
              </p>
            </div>
          </div>
          <div *ngIf="choosenReservaTenat.unPago == false" class="pago--mes">
            <p>Resumen primer pago</p>
            <div class="pago--mes--summary">
              <p>Pago Total:</p>
              <p>{{ finalPrice | currency: "COP ":"code" }}</p>
            </div>
            <div class="pago--mes--total">
              <p class="total--payment"><strong>Total primer pago:</strong></p>
              <p class="total--payment">
                <strong>{{
                  finalPrice / choosenReservaTenat.duracion
                    | currency: "COP ":"code"
                }}</strong>
              </p>
            </div>
          </div>
          <div class="pago--tipo--info">
            <p>¿Qué cubre el monto del primer pago?</p>
            <fa-icon class="infoIcon" [icon]="faInfoCircle"></fa-icon>
          </div>
          <div class="pago--tipo--politicas--cancelacion">
            <p>Políticas de cancelación</p>
            <fa-icon class="infoIcon" [icon]="faInfoCircle"></fa-icon>
          </div>
          <div class="pago--tipo--politicas--medios--de--pago">
            <p>Medio de pago</p>
            <div class="pago--tipo--politicas--medios--de--pago--flex">
              <div
                *ngIf="creditCard == false"
                class="pago--tipo--politicas--medios--de--pago--icon"
              >
                <button (click)="onCreditCard()" class="option">
                  <div class="icon-flex">
                    <fa-icon class="cardIcon" [icon]="faCreditCard"></fa-icon>
                    <p>Tarjeta de crédito</p>
                  </div>
                </button>
              </div>
              <div
                *ngIf="creditCard"
                class="pago--tipo--politicas--medios--de--pago--icon purple"
              >
                <button (click)="onDeactivateCreditCard()" class="option">
                  <div class="icon-flex">
                    <fa-icon class="cardIcon" [icon]="faCreditCard"></fa-icon>
                    <p>Tarjeta de crédito</p>
                  </div>
                </button>
              </div>
              <div
                *ngIf="pse == false"
                class="pago--tipo--politicas--medios--de--pago--icon space"
              >
                <button (click)="onPse()" class="option">
                  <div class="icon-flex">
                    <img
                      class="pse"
                      src="../../../assets/Logos/pse2.png"
                      alt=""
                    />
                    <p class="text--pse">Pse</p>
                  </div>
                </button>
              </div>
              <div
                *ngIf="pse"
                class="pago--tipo--politicas--medios--de--pago--icon purple space"
              >
                <button (click)="onDeactivatePse()" class="option">
                  <div class="icon-flex">
                    <img
                      class="pse"
                      src="../../../assets/Logos/pse2.png"
                      alt=""
                    />
                    <p class="text--pse">Pse</p>
                  </div>
                </button>
              </div>
            </div>
          </div>
          <div class="pago--footer">
            <button
              class="checkout--button"
              id="checkout--button"
              [disabled]="creditCard == false && pse == false"
              (click)="onNextStep(); modal.dismiss('Cross click')"
            >
              Continuar
            </button>
          </div>
        </div>
      </div>
    </ng-template>
  </div>
</div>
<!-- Checkout modal -->
<div class="pago__modal">
  <ng-template #checkout let-modal>
    <div class="modal-header">
      <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="modal.dismiss('Cross click')"
      >
        <span aria-hidden="true">&times;</span>
      </button>
      <p>Cerrar</p>
      <p class="reserva">Activa tu reserva</p>
    </div>
    <div class="modal-body">
      <div class="pago--section--checkout">
        <div class="checkout--section--title">
          <p>Tu pago</p>
          <strong>
            <p *ngIf="choosenReservaTenat.unPago == true">
              {{ finalPrice | currency: "COP ":"code" }}
            </p>
          </strong>
          <strong>
            <p *ngIf="choosenReservaTenat.unPago == false">
              {{
                finalPrice / choosenReservaTenat.duracion
                  | currency: "COP ":"code"
              }}
            </p>
          </strong>
        </div>
        <div class="checkout--section--form">
          <form
            [formGroup]="paymentInformationForm"
            (ngSubmit)="onSubmitClick()"
          >
            <div class="sub-form--payment" formGroupName="informacionTarjeta">
              <strong>
                <p class="payment--title">Informacion de la tarjeta</p>
              </strong>
              <div class="sub-form--payment--name">
                <input
                  class="input--payment--full"
                  id="fullCardName"
                  type="text"
                  formControlName="fullCardName"
                  placeholder="Nombre propietario de la tarjeta"
                />
              </div>
              <div class="sub--form--payment--cc--number">
                <input
                  class="input--payment--full"
                  id="cc-number"
                  minlength="15"
                  maxlength="19"
                  name="cc-number"
                  type="tel"
                  #ccNumber="ccNumber"
                  formControlName="cardNumber"
                  autocomplete="cc-number"
                  placeholder="Número de la tarjeta"
                  ccNumber
                />
              </div>
              <div class="scheme">
                Medio de pago
                <strong
                  ><span #creditCardFranchise>{{
                    ccNumber.resolvedScheme$ | async | uppercase
                  }}</span></strong
                >
              </div>
              <div class="sub--form--extra--info">
                <div class="sub-form--exp--date">
                  <label for="expDate"> Fecha de expiración </label>
                  <input
                    class="input--payment--small"
                    id="expDate"
                    type="tel"
                    formControlName="expDate"
                    placeholder="05 / 2022"
                    autocomplete="cc-exp"
                    ccExp
                  />
                </div>
                <div class="sub--form--extra--info">
                  <div class="sub-form--cvc">
                    <label for="cc-cvc"> CVC </label>
                    <input
                      class="input--payment--small"
                      id="cc-cvc"
                      type="tel"
                      autocomplete="off"
                      formControlName="cvc"
                      ccCVC
                    />
                  </div>
                </div>
              </div>
            </div>
            <strong>
              <p class="payment--title">Info propietario</p>
            </strong>
            <div class="sub-form" formGroupName="informacionPersonal">
              <div class="payment--user--info--flex">
                <div class="sub-form--name">
                  <input
                    class="input--payment--medium"
                    id="name"
                    type="text"
                    formControlName="nombreCompleto"
                    placeholder="Nombre"
                  />
                </div>
                <div class="sub-form--email">
                  <input
                    class="input--payment--medium"
                    id="email"
                    type="email"
                    formControlName="email"
                    placeholder="E-mail"
                  />
                </div>
              </div>
              <div class="sub-form--id">
                <input
                  class="input--payment--medium"
                  id="id-number"
                  type="text"
                  formControlName="numeroDeIdentificacion"
                  placeholder="Número de documento"
                />
              </div>
            </div>
            <div class="bottom--payment--section">
              <div class="bottom--payment--section--back">
                <fa-icon
                  (click)="openModal(pago); modal.dismiss('Cross click')"
                  class="searchIcon"
                  [icon]="faChevronLeft"
                ></fa-icon>
                <a (click)="openModal(pago); modal.dismiss('Cross click')"
                  >Paso anterior</a
                >
              </div>
              <button
                *ngIf="!loading"
                [disabled]="paymentInformationForm.invalid"
                id="submit-button"
                type="submit"
              >
                Pagar
              </button>
              <mat-progress-spinner
                *ngIf="loading"
                class="spinner"
                diameter="40"
                mode="indeterminate"
              >
              </mat-progress-spinner>
            </div>
          </form>
        </div>
      </div>
    </div>
  </ng-template>
  <!-- Checkout modal -->
  <div class="pago__modal">
    <ng-template #checkoutTarjeta let-modal>
      <div class="modal-header">
        <button
          type="button"
          class="close"
          aria-label="Close"
          (click)="modal.dismiss('Cross click')"
        >
          <span aria-hidden="true">&times;</span>
        </button>
        <p>Cerrar</p>
        <p class="reserva">Activa tu reserv con tu tarjeta</p>
      </div>
      <div class="modal-body">
        <div class="pago--section--checkout--flex">
          <div class="checkout--section--title">
            <p>Tu pago</p>
            <strong>
              <p *ngIf="choosenReservaTenat.unPago == true">
                {{ finalPrice | currency: "COP ":"code" }}
              </p>
            </strong>
            <strong>
              <p *ngIf="choosenReservaTenat.unPago == false">
                {{
                  finalPrice / choosenReservaTenat.duracion
                    | currency: "COP ":"code"
                }}
              </p>
            </strong>
          </div>
          <div class="checkout--section--form">
            <div class="flex--tarjetas">
              <label for="">Tu tarjeta</label>
              <div class="flex--tarjetas--main">
                <div class="flex-tarjetas-main--square">
                  <div class="trash"></div>
                  <div class="flex--tarjetas--main-square">
                    {{ tarjeta[0].franquicia }}
                  </div>
                  <div class="tarjetas--main--info">
                    <label for=""
                      >**** **** **** {{ tarjeta[0].ultimos }}</label
                    >
                    <label for="">{{ tarjeta[0].nombreTarjeta }}</label>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div class="flex-tarjeta--checked">
              <fa-icon *ngIf="tarjetas.preferidad" class="icon--card" (click)="onChooseTarjeta(i)" [icon]="faCheckCircle" class="green--circle"></fa-icon>
              <fa-icon *ngIf="!tarjetas.preferidad" class="icon--card" (click)="onChooseTarjeta(i)" [icon]="faCheckCircle" class="red--circle"></fa-icon>
            </div> -->
            <!-- <form [formGroup]="paymentInformationForm" (ngSubmit)="onSubmitClick()">
            <div class="sub-form--payment" formGroupName="informacionTarjeta">
              <strong>
                <p class="payment--title">Informacion de la tarjeta</p>
              </strong>
              <div class="sub-form--payment--name">
                <input
                  class="input--payment--full"
                  id="fullCardName"
                  type="text"
                  formControlName="fullCardName"
                  placeholder="Nombre propietario de la tarjeta"
                />
              </div>
              <div class="sub--form--payment--cc--number">
                <input
                  class="input--payment--full"
                  id="cc-number"
                  minlength="15"
                  maxlength="19"
                  name="cc-number"
                  type="tel"
                  #ccNumber="ccNumber"
                  formControlName="cardNumber"
                  autocomplete="cc-number"
                  placeholder="Número de la tarjeta"
                  ccNumber
                />
              </div>
              <div class="scheme">
                Medio de pago
                <strong
                  ><span #creditCardFranchise>{{ ccNumber.resolvedScheme$ | async | uppercase }}</span></strong
                >
              </div>
              <div class="sub--form--extra--info">
                <div class="sub-form--exp--date">
                  <label for="expDate"> Fecha de expiración </label>
                  <input
                    class="input--payment--small"
                    id="expDate"
                    type="tel"
                    formControlName="expDate"
                    placeholder="05 / 2022"
                    autocomplete="cc-exp"
                    ccExp
                  />
                </div>
                <div class="sub--form--extra--info">
                  <div class="sub-form--cvc">
                    <label for="cc-cvc"> CVC </label>
                    <input class="input--payment--small" id="cc-cvc" type="tel" autocomplete="off" formControlName="cvc" ccCVC />
                  </div>
                </div>
              </div>
            </div>
            <strong>
              <p class="payment--title">Info propietario</p>
            </strong>
            <div class="sub-form" formGroupName="informacionPersonal">
              <div class="payment--user--info--flex">
                <div class="sub-form--name">
                  <input class="input--payment--medium" id="name" type="text" formControlName="nombreCompleto" placeholder="Nombre" />
                </div>
                <div class="sub-form--email">
                  <input class="input--payment--medium" id="email" type="email" formControlName="email" placeholder="E-mail" />
                </div>
              </div>
              <div class="sub-form--id">
                <input
                  class="input--payment--medium"
                  id="id-number"
                  type="text"
                  formControlName="numeroDeIdentificacion"
                  placeholder="Número de documento"
                />
              </div>
            </div>
            <div class="bottom--payment--section">
              <div class="bottom--payment--section--back">
                <fa-icon (click)="openModal(pago); modal.dismiss('Cross click')" class="searchIcon" [icon]="faChevronLeft"></fa-icon>
                <a (click)="openModal(pago); modal.dismiss('Cross click')">Paso anterior</a>
              </div>
              <button *ngIf="!loading" [disabled]="paymentInformationForm.invalid" id="submit-button" type="submit">Pagar</button>
              <mat-progress-spinner *ngIf="loading" class="spinner" diameter="40" mode="indeterminate"></mat-progress-spinner>
            </div>
          </form> -->
          </div>
        </div>
      </div>
    </ng-template>
  </div>
</div>
