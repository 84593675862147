import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "app-pqrs",
  templateUrl: "./pqrs.component.html",
  styleUrls: ["./pqrs.component.scss"],
})
export class PqrsComponent implements OnInit {
  /** This var create the close output function of modal when the user click close button */
  @Output() close = new EventEmitter<Boolean>();

  arrayTest = [1, 2, 3, 4, 5, 67, 8];
  constructor() {}

  ngOnInit(): void {}

  /**
   * Function to close a modal
   */
  public onClose() {
    this.close.emit(false);
  }
}
