import { Inject, Injectable, Optional, PLATFORM_ID } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(@Inject(PLATFORM_ID) private platformId,
  @Optional() @Inject('REQUEST') private request: Request,
  private cookieService: CookieService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (!request.url.includes('maps')) {
      // If the app is currently running on the server
      if (isPlatformServer(this.platformId)) {
        // Get the cookie from the request header
        const cookie = this.request.headers['cookie'];
        if (cookie !== undefined && cookie !== null && cookie !== ''){
            // If the cookie exists and has a token, add it to the authorization headers
            const token = this.getItem(cookie, 'flexpot-token');
            if (token !== null) {
              request = request.clone({
                    headers: request.headers.set('Authorization', token)
                });
            }
        }
      } else if (isPlatformBrowser(this.platformId)) {
        // If the app is currently running on the browser
        const token = this.cookieService.get('flexpot-token');
        // Add the token to the headers by retrieving it from the cookie service
        if (token !== null && token !== undefined && token !== '') {
          request = request.clone({
                headers: request.headers.set('Authorization', token),
            });
        }
      }
    }
    return next.handle(request)
  }

  private getItem(cookies, sKey): string {
    if (!sKey) {
        return null;
    }
    return decodeURIComponent(cookies.replace(new RegExp(
               '(?:(?:^|.*;)\\s*' +
               encodeURIComponent(sKey).replace(/[\-\.\+\*]/g, '\\$&') +
               '\\s*\\=\\s*([^;]*).*$)|^.*$'), '$1'
     )
    ) || null;
  }
}
