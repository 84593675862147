import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MapsService {

  /** CONSTANTS  */
  /** Variable that holds te geocoding API */
  private readonly GEOCODING_API = 'https://maps.googleapis.com/maps/api/geocode/json?address=';

  constructor(private http: HttpClient) { }

  /**
   * Uses the google geocode API to turn an address into a set of coordinates
   * @param address string of the address
   */
  getGeocode({address}: {address: string}): Observable<any> {
    let apiKey = environment.MAPS_KEY;
    let encodedAddress = encodeURIComponent(address);
    return this.http.get(`${this.GEOCODING_API}${encodedAddress}&key=${apiKey}`);
  }
}
