import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ApiService } from "../public/services/api.service";
import {
  faChevronLeft,
  faCalendarAlt,
  faTimes,
  faCheck,
  faQuestion,
} from "@fortawesome/free-solid-svg-icons";
import { DatePipe, CurrencyPipe } from "@angular/common";
import { NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";
import { Router } from "@angular/router";
@Component({
  selector: "app-reservas-detail",
  templateUrl: "./reservas-detail.component.html",
  styleUrls: ["./reservas-detail.component.scss"],
})
export class ReservasDetailComponent implements OnInit {
  /**Api Variables */
  RESERVAS_API = "api/reserva";
  /**Fontawesome variables */
  faChevronLeft = faChevronLeft;
  faQuestion = faQuestion;
  faCheck = faCheck;
  faCalendarAlt = faCalendarAlt;
  faTimes = faTimes;
  reserva: any = [];
  espacesCost: any = 0;
  servicesCost: any = 0;
  servicesQuantity: any = 0;
  total: any = "";
  fee: any = "";
  profit: any = "";
  fechaInicial: any = "";
  duracion: any = "";
  response: any = "";

  closeResult = "";
  constructor(
    public activatedRoute: ActivatedRoute,
    private apiService: ApiService,
    private modalService: NgbModal,
    public datepipe: DatePipe,
    private toastr: ToastrService,
    private router: Router
  ) {
    var id = this.activatedRoute.snapshot.params["id"];
    this.getReserva(id);
  }

  ngOnInit(): void {}
  /**
   * Get the reserva from the Api
   * @param id Id of the reserva
   */
  public getReserva(id) {
    this.apiService
      .get({ api: `${this.RESERVAS_API}/${id}` })
      .subscribe((reserva) => {
        this.reserva = reserva;
        this.duracion = reserva.duracion;
        this.onCotizaMesPositions({ reserva: this.reserva });
        this.onCotizaMesServicio({ reserva: this.reserva });
        this.profitCalculation();
      });
  }
  public onGoToBodega(id: string) {
    this.router.navigate(["bodegas/bodega/" + id]);
  }
  /**
   * Calculate the cost of the postiion
   * @param array Reserva
   */
  public onCotizaMesPositions({ reserva }) {
    this.espacesCost = reserva.espacios.reduce((accumulator, element) => {
      const costo =
        element.espacio.costo * element.cantidad * reserva.duracion * 7;
      return accumulator + costo;
    }, 0);
  }
  /**
   * Calculate the cost of the servicios in the reserva
   * @param array Reserva
   */
  public onCotizaMesServicio({ reserva }) {
    this.servicesCost = reserva.otros.reduce((accumulator, element) => {
      const serviceCost = element.otro.valor * element.cantidad;
      return accumulator + serviceCost;
    }, 0);
    this.servicesQuantity = reserva.otros.reduce((acc, element) => {
      return acc + element.cantidad;
    }, 0);
  }
  /**
   * Calculate the profit of the reserva
   * @param array Reserva
   */
  public profitCalculation() {
    this.total = this.servicesCost + this.espacesCost;
    this.fee = this.total * 0.25;
    this.profit = this.total - this.fee;
  }
  public showToastr({
    position,
    title,
    message,
  }: {
    position?: any;
    title: string;
    message?: string;
  }): void {
    let realPosition = position ? position : "top-end";
    let realMessage = message ? message : "";
    let duractionMilisec = 4500;
    this.toastr.show(`${realMessage}`, `${title}`, {
      positionClass: "toast-bottom-right",
      timeOut: duractionMilisec,
    });
  }
  /**
   * Handles navigation to the specified URL
   * @param {string} path
   * @return void
   */
  public routerNavigateTo({ path }: { path: string }): void {
    this.router.navigate([path]).then((fulfilled) => {
      if (!fulfilled) this.showToastr({ title: "Invalid URL" });
    });
  }
  /**
   * Send the update to the api, about the status of the reserva
   * @param id Id of the reserva
   */
  public onApprove(id) {
    const reserva = {
      estado: "aceptado",
    };
    // Send the request
    this.apiService
      .put({ api: `${this.RESERVAS_API}/${id}`, data: reserva })
      .subscribe(
        (response) => {
          const response_api = response;
          this.response = response_api;
          this.showToastr({
            title: "Reserva aprobada con éxito",
          });
          this.routerNavigateTo({ path: "/reservas" });
        },
        (err) => {
          this.showToastr({
            title: "Errors have occured",
            message: err.message || err || "not found",
          });
        }
      );
  }
  /**Handles opening the modal */
  public open(content) {
    this.modalService
      .open(content, { ariaLabelledBy: "modal-basic-title" })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }
  /**Handles closing the modal */
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }
}
