import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";

@Injectable({
  providedIn: "root",
})
export class MovementService {
  MOVEMENT_REMOVE_API = "api/ordenRetiro/reserva/";
  MOVEMENT_ADD_API = "api/movimientoAgregar/reserva/";

  constructor(private apiService: ApiService) {}

  /**
   *
   * @param param0
   * @returns
   */
  async postMovementRemove({
    id,
    data,
  }: {
    id: string;
    data: any;
  }): Promise<any> {
    return await this.apiService.postPromise({
      api: `${this.MOVEMENT_REMOVE_API}${id}`,
      data: data,
    });
  }

  /**
   *
   * @param param0
   * @returns
   */
  async postMovementAdd({ id, data }: { id: string; data: any }): Promise<any> {
    return await this.apiService.postPromise({
      api: `${this.MOVEMENT_ADD_API}${id}`,
      data: data,
    });
  }
}
