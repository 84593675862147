import { Component, OnInit } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { LoginComponent } from "../login/login.component";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { AuthService } from "../../services/auth.service";

@Component({
  selector: "app-forgot-password",
  templateUrl: "./forgot-password.component.html",
  styleUrls: ["./forgot-password.component.scss"]
})
export class ForgotPasswordComponent implements OnInit {
  // Email to send to back
  email: string = "";
  faTimes = faTimes;

  constructor(public dialogRef: MatDialogRef<ForgotPasswordComponent>, private dialog: MatDialog, private authService: AuthService) { }

  ngOnInit(): void { }

  /**
   * Handles the closing of the dialog   */
  onClose(): void {
    this.dialogRef.close();
  }

  onBackClicked(): void {
    this.dialogRef.close();
    this.dialog.open(LoginComponent, { closeOnNavigation: true });
  }

  onSendPassword(): void {
    this.authService
      .forgotPasswordPromise({ email: this.email })
      .then((response) => {
        alert(response.mensaje);
        this.dialogRef.close();
      })
      .catch((err) => {
        console.error(err);
        alert(err.error.mensaje);
      });
  }
}
