<div class="card-box" [ngClass]="{'card-box-selected': warehouse.shouldBounce, 'card-box': !warehouse.shouldBounce}">
    <div class="main__warehouses--title">
        <div *ngIf="warehouse.costoEspacioMasEconomico">
            Desde:
            <br>
            <p class="main__warehouses--price">
                {{ warehouse.costoEspacioMasEconomico || 0 | currency:'$':'code' }}/Posición
            </p>
        </div>
        <div *ngIf="!warehouse.costoEspacioMasEconomico">
            Desde:
            <br>
            <p class="main__warehouses--price">
                Precio no disponible
            </p>
        </div>
    </div>
    <div class="main__warehouses--main">
        <div class="margin-left-cards">
            <label class="title-warehouse">{{warehouse.nombre}}</label>
            <div class="main__warehouses--main--rating">
                <fa-icon [icon]="faStar" style="font: 15px;"></fa-icon>
                <p>{{warehouse.calificacion}}</p>
            </div>
        </div>
        <div class="footer">
            <label class="points" *ngIf="warehouse.direccion">
                <fa-icon [icon]="faMapMarkerAlt"></fa-icon>
                {{warehouse.direccion.replace('undefined','')}}
            </label>
        </div>
    </div>

</div>