import {Injectable} from "@angular/core";
import {ApiService} from "./api.service";

@Injectable({
  providedIn: "root"
})
export class PagosService {
  TARJETA_API = "api/tarjeta/";

  constructor(private apiService: ApiService) {}

  /**
   *
   * @param param0
   * @returns
   */
  postTarjeta({data}: {data: any}): Promise<any> {
    return this.apiService.postPromise({api: `${this.TARJETA_API}`, data: data});
  }
}
