<div class="container">
    <div class="historial">
        <div class="historial__header">
            <div class="historial__header--flex">
                <button (click)="onGoingBack(pagos[0].reserva._id)" id="back-button">
                    <fa-icon (click)="onGoingBack(pagos[0].reserva._id)" [icon]="faChevronLeft"></fa-icon> Volver atrás
                </button>
            </div>
        </div>
    </div>
    <div class="historial-pagos-containter">
    
        <div class="historial-pagos-table-container">
            <div class="historial-pagos-serch-header">
                <h1 class="historial-pagos-title">Historial de pagos</h1>
                <input type="text" placeholder="Buscar" class="serch-data-input">
                <div class="historial-pagos-table">
                    <ng2-smart-table [settings]="settings" [source]="source" (custom)="onCustom($event)">
                    </ng2-smart-table>
                </div>
            </div>
        </div>
        <div class="historial-pagos-card">
            <div class="historial-pagos-card-bill">
                <div class="card-pagos-info-header">
                    <h1 class="card-pagos-title">Actualiza el pago del nuevo corte</h1>
                    <div class="historial-pagos-card-date">
                        <fa-icon class="searchIcon" [icon]="faCalendarAlt" class="calendar"></fa-icon>
                        <div class="historial__main--right--date">
                            <p class="descripcion-pago">Fecha:</p>
                            <p class="date">Sep 10 de 2021</p>
                        </div>
                    </div>
                </div>
                <div class="card-pagos-info-content">
                    <div *ngFor="let item of pagosValor" class="historial-pagos-card-description">
                        <p class="descripcion-pago">Espacio 1 (5 posiciones)</p>
                        <p class="descripcion-pago">{{ item }}</p>
                    </div>
                </div>
                <div class="card-pagos-total">
                    <div class="historial-pagos-card-description">
                        <strong>
                            <p class="descripcion-pago-total">Total</p>
                        </strong>
                        <strong>
                            <p class="descripcion-pago-total">$5'000.000</p>
                        </strong>
                    </div>
                </div>
                <div class="card-pagos-info-pay-methods">
                    <div class="pago--tipo--politicas--medios--de--pago">
                        <p>Medio de pago</p>
                        <div class="pago--tipo--politicas--medios--de--pago--flex">
                            <div class="pago--tipo--politicas--medios--de--pago--icon">
                                <fa-icon class="cardIcon" [icon]="faCreditCard"></fa-icon>
                                <p class="descripcion-pago">Tarjeta de crédito</p>
                            </div>
                            <div class="pago--tipo--politicas--medios--de--pago--icon">
                                <img class="pse" src="../../../assets/Logos/pse2.png" alt="" />
                                <p class="pse--text">PSE</p>
                            </div>
                        </div>
                    </div>
                </div>
                <hr class="separator">
                <div class="card-pagos-footer">
                    <div class="historial__main--right--button">
                        <button class="checkout--button">Pagar</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

