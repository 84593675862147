import { Component, OnInit } from "@angular/core";
import { ModalDismissReasons, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import * as moment from "moment";
import { MyWarehousesService } from "../../services/my-warehouses.service";
import { NavigationService } from "../../services/navigation.service";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService } from "../../services/auth.service";

@Component({
  selector: "app-my-warehouses",
  templateUrl: "./my-warehouses.component.html",
  styleUrls: ["./my-warehouses.component.scss"],
})
export class MyWarehousesComponent implements OnInit {
  allWarehouses: any = [];
  loadingAllWareHouses = false;
  warehousesInProgress: any = [];
  totalWarehouses: number = 0;
  warehousePerPage: number = 6;
  page: number = 1;
  currentStatus: string = "todos";
  toggleBt1 = true;
  toggleBt2 = false;
  toggleBt3 = false;
  toggleBt4 = false;
  positions: any = {};

  constructor(
    private myWarehousesService: MyWarehousesService,
    private activatedRoute: ActivatedRoute,
    private navigationService: NavigationService,
    private modalService: NgbModal,
    private authService: AuthService,
    private router: Router
  ) {}

  ngOnInit(): void {
    let initialType = this.activatedRoute.snapshot?.queryParams?.type;
    if(initialType) {
      this.onFilterByQuery({ value: initialType });
    } else {
      this.onFilterByQuery({ value: "todos" });
    }
    this.getNumberOfPositionsById();
  }

  public goToStats() {
    this.navigationService.navigateTo({ path: "estadisticas" });
  }

  /**
   * It is in charge of obtaining the warehouses created by the user.
   * @param param0 Receives a string that can be approved, rejected, pending or in progress, which is the status of the warehouse.
   */
  onFilterByQuery({ value }: { value: string }): void {
    this.navigationService.navigateTo({ path: "mis-bodegas", params: { type: value } });
    this.toggleBt1 = false;
    this.toggleBt2 = false;
    this.toggleBt3 = false;
    this.toggleBt4 = false;
    if (value === "todos") {
      this.toggleBt1 = true;
      this.page = 1;
    } else if (value === "pendiente") {
      this.toggleBt2 = true;
      this.page = 1;
    } else if (value === "rechazada") {
      this.toggleBt3 = true;
      this.page = 1;
    } else if (value === "aprobada") {
      this.toggleBt4 = true;
      this.page = 1;
    }

    this.currentStatus = value;
    this.getWarehousesByStatus({
      value: value,
      page: this.page,
      size: this.warehousePerPage,
      all: "false",
    });
  }
  public async getNumberOfPositionsById() {
    const user = this.authService.getUser();
    const id = user._id;
    this.positions =
      await this.myWarehousesService.getNumberOfPositionsByUserId({ id: id });
  }
  public calculatePositions(i) {
    let percentage = 0;
    if (
      this.positions.positionsOccupied &&
      i < this.positions.positionsOccupied.length
    ) {
      let numberOfPositionsOccupied =
        this.positions.positionsOccupied[i].numberOfPositions;
      let numberOfPositionsAvaible =
        this.allWarehouses[i].cantidadTotalPosiciones;
      if (numberOfPositionsAvaible !== 0) {
        percentage =
          (numberOfPositionsOccupied / numberOfPositionsAvaible) * 100;
      }
    }
    return percentage;
  }
  /**
   * Navigates to create warehouse component
   */
  goToCreateWarehouse() {
    this.myWarehousesService.warehouseInProgress = undefined;
    this.navigationService.navigateTo({ path: "crear-bodega" });
  }

  onRemoveWarehouse({ id }: { id: string }) {
    // remove from back
    this.myWarehousesService
      .deleteWarehouse({ id: id })
      .then((response) => {
        this.getWarehousesInProgress();
      })
      .catch((err) => {
        alert(err.error.mensaje);
      });
  }

  /**
   * Redirect to craete warehouse component to finish warehouse progress
   * @param warehouse warehouse in progress
   */
  onRedirectToCompletedWarehouse({ warehouse }: { warehouse: any }) {
    // redirect
    this.myWarehousesService.warehouseInProgress = warehouse;
    this.navigationService.navigateTo({ path: "crear-bodega" });
  }

  formatDate({ date }: { date: string }): string {
    moment.locale("es");
    return moment(date).format("DD [de] MMMM [de] YYYY");
  }

  /**Handles opening the modal */
  onOpenModal({ content }: { content: any }) {
    // this.getWarehousesByStatus({status: "enProgreso"});
    this.getWarehousesInProgress();
    this.modalService
      .open(content, { ariaLabelledBy: "modal-basic-title" })
      .result.then(
        (result) => {},
        (reason) => {
          this.dismissReasonAction({ reason: reason });
        }
      );
  }

  /**Opens a modal */
  open(content) {
    this.modalService
      .open(content, { size: "lg", ariaLabelledBy: "modal-basic-title" })
      .result.then(
        (result) => {},
        (reason) => {
          this.dismissReasonAction({ reason: reason });
        }
      );
  }

  onDetailWarehouse(id) {
    this.router.navigate(["mis-bodegas/" + id]);
  }

  onPageChange({ event }: { event: any }) {
    this.page = event;
    this.getWarehousesByStatus({
      value: this.currentStatus,
      page: this.page,
      size: this.warehousePerPage,
      all: "false",
    });
  }

  onPerPageChange({ event }: { event: any }) {
    this.warehousePerPage = event;
    this.getWarehousesByStatus({
      value: this.currentStatus,
      page: this.page,
      size: this.warehousePerPage,
      all: "false",
    });
  }

  /**Handles closing the modal */
  private dismissReasonAction({ reason }: { reason: any }): string {
    if (
      reason === ModalDismissReasons.ESC ||
      reason === ModalDismissReasons.BACKDROP_CLICK ||
      reason === "Cross exit"
    ) {
      return `with: ${reason}`;
    } else {
      return `with: ${reason}`;
    }
  }

  private getWarehousesByStatus({
    value,
    page,
    size,
    all,
  }: {
    value: string;
    page: number;
    size: number;
    all: string;
  }): void {
    this.loadingAllWareHouses = true;
    this.myWarehousesService
      .getWarehouses({ value: value, page: page, size: size, all: all })
      .then((response) => {
        this.loadingAllWareHouses = false;
        this.allWarehouses = response.docs;
        let positionsActs = [...this.allWarehouses];
        this.allWarehouses.forEach(async (act, index) => {
          let res = await this.myWarehousesService.getNumberOfPositions({
            id: act._id,
          });
          positionsActs[index] = res;
        });
        this.positions.positionsOccupied = positionsActs;
        this.totalWarehouses = response.totalDocs;
      })
      .catch((err) => {
        console.error(err);
        this.loadingAllWareHouses = false;
        alert(err.error.mensaje);
      });
  }

  private getWarehousesInProgress(): void {
    this.myWarehousesService
      .getWarehouses({ value: "enProgreso", page: 0, size: 0, all: "true" })
      .then((response) => {
        this.warehousesInProgress = response.docs;
      })
      .catch((err) => {
        console.error(err);
        alert(err.error.mensaje);
      });
  }
}
