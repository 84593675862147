import { Injectable } from "@angular/core";
import { S3 } from "aws-sdk";
import { Observable } from "rxjs";
import { ApiService } from "./api.service";

@Injectable({
  providedIn: "root",
})
export class S3Service {
  /** Variable that holds S3 buckets */
  bucket: S3;
  /** Backend path that retrieves S3 credentials */
  readonly S3_CREDENTIALS_API = "credentials/s3-credentials";
  /** S3 Bucket name */
  readonly S3_BUCKET = "flexpot-recursos";
  /** S3 bucket region */
  readonly S3_BUCKET_REGION = "us-east-1";
  /** S3 bucket ACL */
  readonly S3_BUCKET_ACL = "public-read";

  constructor(private apiService: ApiService) {}

  /**
   * Method that uploads a file in amazon S3
   * @param file File the is going to be uploaded
   * @param key: Path used to store the file ->(next line)
   * @param callback: callback that handles S3 response
   */
  async uploadFile({ file, key, callback }): Promise<any> {
    this.getS3Credentials().subscribe((response) => {
      const contentType = file.type;
      this.bucket = new S3({
        accessKeyId: response.accessKeyId,
        secretAccessKey: response.secretAccessKey,
        region: this.S3_BUCKET_REGION,
      });
      const params = {
        Bucket: this.S3_BUCKET,
        Key: key,
        Body: file,
        ACL: this.S3_BUCKET_ACL,
        ContentType: contentType,
      };
      const options = {
        // Part Size of 10mb
        partSize: 10 * 1024 * 1024,
        queueSize: 1,
        // Give the owner of the bucket full control
        ACL: this.S3_BUCKET_ACL,
      };
      this.bucket.upload(params, options, callback);
    });
  }

  public uploadFilePromise({ file, key }) {
    return new Promise((resolve) => {
      this.getS3Credentials().subscribe((response) => {
        const contentType = file.type;
        this.bucket = new S3({
          accessKeyId: response.accessKeyId,
          secretAccessKey: response.secretAccessKey,
          region: this.S3_BUCKET_REGION,
        });
        const params = {
          Bucket: this.S3_BUCKET,
          Key: key,
          Body: file,
          ACL: this.S3_BUCKET_ACL,
          ContentType: contentType,
        };
        const options = {
          // Part Size of 10mb
          partSize: 10 * 1024 * 1024,
          queueSize: 1,
          // Give the owner of the bucket full control
          ACL: this.S3_BUCKET_ACL,
        };
        this.bucket.upload(params, options, (err, data) => {
          if (err) {
            // If there is an error alert the user
            resolve({
              success: false,
              msg: "Hubo un error subiendo los archivos, por favor intente más tarde o contacte a soporte",
            });
          } else {
            // Assign the value to the corresponding component
            resolve({
              success: true,
              url: data.Location,
            });
          }
        });
      });
    });
  }

  /**
   * Uploads a batch of objects to S3
   * @param list list of objects that are going to be uploaded to S3
   */
  async uploadBatch({ list }: { list: Array<any> }): Promise<any[]> {
    const credentials = await this.getS3Credentials().toPromise();
    const uploadList = [];
    for (const upload of list) {
      const contentType = upload.file.type;
      this.bucket = new S3({
        accessKeyId: credentials.accessKeyId,
        secretAccessKey: credentials.secretAccessKey,
        region: this.S3_BUCKET_REGION,
      });
      const params = {
        Bucket: this.S3_BUCKET,
        Key: upload.key,
        Body: upload.file,
        ACL: this.S3_BUCKET_ACL,
        ContentType: contentType,
      };
      const options = {
        // Part Size of 10mb
        partSize: 10 * 1024 * 1024,
        queueSize: 1,
        // Give the owner of the bucket full control
        ACL: this.S3_BUCKET_ACL,
      };
      uploadList.push(this.bucket.upload(params, options).promise());
    }

    return Promise.all(uploadList);
  }

  /**
   * Deletes an object from S3.
   * @param key key of the object that is going to be deleted
   * @param callback callback that handles S3 response
   */
  async deleteFileS3({ key, callback }): Promise<any> {
    this.getS3Credentials().subscribe((response) => {
      this.bucket = new S3({
        accessKeyId: response.accessKeyId,
        secretAccessKey: response.secretAccessKey,
        region: this.S3_BUCKET_REGION,
      });

      const params = {
        Bucket: this.S3_BUCKET,
        Key: key,
      };

      this.bucket.deleteObject(params, callback);
    });
  }

  /**
   * Copies an s3 object from one path to another
   * @param sourcePath: path of the file that is going to be copied
   * @param newKey: new key for the copied object
   */
  async copyObject({ sourcePath, newKey, callback }): Promise<any> {
    this.getS3Credentials().subscribe((response) => {
      this.bucket = new S3({
        accessKeyId: response.accessKeyId,
        secretAccessKey: response.secretAccessKey,
        region: this.S3_BUCKET_REGION,
      });

      const params = {
        Bucket: this.S3_BUCKET,
        CopySource: `${this.S3_BUCKET}/${sourcePath}`,
        Key: newKey,
      };
      this.bucket.copyObject(params, callback);
    });
  }

  /**
   * Generates a random key
   * @return string
   */
  generateKey(): string {
    let result = "";
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;
    for (let i = 0; i < 15; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  /**
   * Retrieves s3 api credentials from the backend
   * @return Observable
   */
  private getS3Credentials(): Observable<any> {
    return this.apiService.get({ api: this.S3_CREDENTIALS_API });
  }
}
