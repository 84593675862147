import { Component, Input, OnInit } from '@angular/core';
import { faStar, faSortDown, faSortUp, faBoxOpen, faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: 'app-warehouse-item-client',
  templateUrl: './warehouse-item-client.component.html',
  styleUrls: ['./warehouse-item-client.component.scss']
})
export class WarehouseItemClientComponent implements OnInit {
  faStar = faStar;
  faBoxOpen = faBoxOpen;
  faSortDown = faSortDown;
  faSortUp = faSortUp;
  faMapMarkerAlt = faMapMarkerAlt;

  @Input() warehouse: any = {};
  constructor() { }

  ngOnInit(): void {
  }

}
