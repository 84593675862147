import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { HomeComponent } from "./public/home/home.component";
import { MyAccountComponent } from "./public/my-account/my-account.component";
import { ContactComponent } from "./public/contact/contact.component";
import { WarehouseCreateComponent } from "./public/warehouse/warehouse-create/warehouse-create.component";
import { MyWarehousesComponent } from "./public/warehouse/my-warehouses/my-warehouses.component";
import { SearchComponent } from "./public/search/search.component";
import { CalculaEspacioComponent } from "../app/calcula-espacio/calcula-espacio.component";
import { ReservasComponent } from "../app/public/reservas/reservas.component";
import { ReservasDetailComponent } from "../app/reservas-detail/reservas-detail.component";
import { ReservationAcceptedComponent } from "./public/reservation-accepted/reservation-accepted.component";
import { InventoryReserveComponent } from "./components/reserve/inventory-reserve/inventory-reserve.component";
import { MovementComponent } from "./components/movement/movement.component";
import { AdminGuard } from "./public/guards/admin.guard";
import { UserGuard } from "./public/guards/user.guard";
import { LoginComponent } from "../app/public/auth/login/login.component";
import { NotFoundComponent } from "./public/not-found/not-found.component";
import { PagosHistorialComponent } from "./pagos-historial/pagos-historial.component";
import { ReservasMineComponent } from "./public/reservas-mine/reservas-mine.component";
import { EditWarehouseComponent } from "../app/edit-warehouse/edit-warehouse.component";
import { SingupGuard } from "./public/guards/singup.guard";
import { MisBodegasDetailComponent } from "./public/mis-bodegas-detail/mis-bodegas-detail.component";
import { ReservasHistoryComponent } from "./reservas-history/reservas-history.component";
import { MyMessagesComponent } from "./public/my-messages/my-messages.component";
import { StatsComponent } from "./public/stats/stats.component";
import { PaymentReservationsComponent } from "./payment-reservations/payment-reservations.component";

const routes: Routes = [
  {
    path: "",
    children: [
      {
        path: "auth",
        loadChildren: () =>
          import("./auth/auth.module").then((m) => m.AuthModule),
      },
      {
        path: "",
        redirectTo: "home",
        pathMatch: "full",
      },
      {
        path: "home",
        component: HomeComponent,
      },
      {
        path: "not-autorized",
        component: NotFoundComponent,
      },
      {
        path: "login",
        component: LoginComponent,
      },
      {
        path: "cuenta",
        component: MyAccountComponent,
        canActivate: [UserGuard],
      },
      {
        path: "contacto",
        component: ContactComponent,
      },
      {
        path: "bodegas",
        loadChildren: () =>
          import("./warehouse-search/warehouse-search.module").then(
            (m) => m.WarehouseSearchModule
          ),
      },
      {
        path: "crear-bodega",
        component: WarehouseCreateComponent,
        canActivate: [UserGuard],
      },
      {
        path: "buscar",
        component: SearchComponent,
      },
      {
        path: "unidad",
        component: CalculaEspacioComponent,
        canActivate: [UserGuard],
      },
      {
        path: "mis-bodegas",
        component: MyWarehousesComponent,
        canActivate: [UserGuard],
      },
      {
        path: "editar-bodega/:id",
        component: EditWarehouseComponent,
        canActivate: [UserGuard],
      },
      {
        path: "reservas",
        component: ReservasComponent,
        canActivate: [UserGuard],
      },
      {
        path: "reservas/:id",
        component: ReservasDetailComponent,
        canActivate: [UserGuard],
      },
      {
        path: "admin",
        loadChildren: () =>
          import("./admin/admin.module").then((m) => m.AdminModule),
        canActivate: [AdminGuard],
      },
      {
        path: "otras-reservas/:id",
        component: ReservationAcceptedComponent,
        canActivate: [UserGuard],
      },
      {
        path: "mis-reservas/:id",
        component: ReservasMineComponent,
      },
      {
        path: "inventario/:id",
        component: InventoryReserveComponent,
        canActivate: [UserGuard],
      },
      {
        path: "movimiento/:id",
        component: MovementComponent,
        canActivate: [UserGuard],
      },
      {
        path: "pagos/:id",
        component: PagosHistorialComponent,
        canActivate: [UserGuard],
      },
      {
        path: "mis-bodegas/:id",
        component: MisBodegasDetailComponent,
        canActivate: [UserGuard],
      },
      {
        path: "historial-reservas/:id",
        component: ReservasHistoryComponent,
        canActivate: [UserGuard],
      },
      {
        path: "mis-mensajes",
        component: MyMessagesComponent,
        canActivate: [UserGuard],
      },
      {
        path: "estadisticas",
        component: StatsComponent,
        canActivate: [UserGuard],
      },
      {
        path: "pago-reserva",
        component: PaymentReservationsComponent,
        canActivate: [UserGuard],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
