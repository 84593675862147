<div class="stats-container">
  <h2>Estadísticas</h2>

  <div class="my-stats">
    <ngx-loading [show]="loading" [config]="{
        primaryColour: '#ffffff',
        secondaryColour: '#ccc',
        tertiaryColour: '#ffffff',
        backdropBorderRadius: '3px'
      }">
    </ngx-loading>
    <div class="item container-left-top">
      <div class="body-left">
        <div class="grid-left">
          <label class="title-left"> Órdenes pendientes </label>
          <label class="description-left">
            {{ movimientosPendientes?.length || 0 }}
          </label>
        </div>
        <div class="grid-mid">
          <label class="title-left"> Órdenes pendientes de ingreso </label>
          <label class="description-left">
            {{ movimientosAgregarPendientes?.length || 0 }}
          </label>
        </div>
        <div class="grid-right">
          <label class="title-left"> Órdenes pendientes de retiro </label>
          <label class="description-left">
            {{ movimientosRetiroPendientes?.length || 0 }}
          </label>
        </div>
      </div>
    </div>
    <div class="item container-right-top">
      <label class="title-left"> Órdenes aprobadas </label>
      <label class="description-left">
        {{ movimientosAprobados?.length || 0 }}
      </label>
    </div>
    <div class="item container-left-bottom ">
      <div class="graph-title-container">
        <h4 class="graph-title">Retiros por día</h4>
        <div>
          <label class="sub-title">Bodega:</label>
          <select class="select-month" [(ngModel)]="warehouseDaySelected" (change)="onChangeDay({ date: daySelected })">
            <option value="" disabled>Seleccione una bodega</option>
            <option value="Todas">Todas</option>
            <option *ngFor="let bodega of bodegas" [value]="bodega._id">{{ bodega.nombre }}</option>
          </select>
          <label class="sub-title">Fecha:</label>
          <app-calendar (newDate)="onChangeDay({ date: $event})"></app-calendar>
        </div>
      </div>
      <canvas #dailyChart style="width: 100%; height: 400px;"></canvas>
      <hr>
      <div class="graph-title-container">
        <h4 class="graph-title">Retiros por mes</h4>
        <div>
          <label class="sub-title">Bodega:</label>
          <select class="select-month" [(ngModel)]="warehouseMonthSelected" (change)="onUpdatedMonthCharts()">
            <option value="" disabled>Seleccione una bodega</option>
            <option value="Todas">Todas</option>
            <option *ngFor="let bodega of bodegas" [value]="bodega._id">{{ bodega.nombre }}</option>
          </select>
          <label class="sub-title">Fecha:</label>

          <select class="select-month" [(ngModel)]="monthSelected" (change)="onUpdatedMonthCharts()">
            <option *ngFor="let month of months" [value]="month.index">{{ month.name }}</option>
          </select>
          <select [(ngModel)]="yearSelected" (change)="onUpdatedMonthCharts()">
            <option *ngFor="let year of years" [value]="year">{{ year }}</option>
          </select>
        </div>

      </div>
      <canvas #monthlyChart style="width: 100%; height: 400px;"></canvas>
    </div>
  </div>
</div>