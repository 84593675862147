<div class="color-404-error">
  <div class="container">
    <h1>403</h1>
    <p>No estás autorizado a acceder a esta página</p>
    <img class="ghost" src="/assets/Logos/ghost.svg" alt="ghost" />
    <p class="shadowFrame">
      <img class="shadow" src="/assets/Logos/shadow.svg" alt="shadow" />
    </p>
    <button class="checkout--button" (click)="onLoginClick()">
      Inicia Sesion
    </button>
  </div>
</div>
