import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  HostListener,
} from "@angular/core";
import { AuthService } from "../services/auth.service";
import { UserService } from "../services/user.service";
import { ActivatedRoute } from "@angular/router";
import {
  faCheck,
  faSignOutAlt,
  faDollarSign,
  faCommentsDollar,
  faBoxes,
  faWarehouse,
  faConciergeBell,
  faBox,
  faCalendarAlt,
  faEye,
  faEyeSlash,
  faChevronLeft,
  faTimes,
  faTrash,
  faBoxOpen,
  faCheckCircle,
  faPlus,
  faQuestion,
} from "@fortawesome/free-solid-svg-icons";
import * as moment from "moment";
import { SuccessComponent } from "../shared/success/success.component";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { NavigationService } from "../services/navigation.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MyWarehousesService } from "../services/my-warehouses.service";
import { S3Service } from "../services/s3.service";
import { ApiService } from "src/app/public/services/api.service";
import { CreditCardValidators } from "angular-cc-library";
import { PagosService } from "../services/pagos.service";
import { forkJoin } from "rxjs";
@Component({
  selector: "app-my-account",
  templateUrl: "./my-account.component.html",
  styleUrls: ["./my-account.component.scss"],
})
export class MyAccountComponent implements OnInit {
  /**API VARIables */
  API_TARJETA = "api/tarjeta/";
  /**Regular variables */
  user: any = {};
  faPlus = faPlus;
  faQuestion = faQuestion;
  /**Font awesome variables */
  faCalendarAlt = faCalendarAlt;
  faBoxOpen = faBoxOpen;
  faBox = faBox;
  faTrash = faTrash;
  faCheck = faCheck;
  faBoxes = faBoxes;
  faWarehouse = faWarehouse;
  faConciergeBell = faConciergeBell;
  faCommentsDollar = faCommentsDollar;
  faSignOutAlt = faSignOutAlt;
  faDollarSign = faDollarSign;
  faChevronLeft = faChevronLeft;
  faTimes = faTimes;
  faEye = faEye;
  faEyeSlash = faEyeSlash;

  /**Counters */
  numberWarehouses = 0;
  numberSpaces = 0;
  /**Boolean variables */
  tenant: boolean = false;
  owner: boolean = true;
  edit: boolean = false;
  loading: boolean = false;

  /**Regular variables  */
  warehouses: any = [];
  reservations: any = [];
  cards: any = [];
  reservationsTenant: any = [];
  spaces: any = [];
  payments: any = [];
  total: any = "";
  totalSpaces: any = "";
  unidad: any = "";
  indexCard: any = "";
  cantidad: any = "";
  metros: boolean = false;
  estanteria: boolean = false;
  buscarProducto: any = "";
  tamanoMinimo: any = "";
  tamanoMaximo: any = "";
  cantidadMinima: any = "";
  metrosModel: any = "";
  primaryColour = "#ffffff";
  secondaryColour = "#ccc";
  cantidadMaxima: any = "";
  estantesModel: any = "";
  ciudadFiltro: any = "";
  cantidadEspacios: any = "";
  /** Variable that holds the type of the password input */
  oldType: string = "password";
  newType: string = "password";
  newConfirmType: string = "password";
  passwordSecurityTxt: string = "";
  panelOpenState = false;
  selected: boolean = false;
  bankRefPreview: any = "";
  bankRef: any = "";
  rutPreview: any = "";
  documentRut: any = "";
  rut: any = "";
  faCheckCircle = faCheckCircle;
  /**Cities and countries for the search bar */
  ciudades = [];

  paises = [];
  /** Variable that holds the form for the signup  */
  editForm: FormGroup;
  /** variable that holds the payment information form */
  paymentInformationForm: FormGroup;

  // Declare height and width variables
  scrWidth: any;

  displayComp: boolean = false;

  /** Array to set all pending movements */
  pendingMovements: any = [];
  /**Api variables type string */
  MOVIMIENTO_AGREGAR_API = "api/movimientoAgregar/user";
  MOVIMIENTO_RETIRO_API = "api/ordenRetiro/user";

  /** Variable to open or close Add credit card modal */
  public addCardModal: boolean = false;

  /**Check ig logged user is an admin */
  private usuario = JSON.parse(localStorage.getItem("flexpot-user"));
  isAnAdmin = this.usuario.tipo === "administrador";

  // FORMS
  passwordForm: FormGroup;
  @ViewChild("tarjetaModal", { static: false }) tarjetaModal: ElementRef;

  @HostListener("window:resize", ["$event"])
  getScreenSize(event?) {
    this.scrWidth = window.innerWidth;
  }

  constructor(
    private authService: AuthService,
    private router: Router,
    private userService: UserService,
    private navigationService: NavigationService,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private myWarehousesService: MyWarehousesService,
    private s3Service: S3Service,
    private dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private apiService: ApiService,
    private pagosService: PagosService
  ) {
    this.getLocations();
    this.getScreenSize();
  }

  async ngOnInit() {
    const id = this.authService.getUser()._id;
    await this.getUserById({ id: id });
    this.getWarehouseByUserId({ id: id });
    this.getReservationByUserId({ id: id });
    this.getSpacesByUserId({ id: id });
    this.getTotalByReservationId({ id: id });
    this.getReservationTenantByUserId({ id: id });
    this.onGetCards({ userId: id });
    this.getPendingmovements({ id });

    this.passwordForm = this.formBuilder.group({
      oldPassword: ["", Validators.required],
      newPassword: ["", Validators.required],
      newPasswordConfirm: ["", Validators.required],
    });
    this.editForm = this.formBuilder.group({
      nombre: [this.user.nombre, Validators.required],
      apellido: [this.user.apellido, Validators.required],
      documento: [this.user.tipoDocumento, Validators.required],
      numeroDocumento: [this.user.numeroDocumento, Validators.required],
      ciudad: [this.user.ciudad, Validators.required],
      direccion: [this.user.direccion, Validators.required],
      celular: [this.user.celular, Validators.required],
      telefonoFijo: [this.user.celular],
      refBancaria: [this.user.banco],
      RUT: [this.user.rut],
    });
    this.paymentInformationForm = this.formBuilder.group({
      informacionPersonal: this.formBuilder.group({
        nombreCompleto: ["", Validators.required],
        email: ["", [Validators.required, Validators.email]],
        // tipoDeDocumento: ['', Validators.required],
        numeroDeIdentificacion: ["", Validators.required],
      }),
      informacionTarjeta: this.formBuilder.group({
        fullCardName: ["", Validators.required],
        // idNumber: ['', Validators.required],
        cardNumber: [
          "",
          [Validators.required, CreditCardValidators.validateCCNumber],
        ],
        expDate: [
          "",
          [Validators.required, CreditCardValidators.validateExpDate],
        ],
        cvc: [
          "",
          [
            Validators.required,
            Validators.minLength(3),
            Validators.maxLength(4),
          ],
        ],
      }),
    });
  }

  /**
   * Function to get all movement of user
   * @param id user id
   */
  public getPendingmovements({ id }) {
    let addMovement = this.apiService.get({
      api: `${this.MOVIMIENTO_AGREGAR_API}/${id}`,
    });
    let removeMovement = this.apiService.get({
      api: `${this.MOVIMIENTO_RETIRO_API}/${id}`,
    });
    forkJoin([addMovement, removeMovement]).subscribe((data) => {
      this.pendingMovements = data[0].movimientosAgregarPendientes.concat(
        data[1].ordenesRetiroPendientes
      );
    });
  }

  /**
   * Function to route stats component
   */
  public onGoToStats() {
    this.navigationService.navigateTo({ path: "estadisticas" });
  }

  /**
   * Function to open Add credit card modal
   */
  public onOpenCreditCardModal() {
    this.addCardModal = true;
  }

  /**
   * Function to close Add credit card modal
   */
  public onCloseCreditCardModal() {
    this.addCardModal = false;
  }

  /**
   * Function to route reservations component
   */
  public onGoToRerservations({ type }) {
    this.navigationService.navigateTo({ path: "reservas", params: { type } });
  }

  /**
   * Function to route warehouses component
   */
  public onGoToWarehouses() {
    this.navigationService.navigateTo({ path: "mis-bodegas" });
  }

  public onEdit() {
    if (this.edit === false) {
      this.edit = true;
    } else {
      this.edit = false;
    }
  }
  public async getLocations() {
    let locations = await this.myWarehousesService.getLocations();
    for (let i = 0; i < locations.length; i++) {
      let element = locations[i];
      for (let j = 0; j < element.ciudades.length; j++) {
        this.ciudades.push(element.ciudades[j]);
      }
      this.paises.push(element.pais);
    }
  }

  /**
   * Handles document upload
   * @param name Name of the document
   * @param event Upload event ($event)
   */
  onUploadDocument({ name, event }: { name: string; event: any }): void {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      if (name === "Referencia-bancaria") {
        this.bankRef = file;
        this.bankRefPreview = URL.createObjectURL(file);
      } else {
        this.rut = file;
        this.rutPreview = URL.createObjectURL(file);
        this.documentRut = true;
      }
    }
  }
  async getUserById({ id }) {
    this.user = await this.userService.getUserByid({ id: id });
    this.bankRefPreview = this.user.banco;
    this.rutPreview = this.user.rut;
  }
  async getReservationByUserId({ id }: { id: string }) {
    this.reservations = await this.userService.getReservaByOwner({ id: id });
  }
  async getReservationTenantByUserId({ id }: { id: string }) {
    this.reservationsTenant = await this.userService.getReservaByTenat({
      id: id,
    });
    this.totalSpaces = this.calculateTotal({
      element: this.reservationsTenant,
    });
  }
  async getTotalByReservationId({ id }: { id: string }) {
    this.total = await this.userService.getReservaAndCalculateTotal({ id: id });
  }
  async getSpacesByUserId({ id }: { id: string }) {
    this.spaces = await this.userService.getSpacesByUserId({ id: id });
    // this.numberSpaces=this.warehouses.docs.length
  }
  async getWarehouseByUserId({ id }: { id: string }) {
    let value = "todos";
    this.warehouses = await this.userService.getWarehouseByUserId({
      value: value,
      id: id,
    });
    this.numberWarehouses = this.warehouses.docs.length;
  }
  /**
   * Handles the click on the login button
   */
  onLogoutClick(): void {
    this.authService.logout();
    this.navigationService.navigateTo({ path: "/home" });
  }
  public OnTenant() {
    this.tenant = true;
    this.owner = false;
  }
  public calculateTotal({ element }: { element: any }) {
    const total = element.reduce((accumulator, element) => {
      return accumulator + element.espacios.length;
    }, 0);
    return total;
  }
  public OnOwner() {
    this.tenant = false;
    this.owner = true;
  }

  /**
   * Navigates to create warehouse component
   */
  public goToCreateWarehouse() {
    this.navigationService.navigateTo({ path: "crear-bodega" });
  }

  /**
   * Navigates to admin component
   */
  public onGoToAdminPanel() {
    this.navigationService.navigateTo({ path: "admin" });
  }

  /**
   * Navigates to search component
   */
  public goToReservas() {
    this.navigationService.navigateTo({ path: "reservas", params: { type: "owner"} });
  }
  public onChangeUnidad(event) {
    if (event.target.value == "metros") {
      this.metros = true;
      this.estanteria = false;
      if (this.estantesModel != "") {
        this.estantesModel = "";
        this.cantidadMinima = "";
        this.cantidadMaxima = "";
      }
    } else if (event.target.value == "estanteria") {
      this.metros = false;
      this.estanteria = true;
      if (this.metrosModel != "") {
        this.metrosModel = "";
        this.tamanoMinimo = "";
        this.tamanoMaximo = "";
      }
    }
  }
  public onNavigateBuscador() {
    this.router.navigate(["buscar"], {
      queryParams: {
        ciudad: this.ciudadFiltro,
        metrosModel: this.metrosModel,
        estantesModel: this.estantesModel,
        buscarProducto: this.buscarProducto,
      },
    });
  }
  public onOpenPasswordModal({ content }: { content: any }) {
    this.clearFormPassword();
    this.oldType = "password";
    this.newType = "password";
    this.newConfirmType = "password";
    this.openModal({ content: content });
  }

  public clearFormPassword(): void {
    this.passwordForm = this.formBuilder.group({
      oldPassword: ["", Validators.required],
      newPassword: ["", Validators.required],
      newPasswordConfirm: ["", Validators.required],
    });
  }

  /**Handles opening the modal */
  public openModal({ content }: { content: any }) {
    this.modalService
      .open(content, { ariaLabelledBy: "modal-basic-title", size: "lg" })
      .result.then(
        (result) => {},
        (reason) => {}
      );
  }
  /**Opens a modal */
  open(content) {
    this.modalService
      .open(content, { size: "m", ariaLabelledBy: "modal-basic-title" })
      .result.then(
        (result) => {
          // this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  public onChangePassword({ modal }: { modal: any }): void {
    if (this.passwordForm.valid && this.checkNewPassword()) {
      let email = this.authService.getUser().email;
      let formValue = this.passwordForm.value;
      this.authService
        .changePasswordPromise({
          email: email,
          oldPassword: formValue.oldPassword,
          newPassword: formValue.newPassword,
        })
        .then((success) => {
          alert("Se cambió la contraseña correctamente.");
          this.authService.authenticateUser({
            email: email,
            password: formValue.newPassword,
            dialogRef: null,
          });
          modal.close("Cross yes");
        })
        .catch((err) => {
          alert(err.error.mensaje);
        });
    } else {
      this.passwordForm.markAllAsTouched();
    }
  }

  public onShowPassword({ passwordInputType }: { passwordInputType: string }) {
    if (passwordInputType === "old") {
      this.oldType = this.invertPassType({ passwordType: this.oldType });
    } else if (passwordInputType === "new") {
      this.newType = this.invertPassType({ passwordType: this.newType });
    } else {
      this.newConfirmType = this.invertPassType({
        passwordType: this.newConfirmType,
      });
    }
  }

  public fieldIsInvalid({ field }: { field: string }) {
    return (
      this.passwordForm.get(field)?.touched &&
      this.passwordForm.get(field)?.invalid
    );
  }

  public checkNewPassword(): Boolean {
    return (
      this.passwordForm.get("newPassword")?.value ===
      this.passwordForm.get("newPasswordConfirm")?.value
    );
  }

  private invertPassType({ passwordType }): string {
    if (passwordType === "text") {
      return "password";
    }

    return "text";
  }
  public reloadCurrentRoute() {
    let currentUrl = this.router.url;
    this.router.navigateByUrl("/", { skipLocationChange: true }).then(() => {
      this.router.navigate([currentUrl]);
    });
  }
  public onSubmit() {
    let id = this.user._id;
    let form = this.editForm.value;
    this.loading = true;
    //     // Upload both files to AWS and add them to the form
    let bankRefKey = "";
    let rutKey = "";
    // Setup the keys
    //In the case that the type of account is a business account
    if (this.user.juridica) {
      bankRefKey = `usuario-juridico/documentos/${form.numerodocumento}/referencia-bancaria`;
      rutKey = `usuario-juridico/documentos/${form.numerodocumento}}/rut`;
    } else {
      bankRefKey = `usuario-natural/documentos/${form.documento}/${form.numeroDocumento}/referencia-bancaria`;
      rutKey = `usuario-natural/documentos/${form.documento}/${form.numeroDocumento}/rut`;
    }

    // Upload the bank reference
    this.s3Service.uploadFile({
      file: this.bankRef,
      key: bankRefKey,
      callback: (err, data) => {
        if (err) {
          // If there is an error alert the user

          alert(
            "Hubo un error subiendo los archivos, por favor intente más tarde o contacte a soporte"
          );
        } else {
          // Assign the value to the corresponding component
          form.refBancaria = data.Location;
        }
      },
    });

    // Upload the bank reference
    this.s3Service.uploadFile({
      file: this.rut,
      key: rutKey,
      callback: (err, data) => {
        if (err) {
          this.loading = false;
          // If there is an error alert the user
          console.error(err);
          alert(
            "Hubo un error subiendo los archivos, por favor intente más tarde o contacte a soporte"
          );
        } else {
          // Assign the value to the corresponding component
          form.RUT = data.Location;
        }
      },
    });
    let profile = {
      nombre: form.nombre,
      apellido: form.apellido,
      email: form.email,
      tipoDocumento: form.documento,
      numeroDocumento: form.numeroDocumento,
      ciudad: form.ciudad,
      direccion: form.direccion,
      celular: form.celular,
      banco: form.refBancaria,
      rut: form.RUT,
    };
    this.userService
      .putProfile({ id: id, data: profile })
      .then(() => {
        this.loading = false;
        this.dialog.open(SuccessComponent, {
          data: {
            title: "¡Genial!",
            message: "Tu cuenta se ha actualizado exitosamente",
            onOk: () => {
              this.navigationService.navigateTo({ path: "cuenta" });
            },
          },
          disableClose: true,
        });
        this.reloadCurrentRoute();
      })
      .catch((err) => {
        alert(err.error.mensaje);
        this.loading = false;
      });
  }

  public onDelete(index) {
    let id = this.cards[index]._id;
    this.loading = true;
    this.apiService.delete({ api: `${this.API_TARJETA}${id}` }).subscribe(
      (res) => {
        this.dialog.open(SuccessComponent, {
          data: {
            title: "¡Genial!",
            message: "Tu tarjeta se ha eliminado extitosaente",
            onOk: () => {
              this.navigationService.navigateTo({ path: "cuenta" });
            },
          },
          disableClose: true,
        });
        this.loading = false;
        this.reloadCurrentRoute();
      },
      (err) => {
        this.loading = false;
        alert(JSON.stringify(err));
        alert("Hubo un error eliminando tu tarjeta contacta al administrador ");
      }
    );
  }
  public onSubmitClick() {
    this.loading = true;
    const informacionPersonal =
      this.paymentInformationForm.value.informacionPersonal;
    const informacionTarjeta =
      this.paymentInformationForm.value.informacionTarjeta;
    /**Fecha de expiracion tarjeta */
    let expDate = informacionTarjeta.expDate;
    let momentDate = moment(expDate, "MM/YYYY");
    /**Mes y año de expiracion */
    let expMonth = momentDate.format("MM");
    let expYear = momentDate.format("YY");
    /**Numero de tarjeta */
    let cc = informacionTarjeta.cardNumber;
    // cc=cc.replace(/^\s+|\s+$|\s+(?=\s)/g, "");
    cc = cc.replace(/\s+/g, "");
    /**cvc */
    let cvc = informacionTarjeta.cvc;
    /**Dueño tarjeta */
    let cardHolder = informacionTarjeta.fullCardName;

    // const franchise = this.franchiseElements.nativeElement.innerText;
    //Creting the payload form
    /**Construyedo el objeto a enviar */
    const tarjeta = {
      number: cc,
      cvc: cvc,
      exp_month: expMonth,
      exp_year: expYear,
      card_holder: cardHolder,
      preferidad: false,
    };

    this.pagosService
      .postTarjeta({ data: tarjeta })
      .then(() => {
        this.modalService.dismissAll();
        this.dialog.open(SuccessComponent, {
          data: {
            title: "Se ha creado la tarjeta de manera exitosa",
            onOk: () => {
              this.reloadCurrentRoute();
            },
          },
          disableClose: true,
        });
        this.loading = false;
      })
      .catch((err) => {
        this.loading = false;
        alert(err.error.mensaje);
      });
  }

  public onChooseTarjeta(index) {
    let card = this.cards[index];
    if (card.preferidad) {
      alert("Esta tarjeta ya es tu tarjeta preferida");
    } else if (card.preferidad === false && this.selected === true) {
      alert("Solo puedes tener una tarjeta preferida");
    } else {
      this.selected === true;
      this.indexCard = index;
      this.open(this.tarjetaModal);
    }
  }
  public onChangeCardStatus() {
    // this.loading=true;
    let cardChoosen = this.cards[this.indexCard]._id;
    let cardsNotChoosen = this.cards.filter((card) => {
      if (card.preferidad) {
        return card._id;
      }
    });
    let data = {
      preferedCards: cardsNotChoosen,
      cardToChange: cardChoosen,
    };
    this.userService
      .putCard({ data: data })
      .then(() => {
        this.loading = false;
        this.modalService.dismissAll();
        this.dialog.open(SuccessComponent, {
          data: {
            title: "¡Genial!",
            message: "Esta tarjeta ha sido escogida como la preferida",
            onOk: () => {
              this.navigationService.navigateTo({ path: "cuenta" });
            },
          },
          disableClose: true,
        });
        this.reloadCurrentRoute();
      })
      .catch((err) => {
        alert(err.error.mensaje);
        this.loading = false;
      });
  }

  public showDetails({ item }) {
    if (item == "form") {
      this.displayComp = !this.displayComp;
    }
  }

  public onCreationSuccess() {
    this.addCardModal = false;
    this.dialog.open(SuccessComponent, {
      data: {
        title: "Se ha creado la tarjeta de manera exitosa",
        onOk: () => {
          this.onGetCards({ userId: this.user._id });
        },
      },
      disableClose: true,
    });
  }

  /**
   * Function to get user cards
   * @param userId Id of user logged
   */
  public async onGetCards({ userId }) {
    this.cards = await this.userService.getTarjeta({ id: userId });
    this.dialog.closeAll();
  }
}
