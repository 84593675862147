import { Location } from "@angular/common";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { InventoryService } from "src/app/public/services/inventory.service";
import { ToastrService } from "ngx-toastr";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ReservationAcceptedService } from "src/app/public/services/reservation-accepted.service";
import { MovementService } from "src/app/public/services/movement.service";
import * as xlsx from "xlsx";
import { movimientoAgregar, ordenRetiro } from "src/app/interfaces/movements";

@Component({
  selector: "app-inventory-reserve",
  templateUrl: "./inventory-reserve.component.html",
  styleUrls: ["./inventory-reserve.component.scss"],
})
export class InventoryReserveComponent implements OnInit {
  // This is a var with settings for the smart table
  settings: any = {};
  // This is a array with all inventory of reserve get from back
  sourceInventory: ordenRetiro[];
  // This is a var with selection of flow
  flowOption: any = "";
  // This is a var with the file
  // fileToUpload: File | null = null;
  // This is a var with the info of file
  fileInfo: string;
  // This is a var to know is if possible continue
  continueButtonActive: boolean = false;
  // This is a var with reserve id
  reserveId: string = "";
  // This is a var to know how many items to movement
  itemsToMoveTotal: number = 0;
  // This is a var to edit with index
  temporalIndex: number = -1;
  // This is a array with element to create a flow to add
  inventoryToAdd: movimientoAgregar[] = [];
  // This is a array with all spaces names
  spacesNames: string[] = [];
  errorToSave: boolean = false;
  errorMessage: string = "";
  unidades: any = [];
  // FORMS
  productForm: FormGroup;
  // This is a var to spinner
  loading = false;
  primaryColour = "#ffffff";
  secondaryColour = "#ccc";
  constructor(
    private location: Location,
    private inventoryService: InventoryService,
    private movementService: MovementService,
    private activatedRoute: ActivatedRoute,
    private toastr: ToastrService,
    private router: Router,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private reservation: ReservationAcceptedService
  ) {
    this.reserveId = this.activatedRoute.snapshot.params["id"];
    this.productForm = this.formBuilder.group({
      sku: ["", Validators.required],
      nombre: ["", Validators.required],
      cantidad: [undefined, Validators.required],
      unidad: ["", Validators.required],
      valor: [undefined, Validators.required],
      peso: [undefined, Validators.required],
      volumen: [undefined],
      espacio: ["", Validators.required],
    });
  }

  ngOnInit(): void {
    this.configSmartTable();
    this.getNamesOfSpaces();
    this.initInventory();
    this.getUnits();
  }
  public async getUnits() {
    this.unidades = await this.inventoryService.getUnidades();
  }

  /**
   * Method that configures the LocalDataSource
   */
  private async initInventory(): Promise<void> {
    // Change struct for inventory of back to show in table
    let inv = await this.inventoryService.getInventoryByReserveId({
      id: this.reserveId,
    });
    let inventaryTable: ordenRetiro[] = [];

    for (let index = 0; index < inv.length; index++) {
      const element = inv[index];
      inventaryTable.push({
        producto: element.producto.nombre,
        cantidad: element.cantidad,
        sku: element.producto.sku,
        ubicacionNombre: element.espacio.nombre,
        cantidadAgregada: 0,
        espacioId: element.espacio._id,
        productoId: element.producto._id,
      });
    }

    this.sourceInventory = inventaryTable;

    let inventoryService = this.inventoryService.inventoryToRemove;
    for (let index = 0; index < inventoryService.length; index++) {
      const element = inventoryService[index];
      let findElement = this.findElementInSourceInventory({ sku: element.sku });
      if (findElement) {
        findElement.cantidadAgregada = element.cantidadAgregada;
        this.continueButtonActive = true;

        this.flowOption = "retiro";
        this.itemsToMoveTotal += element.cantidadAgregada;
      }
    }
  }

  private findElementInSourceInventory({ sku }: { sku: string }) {
    let skuNormalize = this.normalizeString({ str: sku });
    return this.sourceInventory.find(
      (item) => this.normalizeString({ str: item.sku }) === skuNormalize
    );
  }

  /**
   * Method that configures the smart table
   */
  private configSmartTable(): void {
    this.settings = {
      mode: "inline",
      pager: {
        display: true,
        perPage: 10,
      },
      actions: {
        columnWidth: "50px",
        columnTitle: "Acción",
        add: false,
        edit: false,
        delete: false,
        position: "right",
        custom: [
          {
            name: "add",
            title: '<i class="fa fa-plus"><span>Añadir</span></i>',
          },
          {
            name: "out",
            title: '<i class="fa fa-minus"><span>Retirar</span></i>',
          },
        ],
      },
      hideSubHeader: false,
      columns: {
        producto: {
          title: "Producto",
        },
        cantidad: {
          title: "Cantidad",
        },
        sku: {
          title: "SKU Producto",
        },
        ubicacionNombre: {
          title: "Ubicación",
        },
      },
      rowClassFunction: (row) => {
        if (row.data.cantidadAgregada > 0) {
          return "active";
        }
      },
      attr: {
        class: "table table-bordered",
      },
      noDataMessage: "El inventario está vacío",
    };
  }

  /**
   * This method create a toast
   * @param param0 type is a boolean with success of upload excel
   */
  private showToaster({
    type,
    message,
  }: {
    type: boolean;
    message: string;
  }): void {
    if (type) {
      this.toastr.success(message, "El archivo se ha subido correctamente.");
    } else {
      this.toastr.error(message, "Ha ocurrido un error al subir el archivo.");
    }
  }

  private getNamesOfSpaces(): void {
    this.reservation
      .getReserve({ id: this.reserveId })
      .then((response) => {
        for (let i = 0; i < response.espacios.length; i++) {
          const element = response.espacios[i];
          this.spacesNames.push(element.espacio.nombre);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  private findSpaceName({ spaceName }: { spaceName: string }) {
    let spaceNameNormalize = this.normalizeString({ str: spaceName });
    return this.spacesNames.find(
      (item) => this.normalizeString({ str: item }) === spaceNameNormalize
    );
  }

  private getAllSpacesNames(): string {
    let result = "";
    for (let spaceName of this.spacesNames) {
      result += spaceName + ", ";
    }

    return result.trim().slice(0, -1);
  }

  private checkDataMovementAddFromXlsx({
    movementAdd,
  }: {
    movementAdd: movimientoAgregar;
  }) {
    let spaceName = this.findSpaceName({ spaceName: movementAdd.espacio });
    let existMovement = this.existSkuMovementAdd({ sku: movementAdd.sku });
    let reserva = this.activatedRoute.snapshot.params["id"];
    if (!spaceName) {
      return `El nombre del espacio ${
        movementAdd.espacio
      } no existe en la reserva. \nLos nombres de espacio disponible son: ${this.getAllSpacesNames()}.\n`;
    }
    if (existMovement) {
      return `El producto con el sku ${movementAdd.sku} ya existe por favor ingrese informacion diferente o edite el producto existente desde la lista de productos agregados.\n`;
    }

    movementAdd.espacio = spaceName;
    return "";
  }

  private async createMovementAdd({ file }: { file: File }) {
    let dataFromXlsx = await this.convertExcelAddToObject({ file: file });
    if (dataFromXlsx instanceof Error) {
      alert(dataFromXlsx.message);
    }
    if (dataFromXlsx instanceof Array) {
      let WarningMessage = "";
      for (let movementAdd of dataFromXlsx) {
        let result = await this.checkDataMovementAddFromXlsx({
          movementAdd: movementAdd,
        });
        if (result === "") {
          this.addProductToArray({ product: movementAdd });
        } else {
          WarningMessage += result;
        }
      }
      if (WarningMessage) {
        alert(WarningMessage);
      }
    }
  }

  private async createMovementRemove({ file }: { file: File }) {
    let WarningMessage = "";
    this.itemsToMoveTotal = 0;
    let dataFromXlsx = await this.convertExcelRemoveToObject({ file: file });
    if (dataFromXlsx instanceof Error) {
      alert(dataFromXlsx.message);
    }
    if (dataFromXlsx instanceof Array) {
      for (let movementRemove of dataFromXlsx) {
        let findElement = this.findElementInSourceInventory({
          sku: movementRemove.sku,
        });
        if (findElement && findElement.cantidad < movementRemove.cantidad) {
          findElement.cantidadAgregada = findElement.cantidad;
          this.itemsToMoveTotal += findElement.cantidad;
          this.continueButtonActive = true;
          // WarningMessage += `La cantidad a retirar con el producto de sku ${movementRemove.sku} es mayor a la disponible.\n`;
        } else if (!findElement) {
          WarningMessage += `No se encontro el producto con sku ${movementRemove.sku} en el inventario para retirar.\n`;
        } else if (movementRemove.cantidad < 0) {
          WarningMessage += `No se permite ingresar cantidades negativas por favor verifica tu archivo`;
        } else {
          this.continueButtonActive = true;
          findElement.cantidadAgregada = movementRemove.cantidad;
          this.itemsToMoveTotal += movementRemove.cantidad;
        }
      }
    }
    if (WarningMessage) {
      alert(WarningMessage);
    }
  }

  private async convertExcelAddToObject({ file }: { file: File }) {
    try {
      let buffer = await file.arrayBuffer();
      // read workbook
      const wb = xlsx.read(buffer, { type: "buffer" });
      // grab first sheet
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      let data = xlsx.utils.sheet_to_json(ws, { header: 1, blankrows: false });
      if (
        data[0][0] != "SKU" ||
        data[0][1] != "Nombre producto" ||
        data[0][2] != "Cantidad" ||
        data[0][3] != "Unidad" ||
        data[0][4] != "Valor" ||
        data[0][5] != "Peso (kg)" ||
        data[0][6] != "Volumen (m3)" ||
        data[0][7] != "Espacio"
      ) {
        return new Error(
          "El archivo no tiene las mismas columnas que la platilla agregar."
        );
      } else {
        let obligatoryFields = false;
        let response: movimientoAgregar[] = [];

        if (data.length === 1) {
          return new Error("No hay elementos.");
        }

        for (let i = 1; i < data.length; i++) {
          if (
            !data[i][0] ||
            !data[i][1] ||
            !data[i][2] ||
            !data[i][3] ||
            !data[i][4] ||
            !data[i][5] ||
            !data[i][7]
          ) {
            obligatoryFields = true;
            break;
          }

          let movement: movimientoAgregar = {
            sku: data[i][0] + "",
            nombre: data[i][1] + "",
            cantidad: parseInt(data[i][2]),
            unidad: data[i][3] + "",
            valor: parseFloat(data[i][4]),
            peso: parseFloat(data[i][5]),
            volumen: parseFloat(data[i][6]),
            espacio: data[i][7] + "",
          };
          response.push(movement);
        }

        if (obligatoryFields) {
          return new Error(
            "Los campos sku, nombre, cantidad, unidad, valor, peso y espacio son obligatorios porfavor revise que los tenga.\n"
          );
        }

        return response;
      }
    } catch (err) {
      console.error("Error obteniendo el buffer", err);
      return new Error("Error obteniendo la informacion del excel.");
    }
  }

  private async convertExcelRemoveToObject({ file }: { file: File }) {
    try {
      let buffer = await file.arrayBuffer();
      // read workbook
      const wb = xlsx.read(buffer, { type: "buffer" });
      // grab first sheet
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      let data = xlsx.utils.sheet_to_json(ws, { header: 1, blankrows: false });
      if (data[0][0] != "SKU" || data[0][1] != "Cantidad") {
        return new Error(
          "El archivo no tiene las mismas columnas que la platilla agregar."
        );
      } else {
        let obligatoryFields = false;
        let response: ordenRetiro[] = [];

        if (data.length === 1) {
          return new Error("No hay elementos.");
        }

        for (let i = 1; i < data.length; i++) {
          if (!data[i][0] || !data[i][1]) {
            obligatoryFields = true;
            break;
          }

          let product: ordenRetiro = {
            sku: data[i][0] + "",
            cantidad: parseInt(data[i][1]),
          };
          response.push(product);
        }

        if (obligatoryFields) {
          return new Error(
            "Los campos sku y cantidad son obligatorios porfavor revise que los tenga.\n"
          );
        }

        return response;
      }
    } catch (err) {
      console.error("Error obteniendo el buffer", err);
      return new Error("Error obteniendo la informacion del excel.");
    }
  }

  /**
   * To clear values of flows
   */
  private clearMovements(): void {
    this.itemsToMoveTotal = 0;
    this.inventoryToAdd = [];

    for (let i = 0; i < this.sourceInventory.length; i++) {
      const element = this.sourceInventory[i];
      element.cantidadAgregada = 0;
    }
  }

  private existSkuMovementAdd({ sku }: { sku: string }) {
    let skuNormalize = this.normalizeString({ str: sku });
    return this.inventoryToAdd.find(
      (item) => this.normalizeString({ str: item.sku }) === skuNormalize
    );
  }

  private normalizeString({ str }: { str: string }) {
    return str
      .toLowerCase()
      .normalize("NFD")
      .trim()
      .replace(/[\u0300-\u036f]/g, "");
  }

  /**
   * To go to the previous view
   */
  onBackClicked(): void {
    this.router.navigate(["otras-reservas/" + this.reserveId]);
  }

  /**
   * To get xlxs file to add inventory
   *
   * @param input the file input HTMLElement
   */
  onUploadFile({ input }: { input: File }): void {
    this.loading = true;
    // this.clearMovements();
    /**
     * Format the size to a human readable string
     *
     * @param bytes
     * @returns the formatted string e.g. `105 kB` or 25.6 MB
     */
    function formatBytes(bytes: number): string {
      const UNITS = ["Bytes", "kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
      const factor = 1024;
      let index = 0;

      while (bytes >= factor) {
        bytes /= factor;
        index++;
      }

      return `${parseFloat(bytes.toFixed(2))} ${UNITS[index]}`;
    }

    if (this.flowOption === "retiro") {
      this.createMovementRemove({ file: input });
    } else {
      this.createMovementAdd({ file: input });
    }
    this.loading = false;
  }

  /**
   * To clear values of flows
   */
  onClear(): void {
    this.continueButtonActive = false;
    this.flowOption = "";
    this.itemsToMoveTotal = 0;
    this.inventoryToAdd = [];

    for (let i = 0; i < this.sourceInventory.length; i++) {
      const element = this.sourceInventory[i];
      element.cantidadAgregada = 0;
    }
  }

  /**
   * To continue with the proccess
   */
  async onContinue({ content }: { content: any }): Promise<void> {
    // Excel
    // let data = await this.inventoryService.postExcel({id: this.reserveId, data: this.fileToUpload});

    // if (data.error) {
    //   this.showToaster({type: data.success, message: data.error.message});
    // } else {
    //   this.showToaster({type: data.success, message: data.message});
    // }

    // this.onDeleteFileClicked();
    if (this.flowOption == "ingreso") {
      this.errorToSave = false;
      this.errorMessage = "";
      this.movementService
        .postMovementAdd({ id: this.reserveId, data: this.inventoryToAdd })
        .then((result) => {
          console.log(result);
          this.continueButtonActive = false;
          this.inventoryToAdd = [];
        })
        .catch((err) => {
          this.errorToSave = true;
          this.errorMessage = err.error.mensaje;
        });
      this.openModal({ content: content });
    } else {
      let inventoryToRemove: ordenRetiro[] = [];

      for (let item of this.sourceInventory) {
        if (item.cantidadAgregada > 0) {
          inventoryToRemove.push(item);
        }
      }

      this.inventoryService.inventoryToRemove = inventoryToRemove;
      this.inventoryService.itemsToMoveTotal = this.itemsToMoveTotal;

      this.router.navigate(["movimiento/" + this.reserveId]);
    }
  }

  /**
   * This method is associated to the smart table to obtain each element and add it to the list.
   * @param event Element of the inventory arrangement
   */
  onCustom({ event }: { event: any }): void {
    if (event.action === "out") {
      if (event.data.cantidadAgregada === 0) {
        event.data.cantidadAgregada = 1;
        this.continueButtonActive = true;

        this.flowOption = "retiro";
        this.itemsToMoveTotal++;
      }
    } else {
      if (event.data.cantidadAgregada === 0) {
        this.continueButtonActive = true;
        let newItem = { ...event.data };
        newItem.cantidad = 0;
        this.flowOption = "ingreso";
        let indexValidation = this.inventoryToAdd.findIndex(
          (i) => i.sku == newItem.sku
        );
        if (indexValidation == -1) this.inventoryToAdd.push(newItem);
        else alert("Este producto ya fue añadido a la orden de ingreso");
      }
    }
  }

  /**
   * This method removes the order element from the dictionary.
   * @param key item identifier
   */
  onRemoveItemDelete({ index }: { index: number }): void {
    this.itemsToMoveTotal -= this.sourceInventory[index].cantidadAgregada;
    this.sourceInventory[index].cantidadAgregada = 0;

    if (this.itemsToMoveTotal === 0) {
      this.continueButtonActive = false;
    }
  }

  /**
   * This method takes care of increasing the quantity to add
   * @param key item identifier
   */
  onRemoveItemAdd({ index }: { index: number }): void {
    this.sourceInventory[index].cantidadAgregada += 1;
    this.itemsToMoveTotal++;
  }

  /**
   * This method takes care of decreasing the quantity to add
   * @param key item identifier
   */
  onRemoveItemLess({ index }: { index: number }): void {
    this.sourceInventory[index].cantidadAgregada -= 1;
    this.itemsToMoveTotal--;
  }

  /**
   * This method removes the element of inventory to create
   * @param key item identifier
   */
  onAddItemDelete({ index }: { index: number }): void {
    this.inventoryToAdd.splice(index, 1);

    if (this.inventoryToAdd.length === 0) {
      this.continueButtonActive = false;
    }
  }

  /**
   * This method takes care of increasing the quantity to add in invetory to create
   * @param key item identifier
   */
  onAddItemAdd({ index }: { index: number }): void {
    this.inventoryToAdd[index].cantidad++;
  }

  /**
   * This method takes care of decreasing the quantity to add in invetory to create
   * @param key item identifier
   */
  onAddItemLess({ index }: { index: number }): void {
    this.inventoryToAdd[index].cantidad--;
  }

  onEditProduct({ index, content }: { index: number; content: any }): void {
    let product = this.inventoryToAdd[index];

    this.productForm.patchValue({
      sku: product.sku,
      nombre: product.nombre,
      cantidad: product.cantidad,
      unidad: product.unidad,
      valor: product.valor,
      peso: product.peso,
      volumen: product.volumen,
      espacio: product.espacio,
    });
    this.temporalIndex = index;
    this.openModal({ content: content });
  }

  onCreateProduct({ content }: { content: any }): void {
    this.temporalIndex = -1;
    this.clearForm();
    this.openModal({ content: content });
  }

  /**Handles opening the modal */
  openModal({ content }: { content: any }) {
    this.modalService
      .open(content, { ariaLabelledBy: "modal-basic-title", size: "lg" })
      .result.then(
        (result) => {
          let valueform = this.productForm.value;
          let product: movimientoAgregar = {
            sku: valueform.sku.trim(),
            nombre: valueform.nombre.trim(),
            cantidad: valueform.cantidad,
            unidad: valueform.unidad.trim(),
            valor: valueform.valor,
            peso: valueform.peso,
            volumen: valueform.volumen,
            espacio: valueform.espacio.trim(),
          };
          if (this.temporalIndex === -1) {
            this.addProductToArray({ product: product });
          } else {
            this.inventoryToAdd[this.temporalIndex] = product;
          }
        },
        (reason) => {}
      );
  }

  addProductToArray({ product }: { product: movimientoAgregar }) {
    this.continueButtonActive = true;
    this.inventoryToAdd.push(product);
  }

  clearForm(): void {
    this.productForm = this.formBuilder.group({
      sku: ["", Validators.required],
      nombre: ["", Validators.required],
      cantidad: [undefined, Validators.required],
      unidad: ["", Validators.required],
      valor: [undefined, Validators.required],
      peso: [undefined, Validators.required],
      volumen: [undefined],
      espacio: ["", Validators.required],
    });
  }

  fieldIsInvalid({ field }: { field: string }) {
    return (
      this.productForm.get(field)?.touched &&
      this.productForm.get(field)?.invalid
    );
  }

  async addProduct({ modal }: { modal: any }) {
    let id = this.activatedRoute.snapshot.params["id"];
    let sku = this.productForm.value.sku.trim();
    let existMovement = this.existSkuMovementAdd({ sku: sku });
    this.inventoryService.getSkuRepetead({ id: id, data: { sku: sku } }).then(
      (res) => {
        if (existMovement) {
          alert("Ya existe un producto con ese SKU");
          this.productForm.markAllAsTouched();
        } else if (this.productForm.valid) {
          modal.close("Cross yes");
        } else {
          this.productForm.markAllAsTouched();
        }
      },
      (err) => {
        alert("Ya existe un producto con ese SKU");
        this.productForm.markAllAsTouched();
      }
    );
  }
}
