<div class="main-container">
  <div class="container-button">
    <button class="back-button" (click)="onBackClicked()">
      <img src="./assets/Utils/flecha.png" class="image" />
      Volver
    </button>
  </div>
  <div class="container-info">
    <div class="container-headers">
      <h3>Detalles del movimiento</h3>
      <!-- <div class="headers-buttons">
        <select [(ngModel)]="managementOption" class="select-input" (ngModelChange)="onChangeSelect()">
          <option value="" disabled selected>Gestion de entrega</option>
          <option value="externa">Externa</option>
          <option value="flexpot">Gestion con flexpot</option>
        </select>

        <img src="./assets/Utils/info.png" class="image" />
      </div> -->
    </div>
    <div class="container-elements-gestion-flexpot" *ngIf="managementOption === 'flexpot'">
      <div class="container-elements-headers">
        <p>{{ itemsToMoveTotal }} Unidades seleccionadas</p>
        <!-- <button class="mod-button" (click)="onModClicked()">Modificar seleccion</button> -->
      </div>

      <div class="container-labels-info">
        <p>{{ inventoryToRemove.length }} Referencias</p>
      </div>
      <hr />
      <div class="container-labels-info">
        <p>Indica los puntos de entrega</p>
        <img src="./assets/Utils/info.png" class="image" />
      </div>
      <form class="container-shipping-info" [formGroup]="dataInformation">
        <div class="form-div">
          <select (change)="getPriceBodega($event)" formControlName="cityOption" class="city-input"
            [ngClass]="{'red-border': fieldIsInvalid({field: 'cityOption'})}">
            <option value="" disabled selected>Selecciona tu departamento</option>
            <option *ngFor="let ciudad of citiesList" value="{{ ciudad.costo }}">
              {{ ciudad.ciudadDestino }}
            </option>
          </select>
          <span *ngIf="fieldIsInvalid({ field: 'cityOption' })" class="invalid-warning">Campo obligatorio</span>
        </div>
        <div class="form-div">
          <input class="label" type="address" id="address" placeholder="Dirección de entrega" formControlName="address"
            [ngClass]="{ 'red-border': fieldIsInvalid({ field: 'address' }) }" />
          <span *ngIf="fieldIsInvalid({ field: 'address' })" class="invalid-warning">Campo obligatorio</span>
        </div>
        <div class="form-div">
          <input class="label" type="name" id="name" placeholder="Nombre completo encargado" formControlName="fullName"
            [ngClass]="{ 'red-border': fieldIsInvalid({ field: 'fullName' }) }" />
          <span *ngIf="fieldIsInvalid({ field: 'fullName' })" class="invalid-warning">Campo obligatorio</span>
        </div>
        <div class="form-div">
          <input class="label" (keyup)="onValidateSpecialCharacters({ type: 'phone' })" type="tel" id="celular"
            placeholder="Celular encargado" formControlName="mobile"
            [ngClass]="{'red-border': fieldIsInvalid({field: 'mobile'})}" />
          <span *ngIf="fieldIsInvalid({field: 'mobile'})" class="invalid-warning">Campo obligatorio</span>
        </div>
      </form>
      <div class="container-elements-headers">
        <p>Unidades a entregar</p>
        <button class="mod-button" (click)="onModClicked()">Modificar selección</button>
        <!-- <br /> -->
        <!-- <span *ngIf="totalProducts == 0" class="invalid-warning">No hay productos</span> -->
      </div>
      <div class="container-items">
        <div class="item" *ngFor="let item of inventoryToRemove; let i = index">
          <div class="sku-name">
            <!-- <button class="sku-item-button" (click)="onRemoveItemDelete({index: i})">
              <span class="material-icons"> close </span>
            </button> -->
            <p>{{ item.sku }}</p>
            <p>-</p>
            <p>{{ item.producto }}</p>
          </div>

          <div class="unidades">
            <!-- <button class="remove-item-button" (click)="onRemoveItemLess({index: i})"
              [disabled]="item.cantidadAgregada > 1 ? null : 'disabled'">
              <span class="material-icons"> remove </span>
            </button> -->
            <p>{{ item.cantidadAgregada }}</p>

            <!-- <button class="add-item-button" (click)="onRemoveItemAdd({index: i})"
              [disabled]="item.cantidadAgregada < item.cantidad ? null : 'disabled'">
              <span class="material-icons"> add </span>
            </button> -->
          </div>
        </div>
      </div>
      <div class="costo-mercancia">
        <p>Calcula el costo de transporte</p>
        <div class="costo-mercancia-form">
          <form [formGroup]="shippingCost">
            <div class="costo-mercancia-form--flex">
              <div class="costo-mercancia-form-input">
                <label for="peso">Peso (Kg)</label>
                <input class="label" type="tel" id="peso" placeholder="Peso" formControlName="peso"
                  (keyup)="onValidateSpecialCharacters({ type: 'peso' })" (change)="onCalculateCosto()"
                  [ngClass]="{'red-border': costIsInvalid({field: 'peso'})}" />
                <span *ngIf="costIsInvalid({field: 'peso'})" class="invalid-warning">Campo obligatorio</span>
              </div>
              <div class="costo-mercancia-form-input">
                <label for="volumen">Volumen mercancía (mt3)</label>
                <input class="label" type="tel" id="volumen" placeholder="Volumen" formControlName="volumen"
                  (keyup)="onValidateSpecialCharacters({ type: 'volumen' })" (change)="onCalculateCosto()"
                  [ngClass]="{'red-border': costIsInvalid({field: 'volumen'})}" />
                <span *ngIf="pesoVolumetrico">Peso Volumetrico: {{ pesoVolumetrico }}</span>
                <span *ngIf="costIsInvalid({field: 'volumen'})" class="invalid-warning">Campo obligatorio</span>
              </div>
              <div class="costo-mercancia-form-input">
                <label for="valorMercancia">Valor mercancía (COP)</label>
                <input class="label" type="valorMercancia" id="valorMercancia" placeholder="Valor mercancía en COP"
                  formControlName="valorMercancia" (change)="onCalculateCosto()"
                  (keyup)="onValidateSpecialCharacters({ type: 'valor' })"
                  [ngClass]="{'red-border': costIsInvalid({field: 'valorMercancia'})}" />
                <span *ngIf="costIsInvalid({field: 'valorMercancia'})" class="invalid-warning">Campo obligatorio</span>
              </div>
            </div>
            <div class="costo-mercancia-form--info">
              <p>Factor de conversión: {{ factorConversionValue }}</p>
              <p>Porcentaje seguro: {{ porcentajeSeguroValue }}%</p>
              <p>Costo por kilo : {{ costoKg | currency: "COP ":"code" }}</p>
              <h2 class="total">
                Total costo transporte: {{ totalTransportation | currency: "COP ":"code" }}
              </h2>
            </div>
          </form>
        </div>
      </div>
      <hr />
      <div class="container-right-items-footer">
        <button *ngIf="!loading" class="continue-button" (click)="onClick({content: checkout})"
          [disabled]="!totalTransportationValidation">Continuar</button>
        <mat-progress-spinner *ngIf="loading" class="spinner" diameter="40" mode="indeterminate"></mat-progress-spinner>
      </div>
    </div>
    <!-- <form class="container-elements-getion-externa" [formGroup]="dataInformationExt"
      *ngIf="managementOption === 'externa'">
      <div class="row">
        <span class="col-sm-12">¿Quién recoge la mercancía?</span>
        <div class="col-sm-6 div-col">
          <input class="label" type="name" id="name" placeholder="Nombre completo encargado" formControlName="fullName"
            [ngClass]="{
              'red-border': fieldExIsInvalid({ field: 'fullName' })
            }" />
          <span *ngIf="fieldExIsInvalid({ field: 'fullName' })" class="invalid-warning">Campo obligatorio</span>
        </div>
        <div class="col-sm-6 div-col">
          <input class="label" type="tel" id="celular" placeholder="Celular encargado" formControlName="mobile"
            [ngClass]="{ 'red-border': fieldExIsInvalid({ field: 'mobile' }) }" />
          <span *ngIf="fieldExIsInvalid({ field: 'mobile' })" class="invalid-warning">Campo obligatorio</span>
        </div>
        <span class="col-sm-12">¿Cuándo?</span>
        <div class="col-sm-6 div-col">
          <select class="label" (change)="onChangeDate($event)" formControlName="date"
            [ngClass]="{ 'red-border': fieldExIsInvalid({ field: 'date' }) }">
            <option value="" disabled selected>Fecha</option>
          </select>
          <span *ngIf="fieldExIsInvalid({ field: 'date' })" class="invalid-warning">Campo obligatorio</span>
        </div>
        <div class="col-sm-6 div-col">
          <select class="label" (change)="onChangeHour($event)" formControlName="hour"
            [ngClass]="{ 'red-border': fieldExIsInvalid({ field: 'hour' }) }">
            <option value="" disabled selected>Selecciona la hora</option>
          </select>
          <span *ngIf="fieldExIsInvalid({ field: 'hour' })" class="invalid-warning">Campo obligatorio</span>
        </div>
      </div>
    </form> -->
  </div>
</div>

<!-- Modal checkout -------------------------------------------------- -->
<ng-template #checkout let-modal>
  <div class="modal-header">
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross exit')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="modal--body--circle--outside">
      <div class="modal__body--circle--inside">
        <fa-icon [icon]="faQuestion"></fa-icon>
      </div>
    </div>
    <div class="modal--body-question">
      <h1>¿Estás seguro de crear el siguiente movimiento de retiro?</h1>
      <p>Esta acción no tiene vuelta atrás</p>
    </div>
    <div class="modal--body-options">
      <button (click)="modal.dismiss('Cross cancel')" class="cancel">
        No,cancelar
      </button>
      <button class="yes" (click)="putDataInBack({ content: succesOrError }); 
      modal.dismiss('Cross yes')">
        Si, seguro
      </button>
    </div>
  </div>
</ng-template>

<!-- Modal succes or error -------------------------------------------------- -->
<ng-template #succesOrError let-modal>
  <div class="modal-header">
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross exit')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <img src="./assets/Utils/exito2.png" *ngIf="!errorToSave" class="modal-image" />
    <img src="./assets/Utils/error.png" *ngIf="errorToSave" class="modal-image" />
    <div class="modal--body-question">
      <h1 *ngIf="!errorToSave">¡Yaay!</h1>
      <p *ngIf="!errorToSave">
        Tu orden fue subida con éxito, quedará programado el movimiento para la fecha indicada
      </p>
      <h1 *ngIf="errorToSave">¡Ha ocurrido un error!</h1>
      <p *ngIf="errorToSave">{{ errorMessage }}</p>
    </div>
    <div class="modal--body-options">
      <button (click)="modal.dismiss('Cross yes')" class="yes">Listo</button>
      <br>
      <p class="go-to-profile" *ngIf="wompiError" (click)="modal.dismiss('Cross yes'); onGoToProfile()">
        Ir al perfil
      </p>
    </div>
  </div>
</ng-template>