import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Inject, Injectable, PLATFORM_ID } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { AuthService } from "./auth.service";

@Injectable({
  providedIn: "root"
})
export class ApiService {
  /** API Access - ENV */
  public baseUrl: string = "";

  /** Variable that holds the http headers */
  private headers: HttpHeaders;

  constructor(
    private http: HttpClient,
    private autService: AuthService,
    @Inject(PLATFORM_ID) private platformId
  ) {
    this.setConfig();
  }

  public setConfig() {
    this.baseUrl = environment.baseUrl;
    this.headers = new HttpHeaders();
    let token = this.autService.getToken();
    if (token != null) this.headers = this.headers.set("Authorization", this.autService.getToken());
    this.headers = this.headers.set("Content-Type", "application/json");
  }

  /**
   * Executes get instruction from the backend on the given API.
   * @param api api path
   */
  get({ api }: { api: string }): Observable<any> {
    this.setConfig();
    return this.http.get(`${this.baseUrl}${api}`, { headers: this.headers });
  }

  /**
   * Executes delete instruction from the backend on the given API.
   * @param api api path
   */
  delete({ api }: { api: string }): Observable<any> {
    this.setConfig();
    return this.http.delete(`${this.baseUrl}${api}`, { headers: this.headers });
  }

  /**
   * Executes get instruction from the backend on the given API.
   * @param api api path
   */
  postExcelTypePromise({ api, fileToUpload }: { api: string; fileToUpload: File }): Promise<any> {
    // Cambiar forma
    return new Promise((success, reject) => {
      let headers = new HttpHeaders();
      headers = headers.set("Authorization", this.autService.getToken());

      let formData: FormData = new FormData();
      formData.append("excel", fileToUpload, fileToUpload.name);

      this.http
        .post(`${this.baseUrl}${api}`, formData, {
          headers: headers
        })
        .subscribe(
          (data) => {
            success(data);
          },
          (err) => {
            reject(err);
            console.error(`Ocurrio un error ${err}`);
          }
        );
    });
  }

  /**
   * Executes get instruction from the backend on the given API.
   * @param api api path
   * @param queryParams queryParams to send aditional information
   */
  getWithParams({ api, queryParams }: { api: string; queryParams: HttpParams }): Observable<any> {
    this.setConfig();
    return this.http.get(`${this.baseUrl}${api}`, { headers: this.headers, params: queryParams });
  }

  /**
   * Executes the post instruction in the backend, on the given API
   * @param api api path
   * @param data data used for the post instruction
   */
  post({ api, data }: { api: string; data: any }): Observable<any> {
    this.setConfig();
    return this.http.post(`${this.baseUrl}${api}`, data, {
      headers: this.headers
      // observe: "response"
    });
  }
  /**
   * Executes the post instruction in the backend, on the given API
   * @param api api path
   * @param data data used for the post instruction
   */
  postResponse({ api, data }: { api: string; data: any }): Observable<any> {
    this.setConfig();
    return this.http.post(`${this.baseUrl}${api}`, data, {
      headers: this.headers,
      observe: "response"
    });
  }

  /**
   * Executes the post instruction in the backend, on the given API
   * @param api api path
   * @param data data used for the post instruction
   */
  postPromise({ api, data }: { api: string; data: any }): Promise<any> {
    this.setConfig();
    return this.http.post(`${this.baseUrl}${api}`, data, { headers: this.headers }).toPromise();
  }

  /**
   * Executes the put instruction in the backend, on the given API
   * @param api api path
   * @param data data used for the put operation
   */
  putPromise({ api, data }: { api: string; data: any }) {
    this.setConfig();
    return this.http.put(`${this.baseUrl}${api}`, data, { headers: this.headers }).toPromise();
  }

  /**
   * Executes the put instruction in the backend, on the given API
   * @param api api path
   * @param data data used for the put operation
   */
  put({ api, data }: { api: string; data: any }): Observable<any> {
    this.setConfig();
    return this.http.put(`${this.baseUrl}${api}`, data, {
      headers: this.headers
    });
  }

  /**
   * Executes the get instruction in the backend but returns a promise instead of an observable
   * @param api api path
   */
  getAsPromise({ api }: { api: string }): Promise<any> {
    this.setConfig();
    return this.http.get(`${this.baseUrl}${api}`, { headers: this.headers }).toPromise();
  }

  /**
   * Executes the get instruction in the backend but returns a promise instead of an observable
   * @param api api path
   */
  deleteAsPromise({ api }: { api: string }): Promise<any> {
    this.setConfig();
    return this.http.delete(`${this.baseUrl}${api}`, { headers: this.headers, observe: "response" }).toPromise();
  }
}
