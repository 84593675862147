<div class="home">
  <!-- Landing page section -->
  <section class="landing">
    <div class="landing-container">
      <div class="banner">
        <div class="row">
          <div class="col-lg-5">
            <img
              src="../../../assets/Home/ilustracion-1.png"
              class="main-page-image"
            />
          </div>
          <div class="col-lg-7">
            <div class="banner-text">
              Un lugar <span class="banner-text-exalted">flexible</span> <br />
              desde dónde tendrás <br />
              todo el control en logística
            </div>
          </div>
        </div>
      </div>
      <div class="search">
        <div class="search-title">Encuentra un espacio</div>
        <div class="search-bar">
          <div class="row">
            <div class="col-sm-6 flex">
              <select (change)="onPais($event)" class="search-input pais">
                <option value="" disabled selected>País</option>
                <option
                  *ngFor="let location of locations; index as i"
                  value="{{ i }}"
                >
                  {{ location.pais }}
                </option>
              </select>
              <select [(ngModel)]="ciudadFiltro" class="search-input">
                <option value="" disabled selected>Ciudad</option>
                <option *ngFor="let ciudad of ciudades" value="{{ ciudad }}">
                  {{ ciudad }}
                </option>
              </select>
            </div>
            <div class="col-sm-3"></div>
            <div class="col-sm-3">
              <button id="search-button" (click)="onNavigateBuscador()">
                Buscar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!--About us section-->
  <section class="about-us">
    <div class="about-us-container">
      <div class="about-us-title">
        Nosotros desarrollamos soluciones <br />
        <span class="about-us-exalted">que se adaptan a ti</span>
      </div>
      <div class="about-us-description">
        <span class="orange">Flexpot</span> es una plataforma tecnológica que te
        conecta con la red de almacenamiento más grande del país de manera
        sencilla, rápida y flexible.
      </div>
      <img id="pod-image" src="../../../assets/Home/ilustracion-2.png" />
      <div class="about-us-description">
        ¡Contratar espacios de almacenamiento será tan fácil como comprar en
        internet!
      </div>
    </div>
  </section>
  <!--Advantages section-->
  <section class="advantages">
    <div class="advantages-container">
      <div class="advantages-title">
        <span class="orange-secondary">Nuestro aporte </span>para tu crecimiento
      </div>
      <div class="advantages-list">
        <div class="row">
          <div class="col-sm-4">
            <div class="col-sm-12 advantage-item">
              <div class="row">
                <div class="col-sm-3">
                  <img
                    class="advantage-img"
                    src="../../../assets/Home/aporte-1.png"
                  />
                </div>
                <div class="col-sm-9">
                  <div class="advantage-title">
                    Sin cláusulas de permanencia
                  </div>
                </div>
                <div class="col-sm-12">
                  <div class="advantage-description">
                    Olvídate de los complicados contratos a largo plazo;
                    contrata y cancela el servicio cuando lo consideres
                    necesario
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="col-sm-12 advantage-item">
              <div class="row">
                <div class="col-sm-3">
                  <img
                    class="advantage-img"
                    src="../../../assets/Home/aporte-2.png"
                  />
                </div>
                <div class="col-sm-9">
                  <div class="advantage-title">Facturación optimizada</div>
                </div>
                <div class="col-sm-12">
                  <div class="advantage-description">
                    Unifica tus pagos en una sola factura digital
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="col-sm-12 advantage-item">
              <div class="row">
                <div class="col-sm-3">
                  <img
                    class="advantage-img"
                    src="../../../assets/Home/aporte-3.png"
                  />
                </div>
                <div class="col-sm-9">
                  <div class="advantage-title">Adiós a los costos fijos</div>
                </div>
                <div class="col-sm-12">
                  <div class="advantage-description">
                    Paga únicamente por el espacio que necesitas, por el tiempo
                    que lo necesitas
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="col-sm-12 advantage-item">
              <div class="row">
                <div class="col-sm-3">
                  <img
                    class="advantage-img"
                    src="../../../assets/Home/aporte-4.png"
                  />
                </div>
                <div class="col-sm-9">
                  <div class="advantage-title">Fácil de utilizar</div>
                </div>
                <div class="col-sm-12">
                  <div class="advantage-description">
                    Plataforma intuitiva que te guía paso a paso en tu proceso
                    de registro o búsqueda y contratación de espacios”
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="col-sm-12 advantage-item">
              <div class="row">
                <div class="col-sm-3">
                  <img
                    class="advantage-img"
                    src="../../../assets/Home/aporte-1.png"
                  />
                </div>
                <div class="col-sm-9">
                  <div class="advantage-title">
                    Gestión de inventarios en línea
                  </div>
                </div>
                <div class="col-sm-12">
                  <div class="advantage-description">
                    Gestiona tu inventario las 24 horas del día, los 7 días de
                    la semana desde la comodidad de tu escritorio
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="col-sm-12 advantage-item">
              <div class="row">
                <div class="col-sm-3">
                  <img
                    class="advantage-img"
                    src="../../../assets/Home/aporte-1.png"
                  />
                </div>
                <div class="col-sm-9">
                  <div class="advantage-title">Único acceso</div>
                </div>
                <div class="col-sm-12">
                  <div class="advantage-description">
                    Gestiona múltiples bodegas a través de una única cuenta
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <button (click)="onStartNow()" id="start-now-button">¡Empieza ya!</button>
    </div>
  </section>
  <!--How it works section -->
  <section class="how-it-works">
    <div class="how-it-works-container">
      <div class="how-it-works-title">¿Cómo funciona?</div>
      <div class="steps">
        <div class="row">
          <div class="col-sm-12 step">
            <div class="col-sm-6 step-image">
              <img
                class="step-image-img"
                src="../../../assets/Home/como-funciona-1.png"
              />
            </div>
            <div class="col-sm-6 step-description">
              <div class="step-title">Regístrate</div>
              <div class="step-detail">
                Es un proceso muy sencillo mediante el cual verificamos tus
                datos para que tu registro quede habilitado. Así podrás publicar
                espacios y alquilarlos.
              </div>
            </div>
          </div>
          <div class="col-sm-12 step-reverse">
            <div class="col-sm-6 step-description">
              <div class="step-title">Encuentra tu espacio</div>
              <div class="step-detail">
                Selecciona la ciudad que desees y el espacio que necesites.
                Inmediatamente se te abrirá la red más grande y flexible de
                almacenamiento del país.
              </div>
            </div>
            <div class="col-sm-6 step-image">
              <img
                class="step-image-img"
                src="../../../assets/Home/como-funciona-2.png"
              />
            </div>
          </div>
          <div class="col-sm-12 step">
            <div class="col-sm-6 step-image">
              <img
                class="step-image-img"
                src="../../../assets/Home/como-funciona-3.png"
              />
            </div>
            <div class="col-sm-6 step-description">
              <div class="step-title">Compara y escoge</div>
              <div class="step-detail">
                Precios, tipos de almacenamiento, servicios y otras opciones que
                sean importantes para cubrir tu necesidad. Tú escoges la opción
                perfecta para ti.
              </div>
            </div>
          </div>
          <div class="col-sm-12 step-reverse">
            <div class="col-sm-6 step-description">
              <div class="step-title">Maneja tu inventario</div>
              <div class="step-detail">
                Tendrás todo el control desde cualquier lugar: controla tu
                inventario y tu operación, revisa tus indicadores y solicita la
                información que necesites.
              </div>
            </div>
            <div class="col-sm-6 step-image">
              <img
                class="step-image-img"
                src="../../../assets/Home/como-funciona-4.png"
              />
            </div>
          </div>
        </div>
      </div>
      <button (click)="onComienza()" id="start-button">
        Comienza con flexpot
      </button>
    </div>
  </section>
  <!--Testimonies section-->
  <section class="testimonies">
    <div class="testimonies-container">
      <div class="testimonies-title">Ellos te lo cuentan</div>
      <div class="testimonial-card">
        <div class="row">
          <div class="col-sm-4">
            <img
              class="testimonial-img"
              src="../../../assets/Home/foto-reseña.png"
            />
          </div>
          <div class="col-sm-8">
            <div class="testimony-detail">
              <div class="col-sm-12">
                <div class="row">
                  <div class="col-sm-8">
                    <div class="testimony-user">Lorena Marsiglia</div>
                  </div>
                  <div class="col-sm-4">
                    <div class="score">
                      <fa-icon class="orange" [icon]="faStar"></fa-icon>
                      5
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-12">
                <div class="testimony-content">
                  Me ha solucionado la vida, no pensé que Flexpot se convirtiera
                  en el aliado perfecto para mi negocio. Excelente servicio.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!--Our partners section-->
  <section class="our-partners">
    <div class="our-partners-container">
      <div class="our-partners-title">Nos apoyan</div>
      <div class="partners-logos">
        <div class="row">
          <div class="partner">
            <img class="partner-logo" src="../../../assets/Home/logo-1.png" />
          </div>
          <div class="partner">
            <img class="partner-logo" src="../../../assets/Home/logo-2.png" />
          </div>
          <div class="partner">
            <img class="partner-logo" src="../../../assets/Home/logo-3.png" />
          </div>
          <div class="partner">
            <img class="partner-logo" src="../../../assets/Home/logo-4.png" />
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
