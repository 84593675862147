<div class="card-box">
    <div class="main__warehouses--title">
        {{warehouse.nombre}}
    </div>
    <div class="main__warehouses--main">
        <label class="points" *ngIf="warehouse.costoEspacioMasEconomico">
            Desde:
            <br>
            {{ warehouse.costoEspacioMasEconomico || 0 | currency:'$':'code' }}/Posición
        </label>
        <label class="points" *ngIf="!warehouse.costoEspacioMasEconomico">
            Desde:
            <br>
            Precio no disponible
        </label>
    </div>

</div>