import { Component, OnInit } from '@angular/core';
import { ApiService } from '../services/api.service';

@Component({
  selector: 'app-my-messages',
  templateUrl: './my-messages.component.html',
  styleUrls: ['./my-messages.component.scss']
})
export class MyMessagesComponent implements OnInit {

  //Messages variables
  public conversations: any[];
  public conversation: any;
  public newContentMessage: any = '';
  public user: any = {};

  constructor(
    private apiService: ApiService,
  ) { }

  ngOnInit(): void {
    //get item user from local storage
    this.user = JSON.parse(localStorage.getItem("flexpot-user")) || {};
    //Validation of item information for get Contacts
    if (this.user && this.user._id) this.getConctacts({ _id: this.user._id });
  }

  /**
   * Function to get contacts using id
   *  @param _id
   */
  public getConctacts({ _id }) {
    //Executes get instruction from the api service on the given API
    this.apiService.get({ api: `api/message/conversations/user/${_id}` }).subscribe(respond => {
      this.conversations = respond;
    });
  }

  /**
   * Function to assign a selected Conversation
   * @param conversation 
   */
  public onOpenConversation(conversation) {
    this.conversation = conversation;
  }

  /**
   * Create message on database and set this in conversation
   */
  public onSendMessage() {
    //Template of message
    let messageTemplate = {
      message: this.newContentMessage,
      to: this.conversation._id,
      from: this.user._id
    }
    //Method to create the message on database
    this.apiService.post({ api: 'api/message', data: { message: messageTemplate}  }).subscribe(respond => {
      this.newContentMessage = "";
      // Set message in current conversation
      this.conversation.messages.push(respond);
      this.conversation.lastMessage = respond;
      let i = this.conversations.findIndex(element => element._id == this.conversation._id);
      this.conversations[i] = this.conversation;
    });
  }
}
