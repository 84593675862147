import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss']
})
export class CalendarComponent implements OnInit {

  /** */
  public dateInput = moment().format('MMM, DD YYYY');
  /** */
  public dateString;
  /**  */
  public today = { year: moment().year(), month: moment().month() + 1, day: moment().date() };
  // Var to validate first click in the datePicker and cancel animation in the second  click
  public ignoreClick = false;
  /** This object have the data for vote */
  public date: any;

  /** This var create the function to send the date selected*/
  @Output() newDate = new EventEmitter<Boolean>();

  constructor() { }

  ngOnInit(): void {
  }


  /**
   * Function to validate first click in the datePicker and cancel animation when select date click
   * @param datePicker Reference Date Picker
   */
  public onClickDatePicker(datePicker, event) {
    // This class is when we click in month and year in the calendar.
    if (event.target.classList[0].includes('custom-select')) {
      return
    }
    !this.ignoreClick && datePicker.toggle();
  }

  /**
   * Function to set dates and get reservations of day selected
   * @param event date
   */
  public dateSelected(event) {
    let dateFormat
    //Reset animation for close modal
    // this.ignoreClick = true;
    // requestAnimationFrame(() => this.ignoreClick = false);
    dateFormat = event.year.toString() + '-' + ('0' + event.month.toString()).slice(-2) + '-' + ('0' + event.day.toString()).slice(-2);
    this.dateInput = moment(dateFormat).format('MMM, DD YYYY');
    this.dateString = moment(dateFormat).format('YYYY-MM-DD');
    this.newDate.emit(dateFormat);
    // this.loading = false;

  }

}