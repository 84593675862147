import { Component, OnInit, Input, Output, EventEmitter  } from '@angular/core';

@Component({
  selector: 'app-reserva-item',
  templateUrl: './reserva-item.component.html',
  styleUrls: ['./reserva-item.component.scss']
})
export class ReservaItemComponent implements OnInit {

  @Input() reservaInfo: any=[];

  constructor() { }

  ngOnInit(): void {
  }


}
