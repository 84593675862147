<div class="search">
  <ngx-loading
    [show]="loading"
    [config]="{
      primaryColour: primaryColour,
      secondaryColour: secondaryColour,
      tertiaryColour: primaryColour,
      backdropBorderRadius: '3px'
    }"
  >
  </ngx-loading>
  <div class="search-title">
    <div class="search-bar">
      <div class="option1">
        <!-- <select [(ngModel)]='ciudadFiltro' class="search-input first">
                        <option value="" disabled> Selecciona tu ciudad</option>
                        <option value="Bogota">Bogota</option>
                        <option value="Chía">Chía</option>
                    </select> -->
        <select [(ngModel)]="ciudadFiltro" class="search-input first">
          <option value="" disabled selected>Selecciona tu ciudad</option>
          <option *ngFor="let ciudad of ciudades" value="{{ ciudad }}">
            {{ ciudad }}
          </option>
        </select>
      </div>
      <div class="option2">
        <select [(ngModel)]="buscarProducto" class="search-input">
          <option value="" disabled selected>Tipo de producto</option>
          <option *ngFor="let producto of productos" value="{{ producto._id }}">
            {{ producto.nombre }}
          </option>
        </select>
      </div>
      <div class="option3">
        <select
          [(ngModel)]="unidad"
          (change)="onChangeUnidad($event)"
          class="search-input"
        >
          <option value="" disabled>Unidad</option>
          <option value="metros">M2</option>
          <option value="estanteria">Estantería</option>
        </select>
      </div>
      <div class="option4">
        <select
          *ngIf="!metros && !estanteria"
          [(ngModel)]="cantidadEspacios"
          class="search-input"
        >
          <option value="" disabled>Cantidad</option>
        </select>
        <select *ngIf="metros" [(ngModel)]="metrosModel" class="search-input">
          <option value="" disabled>Tamaño en m2</option>
          <option value="1">Menos de 100m2</option>
          <option value="2">Más de 100 menos de 500m2</option>
          <option value="3">Más de 500 menos de 1000m2</option>
          <option value="4">Más de 1000m2</option>
        </select>
        <select
          *ngIf="estanteria"
          [(ngModel)]="estantesModel"
          class="search-input"
        >
          <option value="" disabled>Número de estantes</option>
          <option value="1">De 1 a 50 estantes</option>
          <option value="2">De 50 a 100 estantes</option>
          <option value="3">De 100 a 500 estantes</option>
          <option value="4">De 500 a 1000 estantes</option>
          <option value="5">Más de 1000 estantes</option>
        </select>
      </div>
      <div class="option5">
        <div class="boton-busqueda">
          <button (click)="open(content)" id="filter-button">
            Filtrar más
          </button>
        </div>
      </div>
      <div class="option6">
        <div class="boton-busqueda">
          <button id="search-button" (click)="onFiltro()">Aplicar</button>
        </div>
        <div class="boton-busqueda">
          <button (click)="onCleanFilter()" id="clean-button">
            Limpiar filtros
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- Resultado de busqueda -->
  <div class="row">
    <div class="col-md-6">
      <div class="row">
        <div class="col-12">
          <label>
            <h1 class="display-inline-block">Resultados de búsqueda</h1>
            <p class="display-inline-block margin-left">
              {{ viewWarehouses?.length || 0 }} bodegas encontrados
            </p>
          </label>
        </div>
      </div>
      <div class="row card-box-container">
        <div
          *ngIf="viewWarehouses.length === 0"
          class="reservas--main--left--reservas--empty right space-left"
        >
          <div class="box--empty">
            <fa-icon [icon]="faBoxOpen" class="icon-caja"></fa-icon>
          </div>
          <p class="nothing">No se encontraron bodegas</p>
        </div>
        <app-warehouse-item-client
          class="col-6"
          *ngFor="let warehouse of viewWarehouses"
          (click)="onDetailSpaces(warehouse._id)"
          (mouseenter)="onWarehouseHover(warehouse, true)"
          (mouseleave)="onWarehouseHover(warehouse, false)"
          [warehouse]="warehouse"
        >
        </app-warehouse-item-client>
      </div>
    </div>
    <div class="col-md-6 map-box">
      <agm-map class="map-css" [zoom]="10" [latitude]="lat" [longitude]="long">
        <agm-marker
          *ngFor="let warehouse of viewWarehouses; let i = index"
          [latitude]="onConvertToNumber(warehouse.latitud)"
          [longitude]="onConvertToNumber(warehouse.longitud)"
          [animation]="warehouse.shouldBounce ? 'BOUNCE' : ''"
          [iconUrl]="icon"
          (mouseOver)="infoWindow.open()"
          (mouseOut)="infoWindow.close()"
          (markerClick)="onDetailSpaces(warehouse._id)"
          [markerDraggable]="false"
        >
          <agm-info-window #infoWindow [disableAutoPan]="true">
            <app-warehouse-item-map class="col-6" [warehouse]="warehouse">
            </app-warehouse-item-map>
          </agm-info-window>
        </agm-marker>
        <!-- <agm-overlay *ngFor="let lugar of viewWarehouses;let i=index;"
					[latitude]="onConvertToNumber(lugar.latitud)" [longitude]="onConvertToNumber(lugar.longitud)"
					[animation]="lugar.shouldBounce ? 'BOUNCE' : ''"
					[iconUrl]="lugar.shouldBounce ? 'http://maps.google.com/mapfiles/ms/icons/green-dot.png': 'http://maps.google.com/mapfiles/ms/icons/blue-dot.png'">
					<div class="overlay--logo">
						<img src="../../../assets/Logos/pin-azul.png" alt="">
					</div> 
					<agm-info-window>Info Window {{i}}</agm-info-window>
				</agm-overlay> -->
      </agm-map>
    </div>
  </div>

  <!-- Modal Filtrar mas  -->
  <ng-template #content let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Aplica más filtros</h4>
      <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="modal.dismiss('Cross click')"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <ng-container>
      <div class="modal-body">
        <div class="form-group">
          <div class="form-group--spaces">
            <p>Presupuesto</p>
            <ngx-slider
              [(value)]="value"
              [(highValue)]="highValue"
              [options]="options"
              (userChange)="onValueChange($event)"
            >
            </ngx-slider>
            <p class="values-range">
              {{ showRangeValues.value }} - {{ showRangeValues.highValue }}
            </p>
          </div>
          <!-- <p class="title-stars">Selecciona por estrellas</p>
					<div class="form-group--rating">
						<ngb-rating [(rate)]="currentRate" [max]="5"></ngb-rating>
						<p>{{currentRate}}</p>
					</div> -->
          <!-- <div class="form-group--accepted--products">
						<p>Productos aceptados </p>
						 Div for the arrow looking up or down
                             -->
          <!-- <fa-icon *ngIf="checkboxes===false" (click)="onClickCheckboxes()" class="arrowDown" [icon]="faSortDown">
						</fa-icon>
						<fa-icon *ngIf="checkboxes" (click)="onClickCheckboxesClose()" class="arrowDown" [icon]="faSortUp">
						</fa-icon>
					</div> -->
          <div *ngIf="checkboxes" class="row">
            <div
              class="col-6"
              *ngFor="let producto of productos; let i = index"
            >
              <mat-checkbox
                color="primary"
                (change)="onChangeProductos($event, producto._id)"
              >
                {{ producto.nombre }}
              </mat-checkbox>
            </div>
            <div class="divider"></div>
          </div>
          <div class="form-group--accepted--products">
            <p>Especificaciones</p>
            <!-- Div for the arrow looking up or down
                             -->
            <fa-icon
              *ngIf="checkboxes1 === false"
              (click)="onClickCheckboxes1()"
              class="arrowDown"
              [icon]="faSortDown"
            >
            </fa-icon>
            <fa-icon
              *ngIf="checkboxes1"
              (click)="onClickCheckboxesClose1()"
              class="arrowDown"
              [icon]="faSortUp"
            >
            </fa-icon>
          </div>
          <div *ngIf="checkboxes1" class="form-group--checkboxes">
            <p>Tipo de espacio</p>
            <div class="row">
              <div class="col-6">
                <mat-checkbox
                  [(ngModel)]="piso"
                  color="primary"
                  (change)="changePiso($event)"
                >
                  <span class="checkbox-span">Piso</span>
                </mat-checkbox>
              </div>
              <div class="col-6">
                <mat-checkbox
                  [(ngModel)]="estanteriaFilter"
                  color="primary"
                  (change)="changeEstanteria($event)"
                >
                  <span class="checkbox-span"> Estantería </span>
                </mat-checkbox>
              </div>
            </div>
            <p>Ambiente</p>
            <div class="row">
              <!-- ESTO CORRESPONDE A UN ESPACIO NO HA UNA BODEGA COMO TAL, EL QUERY ESTA DISEÑADO PARA FILTRAR BODEGAS  -->
              <!-- <div class="col-6">
								<mat-checkbox [(ngModel)]="refrigerado" color='primary' (change)="changeRefrigerado($event)">
									Refrigerado
								</mat-checkbox>
							</div> -->
              <!-- <div class="col-6">
								<mat-checkbox [(ngModel)]="noRefrigerados" color='primary' (change)="changeNoRefrigerado($event)">
									No refrigerados
								</mat-checkbox>
							</div> -->
            </div>
            <p>Techo</p>
            <div class="row">
              <div class="col-6">
                <mat-checkbox
                  [(ngModel)]="cubierta"
                  color="primary"
                  (change)="changeCubierta($event)"
                >
                  <span class="checkbox-span"> Cubierta </span>
                </mat-checkbox>
              </div>
              <div class="col-6">
                <mat-checkbox
                  [(ngModel)]="descubierto"
                  color="primary"
                  (change)="changeDescubierto($event)"
                >
                  <span class="checkbox-span"> Descubierto </span>
                </mat-checkbox>
              </div>
              <div class="divider"></div>
            </div>
          </div>
          <div class="form-group--accepted--products">
            <p>Servicios adicionales</p>
            <!-- Div for the arrow looking up or down
                             -->
            <fa-icon
              *ngIf="checkboxes2 === false"
              (click)="onClickCheckboxes2()"
              class="arrowDown"
              [icon]="faSortDown"
            >
            </fa-icon>
            <fa-icon
              *ngIf="checkboxes2"
              (click)="onClickCheckboxesClose2()"
              class="arrowDown"
              [icon]="faSortUp"
            >
            </fa-icon>
          </div>
          <div *ngIf="checkboxes2" class="row">
            <div class="col-6">
              <mat-checkbox
                *ngFor="let servicio of servicios"
                [(ngModel)]="etiquetado"
                color="primary"
                (change)="changeEtiquetado($event)"
              >
                <span class="checkbox-span" style="margin: 0 10px 0 3px">
                  {{ servicio.nombre }}
                </span>
              </mat-checkbox>
            </div>
            <div class="divider"></div>
          </div>
          <div class="form-group--accepted--products">
            <p>Certificaciones</p>
            <!-- Div for the arrow looking up or down
                             -->
            <fa-icon
              *ngIf="checkboxes3 === false"
              (click)="onClickCheckboxes3()"
              class="arrowDown"
              [icon]="faSortDown"
            >
            </fa-icon>
            <fa-icon
              *ngIf="checkboxes3"
              (click)="onClickCheckboxesClose3()"
              class="arrowDown"
              [icon]="faSortUp"
            >
            </fa-icon>
          </div>
          <div *ngIf="checkboxes3" class="row">
            <div class="col-6">
              <mat-checkbox
                *ngFor="let certification of certificados"
                [(ngModel)]="BASC"
                color="primary"
                (change)="changeBASC($event, certification._id)"
              >
                <span class="checkbox-span"> {{ certification.nombre }} </span>
              </mat-checkbox>
            </div>
            <!-- <div class="col-6">
							<mat-checkbox [(ngModel)]="ISO9001" color='primary' (change)="changeISO9001($event)">
								ISO9001
							</mat-checkbox>
						</div>
						<div class="col-6">
							<mat-checkbox [(ngModel)]="INVIMA" color='primary' (change)="changeInvima($event)">
								INVIMA
							</mat-checkbox>
						</div>
						<div class="col-6">
							<mat-checkbox [(ngModel)]="BPM" color='primary' (change)="changeBpm($event)">
								BPM
							</mat-checkbox>
						</div>
						<div class="col-6">
							<mat-checkbox [(ngModel)]="CertificadoAmbiental" color='primary' (change)="changeCheckboxes($event)">
								Certificado Ambiental
							</mat-checkbox>
						</div> -->
            <div class="divider"></div>
          </div>
          <div class="input-group">
            <div class="input-group-append">
              <div class="boton-busqueda--modal">
                <div class="boton-busqueda--modal">
                  <button (click)="onCleanFilterCheckboxes()" id="clean-button">
                    Limpiar filtros
                  </button>
                </div>
                <button id="search-button" (click)="onFiltroModal()">
                  Aplicar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-template>
</div>
