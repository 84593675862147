import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MovementComponent } from "./movement/movement.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { CalendarComponent } from './calendar/calendar.component';
import { NbDatepickerModule, NbIconModule } from "@nebular/theme";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { PqrsComponent } from './pqrs/pqrs.component';
import { ReservaItemComponent } from "./reserva-item/reserva-item.component";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatCardModule } from "@angular/material/card";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatDialogModule } from "@angular/material/dialog";
import { MatTableModule } from "@angular/material/table";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatSortModule } from "@angular/material/sort";
import { MatSliderModule } from "@angular/material/slider";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { WarehouseItemClientComponent } from './warehouse-item-client/warehouse-item-client.component';
import { WarehouseItemMapComponent } from './warehouse-item-map/warehouse-item-map.component';
import { PoliticsComponent } from './politics/politics.component';
import { TermsConditionsComponent } from './terms-conditions/terms-conditions.component';
import { NbEvaIconsModule } from "@nebular/eva-icons";
import { AddCreditCardComponent } from './add-credit-card/add-credit-card.component';
import { NgxLoadingModule } from "ngx-loading";

@NgModule({
  declarations: [MovementComponent, CalendarComponent, PoliticsComponent, TermsConditionsComponent, PqrsComponent, ReservaItemComponent, WarehouseItemClientComponent, WarehouseItemMapComponent, AddCreditCardComponent],
  imports: [
    CommonModule,
    FormsModule,
    NgbModule,
    NbDatepickerModule,
    FontAwesomeModule,
    MatProgressSpinnerModule,
    ReactiveFormsModule,
    MatProgressBarModule,
    MatCardModule,
    MatTableModule,
    MatExpansionModule,
    MatCheckboxModule,
    MatDialogModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatSliderModule,
    MatPaginatorModule,
    NgxLoadingModule.forRoot({}),
    NbIconModule,
    NbEvaIconsModule,
    MatSortModule
  ],
  exports: [
    MovementComponent,
    CalendarComponent,
    PoliticsComponent,
    TermsConditionsComponent,
    PqrsComponent,
    ReservaItemComponent,
    WarehouseItemClientComponent,
    WarehouseItemMapComponent,
    AddCreditCardComponent
  ]
})
export class ComponentsModule { }
