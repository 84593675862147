<div class="reservas">
  <div class="reservas--header">
    <button id="back-button" (click)="onBackClicked()">
      <fa-icon (click)="onBackClicked()" [icon]="faChevronLeft"></fa-icon> Volver a reservas
    </button>
    <h1 class="main__title">Historial de reservas</h1>
  </div>
  <div class="reservas--main">
    <div class="reservas--main--left">
      <div class="empty" *ngIf="reservas.length === 0 ">
        <div class="box--empty">
          <fa-icon [icon]="faBoxOpen" class="icon-caja"></fa-icon>
        </div>
        <p class="nothing">Nada por aquí</p>
      </div>
      <div *ngIf="reservas.length > 0 " class="reservas--main--left--navbar">
        <div class="reservas--main--left--navbar--search--flex">
          <fa-icon class="searchIcon" [icon]="faSearch"></fa-icon>
          <input class="reservas--main--left--navbar--search" type="text" placeholder="Buscar"
            [(ngModel)]="searchBar" />
        </div>
        <div class="reservas--main--left--navbar--select--flex">
          <input placeholder="Fecha" type="text" ngxDaterangepickerMd [(ngModel)]="selectedDate"
            (change)="onChangeDate($event)" class="form-control" />
        </div>
      </div>
      <div class="reservas--main--left--reservas">
        <!-- <app-reserva-item *ngFor="let reserva of viewReservas | search: searchBar; let i = index"
        (click)="onReservation({id: reserva._id})" [reservaInfo]="reserva"></app-reserva-item> -->
        <app-reserva-item *ngFor="let reserva of viewReservas | search: searchBar; let i = index"
          [reservaInfo]="reserva"></app-reserva-item>
      </div>
    </div>
  </div>
</div>