<ngx-loading [show]="loading" [config]="{
    primaryColour: primaryColour,
    secondaryColour: secondaryColour,
    tertiaryColour: primaryColour,
    backdropBorderRadius: '3px'
  }">
</ngx-loading>
<div class="main-container-inventory">
  <div class="main-container-left">
    <div class="container-left-button">
      <button class="back-button" (click)="onBackClicked()">
        <img src="./assets/Utils/flecha.png" class="image" />
        Volver
      </button>
    </div>
    <div class="container-left-header">
      <h5>Inventario de mi reserva</h5>
      <div class="container-left-header-info">
        <img src="./assets/Utils/info.png" class="image" />
        <p>Sobre el seguro de mi mercancía</p>
      </div>
    </div>
    <div class="container-left-table">
      <ng2-smart-table [settings]="settings" [source]="sourceInventory" (custom)="onCustom({ event: $event })">
      </ng2-smart-table>
    </div>
  </div>
  <div class="main-container-right">
    <div class="main-container-right-header">
      <p>Crea un movimiento</p>
      <button class="clear-button" (click)="onClear()">Limpiar</button>
    </div>
    <div class="main-container-right-options">
      <select [(ngModel)]="flowOption" class="flow-input">
        <option value="" disabled selected>Tipo de movimiento</option>
        <option value="retiro">Retiro</option>
        <option value="ingreso">Ingreso</option>
      </select>
      <!-- TODO: Add calendar picker -->
    </div>
    <div class="main-container-right-items">
      <!-- Retiro -->
      <div class="flow" *ngIf="flowOption == 'retiro'">
        <div class="main-container-right-items-buttons">
          <a href="https://flexpot-recursos.s3.amazonaws.com/plantillas/Plantilla-inventario-retirar.xlsx"
            class="download-button">
            <img src="./assets/Utils/bajar.png" class="image" />Descagar excel
            de muestra
          </a>
        </div>
        <div class="main-container-right-items-info">
          <p>Items a mover</p>
          <p class="items">{{ itemsToMoveTotal }} Unidades</p>
        </div>
        <div class="main-container-right-items-movement">
          <div *ngFor="let item of sourceInventory; let i = index">
            <div *ngIf="item.cantidadAgregada > 0">
              <div class="main-container-right-items-movement-headers">
                <p>SKU producto</p>
                <p>Unidades</p>
              </div>
              <div class="main-container-right-items-movement-info">
                <div class="sku">
                  <button class="sku-item-button" (click)="onRemoveItemDelete({ index: i })">
                    <span class="material-icons"> close </span>
                  </button>
                  <p>{{ item.sku }}</p>
                </div>
                <div class="unidades">
                  <button class="remove-item-button" (click)="onRemoveItemLess({ index: i })"
                    [disabled]="item.cantidadAgregada > 1 ? null : 'disabled'">
                    <span class="material-icons"> remove </span>
                  </button>
                  <p>{{ item.cantidadAgregada }}</p>

                  <button class="add-item-button" (click)="onRemoveItemAdd({ index: i })" [disabled]="
                      item.cantidadAgregada < item.cantidad ? null : 'disabled'
                    ">
                    <span class="material-icons"> add </span>
                  </button>
                </div>
              </div>
              <hr />
            </div>
          </div>
        </div>
      </div>
      <!-- Ingreso  -->
      <div class="flow" *ngIf="flowOption == 'ingreso'">
        <div class="main-container-right-items-buttons">
          <button class="product-button" (click)="onCreateProduct({ content: createProduct })">
            Crear Producto
          </button>
          <!-- <a href="https://flexpot-recursos.s3.amazonaws.com/plantillas/Plantilla-inventario-agregar.xlsx"
            class="download-button">
            <img src="./assets/Utils/bajar.png" class="image" />Descagar excel de muestra
          </a> -->
        </div>
        <div class="main-container-right-items-info">
          <p>Productos a crear</p>
          <p class="items">{{ inventoryToAdd.length }} Unidades</p>
        </div>
        <div class="main-container-right-items-movement">
          <div *ngFor="let item of inventoryToAdd; let i = index">
            <div class="main-container-right-items-movement-headers">
              <p>SKU producto</p>
              <p>Cantidad</p>
            </div>
            <div class="main-container-right-items-movement-info">
              <div class="sku">
                <button class="sku-item-button" (click)="onAddItemDelete({ index: i })">
                  <span class="material-icons"> close </span>
                </button>
                <p>{{ item.sku }}</p>
                <!-- <button class="sku-item-button" (click)="onEditProduct({index: i, content: createProduct})">
                  <span class="material-icons"> edit </span>
                </button> -->
              </div>
              <div class="unidades">
                <button class="remove-item-button" (click)="onAddItemLess({ index: i })"
                  [disabled]="item.cantidad > 1 ? null : 'disabled'">
                  <span class="material-icons"> remove </span>
                </button>
                <p>{{ item.cantidad }}</p>

                <button class="add-item-button" (click)="onAddItemAdd({ index: i })">
                  <span class="material-icons"> add </span>
                </button>
              </div>
            </div>
            <hr />
          </div>
        </div>
      </div>
      <div class="main-container-right-items-img" *ngIf="!continueButtonActive">
        <img src="./assets/Utils/estado-vacio.png" class="image" />
        <p class="items">No hay nada por aquí</p>
      </div>
    </div>
    <hr />
    <div class="main-container-right-items-footer">
      <button *ngIf="!loading && flowOption != 'ingreso'" class="upload-button" (click)="fileInput.click()"
        [disabled]="flowOption ? null : 'disabled'">
        <img src="./assets/Utils/subir.png" class="image" />
        <input #fileInput type="file" (change)="onUploadFile({ input: $event.target.files[0] })" />
        <p>Cargar excel lleno</p>
      </button>
      <mat-progress-spinner *ngIf="loading" class="spinner" diameter="40" mode="indeterminate"></mat-progress-spinner>
      <button class="continue-button" (click)="onContinue({ content: succesOrError })"
        [disabled]="continueButtonActive ? null : 'disabled'">
        Continuar
      </button>
    </div>
  </div>
</div>

<!-- Excel -->
<!-- <p>Puedes descargar el formato de excel para subirlo diligenciado y los cargar productos</p>
<a href="https://flexpot-recursos.s3.amazonaws.com/plantillas/Plantilla-inventario-agregar.xlsx" class="download-button">
  <img src="./assets/Utils/bajar.png" class="image" />Descagar excel de muestra
</a>
<button class="upload-button" (click)="fileInput.click()" [disabled]="fileToUpload == null ? null : 'disabled'">
  <img src="./assets/Utils/subir.png" class="image" />
  <input #fileInput type="file" (change)="onFileInput({input: $event})" />
  <p>Cargar excel lleno</p>
</button>
<p>Archivo Subido</p>
<p class="selected-file" *ngIf="fileToUpload == null">No has subido nada :(</p>
<div class="main-container-right-file-info" *ngIf="fileToUpload != null">
  <p class="selected-file">{{ fileInfo }}</p>
  <button class="delete-button" (click)="onDeleteFileClicked()">
    <img src="./assets/Utils/eliminar.png" class="image" />
  </button>
</div> -->

<!-- Modal create product -------------------------------------------------- -->
<ng-template class="modal-template" #createProduct let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Creación de producto</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross exit')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <h5 class="product-title">Descripción del producto:</h5>
    <form class="main-form" [formGroup]="productForm">
      <div class="form-div">
        <span>SKU</span>
        <input class="label" type="text" id="text" placeholder="SKU" formControlName="sku"
          [ngClass]="{ 'red-border': fieldIsInvalid({ field: 'sku' }) }" />
        <span *ngIf="fieldIsInvalid({ field: 'sku' })" class="invalid-warning">Campo obligatorio</span>
      </div>
      <div class="form-div">
        <span>Nombre producto</span>
        <input class="label" type="text" id="text" placeholder="Nombre producto" formControlName="nombre"
          [ngClass]="{ 'red-border': fieldIsInvalid({ field: 'nombre' }) }" />
        <span *ngIf="fieldIsInvalid({ field: 'nombre' })" class="invalid-warning">Campo obligatorio</span>
      </div>
      <div class="form-div">
        <span>Ancho (m)</span>
        <input class="label" type="number" min="1" id="ancho" placeholder="Ancho (m)" formControlName="volumen" />
      </div>
      <div class="form-div">
        <span>Alto (m)</span>
        <input class="label" type="number" min="1" id="alto" placeholder="Alto (m)" formControlName="volumen" />
      </div>
      <div class="form-div">
        <span>Largo (m)</span>
        <input class="label" type="number" min="1" id="largo" placeholder="Largo (m)" formControlName="volumen" />
      </div>
      <div class="form-div">
        <span>Unidad</span>
        <select formControlName="unidad" [ngClass]="{ 'red-border': fieldIsInvalid({ field: 'unidad' }) }"
          class="search-input">
          <option value="" disabled selected>Unidad</option>
          <option *ngFor="let unidad of unidades" value="{{ unidad.nombre }}">
            {{ unidad.nombre }}
          </option>
        </select>
        <span *ngIf="fieldIsInvalid({ field: 'unidad' })" class="invalid-warning">Campo obligatorio</span>
      </div>
      <div class="form-div">
        <span>Valor</span>
        <input class="label" type="number" id="number" placeholder="Valor" min="1" formControlName="valor"
          [ngClass]="{ 'red-border': fieldIsInvalid({ field: 'valor' }) }" />
        <span *ngIf="fieldIsInvalid({ field: 'valor' })" class="invalid-warning">Campo obligatorio</span>
      </div>
      <div class="form-div">
        <span>Peso (kg)</span>
        <input class="label" type="number" id="number" min="1" placeholder="Peso (kg)" formControlName="peso"
          [ngClass]="{ 'red-border': fieldIsInvalid({ field: 'peso' }) }" />
        <span *ngIf="fieldIsInvalid({ field: 'peso' })" class="invalid-warning">Campo obligatorio</span>
      </div>
    </form>
    <hr class="modal-separator" />
    <h5 class="product-title">Descripción del movimiento:</h5>
    <div class="main-form" [formGroup]="productForm">
      <div class="form-div">
        <span>Cantidad</span>
        <input class="label" type="number" id="number" min="1" placeholder="Cantidad" formControlName="cantidad"
          [ngClass]="{ 'red-border': fieldIsInvalid({ field: 'cantidad' }) }" />
        <span *ngIf="fieldIsInvalid({ field: 'cantidad' })" class="invalid-warning">Campo obligatorio</span>
      </div>
      <div class="form-div">
        <span>Espacio</span>
        <select formControlName="espacio" class="select-input"
          [ngClass]="{ 'red-border': fieldIsInvalid({ field: 'espacio' }) }">
          <option value="" disabled selected>Espacio</option>
          <option *ngFor="let spaceName of spacesNames" [value]="spaceName.trim()">
            {{ spaceName }}
          </option>
        </select>
        <span *ngIf="fieldIsInvalid({ field: 'espacio' })" class="invalid-warning">Campo obligatorio</span>
      </div>
    </div>
    <hr class="modal-separator-bottom" />
    <div class="modal--body-options">
      <button (click)="modal.dismiss('Cross cancel')" class="cancel">
        Cancelar
      </button>
      <button (click)="addProduct({ modal: modal })" class="yes">
        {{ temporalIndex === -1 ? "Agregar producto" : "Editar producto" }}
      </button>
    </div>
  </div>
</ng-template>

<!-- Modal succes or error -------------------------------------------------- -->
<ng-template #succesOrError let-modal>
  <div class="modal-header">
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross exit')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <img src="./assets/Utils/exito2.png" *ngIf="!errorToSave" class="modal-image" />
    <img src="./assets/Utils/error.png" *ngIf="errorToSave" class="modal-image" />
    <div class="modal--body-question">
      <h1 *ngIf="!errorToSave">¡Yaay!</h1>
      <p *ngIf="!errorToSave">
        Tu movimimiento para agregar productos fue creada con éxito
      </p>
      <h1 *ngIf="errorToSave">¡Ha ocurrido un Error!</h1>
      <p *ngIf="errorToSave">{{ errorMessage }}</p>
    </div>
    <div class="modal--body-options">
      <button (click)="modal.dismiss('Cross yes')" class="yes">Listo</button>
    </div>
  </div>
</ng-template>