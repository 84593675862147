<ngx-loading [show]="loading" [config]="loaderOptions"></ngx-loading>
<div class="warehouse-create">
  <!-- Header -->
  <div class="warehouse-create-header">
    <button id="cancel-button" (click)="onCancel()">
      <fa-icon [icon]="faTimes"></fa-icon> Cancelar
    </button>
    <div class="warehouse-create-title">Crear una bodega</div>
    <button [disabled]="loading" id="save-button" (click)="onSaveClick({transactionType: 'save'})">Salir y guardar
      progreso</button>
  </div>

  <!-- Create form container -->
  <div class="form-container">
    <!-- Container header -->
    <mat-progress-bar [value]="(currentStep / TOTAL_STEPS) * 100"></mat-progress-bar>
    <div class="form-header">
      <div class="form-subtitle">
        <img src="{{ STEPS_IMG[currentStep - 1] }}" class="steps-images" />
        {{ STEPS_TITLES[currentStep - 1] }}
      </div>
      <div class="step-counter">
        <span> Paso {{ currentStep }}/{{ TOTAL_STEPS }} </span>
      </div>
    </div>

    <!-- Form content-->
    <!-- Step 1: Warehouse information-->
    <form [formGroup]="wareHouseForm" *ngIf="currentStep === 1">
      <div class="step-container">
        <div class="row">
          <div class="col-sm-12">
            <input type="text" class="warehouse-input" id="warehouse-name" placeholder="Nombre" formControlName="nombre"
              name="nombre-bodega" [ngClass]="{'red-border': warehouseIsInvalid('nombre')}" />
            <span *ngIf="warehouseIsInvalid('nombre')" class="invalid-warning">Campo obligatorio</span>
          </div>
          <div class="col-sm-12">
            <textarea class="warehouse-input t-area" id="warehouse-description" placeholder="Descripción"
              name="nombre-descripcion" [ngClass]="{'red-border': warehouseIsInvalid('descripcion')}"
              formControlName="descripcion"></textarea>
            <span *ngIf="warehouseIsInvalid('descripcion')" class="invalid-warning">Campo obligatorio</span>
          </div>
          <div class="col-sm-6">
            <input type="tel" class="warehouse-input" id="warehouse-space" placeholder="M2 del espacio"
              (keyup)="onValidateNumberSize()" start="1" min="1" formControlName="tamano"
              [ngClass]="{'red-border': warehouseIsInvalid('tamano')}" />
            <span *ngIf="warehouseIsInvalid('tamano')" class="invalid-warning">Campo obligatorio</span>
          </div>
          <div class="col-sm-6">
            <input type="text" class="warehouse-input" id="real-state" placeholder="Matrícula inmobiliaria"
              formControlName="matricula" [ngClass]="{'red-border': warehouseIsInvalid('matricula')}" />
            <span *ngIf="warehouseIsInvalid('matricula')" class="invalid-warning">Campo obligatorio</span>
          </div>
          <div class="col-sm-8">
            <button class="upload-freedom-certificate" type="button" (click)="freedomCertificateUpload.click()"
              [ngClass]="{'red-border': warehouseIsInvalid('certificado')}">
              <img src="../../../../assets/Utils/archivo.png" class="upload-image" />
              Certificado de libertad y tradición o contrato de arrendamiento
              <input type="file" #freedomCertificateUpload class="hidden-input" id="freedom-certificate-input"
                (change)="onUploadDocument({type: 'freedom-certificate', event: $event})" />
            </button>
            <span *ngIf="warehouseIsInvalid('certificado')" class="invalid-warning">Campo obligatorio</span>
            <a *ngIf="
                wareHouseForm.get('certificado').value !== '' &&
                wareHouseForm.get('certificado').value !== null &&
                wareHouseForm.get('certificado').value !== undefined
              " [href]="wareHouseForm.get('certificado').value | safe" target="_blank">
              Ver documento
            </a>
          </div>
        </div>
        <div class="sec-sub-title">Información de contacto - encargado</div>
        <div class="col-sm-12">
          <mat-checkbox class="form-checkbox" formControlName="mismo" (change)="onChangeUserInfo($event.checked)">
            Mismo contacto
          </mat-checkbox>
        </div>
        <div class="row" formGroupName="contacto">
          <div class="col-sm-6">
            <input type="text" class="warehouse-input" id="contact-name" placeholder="Nombre" formControlName="nombre"
              [ngClass]="{'red-border': warehouseIsInvalid('contacto.nombre')}" />
            <span *ngIf="warehouseIsInvalid('contacto.nombre')" class="invalid-warning">Campo obligatorio</span>
          </div>
          <div class="col-sm-6">
            <input type="text" class="warehouse-input" id="contact-lastname" placeholder="Apellido"
              formControlName="apellido" [ngClass]="{'red-border': warehouseIsInvalid('contacto.apellido')}" />
            <span *ngIf="warehouseIsInvalid('contacto.apellido')" class="invalid-warning">Campo obligatorio</span>
          </div>
          <div class="col-sm-6">
            <input type="tel" class="warehouse-input" id="contact-cellphone" placeholder="Celular"
              (keyup)="onValidateNumberPhone()" formControlName="telefono"
              [ngClass]="{'red-border': warehouseIsInvalid('contacto.telefono')}" />
            <span *ngIf="warehouseIsInvalid('contacto.telefono')" class="invalid-warning">Campo obligatorio</span>
          </div>
          <div class="col-sm-6">
            <input type="email" class="warehouse-input" id="contact-email" placeholder="Correo" formControlName="correo"
              [ngClass]="{'red-border': warehouseIsInvalid('contacto.correo')}" email="true" />
            <span *ngIf="warehouseIsInvalid('contacto.correo')" class="invalid-warning">Campo obligatorio</span>
          </div>
        </div>
      </div>
    </form>

    <!-- Step 2: Address information -->
    <form [formGroup]="addressForm" *ngIf="currentStep === 2">
      <div class="step-container">
        <div class="row">
          <div class="col-sm-3">
            <select formControlName="pais" (change)="onPais($event)" class="select-input"
              [ngClass]="{'red-border': addressIsInvalid('pais')}">
              <option value="" disabled selected>País</option>
              <option *ngFor="let location of locations; index as i" value="{{ i }}">
                {{ location.pais }}
              </option>
            </select>
            <span *ngIf="addressIsInvalid('pais')" class="invalid-warning">Campo obligatorio</span>
          </div>
          <div class="col-sm-3">
            <select formControlName="ciudad" class="select-input"
              [ngClass]="{'red-border': addressIsInvalid('ciudad')}">
              <option value="" disabled selected>Ciudad</option>
              <option *ngFor="let ciudad of ciudades" [value]="ciudad">
                {{ ciudad }}
              </option>
            </select>
            <span *ngIf="addressIsInvalid('ciudad')" class="invalid-warning">Campo obligatorio</span>
          </div>
          <div class="col-sm-6">
            <input type="text" class="warehouse-input" id="warehouse-address" placeholder="Dirección"
              formControlName="direccion" [ngClass]="{'red-border': addressIsInvalid('direccion')}" />
            <span *ngIf="addressIsInvalid('direccion')" class="invalid-warning">Campo obligatorio</span>
          </div>
          <div class="col-sm-6">
            <input type="text" class="warehouse-input" id="warehouse-address-detail"
              placeholder="Detalles (Interior, esquina, vereda)" formControlName="detalles" />
          </div>
          <div class="col-sm-6">
            <button type="button" class="verify-address-button" [ngClass]="{'red-border': addressIsInvalid('latitud')}"
              (click)="onVerifyAddressClick()">
              Verificar dirección
            </button>
            <span *ngIf="addressIsInvalid('latitud')" class="invalid-warning">Verificación obligatoria</span>
          </div>
          <div class="col-sm-12 map">
            <agm-map [zoom]="zoom" [latitude]="lat" [longitude]="long" [mapTypeControl]="true"
              (mapReady)="mapReady($event)">
              <agm-marker [latitude]="lat" [longitude]="long"></agm-marker>
            </agm-map>
          </div>
        </div>
      </div>
    </form>

    <!-- Step 3: Schedules and working hours -->
    <form [formGroup]="scheduleForm" *ngIf="currentStep === 3">
      <div class="step-container">
        <div class="sec-sub-title">Selecciona los días en los que labora la bodega</div>
        <div class="row">
          <div class="col-sm-3" *ngFor="let day of WEEK_DAYS; let i = index">
            <mat-checkbox [formControlName]="normalizeString({str: day})">
              {{ day }}
            </mat-checkbox>
          </div>
        </div>
        <div class="sec-sub-title">Indica el horario de la bodega</div>
        <div class="row">
          <div class="col-sm-6">
            <label class="form-label" for="start-time">Hora de inicio</label>
            <input class="warehouse-input" id="start-time" type="time"
              [ngClass]="{'red-border': scheduleIsInvalid('horaInicio')}" formControlName="horaInicio" />
            <span *ngIf="scheduleIsInvalid('horaInicio')" class="invalid-warning">Campo obligatorio</span>
          </div>
          <div class="col-sm-6">
            <label class="form-label" for="end-time">Hora de cierre</label>
            <input class="warehouse-input" id="end-time" [ngClass]="{'red-border': scheduleIsInvalid('horaFin')}"
              type="time" formControlName="horaFin" />
            <span *ngIf="scheduleIsInvalid('horaFin')" class="invalid-warning">Campo obligatorio</span>
          </div>
          <div class="col-sm-6 check-box">
            <label class="form-label" for="end-time">Tiempo máximo de respuesta de una solicitud a la bodega
              (hr)</label>
            <input (keyup)="onValidateMaxHours()" class="warehouse-input" id="max-hour-time"
              [ngClass]="{'red-border': scheduleIsInvalid('horaMax')}" type="number" placeholder="Hr" min="1" max="72"
              formControlName="horaMax" maxlength="4" />
            <span *ngIf="scheduleIsInvalid('horaMax')" class="invalid-warning">Campo obligatorio</span>
          </div>
          <div class="col-sm-6 check-box">
            <mat-checkbox class="form-checkbox" formControlName="muelleDeCarga"></mat-checkbox> ¿La bodega cuenta con
            muelle de carga?
          </div>
        </div>
      </div>
    </form>

    <!-- Step 4: Warehouse specifications-->
    <form *ngIf="currentStep === 4">
      <div class="step-container">
        <div class="sec-sub-title">Productos que se aceptan</div>
        <div class="row">
          <div class="col-sm-6" *ngFor="let product of acceptedProducts; index as i">
            <mat-checkbox class="form-checkbox" [name]="product.name" [(ngModel)]="product.selected"></mat-checkbox> {{
            product.name }}
          </div>
        </div>
        <!-- <span *ngIf="!product.selected" class="invalid-warning">Campo obligatorio</span> -->
        <div class="condition" *ngIf="securityItems.length > 0">
          <div class="sec-sub-title">Seguridad</div>
          <div class="row">
            <div class="col-sm-6" *ngFor="let securityItem of securityItems; index as i">
              <mat-checkbox class="form-checkbox" [name]="securityItem.name" [(ngModel)]="securityItem.selected">
              </mat-checkbox>
              {{ securityItem.name }}
            </div>
          </div>
        </div>
        <div class="condition" *ngIf="certificates.length > 0">
          <div class="sec-sub-title">Certificados</div>
          <div class="row">
            <div class="col-sm-6" *ngFor="let certificate of certificates; index as i">
              <mat-checkbox class="form-checkbox" [name]="certificate.name" [(ngModel)]="certificate.selected">
              </mat-checkbox> {{ certificate.name }}
            </div>
          </div>
        </div>
        <div class="row" *ngIf="onVerifyArray(certificates)">
          <div class="col-sm-6">
            <div class="sec-sub-title">
              Por favor adjunta los certificados que seleccionaste
            </div>
          </div>
          <div class="col-sm-6">
            <div class="sec-sub-title">
              Fecha de vencimiento del certificado:
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12" *ngFor="let certificate of certificates | filterService; index as i">
            <div class="row">
              <div class="col-sm-6">
                <label class="button-label" [for]="'upload-certificate-' + certificate.name">
                  <img src="./assets/Utils/archivo.png" class="upload-image" />
                  {{ certificate.name }}
                  <input class="hidden-input" type="file" [id]="'upload-certificate-' + certificate.name"
                    (change)="onUploadDocument({type: 'other-certificate', event: $event, name: certificate.name})" />
                </label>
                <a [href]="certificate.preview | safe" target="_blank"
                  *ngIf="certificate.preview !== '' && certificate.preview !== undefined && certificate.preview !== null">
                  Ver documento
                </a>
              </div>
              <div class="col-sm-6">

                <input type="date" class="warehouse-input" [name]="certificate.name + '-date'"
                  [(ngModel)]="certificate.fechaVencimiento" [value]="certificate.fechaVencimiento" [min]="today"
                  placeholder="Fecha de vencimiento" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>

    <!-- Step 5: Warehouse services -->
    <form *ngIf="currentStep === 5">
      <span class="services-tittle">Servicios adicionales</span>
      <div class="row services-div">
        <div class="col-sm-6 services" *ngFor="let service of serviceList; index as i">
          <mat-checkbox class="form-checkbox" [name]="service.name" [(ngModel)]="service.selected"></mat-checkbox>
          {{ service.name }}
        </div>
      </div>
    </form>

    <!-- Step 6: Spaces of the warehouse -->
    <form *ngIf="currentStep === 6" [formGroup]="spaceForm" (ngSubmit)="onCreateSpace()">

      <div class="row">
        <!-- Seleccionar tipo de espacio -->
        <div class="col-sm-4">
          <p style="white-space: nowrap">Tipo de espacio</p>
          <select class="warehouse-input select" formControlName="tipo" (change)="onChangeSpaceType()">
            <option disabled value="">Indica el tipo de espacio</option>
            <option value="Estanteria">Estantería (1x1,20x1,50 m)</option>
            <option value="Piso">Piso</option>
          </select>
        </div>
        <!--  -->
        <div class="col-sm-4" formGroupName="espacio"
          *ngIf="spaceForm.contains('espacio') || editEstanteria || editPiso">
          <p style="white-space: nowrap">Nombre</p>
          <input class="warehouse-input select" type="text" placeholder="Nombre" id="space-name"
            formControlName="nombre" [ngClass]="{'red-border': spaceIsInvalid('espacio.nombre')}" />
        </div>
        <div class="col-sm-4" formGroupName="espacio"
          *ngIf="spaceForm.contains('espacio') || editEstanteria || editPiso">
          <p style="white-space: nowrap">Tipo de techo</p>
          <select (change)="roof($event)" class="warehouse-input select" formControlName="techo"
            [ngClass]="{'red-border': spaceIsInvalid('espacio.techo')}">
            <option disabled value="">Indica el tipo de techo</option>
            <option value="Cubierto">Cubierto</option>
            <option value="Descubierto" *ngIf="alimentos">Descubierto</option>
          </select>
        </div>
      </div>

      <!-- Inputs for shelving type spaces-->
      <div class="row" *ngIf="spaceForm.value.tipo == 'Piso'">
        <div class="col-sm-4" formGroupName="espacio" *ngIf="spaceForm.contains('espacio')  || editPiso">
          <p class="info">M2 disponibles</p>
          <input class="warehouse-input select" type="tel" placeholder="M2 disponibles" id="space-meters"
            (keyup)="onValidateNumberSpaceForm({ property: 'cantidad'})" formControlName="cantidad"
            [ngClass]="{'red-border': spaceIsInvalid('espacio.cantidad')}" />
        </div>
        <div class="col-sm-4" formGroupName="espacio" *ngIf="spaceForm.contains('espacio')  || editPiso">
          <p class="info">Altura máxima (M)</p>
          <input class="warehouse-input select" type="tel" placeholder="Altura máxima (M)" id="space-height"
            (keyup)="onValidateNumberSpaceForm({ property: 'alto'})" formControlName="alto"
            [ngClass]="{'red-border': spaceIsInvalid('espacio.alto')}" />
        </div>
        <div class="col-sm-4" formGroupName="espacio" *ngIf="spaceForm.contains('espacio')  || editPiso">
          <p class="info">Peso max por M2(Ton)</p>
          <input class="warehouse-input select" type="tel" placeholder="Peso máx por M2(Ton)" id="space-weight"
            (keyup)="onValidateNumberSpaceForm({ property: 'peso'})" formControlName="peso"
            [ngClass]="{'red-border': spaceIsInvalid('espacio.peso')}" />
        </div>
        <div class="col-sm-4" formGroupName="espacio" *ngIf="spaceForm.contains('espacio')  || editPiso">
          <p class="info">Costo por metro(COP)</p>
          <input class="warehouse-input select" type="tel" placeholder="Costo por metro (COP)" id="space-price"
            (keyup)="onValidateNumberSpaceForm({ property: 'costo'})" formControlName="costo"
            [ngClass]="{'red-border': spaceIsInvalid('espacio.costo')}" />
        </div>
      </div>

      <!-- Inputs for shelving type spaces-->

      <div class="row" *ngIf="spaceForm.value.tipo == 'Estanteria'">
        <div class="col-sm-4" formGroupName="espacio" *ngIf="spaceForm.contains('espacio')  || editEstanteria">
          <p class="info">Cantidad de posiciones</p>
          <input class="warehouse-input select" type="tel" placeholder="Cantidad de posiciones" id="space-positions"
            (keyup)="onValidateNumberSpaceForm({ property: 'cantidad'})" formControlName="cantidad"
            [ngClass]="{'red-border': spaceIsInvalid('espacio.cantidad')}" />
        </div>
        <div class="col-sm-4" formGroupName="espacio" *ngIf="spaceForm.contains('espacio')  || editEstanteria">
          <p class="info" style="white-space: nowrap">Peso máximo por posición (Ton)</p>
          <input class="warehouse-input select" type="tel" placeholder="Peso máximo por posición (Ton)"
            (keyup)="onValidateNumberSpaceForm({ property: 'peso'})" id="space-weigh-per-position"
            formControlName="peso" [ngClass]="{'red-border': spaceIsInvalid('espacio.peso')}" />
        </div>
        <div class="col-sm-4" formGroupName="espacio" *ngIf="spaceForm.contains('espacio')  || editEstanteria">
          <p class="info">Costo por posición(COP)</p>
          <input class="warehouse-input select" type="tel" placeholder="Costo por posición(COP)"
            id="space-price-per-position" formControlName="costo"
            (keyup)="onValidateNumberSpaceForm({ property: 'costo'})"
            [ngClass]="{'red-border': spaceIsInvalid('espacio.costo')}" />
        </div>
      </div>

      <div class="sec-sub-title" *ngIf="spaceForm.contains('espacio') && cubierto ">Especificaciones</div>

      <div class="row">
        <!-- Inputs for specifications -->
        <div class="col-sm-4 top-15" formGroupName="espacio" *ngIf="spaceForm.contains('espacio')  && cubierto">
          <mat-checkbox class="form-checkbox" formControlName="temperaturaControlada"
            (change)="onChangeTempeture($event)">
            Temperatura controlada</mat-checkbox>
        </div>
        <div class="col-sm-4" formGroupName="espacio" *ngIf="spaceForm.contains('espacio')  && temperaturaControlada">
          <input (keyup)="onValidateNumberSpaceForm({ property: 'temperatura'})" class="warehouse-input" type="number"
            placeholder="Temperatura del espacio" id="space-temperature" formControlName="temperatura" />
        </div>
        <!-- <div class="col-sm-4 top-15" formGroupName="espacio" *ngIf="spaceForm.contains('espacio') && espacio.contains('estanteriasNumeradas')">
          <mat-checkbox class="form-checkbox" formControlName="estanteriasNumeradas"></mat-checkbox> Estanterías numeradas WM
        </div> -->
      </div>
      <button *ngIf="editEstanteria == false && editPiso == false" id="create-space" type="submit"
        [disabled]="!spaceForm.contains('espacio')">
        Crear espacio
      </button>
      <button id="create-space" (click)="onEditClick()" *ngIf="editEstanteria || editPiso"
        [disabled]="!spaceForm.contains('espacio')">
        Editar espacio
      </button>
    </form>

    <!-- Spaces table of step 6 -->
    <div *ngIf="currentStep === 6" class="spaces-table">
      <div class="sec-sub-title">Espacios creados</div>
      <div class="table-container">
        <table class="spaces">
          <tr>
            <th>Nombre</th>
            <th>Tipo</th>
            <th>Techo</th>
            <th>Posiciones</th>
            <th>Precio/Día</th>
            <th>Altura max</th>
            <th>Peso max</th>
            <th>Temperatura</th>
          </tr>
          <tr class="content-rows" *ngFor="let space of spaceList; index as i">
            <td>{{ space.nombre }}</td>
            <td>{{ space.tipo }}</td>
            <td>{{ space.techo }}</td>
            <td>{{ space.cantidad }}</td>
            <td>{{ space.costo | currency: "COP ":"code" }}</td>
            <td>{{ space.alto }} m</td>
            <td>{{ space.peso }} Ton</td>
            <td>{{ space.temperatura || 'N/A' }} °C</td>
            <td>
              <fa-icon class="table-edit" (click)="onEditSpace({ index: i })" [icon]="faEdit"></fa-icon>
            </td>
            <td>
              <fa-icon class="table-delete" (click)="onDeleteSpace({ index: i })" [icon]="faTimes"></fa-icon>
            </td>
          </tr>
        </table>
      </div>
    </div>


    <!-- Step 7: Prices of the warehouse -->
    <form *ngIf="currentStep === 7">
      <div class="sec-sub-title">Asigna los costos de gestión</div>
      <div class="row">
        <div class="col-sm-6 handling">
          <label for="">Precio por kilogramo</label>
          <input class="warehouse-input" type="tel" placeholder="Manejo por kilogramo" id="pallet-management"
            name="kilogramHandling" [(ngModel)]="prices.kilogramHandling"
            (keyup)="onValidateNumberPrices({ _id: '1' })" />
        </div>
      </div>
      <div class="sec-sub-title">
        <label *ngIf="onValidateServicionSelected()" for="">
          Asigna los costos de servicios adicionales
        </label>
      </div>
      <div class="row">
        <div class="col-sm-6" *ngFor="let service of serviceList | filterService">
          <input class="warehouse-input" type="tel" [placeholder]="'$ ' + service.name" [name]="service.name"
            [(ngModel)]="service.costo" (keyup)="onValidateNumberPrices({ _id: service._id })" />
        </div>
      </div>
    </form>

    <!--Step 8: Images of the warehouse -->
    <form *ngIf="currentStep === 8">
      <p class="form-paragraph">
        Sube las fotografías para mostrar a los demás usuarios cómo se ve la bodega. Mientras más fotos subas mejor.
        Debes subir fotos de cada
        espacio.
      </p>
      <button id="upload-guide">Guía para tomar fotos</button>
      <div class="sec-sub-title">Fotos de la bodega</div>
      <div class="row">
        <div class="col-sm-2 bottom-2" *ngFor="let photo of photos; index as i">
          <img class="warehouse-photo" [src]="photo" />
          <div class="delete-photo absolute" (click)="onDeletePhoto({index: i})">
            <fa-icon [icon]="faTimes"></fa-icon>
          </div>
        </div>
        <div class="col-sm-3">
          <button id="upload-warehouse-photo" class="upload-img" (click)="photoUpload.click()">
            <img src="./assets/Utils/archivo.png" class="upload-image" />
            Sube una foto
            <input type="file" accept="image/*" #photoUpload class="hidden-input" id="photos-input"
              (change)="onUploadDocument({type: 'Photo', event: $event})" />
          </button>
        </div>
      </div>
    </form>

    <!-- Next and previous buttons-->
    <br />
    <div class="row">
      <div class="col-sm-6">
        <button id="previous-step" *ngIf="currentStep > 1" [disabled]="loading" (click)="onPreviousStep()">
          <fa-icon [icon]="faChevronLeft"></fa-icon> Paso anterior
        </button>
      </div>
      <div class="col-sm-6">
        <button *ngIf="currentStep !== 8 && !loading" id="next-step" (click)="onNextStep()">Siguiente paso</button>
        <button *ngIf="currentStep === 8 && !loading" id="submit-request"
          (click)="onSaveClick({transactionType: 'submit'})">Crear bodega</button>
      </div>
      <div class="col-sm-12">
        <mat-progress-bar *ngIf="loading" mode="determinate" [value]="currentProgress"></mat-progress-bar>
      </div>
    </div>
  </div>
</div>