<div class="my-warehouses">
  <h1 class="title">Mis bodegas</h1>
  <div class="my-warehouses-options">
    <div class="left">
      <div class="filters">
        <button [ngClass]="{ enabled: toggleBt1 }" (click)="onFilterByQuery({ value: 'todos' })" [class.active]>
          Todas
        </button>
        <button [ngClass]="{ enabled: toggleBt2 }" (click)="onFilterByQuery({ value: 'pendiente' })" [class.active]>
          Pendientes
        </button>
        <button [ngClass]="{ enabled: toggleBt3 }" (click)="onFilterByQuery({ value: 'rechazada' })" [class.active]>
          Rechazadas
        </button>
        <button [ngClass]="{ enabled: toggleBt4 }" (click)="onFilterByQuery({ value: 'aprobada' })" [class.active]>
          Aprobadas
        </button>
      </div>
    </div>
    <div class="rigth">
      <button class="complete-process" (click)="onOpenModal({ content: finishWarehouse })">
        Completar Proceso
      </button>
      <button class="create-warehouses" (click)="goToCreateWarehouse()">
        Crear Bodega
      </button>
      <button class="statistics-warehouses" (click)="goToStats()">
        Estadísticas
      </button>
    </div>
  </div>
  <div class="my-warehouses-information">
    <div class="text-center">
      <p style="margin-top: 50px" class="spinner-border text-center" role="status" *ngIf="loadingAllWareHouses"></p>
      <p style="margin-top: 50px" *ngIf="allWarehouses.length === 0 && loadingAllWareHouses === false">
        No hay procesos con este estado.
      </p>
    </div>
    <div class="warehouses-cards" *ngIf="allWarehouses.length > 0 && loadingAllWareHouses === false">
      <div class="card text-center" *ngFor="
          let warehouse of allWarehouses
            | paginate
              : {
                  itemsPerPage: warehousePerPage,
                  currentPage: page,
                  totalItems: totalWarehouses
                };
          index as i
        ">
        <div class="card-body" (click)="onDetailWarehouse(warehouse._id)">
          <div class="percentage">
            <circle-progress [percent]="calculatePositions(i)" class="percentage-circle-progress"></circle-progress>
            <p>Ocupación</p>
          </div>
          <div class="warehouse-information">
            <p class="card-title">{{ warehouse.nombre }}</p>
            <p>{{ warehouse.descripcion }}</p>
            <p>
              <i class="material-icons">place</i>{{
              warehouse.direccion
              ? warehouse.direccion.replace("undefined", "")
              : "Calle 2b #36-26"
              }}
            </p>
          </div>
        </div>
        <div class="card-footer text-white bg-success" *ngIf="warehouse.estado == 'aprobada'">
          <p>Aprobada</p>
        </div>
        <div class="card-footer text-white bg-danger rechazada" *ngIf="warehouse.estado == 'rechazada'">
          <p>Rechazada</p>
          <a (click)="open(rechazo)">Ver motivo de rechazo</a>
        </div>
        <div class="card-footer text-white bg-primary" *ngIf="warehouse.estado == 'enProgreso'">
          <p>En progreso</p>
        </div>
        <div class="card-footer text-white bg-secondary" *ngIf="warehouse.estado == 'pendiente'">
          <p>Pendiente</p>
        </div>
        <!-- Modal motivo rechazo -->
        <ng-template #rechazo let-modal>
          <div class="modal-header">
            <h1>Motivo de rechazo</h1>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="modal-body--header">
              <p>{{ warehouse.nombre }}</p>
              <p>Iniciaste el {{ warehouse.createdAt | slice: 0:10 }}</p>
            </div>
            <div class="modal-body--info">
              <p>{{ warehouse.rechazadoMessage }}</p>
            </div>
          </div>
        </ng-template>
      </div>
    </div>
    <div class="row" *ngIf="totalWarehouses > 0 && loadingAllWareHouses === false">
      <div class="col-lg-12">
        <pagination-controls [previousLabel]="'Página anterior'" [nextLabel]="'Página siguiente'" [responsive]="true"
          (pageChange)="onPageChange({ event: $event })"></pagination-controls>
        <div class="document-number-selector">
          {{ "Bodegas por página" }}:
          <select id="paginator-select" [ngModel]="warehousePerPage"
            (ngModelChange)="onPerPageChange({ event: $event })">
            <option [value]="6">6</option>
            <option [value]="9">9</option>
            <option [value]="15">15</option>
          </select>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Modal finish warehouse -------------------------------------------------- -->
<ng-template #finishWarehouse let-modal>
  <div class="modal-header">
    <h4>Completa la creación de bodega</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross exit')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="warehouse-card" *ngFor="let warehouseInProgress of warehousesInProgress">
      <div class="warehouse-card-info" (click)="
          onRedirectToCompletedWarehouse({ warehouse: warehouseInProgress });
          modal.dismiss('Cross redirect')
        ">
        <p class="warehouse-card-info-title">
          {{
          warehouseInProgress.nombre === ""
          ? "Nombre pendiente"
          : warehouseInProgress.nombre
          }}
        </p>
        <p class="warehouse-card-info-body">
          Iniciaste el {{ formatDate({ date: warehouseInProgress.createdAt }) }}
        </p>
      </div>
      <div class="warehouse-card-button">
        <button class="remove-warehouse" (click)="
            $event.stopPropagation();
            onRemoveWarehouse({ id: warehouseInProgress._id })
          ">
          Eliminar
        </button>
      </div>
    </div>
  </div>
</ng-template>