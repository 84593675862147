import { Injectable } from "@angular/core";
import { ordenRetiro } from "src/app/interfaces/movements";
import { ApiService } from "./api.service";

@Injectable({
  providedIn: "root",
})
export class InventoryService {
  readonly INVETORY_API = "api/inventario/reserva/";
  readonly MOVEMENT_ADD_API = "api/movimientoAgregar/reserva/";
  readonly UNIDAD_API = "api/unidad/";
  readonly SKU_VERIFY_API = "api/movimientoAgregar/reserva/verification/";

  // This is a dictionary with element to create a flow to remove
  public inventoryToRemove: ordenRetiro[] = [];
  // This is a var to know how many items to movement
  public itemsToMoveTotal: number = 0;

  constructor(private apiService: ApiService) {}

  /**
   * It is in charge of calling the api service to make the get request to obtain the inventory of reserve.
   * @param param0 Receives a string is id of reserve
   * @returns The iventory obtained from the back
   */
  getInventoryByReserveId({ id }: { id: string }): any {
    return this.apiService.getAsPromise({ api: `${this.INVETORY_API}${id}` });
  }
  /**
   * It is in charge of calling the api service to make the get request to obtain the inventory of reserve.
   * @param param0 Receives a string is id of reserve
   * @returns The iventory obtained from the back
   */
  getCheckSku({ id, sku }: { id: string; sku: string }): any {
    return this.apiService.getAsPromise({ api: `${this.SKU_VERIFY_API}${id}` });
  }
  getSkuRepetead({ id, data }: { id: string; data: any }): any {
    return this.apiService.postPromise({
      api: `${this.SKU_VERIFY_API}${id}`,
      data: data,
    });
  }
  /**
   * It is in charge of calling the api service to make the get request to obtain the inventory of reserve.
   * @param param0 Receives a string is id of reserve
   * @returns The iventory obtained from the back
   */
  getUnidades(): any {
    return this.apiService.getAsPromise({ api: `${this.UNIDAD_API}` });
  }

  /**
   * It is in charge of calling the api service to make the get request to obtain the add movements of reserve.
   * @param param0 Receives a string is id of reserve
   * @returns If it was possible to create the movement
   */
  async postExcel({ id, data }: { id: string; data: File }): Promise<any> {
    return await this.apiService
      .postExcelTypePromise({
        api: `${this.MOVEMENT_ADD_API}${id}`,
        fileToUpload: data,
      })
      .then((res) => res)
      .catch((err) => err);
  }
}
