<div class="modal--success">
  <div class="clock--big">
    <img class="clock" src="../../../assets/Logos/reloj.png" alt="" />
  </div>
  <div class="modal--success__main">
    <h1>¡Ya casi!</h1>
    <p class="modal--success__main--text">
      Pronto recibirás respuesta de sí tu solicitud fue aceptada o no
    </p>
  </div>
  <div class="modal--success__footer">
    <footer>
      <button class="checkout--button" (click)="onOkClick()">Listo</button>
    </footer>
  </div>
</div>
