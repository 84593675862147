import { Location } from "@angular/common";
import { ThrowStmt } from "@angular/compiler";
import { Component, OnInit } from "@angular/core";
import {
  FormArray,
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
} from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import {
  faChevronLeft,
  faTimes,
  faEdit,
} from "@fortawesome/free-solid-svg-icons";
import * as moment from "moment";
import { AuthService } from "../../services/auth.service";
import { MapsService } from "../../services/maps.service";
import { MyWarehousesService } from "../../services/my-warehouses.service";
import { NavigationService } from "../../services/navigation.service";
import { DeleteConfirmComponent } from "../../shared/delete-confirm/delete-confirm.component";
import { SuccessComponent } from "../../shared/success/success.component";
import { S3Service } from "./../../services/s3.service";

@Component({
  selector: "app-warehouse-create",
  templateUrl: "./warehouse-create.component.html",
  styleUrls: ["./warehouse-create.component.scss"],
})
export class WarehouseCreateComponent implements OnInit {
  /** API constants */
  /** Constant that holds the API path for retrieving the 'other' items */
  readonly OTHERS_API = "api/otro";
  /** Constant that holds the API path for the warehouses */
  readonly WAREHOUSE_API = "api/bodega";

  /** Functional constants */
  /** Constant that holds the total number of steps */
  readonly TOTAL_STEPS = 8;
  /** Constant that holds the days of the week */
  readonly WEEK_DAYS = [
    "Lunes",
    "Martes",
    "Miércoles",
    "Jueves",
    "Viernes",
    "Sábado",
    "Domingo",
    "Festivos",
  ];
  /** Constant that holds the days of the week */
  readonly WEEK_DAYS_FORMATED = [
    { name: "Lunes", code: "Lu" },
    { name: "Martes", code: "Ma" },
    { name: "Miércoles", code: "Mi" },
    { name: "Jueves", code: "Ju" },
    { name: "Viernes", code: "Vi" },
    { name: "Sábado", code: "Sá" },
    { name: "Domingo", code: "Do" },
    { name: "Festivos", code: "Fe" },
  ];
  /** Constant that holds the titles of the steps */
  readonly STEPS_TITLES = [
    "Información básica",
    "Ubicación",
    "Horarios",
    "Especificaciones",
    "Servicios",
    "Espacios de la Bodega",
    "Costos",
    "¿Cómo se ve?",
  ];
  readonly STEPS_IMG = [
    "./assets/Utils/info-basica.png",
    "./assets/Utils/ubicacion.png",
    "./assets/Utils/horarios.png",
    "./assets/Utils/especificaciones.png",
    "./assets/Utils/servicios.png",
    "./assets/Utils/espacios.png",
    "./assets/Utils/precios.png",
    "./assets/Utils/fotos.png",
  ];

  /** VARIABLES */

  /** Loader option */
  public loaderOptions = {
    primaryColour: "#ffffff",
    secondaryColour: "#ccc",
    tertiaryColour: "#ffffff",
    backdropBorderRadius: "3px",
  };

  /** Variable that holds the current step */
  currentStep = 1;

  /** Variable that holds the certificate of the form (step 2) */
  freedomCertificate: any;

  /** Variables for the specifications form (step 4) */
  /** List for the accepted products */
  acceptedProducts = [];
  /** List for the security specifications*/
  securityItems = [];
  /* List for the certificates of the warehouse */
  certificates = [];

  /**  Variables for the services form (step 5) */
  /** List of services that the warehouse provides */
  serviceList = [];

  kilogramAdded: boolean = false;
  cubierto: boolean = false;
  temperaturaControlada: boolean = false;
  /**Cities and countries for step2  */
  locations = [];
  ciudades = [];
  paises = [];
  indexPais = [];
  prices = {
    palletHandling: undefined,
    unityHandle: undefined,
    kilogramHandling: undefined,
  };

  /** Variables for the photos form (step 8) */
  photos = [];

  /** FONT AWESOME icons */
  faTimes = faTimes;
  faChevronLeft = faChevronLeft;
  faEdit = faEdit;

  /** Variable that holds the latitude of the address */
  lat = 4.592164298;
  /** Variable that holds the longitude of the address */
  long = -74.072166378;

  /** Variables for handling loading status */
  /** Signals if a submission is in progress */
  loading = false;
  /** Signals the current progress of the submission */
  currentProgress = 0;

  /**Variable created for step 6 for knowing when to show the options */
  selectedType: boolean = false;
  alimentos: boolean = true;
  temp: boolean = true;
  editEstanteria: boolean = false;
  editPiso: boolean = false;
  indexEspacios: any = "";
  /** FORMS */
  /** Variable that holds the warehouse form (step 1)*/
  wareHouseForm: FormGroup;
  /** Variable that holds the address form (step 2) */
  addressForm: FormGroup;
  /** Variable that holds the schedule form (step 3) */
  scheduleForm: FormGroup;

  /** Space Form vars */
  /** Form to create or edit a space */
  spaceForm: FormGroup;
  /** List with all spaces created in the warehouse */
  spaceList: any = [];

  public validatedDaySelected = [];
  protected zoom = 18;
  protected map: any;
  public today = moment().format("YYYY-MM-DD");
  /** Function to remplace especial charaters and letters of string */
  formatterInputNumbers = (baseText) =>
    baseText.replace(/[^\w\s]/gi, "").replace(/\D/g, "");
  formatterInputNumbersDots = (baseText) => baseText.replace(/[^0-9.]/g, "");

  constructor(
    private formBuilder: FormBuilder,
    private location: Location,
    private mapsService: MapsService,
    private dialog: MatDialog,
    private s3Service: S3Service,
    private authService: AuthService,
    private navigationService: NavigationService,
    private myWarehousesService: MyWarehousesService
  ) { }

  async ngOnInit(): Promise<void> {
    // Initcialize the space form
    this.buildSpaceForm();
    // Initialize the form with default values for the form of the step 1
    this.wareHouseForm = this.formBuilder.group({
      nombre: ["", [
        Validators.required,
      ]],
      descripcion: ["", [
        Validators.required,
      ]],
      tamano: [undefined, [
        Validators.required,
        Validators.pattern(/^[a-zA-Z0-9]*$/)
      ]],
      matricula: ["", [
        Validators.required,
        Validators.pattern(/^[^.]+$/),
        Validators.minLength(4)
      ]],
      certificado: ["", Validators.required],
      contacto: this.formBuilder.group({
        nombre: ["", [
          Validators.required,
          Validators.pattern(/^[A-Za-z _]([^0-9]*)*$/),
          Validators.minLength(2)
        ]],
        apellido: ["", [
          Validators.required,
          Validators.pattern(/^[A-Za-z _]([^0-9]*)*$/),
          Validators.minLength(2)
        ]],
        telefono: ["", [
          Validators.required,
          Validators.minLength(6)
        ]],
        correo: ["", Validators.required],
      }),
      mismo: [false],
    });

    // Initialize the form with default values of the step 2
    this.addressForm = this.formBuilder.group({
      pais: ["", Validators.required],
      ciudad: ["", Validators.required],
      direccion: ["", [
        Validators.required,
        Validators.minLength(6)
      ]],
      detalles: [""],
      latitud: ["", Validators.required],
      longitud: ["", Validators.required],
    });

    // Form for getting schedule related data
    this.scheduleForm = this.formBuilder.group({
      lunes: false,
      martes: false,
      miercoles: false,
      jueves: false,
      viernes: false,
      sabado: false,
      domingo: false,
      festivos: false,
      horaInicio: ["", Validators.required],
      horaFin: ["", Validators.required],
      horaMax: ["", Validators.required],
      muelleDeCarga: false,
    });

    await this.getLocations();

    this.getOthers();

    this.initWarehousesInProgress();
  }

  /** Functions to use manage */

  /**
   * Function to initialize
   */
  public buildSpaceForm() {
    // Form for adding spaces
    this.spaceForm = this.formBuilder.group({ tipo: "" });
  }

  public onChangeSpaceType() {
    this.spaceForm.removeControl("espacio");
    this.spaceForm.addControl(
      "espacio",
      this.formBuilder.group({
        nombre: ["", Validators.required],
        cantidad: ["", [Validators.required, Validators.pattern(/^[0-9].*$/)]],
        estado: [""],
        alto: ["", Validators.pattern(/^[0-9].*$/)],
        ancho: ["", Validators.pattern(/^[0-9].*$/)],
        profundidad: ["", Validators.pattern(/^[0-9].*$/)],
        peso: ["", [Validators.required, Validators.pattern(/^[0-9].*$/)]],
        costo: ["", [Validators.required, Validators.pattern(/^[0-9].*$/)]],
        temperaturaControlada: [false],
        temperatura: ["", Validators.pattern(/^[0-9].*$/)],
        estanteriasNumeradas: [false],
        techo: ["", Validators.required],
      })
    );
  }

  /**Verifies if a give field is valid in a form */
  public spaceIsInvalid(field) {
    return (
      this.spaceForm.get(field)?.touched && this.spaceForm.get(field)?.invalid
    );
  }

  /**
   * Handles the creation of a space
   */
  onCreateSpace(): void {
    if (this.spaceForm.invalid) {
      this.spaceForm.markAllAsTouched();
    } else {
      let currentSpace = this.spaceForm.value;
      let newList = this.spaceList.slice();
      newList.push({
        nombre: currentSpace.espacio.nombre.trim(),
        tipo: currentSpace.tipo,
        cantidad: currentSpace.espacio.cantidad,
        alto:
          currentSpace.tipo === "Estanteria" ? 1.2 : currentSpace.espacio.alto,
        ancho: currentSpace.tipo === "Estanteria" ? 1.5 : "N/A",
        profundidad: currentSpace.tipo === "Estanteria" ? 1 : "N/A",
        peso: currentSpace.espacio.peso,
        costo: currentSpace.espacio.costo,
        temperaturaControlada: currentSpace.espacio.temperaturaControlada,
        temperatura: currentSpace.espacio.temperaturaControlada
          ? currentSpace.espacio.temperatura
          : "N/A",
        techo: currentSpace.espacio.techo,
      });
      this.spaceList = newList;
      this.spaceForm.patchValue({ tipo: "" });
      this.spaceForm.removeControl("espacio");
    }
  }

  /**
   * Handles space editing from the form
   * @param index index of the space that will be removed
   */
  onEditSpace({ index }: { index }): void {
    this.editPiso = false;
    this.editEstanteria = false;
    if (this.spaceList[index].tipo === "Estanteria") this.editEstanteria = true;
    else if (this.spaceList[index].tipo === "Piso") this.editPiso = true;
    this.editPiso = false;
    this.indexEspacios = index;
    this.cubierto = this.spaceList[index].techo == "Cubierto";
    this.temperaturaControlada = this.spaceList[index].temperaturaControlada;
    this.spaceForm = this.formBuilder.group({
      tipo: [this.spaceList[index].tipo],
    });
    this.spaceForm.setControl(
      "espacio",
      this.formBuilder.group({
        nombre: [this.spaceList[index].nombre],
        tipo: [this.spaceList[index].tipo],
        cantidad: [this.spaceList[index].cantidad],
        alto: [this.spaceList[index].alto],
        ancho: [this.spaceList[index].ancho],
        profundidad: [this.spaceList[index].profundidad],
        peso: [this.spaceList[index].peso],
        costo: [this.spaceList[index].costo],
        temperaturaControlada: [this.spaceList[index].temperaturaControlada],
        temperatura: [this.spaceList[index].temperatura],
        techo: [this.spaceList[index].techo],
      })
    );
  }

  public onEditClick() {
    this.editEstanteria = false;
    this.editPiso = false;
    this.spaceList[this.indexEspacios] =
      this.spaceForm.controls["espacio"].value;
    this.spaceForm.removeControl("espacio");
  }

  /**
   * Handles space deletion from the form
   * @param index index of the space that will be removed
   */
  onDeleteSpace({ index }: { index }): void {
    this.dialog.open(DeleteConfirmComponent, {
      data: {
        callback: () => {
          this.spaceList.splice(index, 1);
        },
      },
    });
  }

  public onClick() { }

  public async getLocations() {
    this.locations = await this.myWarehousesService.getLocations();
    console.log(this.locations);
  }

  public onPais(event) {
    let index = event.target.value;
    this.indexPais = index;
    this.ciudades = this.locations[index].ciudades;
    this.addressForm.patchValue({
      ciudad: "",
    });
  }

  public initWarehousesInProgress() {
    let warehouseInProgress = this.myWarehousesService.warehouseInProgress;
    if (warehouseInProgress) {
      //STEP 1
      this.wareHouseForm.patchValue({
        nombre: warehouseInProgress.nombre,
        descripcion: warehouseInProgress.descripcion,
        tamano: warehouseInProgress.tamano,
        certificado: warehouseInProgress.certificadoLibertad,
        matricula: warehouseInProgress.matricula,
        contacto: {
          nombre: warehouseInProgress.contacto.nombre,
          apellido: warehouseInProgress.contacto.apellido,
          telefono: warehouseInProgress.contacto.telefono,
          correo: warehouseInProgress.contacto.correo,
        },
      });

      // Validation if the conctact info of user is the same information of user
      if (
        warehouseInProgress.contacto.nombre ==
        warehouseInProgress.usuario.nombre &&
        warehouseInProgress.contacto.apellido ==
        warehouseInProgress.usuario.apellido &&
        warehouseInProgress.contacto.telefono ==
        warehouseInProgress.usuario.celular &&
        warehouseInProgress.contacto.correo == warehouseInProgress.usuario.email
      ) {
        this.wareHouseForm.patchValue({ mismo: true });
      }
      //STEP 2
      let indexLocations = this.locations.findIndex(
        (item) => item.pais === warehouseInProgress.pais
      );
      this.ciudades = this.locations[indexLocations].ciudades.slice();
      this.lat = Number(warehouseInProgress.latitud);
      this.long = Number(warehouseInProgress.longitud);

      this.addressForm.patchValue({
        pais: indexLocations,
        ciudad: warehouseInProgress.ciudad,
        direccion: warehouseInProgress.direccion,
        detalles: warehouseInProgress.detalles,
        latitud: warehouseInProgress.latitud,
        longitud: warehouseInProgress.longitud,
      });

      //STEP 3
      let hora = warehouseInProgress.horario.split("-");
      this.scheduleForm.patchValue({
        horaInicio: hora[0],
        horaFin: hora[1],
        horaMax: warehouseInProgress.horaMax,
        muelleDeCarga: warehouseInProgress.muelle,
      });
      this.validatedDaySelected = warehouseInProgress.dias;

      for (let i = 0; i < this.validatedDaySelected.length; i++) {
        let day = this.WEEK_DAYS_FORMATED.find(
          (e) => e.code == this.validatedDaySelected[i]
        );
        let obj: any = {};
        obj[this.normalizeString({ str: day.name })] = true;
        this.scheduleForm.patchValue(obj);
      }

      this.prices.kilogramHandling = warehouseInProgress.costoKilogramo;
      //TODO CARGAR ESPACIOS
      this.spaceList = warehouseInProgress.espaciosTemporales
        ? warehouseInProgress.espaciosTemporales
        : [];

      //STEP 8
      this.photos = warehouseInProgress.fotos;
    }
  }

  /**
   * Retrieves the 'others' elements and sets them up in the form
   */
  getOthers(): void {
    let warehouseInProgress = this.myWarehousesService.warehouseInProgress;
    this.myWarehousesService
      .getOtros()
      .then((response) => {
        for (const other of response) {
          let element: any = {
            _id: other._id,
            name: other.nombre,
            selected: false,
          };
          // Si la bodega esta en proceso y tiene opciones selecionadas las marca
          if (warehouseInProgress) {
            const found = warehouseInProgress.otros.find(
              (item) => item.otro?._id == element._id
            );
            if (found) {
              element.selected = found.ofrecido;
            }
            switch (other.tipo) {
              case "producto":
                this.acceptedProducts.push(element);
                break;
              case "seguridad":
                this.securityItems.push(element);
                break;
              case "certificado":
                this.certificates.push({
                  ...element,
                  file: undefined,
                  preview: found?.otro?.preview
                    ? found.otro.preview
                    : undefined,
                  fechaVencimiento: found?.otro?.fechaVencimiento
                    ? found.otro.fechaVencimiento
                    : undefined,
                });
                break;
              case "servicio":
                this.serviceList.push({
                  ...element,
                  costo: found?.costo ? found.costo : undefined,
                });
            }
          } else {
            switch (other.tipo) {
              case "producto":
                this.acceptedProducts.push(element);
                break;
              case "seguridad":
                this.securityItems.push(element);
                break;
              case "certificado":
                this.certificates.push({
                  ...element,
                  file: undefined,
                  preview: "",
                  fechaVencimiento: undefined,
                });
                break;
              case "servicio":
                this.serviceList.push({ ...element, costo: undefined });
            }
          }
        }
      })
      .catch((err) => {
        console.error(err);

        alert(err.error);
      });
  }

  /** On change functions */

  /**
   * Handles the click on the cancel button
   */
  onCancel(): void {
    this.location.back();
  }
  /**Verifies if a give field is valid in a form */
  public warehouseIsInvalid(field) {
    return (
      this.wareHouseForm.get(field)?.touched &&
      this.wareHouseForm.get(field)?.invalid
    );
  }
  /**Verifies if a give field is valid in a form */
  public addressIsInvalid(field) {
    return (
      this.addressForm.get(field)?.touched &&
      this.addressForm.get(field)?.invalid
    );
  }
  /**Verifies if a give field is valid in a form */
  public scheduleIsInvalid(field) {
    return (
      this.scheduleForm.get(field)?.touched &&
      this.scheduleForm.get(field)?.invalid
    );
  }

  /**
   * Handles the click on the previous step button
   */
  onPreviousStep(): void {
    if (this.currentStep - 1 >= 1) {
      this.currentStep--;
    }
  }

  /**
   * Validation of maximum and minimum values ​​for 'Horas de respuesta' in Step 3
   */
  public onValidateMaxHours() {
    // Get current value of 'Horas de respuesta'
    let currentValue = this.scheduleForm.get("horaMax").value;
    // Validate that the current value does not exceed 72 and isn't less than 1
    if (currentValue > 72) {
      alert("El maximo de horas para la atencion es de 72");
      this.scheduleForm.patchValue({ horaMax: 72 });
    } else if (currentValue < 1 && currentValue != null) {
      alert("El minimo de horas para la atencion es de 1");
      this.scheduleForm.patchValue({ horaMax: 1 });
    }
  }

  public onValidateNumberSize() {
    let value = this.wareHouseForm.get("tamano").value;
    value = this.formatterInputNumbersDots(value);
    this.wareHouseForm.patchValue({ tamano: value });
  }

  public onValidateNumberPhone() {
    let value = this.wareHouseForm.get("contacto.telefono").value;
    value = this.formatterInputNumbers(value);
    this.wareHouseForm.patchValue({ contacto: { telefono: value } });
  }

  public onValidateNumberSpaceForm({ property }: { property: string }) {
    let formValue = this.spaceForm.value["espacio"][property];
    formValue = this.formatterInputNumbersDots(formValue);
    let espacio = { [property]: formValue };
    this.spaceForm.patchValue({ espacio });
  }

  public onValidateNumberPrices({ _id }: { _id: string }) {
    if (_id == "1") {
      let format = this.formatterInputNumbers(this.prices.kilogramHandling);
      this.prices.kilogramHandling = format;
    } else {
      let index = this.serviceList.findIndex((s) => s._id == _id);
      let format = this.serviceList[index].costo;
      format = this.formatterInputNumbers(format);
      this.serviceList[index].costo = format;
    }
  }

  public onVerifyTrue(array) {
    let verification = [];
    for (let i = 0; i < array.length; i++) {
      let element = array[i];
      verification.push(element.selected);
    }
    if (verification.includes(true) === false) {
      return false;
    } else {
      return true;
    }
  }
  /** Handles the click on the next step button */
  onNextStep(): void {
    let productSelected = this.onVerifyTrue(this.acceptedProducts);
    let condition =
      this.scheduleForm.value.horaInicio >= this.scheduleForm.value.horaFin;
    if (this.currentStep === 1 && this.wareHouseForm.invalid) {
      this.wareHouseForm.markAllAsTouched();
    } else if (this.currentStep === 2 && this.addressForm.invalid) {
      this.addressForm.markAllAsTouched();
    } else if (
      (this.currentStep === 3 && this.scheduleForm.invalid) ||
      (this.currentStep === 3 && condition)
    ) {
      if (condition) {
        alert("La hora de inicio debe ser antes de la hora de cierre. Por ejemplo: Abre a las 9:00 am y cierra a las 1:00 pm");
        this.scheduleForm.markAllAsTouched();
      } else {
        this.scheduleForm.markAllAsTouched();
      }
    } else if (this.currentStep === 4 && productSelected === false) {
      alert("Por favor selecciona un producto para continuar");
    } else if (this.currentStep === 6 && this.spaceList.length === 0) {
      alert("Por favor añade un espacio");
    } else if (
      (this.currentStep === 7 && this.prices.kilogramHandling === undefined) ||
      (this.currentStep === 7 && this.prices.kilogramHandling === null)
    ) {
      this.kilogramAdded = false;
      alert("Por favor añade el precio por kilogramo");
    } else {
      if (this.currentStep + 1 <= this.TOTAL_STEPS) {
        this.currentStep++;
      }
    }
  }

  /**
   * Handles the click on the verify address button
   */
  onVerifyAddressClick(): void {
    if (
      this.addressForm.controls["ciudad"].invalid ||
      this.addressForm.controls["direccion"].invalid ||
      this.addressForm.controls["pais"].invalid
    ) {
      this.addressForm.controls["ciudad"].markAsTouched();
      this.addressForm.controls["direccion"].markAsTouched();
      this.addressForm.controls["pais"].markAsTouched();
      return;
    }
    this.loading = true;
    let address = this.addressForm.value;
    if (
      address.ciudad !== "" &&
      address.direccion !== "" &&
      address.pais !== ""
    ) {
      let addressString =
        address.direccion +
        " " +
        address.ciudad +
        " " +
        this.locations[address.pais].pais;
      this.mapsService.getGeocode({ address: addressString }).subscribe(
        (response) => {
          if (response.results.length > 0) {
            if (response.results.length > 1) {
              this.loading = false;
              this.zoom = 18;
              this.recenterMap(
                response.results[1].geometry.location.lat,
                response.results[1].geometry.location.lng
              );
              this.markerClicked(
                response.results[1].geometry.location.lat,
                response.results[1].geometry.location.lng
              );
            } else {
              this.loading = false;
              this.zoom = 18;
              this.recenterMap(
                response.results[0].geometry.location.lat,
                response.results[0].geometry.location.lng
              );
              this.markerClicked(
                response.results[0].geometry.location.lat,
                response.results[0].geometry.location.lng
              );
            }
          } else {
            // TODO Mejorar modal
            this.loading = false;
            alert("No se encontraron resultados");
          }
        },
        (err) => {
          this.loading = false;
          console.error(err);
          alert(err.error.mensaje);
        }
      );
    }
  }

  protected mapReady(map) {
    this.map = map;
  }

  public markerClicked(lat, lng) {
    if (this.map) this.map.setCenter({ lat, lng });
  }

  public recenterMap(lat, lng) {
    this.lat = lat;
    this.long = lng;
    this.addressForm.get("latitud").setValue(this.lat);
    this.addressForm.get("longitud").setValue(this.long);
  }

  public onVerifyArray(array) {
    let condition = false;
    for (let i = 0; i < array.length; i++) {
      let element = array[i];
      if (element["selected"]) {
        condition = true;
      }
    }
    if (condition === true) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * Handles the upload of documents
   */
  onUploadDocument({
    type,
    event,
    name,
  }: {
    type: string;
    event: any;
    name?: string;
  }): void {
    switch (type) {
      case "Photo":
        // validation that only 12 photos are uploaded, using the current number of photos uploaded
        if (this.photos.length <= 11) {
          // Read the photo, store it and create a preview
          if (event.target.files && event.target.files.length)
            this.uploadPhotoWarehouse({ file: event.target.files[0] });
        } else alert("No se pueden subir mas de 12 fotos!");
        break;
      case "freedom-certificate":
        // If its the freedom certificate, create a url for file preview and store the file
        if (event.target.files && event.target.files.length) {
          const file = event.target.files[0];
          const previewUrl = URL.createObjectURL(file);
          this.wareHouseForm.get("certificado").setValue(previewUrl);
          this.freedomCertificate = file;
        }
        break;
      case "other-certificate":
        if (event.target.files && event.target.files.length) {
          let i = this.certificates.findIndex((certificate) => {
            return certificate.name === name;
          });
          const file = event.target.files[0];
          const previewUrl = URL.createObjectURL(file);
          this.certificates[i].preview = previewUrl;
          this.certificates[i].file = file;
        }
        break;
    }
  }

  public uploadPhotoWarehouse({ file }) {
    this.loading = true;
    this.s3Service.uploadFile({
      file: file,
      key: `warehouse/photos/${file.name}/${this.s3Service.generateKey()}`,
      callback: (err, data) => {
        let msg =
          "Hubo un error subiendo la imagen, por favor intentelo más tarde o contacte a soporte";
        // If there is an error alert the user
        if (err) alert(msg);
        // Assign the value to the corresponding component
        else this.photos.push(data.Location);
        this.loading = false;
      },
    });
  }
  /**
   * Handles the delete event of a photo in the
   * @param index index of the photo to delete
   */
  onDeletePhoto({ index }: { index: number }): void {
    this.photos.splice(index, 1);
  }

  /**
   * Handles the clicks on the save button
   * @param transactionType Signals the type of transaction
   * save --> Save the form data to continue editing it later.
   * submit --> Submit creation form for review
   */
  async onSaveClick({
    transactionType,
  }: {
    transactionType: string;
  }): Promise<any> {
    // Step 1: Upload files to S3
    // Make an array to upload all files as a batch
    this.loading = true;
    const uploads = [];
    let randomKey = this.s3Service.generateKey();
    if (this.photos.length === 0 && transactionType === "submit") {
      this.loading = false;
      alert("Por favor añade una foto");
    } else if (this.photos.length > 12) {
      this.loading = false;
      alert("Puedes subir máximo 12 fotos");
    } else {
      this.loading = true;
      // Add the documents
      if (this.freedomCertificate !== undefined) {
        uploads.push({
          file: this.freedomCertificate,
          key: `warehouse/freedom-certificate/${this.freedomCertificate.name}/${randomKey}`,
        });
      }
      for (const certificate of this.certificates) {
        if (certificate.selected && certificate.file) {
          randomKey = this.s3Service.generateKey();
          uploads.push({
            file: certificate.file,
            key: `warehouse/certificate/${certificate.name}/${certificate.file.name}/${randomKey}`,
          });
        }
      }
      // Upload and store the location of the documents
      let uploadResults = await this.s3Service.uploadBatch({ list: uploads });
      for (const result of uploadResults) {
        if (result.key.includes("freedom-certificate")) {
          this.wareHouseForm.get("certificado").setValue(result.Location);
        } else {
          for (const certificate of this.certificates) {
            if (result.key.includes(certificate.name)) {
              certificate.preview = result.Location;
            }
          }
        }
      }
      this.currentProgress += 33;

      // Step 2: Create the warehouse in the backend
      const warehouseData = this.wareHouseForm.value;
      const addressData = this.addressForm.value;
      const scheduleData = this.scheduleForm.value;
      const pricesData = this.prices;

      const user = this.authService.getUser()._id;
      // Setup the others array
      const otros = [];
      for (const product of this.acceptedProducts) {
        if (product.selected)
          otros.push({ otro: product, ofrecido: product.selected });
      }
      for (const securityService of this.securityItems) {
        if (securityService.selected)
          otros.push({
            otro: securityService,
            ofrecido: securityService.selected,
          });
      }
      for (const certificate of this.certificates) {
        if (certificate.selected)
          otros.push({
            otro: certificate,
            ofrecido: certificate.selected,
            documento: certificate.preview,
            fechaVencimiento: certificate.fechaVencimiento,
          });
      }
      for (const service of this.serviceList) {
        if (service.selected)
          otros.push({
            otro: service,
            ofrecido: service.selected,
            costo: service.costo,
          });
      }

      // Setup the days array
      const dias = [];
      for (let i = 0; i < this.WEEK_DAYS.length; i++) {
        if (
          this.scheduleForm.get(
            this.normalizeString({ str: this.WEEK_DAYS[i] })
          ).value
        ) {
          dias.push(this.WEEK_DAYS[i].slice(0, 2));
        }
      }

      let idExistente = this.myWarehousesService.warehouseInProgress
        ? this.myWarehousesService.warehouseInProgress._id
        : "";

      // Obtiene las espacios a crear
      // TODO Revisar
      const warehouse = {
        usuario: user,
        nombre: warehouseData.nombre,
        descripcion: warehouseData.descripcion,
        tamano: warehouseData.tamano,
        matricula: warehouseData.matricula,
        certificadoLibertad: warehouseData.certificado,
        ciudad: addressData.ciudad || "",
        pais: this.locations[addressData.pais]?.pais || "",
        direccion: addressData.direccion || "",
        detalles: addressData.detalles || "",
        latitud: this.lat,
        longitud: this.long,
        dias: dias,
        horario: scheduleData.horaInicio + "-" + scheduleData.horaFin,
        muelle: scheduleData.muelleDeCarga,
        horaMax: scheduleData.horaMax,
        otros: otros,
        contacto: warehouseData.contacto,
        costoKilogramo: pricesData.kilogramHandling,
        fotos: this.photos,
        estado: "enProgreso",
        espaciosTemporales: this.spaceList,
        id: idExistente,
      };

      if (transactionType === "submit") warehouse.estado = "pendiente";

      // Send the request
      this.myWarehousesService
        .postWarehouse({ data: warehouse })
        .then(() => {
          this.currentProgress += 33;
          if (transactionType === "save") {
            this.loading = false;
            this.dialog.open(SuccessComponent, {
              data: {
                title: "Se ha guardado el progreso",
                message: "Podrás continuar creando la bodega cuando quieras",
                onOk: () => {
                  this.currentProgress += 33;
                  this.navigationService.navigateTo({ path: "mis-bodegas" });
                },
              },
              disableClose: true,
            });
          } else {
            this.loading = false;
            this.dialog.open(SuccessComponent, {
              data: {
                title: "¡Genial!",
                message:
                  "Se ha iniciado el proceso de revisión de tu bodega. Una vez termine, se te notificará si fue aprobada o no. En caso de que cumpla con los estándares de Flexpot, podrás verla disponible en el catálogo.",
                onOk: () => {
                  this.currentProgress += 33;
                  this.navigationService.navigateTo({ path: "mis-bodegas" });
                },
              },
              disableClose: true,
            });
          }
        })
        .catch((err) => {
          console.error(err);
          alert(err.error.mensaje);
          this.loading = false;
        });
    }
  }

  public onChangeUserInfo(activated) {
    if (activated) {
      const user = this.authService.getUser();
      this.wareHouseForm.get("contacto").get("nombre").setValue(user.nombre);
      this.wareHouseForm
        .get("contacto")
        .get("apellido")
        .setValue(user.apellido);
      this.wareHouseForm.get("contacto").get("correo").setValue(user.email);
      this.wareHouseForm.get("contacto").get("telefono").setValue(user.celular);
    } else {
      this.wareHouseForm.get("contacto").get("nombre").setValue("");
      this.wareHouseForm.get("contacto").get("apellido").setValue("");
      this.wareHouseForm.get("contacto").get("correo").setValue("");
      this.wareHouseForm.get("contacto").get("telefono").setValue("");
    }
  }

  public onChangeProductType() {
    this.selectedType = true;
  }

  /** Normal functions */
  public normalizeString({ str }: { str: string }) {
    return str
      .toLowerCase()
      .normalize("NFD")
      .trim()
      .replace(/[\u0300-\u036f]/g, "");
  }

  public roof(event) {
    this.cubierto = event.target.value == "Cubierto";
  }

  public onChangeTempeture(ob) {
    this.temperaturaControlada = ob.checked;
  }

  public onValidateServicionSelected() {
    return this.serviceList.some((e) => e.selected);
  }
}
