import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ApiService } from "../services/api.service";
import {
  faChevronLeft,
  faCalendarAlt,
  faTimes,
  faEye,
  faPlusCircle,
  faQuestion,
  faMinusCircle,
} from "@fortawesome/free-solid-svg-icons";
import { NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";
import { ReservationAcceptedService } from "../services/reservation-accepted.service";
import { LocalDataSource } from "ng2-smart-table";
import { ToastrService } from "ngx-toastr";
import {
  FormArray,
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
} from "@angular/forms";
import { S3Service } from "../services/s3.service";
import { element } from "protractor";
@Component({
  selector: "app-reservas-mine",
  templateUrl: "./reservas-mine.component.html",
  styleUrls: ["./reservas-mine.component.scss"],
})
export class ReservasMineComponent implements OnInit {
  /**API reservas */
  RESERVAS_API = "api/reserva";
  PAGO_API = "api/transaccion/reserva";
  API_MOVEMENT = "api/movimientoAgregar";
  API_RETIRO = "api/ordenRetiro";
  /** Regular variables */
  reserva: any = {};
  reservasOwnerPending: any = {};
  /**Font awesome variables */
  faQuestion = faQuestion;
  faEye = faEye;
  faPlusCircle = faPlusCircle;
  faMinusCircle = faMinusCircle;
  faChevronLeft = faChevronLeft;
  faTimes = faTimes;
  faCalendarAlt = faCalendarAlt;
  servicesSelected = [];
  servicesCost: any = "";
  servicesQuantity: any = "";
  closeResult = "";
  public source: LocalDataSource;
  total: any = "";
  fee: any = "";
  profit: any = "";
  espacesCost: any = 0;
  pagos: any = [];
  inventario: boolean = true;
  aprobado: any = "";
  movementChoosen = [];
  products = [];
  movementID: any = "";
  indexSpace: any = "";
  add: boolean = false;
  substract: boolean = false;
  id_movement = "";
  id_retiro = "";
  proofFile: any;
  salidaFile: any;
  cantidadProducto: any = "";
  cantidadRetirada: any = "";
  productaOrder: any = [];
  /** Variable that holds the warehouse form (step 1)*/
  documentsForm: FormGroup;
  /**
   * Table variables
   */
  public settingsInventory: any = {};
  public settingsMovements: any = {};
  public settingsFlows: any = {};
  public sourceInventory: any;
  public sourceFlows: any;
  public reserve: any = {};
  aprobada: boolean = false;

  constructor(
    public activatedRoute: ActivatedRoute,
    private apiService: ApiService,
    public router: Router,
    private modalService: NgbModal,
    private reservationService: ReservationAcceptedService,
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private s3Service: S3Service
  ) {
    var id = this.activatedRoute.snapshot.params["id"];
    this.getReserva({ id });
    this.getPago(id);
    this.configInvetorySmartTable();
    this.configFlowsSmartTable();
    this.configMovements();

    // Init principal resources
    this.initInventoryAndFlows({ id: id });
  }

  ngOnInit(): void {
    this.documentsForm = this.formBuilder.group({
      document: ["", Validators.required],
    });
  }
  private async initReserve({ id }: { id: string }): Promise<void> {
    this.reserve = await this.reservationService.getReserve({ id: id });
  }
  @ViewChild("movementOut", { static: false }) movementOut: ElementRef;
  @ViewChild("movementIn", { static: false }) movementIn: ElementRef;
  @ViewChild("add", { static: false }) addModal: ElementRef;
  @ViewChild("substract", { static: false }) substractModal: ElementRef;
  @ViewChild("docummentsIn", { static: false }) documentsIn: ElementRef;
  @ViewChild("out", { static: false }) out: ElementRef;
  /**
   *
   * @param event
   */
  public async onCustom(event: any): Promise<void> {
    this.movementID = event.data.id;
    if (
      event.action === "accept" &&
      (event.data.estado === "recibido" ||
        event.data.estado === "pendiente" ||
        event.data.estado === "despachado")
    ) {
      this.changeMovementStatus(event);
    } else if (
      event.action === "watch" &&
      event.data.tipoMovimiento === "Retiro"
    ) {
      this.products = [];
      await this.requestMovementRetiro(event.data.id);
      this.open(this.movementOut);
    } else if (
      event.action === "watch" &&
      event.data.tipoMovimiento === "Agregar"
    ) {
      this.products = [];
      await this.requestMovement(event.data.id);
      this.open(this.movementIn);
    } else if (
      event.action === "accept" &&
      event.data.estado === "aprobado" &&
      event.data.tipoMovimiento === "Agregar"
    ) {
      this.open(this.documentsIn);
      this.productaOrder = [...event.data.producto];
    } else if (
      event.action === "accept" &&
      event.data.estado === "aprobado" &&
      event.data.tipoMovimiento === "Retiro"
    ) {
      this.productaOrder = [...event.data.producto];
      this.open(this.out);
    }
  }
  /**
   * Handles requesting the products of the movements
   * @param event
   */
  public async requestMovement(id) {
    let movementPromise = await this.reservationService
      .getMovement({ id: id })
      .catch((err) => {
        return err;
      });

    if (movementPromise && movementPromise.stack && movementPromise.message) {
    }
    for (let i = 0; i < movementPromise.productos.length; i++) {
      this.products.push(movementPromise.productos[i].producto);
      this.products[i].cantidad = movementPromise.productos[i].cantidad;
    }
  }
  /**
   * Handles requesting the products of the movements
   * @param event
   */
  public async requestMovementRetiro(id) {
    let movementPromise = await this.reservationService
      .getMovementRetiro({ id: id })
      .catch((err) => {
        return err;
      });

    if (movementPromise && movementPromise.stack && movementPromise.message) {
    }
    for (let i = 0; i < movementPromise.productos.length; i++) {
      this.products.push(movementPromise.productos[i].producto);
      this.products[i].cantidad = movementPromise.productos[i].cantidad;
    }
  }
  /**Handles clicling the icon and opening a url */
  public iconClick(url) {
    window.open(url, "_blank");
  }
  /**
   * Method to change the status of the reserva in the DB,from pendiente,rechazada o en proceso to aprobada
   * @param event Event generated by the table
   */
  changeMovementStatus(event: any): void {
    var id = this.activatedRoute.snapshot.params["id"];
    const movement: any = {
      estado: "aprobado",
    };
    const movementDenied: any = {
      estado: "rechazado",
    };
    let element = event.data;
    if (element.estado == "recibido" && element.tipoMovimiento === "Agregar") {
      this.iconClick(element.linkDocumento);
    } else if (
      element.estado == "despachado" &&
      element.tipoMovimiento === "Retiro"
    ) {
      this.iconClick(element.linkDocumento);
    } else if (
      element.estado == "pendiente" &&
      element.tipoMovimiento === "Agregar"
    ) {
      this.apiService
        .put({
          api: `${this.API_MOVEMENT}/${event.data.id}`,
          data: movement,
        })
        .subscribe(
          (response) => {
            this.initInventoryAndFlows({ id: id });
            this.showToastr({
              title: "Se ha aprobado el movimiento de manera exitosa",
            });
            this.configFlowsSmartTable();
          },
          (error) =>
            this.showToastr({
              title: "Un error ha ocurrido contactanos",
            })
        );
    } else if (
      element.estado == "pendiente" &&
      element.tipoMovimiento === "Retiro"
    ) {
      this.apiService
        .put({
          api: `${this.API_RETIRO}/${event.data.id}`,
          data: movement,
        })
        .subscribe(
          (response) => {
            this.initInventoryAndFlows({ id: id });
            this.showToastr({
              title: "Se ha aprobado la orden de retiro de manera exitosa",
            });
            this.configFlowsSmartTable();
          },
          (error) =>
            this.showToastr({
              title: "Un error ha ocurrido contactanos",
            })
        );
    }
  }
  public showToastr({
    position,
    title,
    message,
  }: {
    position?: any;
    title: string;
    message?: string;
  }): void {
    let realPosition = position ? position : "top-end";
    let realMessage = message ? message : "";
    let duractionMilisec = 4500;
    this.toastr.show(`${realMessage}`, `${title}`, {
      positionClass: "toast-bottom-right",
      timeOut: duractionMilisec,
    });
  }
  // changeMovementStatusDenied(event: any): void {

  //   this.aprobada = false;
  // }

  private async initInventoryAndFlows({ id }: { id: string }): Promise<void> {
    // Change struct for inventory of back to show in table
    let inv = await this.reservationService.getInventory({ id: id });
    let inventaryTable: any = [];
    let flowsTable: any = [];

    for (let index = 0; index < inv.length; index++) {
      const element = inv[index];
      inventaryTable.push({
        producto:
          element.producto != undefined ? element.producto.nombre : "None",
        unidad:
          element.producto != undefined ? element.producto.unidad : "None",
        cantidad: element.producto != undefined ? element.cantidad : "None",
        sku: element.producto != undefined ? element.producto.sku : "None",
        ubicacionNombre:
          element.espacio != undefined ? element.espacio.nombre : "None",
      });
    }

    let addMovements = await this.reservationService.getMovementsAdd({
      id: id,
    });
    for (let index = 0; index < addMovements.length; index++) {
      const element = addMovements[index];
      flowsTable.push({
        id: element._id,
        estado: element.estado,
        tipoMovimiento: "Agregar",
        linkDocumento: element.linkDocumento,
        producto: element.productos, // fechaProgramada: element.producto != undefined ? element.cantidad : "None",
      });
    }

    let removeMovements = await this.reservationService.getMovementsRemove({
      id: id,
    });

    for (let index = 0; index < removeMovements.length; index++) {
      const element = removeMovements[index];

      flowsTable.push({
        id: element._id,
        estado: element.estado,
        tipoMovimiento: "Retiro",
        linkDocumento: element.linkDocumento,
        producto: element.productos,
        // cantidadDespachada:0
      });
    }

    this.sourceInventory = new LocalDataSource(inventaryTable);
    this.sourceFlows = flowsTable;
  }
  private configMovements(): void {
    this.settingsMovements = {
      mode: "inline",
      pager: {
        display: true,
        perPage: 10,
      },
      hideSubHeader: false,
      actions: false,
      columns: {
        nombre: {
          title: "Producto",
        },
        cantidad: {
          title: "Items a mover",
        },
        sku: {
          title: "sku",
        },
      },
      // rowClassFunction: (row) => {
      //   if (row.data.estado == "aprobado") {
      //     //aprobado
      //     return "active";
      //   }
      // },
      noDataMessage: "No hay movimientos",
    };
  }
  private configFlowsSmartTable(): void {
    this.settingsFlows = {
      mode: "inline",
      pager: {
        display: true,
        perPage: 10,
      },
      hideSubHeader: false,
      actions: {
        columnWidth: "50px",
        columnTitle: "Acción",
        add: false,
        edit: false,
        delete: false,
        custom: [
          {
            name: "watch",
            title: '<i class="fa fa-eye"></i>',
          },
          {
            name: "accept",
            title: '<i class="fa fa-check"></i>',
          },
        ],
      },
      columns: {
        id: {
          title: "ID. movimiento",
        },
        estado: {
          title: "Estado",
        },
        tipoMovimiento: {
          title: "Tipo de movimiento",
        },
      },
      rowClassFunction: (row) => {
        if (row.data.estado == "aprobado") {
          //aprobado
          return "active";
        } else if (
          row.data.estado == "recibido" ||
          row.data.estado == "despachado"
        ) {
          return "recibido";
        }
      },
      noDataMessage: "No hay movimientos",
    };
  }
  private configInvetorySmartTable(): void {
    this.settingsInventory = {
      mode: "inline",
      pager: {
        display: true,
        perPage: 10,
      },
      hideSubHeader: false,
      actions: false,
      columns: {
        producto: {
          title: "Producto",
        },
        unidad: {
          title: "Tipo de Unidad",
        },
        cantidad: {
          title: "Cantidad",
        },
        sku: {
          title: "SKU Producto",
        },
        ubicacionNombre: {
          title: "Ubicación",
        },
      },
      rowClassFunction: (row) => {
        if (row.data.estado == "aprobado") {
          //aprobado
          return "active";
        }
      },
      noDataMessage: "El inventario esta vacío",
    };
  }
  /**
   * Method that get the information of the reserva selected
   * @param param0 ID of the user that I'LL use to count the reservas, array that will be modifed by the request
   */
  public getReserva({ id }: { id: string }) {
    this.apiService
      .get({ api: `${this.RESERVAS_API}/${id}` })
      .subscribe((reserva) => {
        this.reserva = reserva;
        this.onCotizaMesPositions({ reserva: this.reserva });
        this.onCotizaMesServicio({ reserva: this.reserva });
        this.profitCalculation();
      });
  }
  /**
   * Gets the cost of the position for one month
   * @param array Corresponds to the reserva array which is the target fot the calculation in this case
   */
  public onCotizaMesPositions({ reserva }) {
    this.espacesCost = reserva.espacios.reduce((accumulator, element) => {
      const costo =
        element.espacio.costo * element.cantidad * reserva.duracion * 7;
      return accumulator + costo;
    }, 0);
  }
  /**
   * Calculate the cost of the servicios in the reserva
   * @param array Reserva
   */
  public onCotizaMesServicio({ reserva }) {
    this.servicesCost = reserva.otros.reduce((accumulator, element) => {
      const serviceCost = element.otro.valor * element.cantidad;
      return accumulator + serviceCost;
    }, 0);
    this.servicesQuantity = reserva.otros.reduce((acc, element) => {
      return acc + element.cantidad;
    }, 0);
  }
  /**
   * Calculate the profit of the reserva
   * @param array Reserva
   */
  public profitCalculation() {
    this.total = this.servicesCost + this.espacesCost;
    this.fee = this.total * 0.25;
    this.profit = this.total - this.fee;
  }
  /**Get pago from the API */
  public async getPago(id) {
    let pagos = await this.apiService.getAsPromise({
      api: `${this.PAGO_API}/${id}`,
    });
    this.pagos.push(pagos);
  }
  public onGoToBodega(id: string) {
    this.router.navigate(["bodegas/bodega/" + id]);
  }
  /**
   * Handles closing the modals with different options
   * @param reason how I am closing the modals
   */
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }
  /**
   * Handles opening a modal
   * @param content Modal
   */

  public open(content) {
    this.modalService
      .open(content, { size: "lg", ariaLabelledBy: "modal-basic-title" })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }
  /**
   * Handles showing the table according to the preference of the user
   */
  public onMovement() {
    this.inventario = false;
  }
  /**
   * Handles showing the table according to the preference of the user.
   */
  public onStock() {
    this.inventario = true;
  }
  public onAdd({ index }) {
    this.open(this.addModal);
    this.indexSpace = index;
    this.add = true;
    this.substract = false;
  }
  public onSubstract({ index }) {
    this.open(this.substractModal);
    this.indexSpace = index;
    this.add = false;
    this.substract = true;
  }
  public async onSubmit() {
    let cantidadActuales = this.reserva.espacios[this.indexSpace].cantidad;
    let cantidadEspacio =
      this.reserva.espacios[this.indexSpace].espacio.cantidad;
    /**Necesito saber cuantas posiciones tiene el espacio disponible para no aumentar mas de eso */
    let spaceId = this.reserva.espacios[this.indexSpace]._id;
    let cantidadDePosicionesActualizado;
    let cantidadDePosicionesPosibles;
    let data;
    /**Se adiciona una posicion */
    if (this.add) {
      cantidadDePosicionesActualizado = cantidadActuales + 1;
      if (cantidadDePosicionesActualizado > cantidadDePosicionesPosibles) {
        alert(
          "Tu espacio no tiene la capacidad para este número de posiciones"
        );
      } else {
        data = {
          spaceId: spaceId,
          cantidad: cantidadDePosicionesActualizado,
        };
        await this.changePosition({
          data: data,
          message: "Posición agregada con éxito",
        });
      }
    } else if (this.substract) {
      cantidadDePosicionesActualizado = cantidadActuales - 1;
      if (cantidadDePosicionesActualizado < 0) {
        alert("No es posible tener cantidad de posiciones negativas");
      } else {
        data = {
          spaceId: spaceId,
          cantidad: cantidadDePosicionesActualizado,
        };
        await this.changePosition({
          data: data,
          message: "Posición restada con éxito",
        });
      }
    }
    this.modalService.dismissAll();
    this.reloadCurrentRoute();
  }

  public async changePosition({
    data,
    message,
  }: {
    data: any;
    message: string;
  }) {
    let reservationId = this.reserva._id;
    await this.reservationService
      .changeNumberPositions({ id: reservationId, data: data })
      .then((response) => alert(message));
    // .catch((err) => alert("Hubo un error contacte al administrador"));
  }
  /**Reload page  */
  reloadCurrentRoute() {
    let currentUrl = this.router.url;
    this.router.navigateByUrl("/", { skipLocationChange: true }).then(() => {
      this.router.navigate([currentUrl]);
    });
  }

  public async onDespachar() {
    // Step 1: Upload files to S3
    // Make an array to upload all files as a batch
    const uploads = [];
    let randomKey = this.s3Service.generateKey();
    // Add the documents
    if (this.salidaFile === undefined) {
      alert("Por favor añade el documento comprobante");
    } else if (this.arrayVerification(this.productaOrder, "salida") === false) {
      alert("Por favor añade la cantidad de producto a despachar");
    } else {
      uploads.push({
        file: this.salidaFile,
        key: `warehouse/orden-despacho/${this.salidaFile.name}/${randomKey}`,
      });
      let uploadResults = await this.s3Service.uploadBatch({ list: uploads });
      let orderLink;
      for (const result of uploadResults) {
        orderLink = result.Location;
      }
      let despachado = {
        estado: "despachado",
        linkDocumento: orderLink,
        productosDespachados: this.productaOrder,
      };
      this.apiService
        .put({
          api: `${this.API_RETIRO}/${this.movementID}`,
          data: despachado,
        })
        .subscribe(
          (response) => {
            this.modalService.dismissAll();
            this.showToastr({
              title: "Se ha despachado el movimiento de manera exitosa",
            });
            this.reloadCurrentRoute();
          },
          (error) =>
            this.showToastr({
              title: "Un error ha ocurrido contactanos",
            })
        );
    }
    //Construyendo elobjeto para modificar las ordenes
  }
  public docummentIsInvalid(field) {
    return (
      this.documentsForm.get(field)?.touched &&
      this.documentsForm.get(field)?.invalid
    );
  }

  public async onAddStock() {
    if (this.proofFile === undefined) {
      alert("Por favor añade el documento comprobante");
    } else if (
      this.arrayVerification(this.productaOrder, "ingreso") === false
    ) {
      alert("Por favor añade la cantidad de producto que se recibió");
    } else {
      const uploads = [];
      let randomKey = this.s3Service.generateKey();
      // Add the documents
      uploads.push({
        file: this.proofFile,
        key: `warehouse/orden-entrada/${this.proofFile.name}/${randomKey}`,
      });
      let uploadResults = await this.s3Service.uploadBatch({ list: uploads });
      let orderLink;
      for (const result of uploadResults) {
        orderLink = result.Location;
      }
      let recibido = {
        estado: "recibido",
        linkDocumento: orderLink,
        productosIngresados: this.productaOrder,
      };
      this.onUpdateOrder(recibido);
    }
  }

  public arrayVerification(array, tipo) {
    if (tipo === "ingreso") {
      for (let i = 0; i < array.length; i++) {
        let element = array[i];
        if (element.cantidadIngresada === undefined) {
          return false;
        }
      }
      return true;
    } else {
      for (let i = 0; i < array.length; i++) {
        let element = array[i];
        if (element.cantidadDespachada === undefined) {
          return false;
        }
      }
      return true;
    }
  }

  public onUpdateOrder(data) {
    this.apiService
      .put({ api: `${this.API_MOVEMENT}/${this.movementID}`, data: data })
      .subscribe(
        (response) => {
          this.modalService.dismissAll();
          this.showToastr({
            title:
              "Se ha agregado el movimiento al inventario de manera exitosa",
          });
          this.reloadCurrentRoute();
        },
        (error) =>
          this.showToastr({ title: "Un error ha ocurrido contactanos" })
      );
  }

  /**
   * Handles the upload of documents
   */
  public onUploadDocument({ event, type }: { event: any; type: any }): void {
    //     // If its the freedom certificate, create a url for file preview and store the file
    if (event.target.files && event.target.files.length) {
      const file = event.target.files[0];
      if (file.type !== "application/pdf") {
        alert("Por favor sube tu archivo en formato PDF ");
      } else {
        const previewUrl = URL.createObjectURL(file);
        this.documentsForm.get("document").setValue(previewUrl);
        if (type === "entrada") {
          this.proofFile = file;
        } else {
          this.salidaFile = file;
        }
      }
    }
  }
}
