<div class="principal-container">
    <div class="inbox">
        <div class="inbox-header">
            <span class="title">Inbox</span>
            <!-- <span>{{0}} mensajes sin leer</span> -->
        </div>
        <hr>
        <div class="message-list">
            <p class="no-messages" *ngIf="conversations?.length == 0">Todavía no tienes contactos asociados</p>
            <div *ngIf="conversations?.length > 0">
                <app-message-item class="cursor-pointer" *ngFor="let conversation of conversations"
                    (click)="onOpenConversation(conversation)"
                    fecha="{{ conversation.lastMessage.createdAt | date: 'dd MMMM yyyy' }}"
                    [nombre]="conversation.fullName" [ultimoMensaje]="conversation.lastMessage.message">
                </app-message-item>
            </div>
        </div>
    </div>
    <div class="messages" *ngIf="conversation">
        <!-- <div class="button-container">
            <button>Envía nuevo mensaje</button>
        </div> -->
        <div class="message">
            <div class="info-header">
                <img class="profile-img" src="./assets/Utils/servicios.png">
                <div class="container-info-msg">
                    <span></span>
                    <div class="name-container">
                        <span>{{ conversation.fullName }}</span>
                    </div>
                </div>
            </div>
            <hr>
            <div class="message-container">
                <div *ngFor="let message of conversation.messages">
                    <div class="msg-header" *ngIf="message.from != user._id">
                        <img class="profile-img" src="./assets/Utils/servicios.png">
                        <div class="container-msg-hour">
                            <p class="container-msg">{{ message.message }}</p>
                            <p class="hour">{{ message.createdAt | date: 'medium' }}</p>
                        </div>
                    </div>
                    <div class="msg-header-receiver" *ngIf="message.from == user._id">
                        <p class="container-msg-receiver">{{ message.message }}</p>
                        <p class="hour">{{ message.createdAt | date: 'medium' }}</p>
                    </div>
                </div>
            </div>
            <hr>
            <div class="send-container">
                <textarea type="text" placeholder="Escribe aquí tu mensaje" [(ngModel)]="newContentMessage"></textarea>
                <img src="./assets/Utils/flecha.png" class="cursor-pointer" (click)="onSendMessage()">
            </div>
        </div>
    </div>

    <div class="empty" *ngIf="!conversation">
        <div class="message full-width">
            <img src="./assets/Utils/estado-vacio.png" class="empty-image">
            <br>
            <span class="title separator">Seleccione una conversación</span>
        </div>
    </div>
</div>