  <canvas
    baseChart
    width="{{width}}"
    height="{{height}}"
    [data]="pieChartData"
    [labels]="pieChartLabels"
    [chartType]="pieChartType"
    [options]="pieChartOptions"
    [colors]="pieChartColors"
    [legend]="pieChartLegend"
  >
  </canvas>