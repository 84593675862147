import { Component, PLATFORM_ID, Inject, OnInit } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { AuthService } from './public/services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'flexpot-web';

  constructor(
    @Inject(PLATFORM_ID) private platformId,
    private authService: AuthService
  ) {
    if (isPlatformBrowser(this.platformId)) this.validationJwt();
  }

  public ngOnInit() {
    this.validationJwt();
  }
  /**
   * Function to validate if JWT is expired
   */
  private validationJwt() {
    //in this case JWT is set in local storage as flexpot-token
    const currentToken = localStorage.getItem('flexpot-token');
    if (currentToken) this.authService.validateJwtExpired(currentToken).subscribe((res: any) => {
      if (!res.success) {
        let { newToken, user } = res;
        this.authService.storeUserData(newToken, user)
      }
    });
  }
}
