import { Component, OnInit, HostListener } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { LoginComponent } from "../../auth/login/login.component";
import { AuthService } from "../../services/auth.service";
import { NavigationService } from "../../services/navigation.service";
import { Router, NavigationEnd } from "@angular/router";
@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"],
})
export class NavbarComponent implements OnInit {
  ciudadFiltro: any = "";
  buscarProducto: any = "";

  /** Variable that determines if menu can be displayed */
  showMenu = false;

  // Declare height and width variables
  scrWidth: any;

  /**This is creating a bug  */
  @HostListener("window:resize", ["$event"])
  getScreenSize(event?) {
    this.scrWidth = window.innerWidth;
  }

  constructor(
    private dialog: MatDialog,
    private router: Router,
    private navigationService: NavigationService,
    private authService: AuthService
  ) {
    this.getScreenSize();
  }

  get auth(): any {
    return this.authService.getUser();
  }

  ngOnInit(): void {}

  /**
   * Open dialog with the login component
   */
  onLoginClick(): void {
    this.dialog.open(LoginComponent, { closeOnNavigation: true });
  }

  /**
   * Navigates to search component
   */
  public goToSearch() {
    this.showMenuAction();
    // this.navigationService.navigateTo({path: "buscar"});
    this.router.navigate(["buscar"], {
      queryParams: {
        ciudad: this.ciudadFiltro,
        buscarProducto: this.buscarProducto,
      },
    });
  }
  /**
   * Navigates to search component
   */
  public goToReservas() {
    this.showMenuAction();
    this.navigationService.navigateTo({ path: "reservas", params: { type: "tenant"} });
  }

  /**
   * Navigates to my warehouses component
   */
  public goToMyWarehouses() {
    this.showMenuAction();
    this.navigationService.navigateTo({ path: "mis-bodegas" });
  }
  /**
   * Navigates to my account component
   */
  public goToMyAccount() {
    this.showMenuAction();
    this.navigationService.navigateTo({ path: "cuenta" });
  }

  /**
   * Navigates to my messages
   */
  public goToMessages() {
    this.navigationService.navigateTo({ path: "mis-mensajes" });
  }

  showMenuAction() {
    this.showMenu = !this.showMenu;
  }
}
