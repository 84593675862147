<div class="footer">
  <button id="footer-logo">flexpot</button>
  <div class="footer-buttons">
    <button class="footer-button">
      Políticas de privacidad
    </button>
    <button class="footer-button">
      Términos y condiciones
    </button>
  </div>
  <div class="social-networks">
    <fa-icon class="social-network" size="2x" [icon]="faFacebook"></fa-icon>
    <fa-icon class="social-network" size="2x" [icon]="faTwitter"></fa-icon>
    <fa-icon class="social-network" size="2x" [icon]="faInstagram"></fa-icon>
  </div>
</div>
