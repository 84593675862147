<div class="warehouse-create">
  <!-- Header -->
  <div class="warehouse-create-header">
    <button id="cancel-button" (click)="onCancel()">
      <fa-icon [icon]="faTimes"></fa-icon> Cancelar
    </button>
  </div>

  <!-- Create form container -->
  <div class="form-container">
    <div class="warehouse-create-title">Edita tu bodega</div>
    <!-- Container header -->
    <mat-progress-bar
      [value]="(currentStep / TOTAL_STEPS) * 100"
    ></mat-progress-bar>
    <div class="form-header">
      <div class="form-subtitle">
        {{ STEPS_TITLES[currentStep - 1] }}
      </div>
      <div class="step-counter">
        <span> Paso {{ currentStep }}/{{ TOTAL_STEPS }} </span>
      </div>
    </div>

    <!-- Form content-->
    <!-- Step 1: Warehouse information-->
    <form [formGroup]="wareHouseForm" *ngIf="currentStep === 1">
      <div class="step-container">
        <div class="row">
          <div class="col-sm-12">
            <label for="warehouse-name">Nombre de la bodega</label>
            <input
              name="nombre"
              placeholder="Ingrese el nombre de la bodega"
              formControlName="nombre"
              type="text"
              class="warehouse-input"
              id="warehouse-name"
            />
          </div>
          <div class="col-sm-12 space">
            <label for="">Descripción</label>
            <textarea
              class="warehouse-input t-area"
              id="warehouse-description"
              name="descripcion"
              placeholder="Ingrese la descripción de la bodega"
              formControlName="descripcion"
            ></textarea>
          </div>
          <div></div>

          <div class="col-sm-6">
            <label for="">Precio por kilogramo</label>
            <input
              class="warehouse-input"
              type="number"
              placeholder="Manejo por kilogramo"
              id="pallet-management"
              name="kilogramHandling"
              formControlName="kilogramHandling"
            />
          </div>
        </div>
        <div class="sec-sub-title">Información de contacto - encargado</div>
        <div class="row" formGroupName="contacto">
          <div class="col-sm-6">
            <label for="">Nombre contacto </label>
            <input
              type="text"
              class="warehouse-input"
              id="contact-name"
              placeholder="{{ warehouse.contacto.nombre }}"
              formControlName="nombre"
            />
          </div>
          <div class="col-sm-6">
            <label for="">Apellido contacto </label>
            <input
              type="text"
              class="warehouse-input"
              id="contact-lastname"
              placeholder="{{ warehouse.contacto.apellido }}"
              formControlName="apellido"
            />
          </div>
          <div class="col-sm-6 space">
            <label for="">Celular contacto </label>
            <input
              type="cel"
              class="warehouse-input"
              id="contact-cellphone"
              placeholder="{{ warehouse.contacto.telefono }}"
              formControlName="telefono"
            />
          </div>
          <div class="col-sm-6 space">
            <label for="">Correo contacto </label>
            <input
              type="email"
              class="warehouse-input"
              id="contact-email"
              placeholder="Correo"
              formControlName="correo"
            />
          </div>
        </div>
      </div>
    </form>

    <!-- Step 3: Schedules and working hours -->
    <form [formGroup]="scheduleForm" *ngIf="currentStep === 2">
      <div class="sec-sub-title">Modifica el horario de la bodega</div>
      <div class="row">
        <div class="col-sm-6">
          <label class="form-label" for="start-time">Hora de inicio</label>
          <input
            class="warehouse-input"
            id="start-time"
            type="time"
            formControlName="horaInicio"
          />
        </div>
        <div class="col-sm-6">
          <label class="form-label" for="end-time">Hora de cierre</label>
          <input
            class="warehouse-input"
            id="end-time"
            type="time"
            formControlName="horaFin"
          />
        </div>
        <!-- <div class="col-sm-6" *ngFor="let service of serviceList | filterService">
          <input class="warehouse-input" type="number" onkeydown="return false" [placeholder]="'$ ' + service.name"
            [name]="service.name" [(ngModel)]="service.precio" />
        </div> -->
      </div>
    </form>

    <div *ngIf="currentStep === 2">
      <div class="sec-sub-title" *ngIf="certificates.length > 0">
        Certificados
      </div>
      <div class="row" *ngFor="let certificate of certificates">
        <div class="col-sm-6">
          <button class="upload-freedom-certificate" type="button">
            <img src="./assets/Utils/archivo.png" class="upload-image" />
            {{ certificate.otro.name }}
            <input type="file" class="hidden-input" />
          </button>
          <br />
          <a [href]="certificate.documento" target="_blank"> Ver documento </a>
        </div>
        <div class="col-sm-6">
          <label class="form-label" for="end-time">
            Fecha de vencimiento del certificado:
          </label>
          <input
            type="date"
            class="warehouse-input"
            [(ngModel)]="certificate.otro.fechaVencimiento"
            [value]="certificate.otro.fechaVencimiento"
            [min]="today"
            placeholder="Fecha de vencimiento"
          />
        </div>
      </div>
      <div class="sec-sub-title" *ngIf="spaces.length > 0">Espacios</div>
      <div class="row" *ngFor="let space of spaces">
        <div class="col-sm-6">
          <label class="form-label" for="end-time">
            {{ space.nombre }} (Costo por dia):
          </label>
        </div>
        <div class="col-sm-6">
          <input
            type="number"
            class="warehouse-input"
            [(ngModel)]="space.costo"
          />
        </div>
      </div>
      <div class="sec-sub-title">Modifica las fotos de la bodega</div>
      <div class="row">
        <div class="col-sm-2 bottom-2" *ngFor="let photo of photos; index as i">
          <img class="warehouse-photo" [src]="photo.preview" />
          <div
            class="delete-photo absolute"
            (click)="onDeletePhoto({ index: i })"
          >
            <fa-icon [icon]="faTimes"></fa-icon>
          </div>
        </div>
        <div class="col-sm-3">
          <button id="upload-warehouse-photo" (click)="photoUpload.click()">
            <img src="./assets/Utils/archivo.png" class="upload-image" />
            Sube una foto
            <input
              type="file"
              accept="image/*"
              #photoUpload
              class="hidden-input"
              id="photos-input"
              (change)="onUploadDocument({ type: 'Photo', event: $event })"
            />
          </button>
        </div>
      </div>
    </div>

    <div class="row space">
      <div class="col-sm-6 space">
        <button
          id="previous-step"
          *ngIf="currentStep > 1"
          [disabled]="loading"
          (click)="onPreviousStep()"
        >
          <fa-icon [icon]="faChevronLeft"></fa-icon> Paso anterior
        </button>
      </div>
      <div class="col-sm-6 space">
        <button
          *ngIf="currentStep !== 2 && !loading"
          id="next-step"
          (click)="onNextStep()"
        >
          Siguiente paso
        </button>
        <button
          *ngIf="currentStep === 2 && !loading"
          id="submit-request"
          (click)="onSaveClick({ transactionType: 'submit' })"
        >
          Guardar cambios
        </button>
      </div>
      <div class="col-sm-12">
        <mat-progress-bar
          *ngIf="loading"
          mode="determinate"
          [value]="currentProgress"
        ></mat-progress-bar>
      </div>
    </div>
  </div>
</div>
