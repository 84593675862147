import {Injectable} from "@angular/core";
import {HttpParams} from "@angular/common/http";
import {ApiService} from "./api.service";
import {AuthService} from "./auth.service";
@Injectable({
  providedIn: "root"
})
export class UserService {
  /**Api variables */
  USER_API = "api/user/";
  WAREHOUSE_USER_API = "api/bodega/user/";
  RESERVATION_BY_OWNER_API = "api/reserva/propietario/";
  RESERVATION_BY_TENANT_API = "api/reserva/arrendatario/";
  SPACES_BY_USER_ID = "api/espacio/user";
  PAGOS_BY_RESERVATION_ID = "api/pago/reservas/";
  TOTAL_BY_RESERVATION_ID = "api/pago/reservas/total/";
  MOVEMENTS_BY_WAREHOUSE_ID = "api/movimientoAgregar/bodega/";
  TARJETA_API = "api/tarjeta/usuario/";
  TARJETA_FAVOURITE_API = "api/tarjetaFavourite/";
  TARJETA_CREATE_API = "api/tarjeta/";
  TARJETA_UPDATE_API = "api/tarjeta/update/";
  /**Regular variables */
  user: any = {};
  USER_ID = "";
  warehouses: any = {};
  reservation: any = {};
  reservationTenant: any = {};
  movements: any = {};
  spaces: any = {};
  payments: any = {};
  total: any = "";
  tarjeta: any = "";
  constructor(private apiService: ApiService, private authService: AuthService) {
    let user = authService.getUser();
    if (user) {
      this.USER_ID = user._id;
    }
  }
  async getUserByid({id}: {id: string}): Promise<any> {
    this.user = await this.apiService.get({api: `${this.USER_API}${id}`}).toPromise();
    return this.user;
  }
  async getTarjeta({id}: {id: string}): Promise<any> {
    this.tarjeta = await this.apiService.get({api: `${this.TARJETA_API}${id}`}).toPromise();
    return this.tarjeta;
  }
  async getTarjetaFavourite({id}: {id: string}): Promise<any> {
    this.tarjeta = await this.apiService.get({api: `${this.TARJETA_FAVOURITE_API}${id}`}).toPromise();
    return this.tarjeta;
  }
  public postTarjeta({data}: {data: any}): Promise<any> {
    return this.apiService.postPromise({api: `${this.TARJETA_CREATE_API}`, data: data});
  }
  /**
   * It is in charge of calling the api service to make the get request with parameters to obtain the warehouses.
   * @param param0 Receives a string that can be approved, rejected, pending or in progress, which is the status of the warehouse.
   * @returns The warehouses obtained from the back
   */
  async getWarehouseByUserId({value, id}: {value: string; id: string}): Promise<any> {
    let params = new HttpParams();
    params = params.append("estado", value);
    this.warehouses = await this.apiService.getWithParams({api: `${this.WAREHOUSE_USER_API}${id}`, queryParams: params}).toPromise();
    return this.warehouses;
  }
  async getReservaByOwner({id}: {id: string}): Promise<any> {
    this.reservation = await this.apiService.get({api: `${this.RESERVATION_BY_OWNER_API}${id}`}).toPromise();
    return this.reservation;
  }
  async getReservaByTenat({id}: {id: string}): Promise<any> {
    this.reservationTenant = await this.apiService.get({api: `${this.RESERVATION_BY_TENANT_API}${id}`}).toPromise();
    return this.reservationTenant;
  }
  async getSpacesByUserId({id}: {id: string}): Promise<any> {
    this.spaces = await this.apiService.get({api: `${this.SPACES_BY_USER_ID}`}).toPromise();
    return this.spaces;
  }
  async getPaymentsByReservationId({reservationId}: {reservationId: string}): Promise<any> {
    this.payments = await this.apiService.get({api: `${this.PAGOS_BY_RESERVATION_ID}${reservationId}`}).toPromise();
    return this.payments;
  }
  async getReservaAndCalculateTotal({id}: {id: string}): Promise<any> {
    this.total = await this.apiService.get({api: `${this.TOTAL_BY_RESERVATION_ID}${id}`}).toPromise();
    return this.total;
  }
  async getMovementsByWarehouseId({id}: {id: string}): Promise<any> {
    this.movements = await this.apiService.get({api: `${this.MOVEMENTS_BY_WAREHOUSE_ID}${id}`}).toPromise();
    return this.movements;
  }
  public putProfile({id,data}: {id:any,data: any}): Promise<any> {
    return this.apiService.putPromise({api: `${this.USER_API}${id}`, data: data});
  }
  public putCard({data}: {data: any}): Promise<any> {
    return this.apiService.putPromise({api: this.TARJETA_UPDATE_API, data: data});
  }
}
