import {Injectable} from "@angular/core";
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router} from "@angular/router";
import {Observable} from "rxjs";
import {AuthService} from "../services/auth.service";
@Injectable({
  providedIn: "root"
})
export class UserGuard implements CanActivate {
  constructor(public authService: AuthService, public router: Router) {}
  canActivate() {
    let usuario = JSON.parse(localStorage.getItem("flexpot-user"));
    if (usuario !== null && (usuario.tipo === "usuario" || usuario.tipo === "administrador")) {
      return true;
    } else if (usuario === null) {
      this.router.navigate(["not-autorized"]);
      return false;
    } else if (usuario.nivel !== 0) {
      return false;
    }
  }
}
