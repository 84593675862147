<div class="contact">
  <div class="contact-form">
    <form [formGroup]="contactForm" (ngSubmit)="onSubmitContact()">
      <!-- Form Header-->
      <div class="row">
        <div class="col-sm-3">
          <img class="contact-img" src="../../../assets/Utils/contacto.png" />
        </div>
        <div class="col-sm-9">
          <div class="form-title">Envíanos un mensaje</div>
          <div class="form-subtitle">Te responderemos lo más pronto posible</div>
        </div>
      </div>

      <!-- Form Body-->
      <div class="row form-body">
        <div class="col-sm-6">
          <input placeholder="Nombre" class="form-input" id="name" type="text" autocomplete="name" formControlName="nombre" />
        </div>
        <div class="col-sm-6">
          <input placeholder="Apellido" class="form-input" id="last-name" type="text" autocomplete="family-name" formControlName="apellido" />
        </div>
        <div class="col-sm-6">
          <input placeholder="Teléfono" class="form-input" id="telephone" type="tel" autocomplete="tel" formControlName="telefono" />
        </div>
        <div class="col-sm-6">
          <input placeholder="Correo" class="form-input" id="contact-email" type="email" autocomplete="email" formControlName="email" />
        </div>
        <div class="col-sm-12">
          <textarea id="message" placeholder="Mensaje" formControlName="mensaje"></textarea>
        </div>
      </div>
      <button *ngIf="!loading" id="send" type="submit" [disabled]="contactForm.invalid">Enviar</button>
      <mat-progress-spinner *ngIf="loading" class="spinner" mode="indeterminate" diameter="35"></mat-progress-spinner>
    </form>
  </div>
</div>
