import { Location } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ApiService } from "src/app/public/services/api.service";
import { AuthService } from "src/app/public/services/auth.service";
import { UserService } from "../public/services/user.service";
import {
  faCheck,
  faTrash,
  faBoxOpen,
  faCheckCircle,
  faPlus,
  faChevronLeft,
} from "@fortawesome/free-solid-svg-icons";
import { SuccessComponent } from "../public/shared/success/success.component";
import { MatDialog } from "@angular/material/dialog";
import { NavigationService } from "../public/services/navigation.service";
declare var WidgetCheckout: any;
@Component({
  selector: "app-payment-reservations",
  templateUrl: "./payment-reservations.component.html",
  styleUrls: ["./payment-reservations.component.scss"],
})
export class PaymentReservationsComponent implements OnInit {
  /**Regular variables */
  faPlus = faPlus;
  /**Font awesome variables */
  faBoxOpen = faBoxOpen;
  faTrash = faTrash;
  faCheck = faCheck;
  faCheckCircle = faCheckCircle;
  faChevronLeft = faChevronLeft;

  /** String of 'reservas' endpoint */
  readonly RESERVAS_API = "api/reserva";
  /** String of 'reservas' endpoint */
  readonly WOMPI_API = "api/wompi";
  /** Set current card API endpoint */
  readonly API_TARJETA = "api/tarjeta";
  /** Endpoint when user pay with credit card */
  readonly API_PAGO_CREDIT_CARD = "api/pago/new-creation";
  /** Endpoint when user pay with PSE */
  readonly API_PAGO_PSE = "api/pago/single-pse";
  /** Object to store the user information */
  public user: any = {};
  /** Object to store the resevation information */
  public reservation: any = {};
  /** Array to separte and store data of spaces reservated */
  public spacesReservated: any = [];
  /** Count of all positions reservated */
  public positionsReservated: number = 0;
  /** Count of all mts reservated */
  public mtsReservated: number = 0;
  /** Boolean to load */
  public loader: boolean = true;
  /** Var to store the total of invoce  */
  public totalInvoce: number = 0;
  /** Array of services selected in reservation */
  public servicesSelected: any = [];
  /** Var to store the slected method to pay  */
  public payMethod: string = "";
  /** Var to set steps */
  public payNextStep: boolean = false;
  /** Variable to open or close Add credit card modal */
  public addCardModal: boolean = false;
  /** Array of cards registrated */
  public cards: any = [];
  /** var to create config of loader */
  loaderConfig = {
    primaryColour: "#ffffff",
    secondaryColour: "#ccc",
    tertiaryColour: "#ffffff",
    backdropBorderRadius: "3px",
  };

  constructor(
    private location: Location,
    private activatedRoute: ActivatedRoute,
    private apiService: ApiService,
    private dialog: MatDialog,
    protected authService: AuthService,
    protected navigationService: NavigationService,
    protected userService: UserService
  ) {}

  ngOnInit(): void {
    this.loader = true;
    // Get user hosted in the local storage
    this.user = this.authService.getUser();
    this.onGetCards({ userId: this.user._id });
    // Case 1:  Get reservation id by params of route
    let reservationId = this.activatedRoute.snapshot?.queryParams?.reservation;
    let id = this.activatedRoute.snapshot?.queryParams?.id;
    // Validate if the reservation id exist
    if (reservationId) this.getReservationById({ reservationId });
    if (id) this.getTransactionStatus({ id });
  }

  /**
   * Function to get reservation of user
   * @param reservationId id of reservation
   */
  public async getReservationById({ reservationId }) {
    this.apiService
      .get({ api: `${this.RESERVAS_API}/${reservationId}` })
      .subscribe((reserva) => {
        this.reservation = reserva;
        this.spacesReservated = reserva.espacios;
        this.servicesSelected = reserva.otros;
        this.countPositionsMtsReservated({
          spacesReservated: this.spacesReservated,
        });
        this.createInvoiceOfSpaces({
          spacesReservated: this.spacesReservated,
          weeks: this.reservation.duracion,
        });
        this.createInvoiceOfServices({
          servicesSelected: this.servicesSelected,
        });
      });
  }

  /**
   * Function to count and divided all positions and mts reservated
   * @spacesReservated array of reservated spaces
   */
  public countPositionsMtsReservated({ spacesReservated }) {
    // Separete spaces by type
    let stantSpaces = spacesReservated.filter(
      (s) => s.espacio.tipo == "Estanteria"
    );
    let floorSpaces = spacesReservated.filter((s) => s.espacio.tipo == "Piso");
    // Create generic function to accum quantity of positions or mts reservated
    const reducer = (total, currentValue) => total + currentValue.cantidad;
    // Get accum for differents types of spaces
    this.positionsReservated = stantSpaces.reduce(
      reducer,
      this.positionsReservated
    );
    this.mtsReservated = floorSpaces.reduce(reducer, this.mtsReservated);
  }

  /**
   * Function to calculate the price of a single payment for the reserva
   * @param spacesReservated recievies the 'espacios' reservated
   * @param weeks recievies the 'duration' of reservation
   */
  public createInvoiceOfSpaces({ spacesReservated, weeks }) {
    spacesReservated.forEach((space) => {
      space.totalCost = space.espacio.costo * space.cantidad * weeks * 7;
      this.totalInvoce = Number(space.totalCost) + this.totalInvoce;
    });
  }

  /**
   * Function to calculates the price of services selected in reservation
   * @param servicesSelected Services selected
   */
  public createInvoiceOfServices({ servicesSelected }) {
    servicesSelected.forEach((service) => {
      service.totalCost = service.otro.valor * service.cantidad;
      this.totalInvoce = this.totalInvoce + service.totalCost;
    });
    this.loader = false;
  }

  /**
   * Function to selected method to pay
   * @param methodSelected
   */
  public onSeletedPayMethod({ methodSelected }) {
    this.payMethod = methodSelected;
  }

  /**
   * Function to route to previus page
   */
  public onGoBack() {
    this.location.back();
  }

  /**
   * Function to open Add credit card modal
   */
  public onOpenCreditCardModal() {
    this.addCardModal = true;
  }

  /**
   * Function to close Add credit card modal
   */
  public onCloseCreditCardModal() {
    this.addCardModal = false;
  }

  /**
   * Function to redirect the user to next step of invoce
   */
  public onNextStep() {
    //If the user selected PSE
    if (this.payMethod == "PSE") this.nextStepPse();
    else if (this.payMethod == "Credit Card") this.payNextStep = true;
    else alert("Por favor seleccione un método de pago");
  }

  public makeid(length) {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  public nextStepPse() {
    const checkout = new WidgetCheckout({
      currency: "COP",
      amountInCents: this.totalInvoce * 100,
      reference: JSON.stringify({
        id: this.makeid(10),
        reservation: this.reservation._id,
      }),
      publicKey: "pub_test_ZujR5Vq7PdlHu2bLKOTkeAFIJa0xRHHv",
      redirectUrl: "https://flexpot.co/pago-reserva",
    });
    checkout.open((result) => {
      const transaction = result.transaction;
    });
  }

  public getTransactionStatus({ id }) {
    this.apiService
      .get({ api: `${this.WOMPI_API}/transaction/${id}` })
      .subscribe(async (response) => {
        if (response.status == "APPROVED") {
          this.apiService
            .post({
              api: this.API_PAGO_PSE,
              data: { wompiTransaction: response },
            })
            .subscribe((response) => {
              this.loader = false;
              this.dialog.open(SuccessComponent, {
                data: {
                  title: "Pago exitoso",
                  message: "Gracias por confiar en Flexpot",
                  onOk: () => {
                    this.navigationService.navigateTo({ path: "reservas" });
                  },
                },
                disableClose: true,
              });
            });
        } else {
          this.dialog.open(SuccessComponent, {
            data: {
              title: "Hubo un error",
              message: "Por favor comunicate con soporte",
              onOk: () => {
                this.navigationService.navigateTo({ path: "reservas" });
              },
            },
            disableClose: true,
          });
        }
      });
  }

  /**
   * Function to get user cards
   * @param userId Id of user logged
   */
  public async onGetCards({ userId }) {
    this.cards = await this.userService.getTarjeta({ id: userId });
  }

  /**
   *
   * @param param0
   */
  public onSetCurrentCard({ cardIndex }) {
    this.loader = true;
    if (this.cards[cardIndex].preferidad)
      alert("Esta tarjeta ya es tu tarjeta preferida");
    else this.selectCurrentCard({ cardIndex });
  }

  /**
   *
   * @param param0
   */
  public selectCurrentCard({ cardIndex }) {
    let currentCardId = this.cards[cardIndex]._id;
    let allCardsIds = [...this.cards].map(({ _id }) => _id);
    this.apiService
      .post({
        api: `${this.API_TARJETA}/set-current`,
        data: { currentCardId, allCardsIds },
      })
      .subscribe(
        (res) => {
          this.loader = false;
          this.dialog.open(SuccessComponent, {
            data: {
              title: "¡Genial!",
              message: "Esta tarjeta ha sido escogida como la preferida",
              onOk: () => {
                this.dialog.closeAll();
                this.onGetCards({ userId: this.user._id });
              },
            },
            disableClose: true,
          });
        },
        (err) => {
          this.loader = false;
          alert(err.error.mensaje);
        }
      );
  }

  public onDelete({ cardIndex: index }) {
    this.loader = true;
    this.apiService
      .delete({ api: `${this.API_TARJETA}/${this.cards[index]._id}` })
      .subscribe(
        (res) => {
          this.loader = false;
          this.dialog.open(SuccessComponent, {
            data: {
              title: "¡Genial!",
              message: "La tarjeta ha sido eliminada con éxito",
              onOk: () => {
                this.dialog.closeAll();
                this.onGetCards({ userId: this.user._id });
              },
            },
            disableClose: true,
          });
        },
        (err) => {
          this.loader = false;
          alert(
            "Hubo un error eliminando tu tarjeta contacta al administrador "
          );
        }
      );
  }

  public onCreationSuccess() {
    this.addCardModal = false;
    this.dialog.open(SuccessComponent, {
      data: {
        title: "Se ha creado la tarjeta de manera exitosa",
        onOk: () => {
          this.dialog.closeAll();
          this.onGetCards({ userId: this.user._id });
        },
      },
      disableClose: true,
    });
  }

  public onCreateCardTransaction() {
    this.loader = true;
    let templateToPay = { ...this.reservation };
    templateToPay.total = this.totalInvoce;
    templateToPay.tarjeta = this.cards.find((card) => card.preferidad);
    if (this.cards.length > 0) {
      this.apiService
        .post({ api: this.API_PAGO_CREDIT_CARD, data: templateToPay })
        .subscribe(
          (response) => {
            this.loader = false;
            this.dialog.open(SuccessComponent, {
              data: {
                title: "Pago exitoso",
                message: "Gracias por confiar en Flexpot",
                onOk: () => {
                  this.navigationService.navigateTo({ path: "reservas" });
                },
              },
              disableClose: true,
            });
          },
          (err) => {
            this.loader = false;
            this.dialog.open(SuccessComponent, {
              data: {
                title: "Hubo un error",
                message: "Por favor comunicate con soporte",
                onOk: () => {},
              },
              disableClose: true,
            });
          }
        );
    } else {
      alert("Por favor registre una tarjeta para continuar con la transaccion");
      this.loader = false;
    }
  }
}
