import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable,Subject} from 'rxjs';

@Injectable()
export class UrlService {
  // private previousUrl: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  
  private  previousUrl$= new Subject<string>();

  constructor() { }

  setPreviousUrl(previousUrl: string) {
    this.previousUrl$.next(previousUrl);
  }
  getPreviousUrl():Observable<any>{
    return this.previousUrl$.asObservable()
  }

}