import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { PagosService } from 'src/app/public/services/pagos.service';
import { CreditCardValidators } from "angular-cc-library";

@Component({
  selector: 'app-add-credit-card',
  templateUrl: './add-credit-card.component.html',
  styleUrls: ['./add-credit-card.component.scss']
})
export class AddCreditCardComponent implements OnInit {
  /** This var create the close output function of modal when the user click close button */
  @Output() close = new EventEmitter<Boolean>();
  @Output() creationSuccess = new EventEmitter<Boolean>();
  /** variable that holds the payment information form */
  cardForm: FormGroup;
  /** var to show the loader */
  loading = false;
  /** var to create config of loader */
  loaderConfig = {
    primaryColour: "#ffffff",
    secondaryColour: "#ccc",
    tertiaryColour: "#ffffff",
    backdropBorderRadius: "3px",
  };

  constructor(
    private formBuilder: FormBuilder,
    protected pagosService: PagosService
  ) { }

  ngOnInit(): void {
    this.initFormCard();
  }

  public initFormCard() {
    this.cardForm = this.formBuilder.group({
      newCard: this.formBuilder.group({
        fullCardName: ["", Validators.required],
        // idNumber: ['', Validators.required],
        cardNumber: ["", [Validators.required, CreditCardValidators.validateCCNumber]],
        expDate: ["", [Validators.required, CreditCardValidators.validateExpDate]],
        cvc: ["", [Validators.required, Validators.minLength(3), Validators.maxLength(4)]]
      })
    });
  }

  public onSubmitClick() {
    this.loading = true;
    const newCard = this.cardForm.value.newCard;
    /** Complete date of exxpiration */
    let momentDate = moment(newCard.expDate, "MM/YYYY");
    /** format expiration date */
    let expMonth = momentDate.format("MM");
    let expYear = momentDate.format("YY");
    /** Format card number */
    let cardNumber = newCard.cardNumber;
    cardNumber = cardNumber.replace(/\s+/g, "");
    // Template of new card
    const templateNewCard = {
      number: cardNumber,
      cvc: newCard.cvc,
      exp_month: expMonth,
      exp_year: expYear,
      card_holder: newCard.fullCardName,
      preferidad: false
    };

    this.pagosService.postTarjeta({ data: templateNewCard }).then((respondCard) => {
      this.loading = false;
      this.creationSuccess.emit(respondCard);
    }).catch((err) => {
      this.loading = false;
      alert(err.error.mensaje);
    });
  }

  /**
   * Function to close a modal
   */
  public onClose() {
    this.close.emit(false);
  }
}
