import { AuthService } from "./../../services/auth.service";
import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { faEye, faTimes } from "@fortawesome/free-solid-svg-icons";
import { ForgotPasswordComponent } from "../forgot-password/forgot-password.component";
import { Router } from "@angular/router";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  /** Font awesome icons */
  faTimes = faTimes;
  faEye = faEye;

  /** Variable that holds the login form */
  loginForm: FormGroup;

  /** Variable that holds the type of the password input */
  passwordInputType = "password";

  constructor(
    public dialogRef: MatDialogRef<LoginComponent>,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private dialog: MatDialog,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.loginForm = this.formBuilder.group({
      email: "",
      password: "",
    });
  }

  /**
   * Changes the type of the password input
   */
  onChangeInputType(): void {
    if (this.passwordInputType === "text") {
      this.passwordInputType = "password";
    } else {
      this.passwordInputType = "text";
    }
  }

  /**
   * Handles the closing of the dialog   */
  onClose(): void {
    this.dialogRef.close();
  }

  /**
   * Handles the closing of the dialog   */
  onForgotPassword(): void {
    this.dialogRef.close();
    this.dialog.open(ForgotPasswordComponent, {
      closeOnNavigation: true,
      panelClass: "forgot-password-dialog-container",
    });
  }

  /**
   * Handles form submission
   */
  onSubmit(): void {
    const credentials = this.loginForm.value;
    this.authService.authenticateUser({
      email: credentials.email,
      password: credentials.password,
      dialogRef: this.dialogRef,
    });
  }

  public onGoToSignUp() {
    this.onClose();
    this.router.navigate(["auth/sign-up"]);
  }
}
