import {Component, OnInit} from "@angular/core";
import {faStar} from "@fortawesome/free-solid-svg-icons";
import {Router,NavigationEnd} from "@angular/router";
import {AuthService} from "src/app/public/services/auth.service";
import {MatDialog} from "@angular/material/dialog";
import {LoginComponent} from "../auth/login/login.component";
import {filter, pairwise} from "rxjs/operators";
import {NavigationService} from "../services/navigation.service";
import { UrlService } from "../services/url.service";
import {MyWarehousesService} from "../services/my-warehouses.service";
@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"]
})
export class HomeComponent implements OnInit {
  /** Font awesome start icon */
  faStar = faStar;
  ciudadFiltro: any = "";
  cantidadEspacios: any = "";
  /**Cities and countries for the search bar */
  ciudades = [];
  paises = [];
  locations: any = [];
  indexPais:any="";
  buscarProducto:any ="";
  constructor(
    private router: Router,
    private authService: AuthService,
    private dialog: MatDialog,
    private navigationService: NavigationService,
    private myWarehousesService: MyWarehousesService
  ) {}

  ngOnInit(): void {
    this.getLocations();
  }
  /**Method that get the locations from the backend */
  public async getLocations() {
    this.locations = await this.myWarehousesService.getLocations();

  }
  public onPais(event) {
    /**Cleaning the array first */
    const empty = arr => arr.length = 0;
    empty(this.ciudades)
    let index = event.target.value;
    this.indexPais=index
    let element = this.locations[index];
    for (let j = 0; j < element.ciudades.length; j++) {
      this.ciudades.push(element.ciudades[j]);
    }
  }
  public onNavigateBuscador() {
    this.router.navigate(["buscar"], {
      queryParams: {
        ciudad: this.ciudadFiltro,
        pais:this.indexPais,
        buscarProducto:this.buscarProducto
      }
    });
  }
  public onLoginClick(): void {
    this.dialog.open(LoginComponent, {closeOnNavigation: true});
  }
  /**
   * Navigates to my warehouses component
   */
  public goToMyWarehouses() {
    this.navigationService.navigateTo({path: "mis-bodegas"});
  }
  /**
   * Navigates to crear bodega
   */
  public goToCreateBodega() {
    this.navigationService.navigateTo({path: "crear-bodega"});
  }
  public onStartNow() {
    let loggeado = this.authService.getUser();
    if (loggeado !== null) {
      this.goToMyWarehouses();
    } else {
      this.onLoginClick();
    }
  }
  /**
   * Depending on the fact that the user is logged or not handles some actions.
   */
  public onComienza() {
    let loggeado = this.authService.getUser();
    if (loggeado !== null) {
      this.goToCreateBodega();
    } else {
      this.onLoginClick();
    }
  }
}
