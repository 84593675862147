<app-add-credit-card *ngIf="addCardModal" (close)="onCloseCreditCardModal()" (creationSuccess)="onCreationSuccess()">
</app-add-credit-card>
<div class="account">
  <ngx-loading [show]="loading" [config]="{
      primaryColour: primaryColour,
      secondaryColour: secondaryColour,
      tertiaryColour: primaryColour,
      backdropBorderRadius: '3px'
    }">
  </ngx-loading>
  <div class="account--left">
    <div class="account--left--up">
      <div class="account--left--up--header--flex">
        <div class="account--left--up--header--profile">
          <img src="../../../assets/Utils/servicios.png" alt="profile" />
        </div>
        <div class="account--left--up--header--info--user">
          <h1>Hola {{ user.nombre }}</h1>
          <p>{{ user.email }}</p>
        </div>
      </div>
      <div class="account--left--up--main--verified">
        <fa-icon [icon]="faCheck"></fa-icon>
        <p>Usuario verificado</p>
      </div>
      <div class="account--left--up--footer">
        <a (click)="onEdit()" class="edit">Editar información personal</a>
        <a *ngIf="isAnAdmin" (click)="onGoToAdminPanel()" class="edit">Ir al panel de administrador</a>
        <a *ngIf="scrWidth < 757" (click)="goToReservas()" class="edit">Ver reservas</a>
        <a *ngIf="scrWidth < 757" (click)="goToCreateWarehouse()" class="edit">Crear bodegas</a>
        <div class="account--left--up--footer--logout">
          <fa-icon class="close" (click)="onLogoutClick()" [icon]="faSignOutAlt"></fa-icon>
          <a class="cerrar-sesion" (click)="onLogoutClick()">Cerrar sesión</a>
        </div>
      </div>
    </div>
    <div class="account--left--bottom" *ngIf="!edit">
      <div class="container-header">
        <p class="title--find">Encuentra un espacio</p>
        <img (click)="showDetails({ item: 'form' })" src="../../../assets/Utils/flecha.png" *ngIf="scrWidth < 757"
          [class.hide-details]="displayComp" />
      </div>
      <div class="account--left--bottom--filter" *ngIf="scrWidth > 756 || displayComp">
        <div class="option">
          <div class="option">
            <select [(ngModel)]="ciudadFiltro" class="search-input first">
              <option value="" disabled selected>Selecciona tu ciudad</option>
              <option *ngFor="let ciudad of ciudades" value="{{ ciudad }}">
                {{ ciudad }}
              </option>
            </select>
          </div>
          <div class="option">
            <select [(ngModel)]="unidad" (change)="onChangeUnidad($event)" class="search-input">
              <option value="" disabled>Unidad</option>
              <option value="metros">M2</option>
              <option value="estanteria">Estantería</option>
            </select>
          </div>
          <select *ngIf="!metros && !estanteria" [(ngModel)]="cantidadEspacios" class="search-input last">
            <option value="" disabled>Cantidad</option>
          </select>
          <select *ngIf="metros" [(ngModel)]="metrosModel" class="search-input last">
            <option value="" disabled>Tamaño en m2</option>
            <option value="1">Menos de 100m2</option>
            <option value="2">Más de 100 menos de 500m2</option>
            <option value="3">Más de 500 menos de 1000m2</option>
            <option value="4">Más de 1000m2</option>
          </select>
          <select *ngIf="estanteria" [(ngModel)]="estantesModel" class="search-input last">
            <option value="" disabled>Número de estantes</option>
            <option value="1">De 1 a 50 estantes</option>
            <option value="2">De 50 a 100 estantes</option>
            <option value="3">De 100 a 500 estantes</option>
            <option value="4">De 500 a 1000 estantes</option>
            <option value="5">Más de 1000 estantes</option>
          </select>
        </div>
        <div class="account--left--bottom--filter--button">
          <button class="search--button" (click)="onNavigateBuscador()">
            Buscar
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="account--right">
    <!-- PANTALLA EDITAR PERFIL  -->

    <div *ngIf="edit" class="edit--profile">
      <div class="reservas__header--volver">
        <fa-icon routerLink="/reservas" [icon]="faChevronLeft"></fa-icon>
        <a (click)="onEdit()">Volver</a>
      </div>
      <div class="form-container">
        <form [formGroup]="editForm" (ngSubmit)="onSubmit()">
          <!-- Account information section -->
          <div class="edit--profile--main">
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <div class="form-subtitle">Datos personales</div>
              </mat-expansion-panel-header>
              <mat-panel-description>
                <div class="container-information">
                  <div class="flex1">
                    <input readonly class="form-input" id="nombre" type="text" placeholder="Nombres"
                      formControlName="nombre" />
                    <input readonly class="form-input separator" id="apellido" type="text" placeholder="Apellido"
                      formControlName="apellido" />
                  </div>
                  <div class="flex1">
                    <input readonly class="form-input" id="doc-type" formControlName="documento" type="text">
                    <input readonly class=" form-input separator" id="nit" type="text" placeholder="Documento"
                      formControlName="numeroDocumento" />
                  </div>
                </div>
              </mat-panel-description>
            </mat-expansion-panel>
            <!-- Contact information -->
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <div class="form-subtitle">Datos de contacto</div>
              </mat-expansion-panel-header>
              <mat-panel-description>
                <div class="container-information">
                  <div class="flex1">
                    <select class="form-input" id="ciudad" formControlName="ciudad">
                      <option value="" disabled>Ciudad</option>
                      <option value="Bogota">Bogotá</option>
                      <option value="Cartagena">Cartagena</option>
                    </select>
                    <input class="form-input separator" id="direccion" type="text" placeholder="Dirección"
                      formControlName="direccion" />
                  </div>
                  <div class="flex1">
                    <input class="form-input" id="celular" type="tel" placeholder="Celular" formControlName="celular" />
                    <input class="form-input separator" id="telefono-fijo" type="tel" placeholder="Teléfono fijo"
                      formControlName="telefonoFijo" />
                  </div>
                </div>
              </mat-panel-description>
            </mat-expansion-panel>

            <!-- Official documentation -->
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <div class="form-subtitle">
                  Documentación para arrendamiento
                </div>
              </mat-expansion-panel-header>
              <mat-panel-description>
                <div class="container-fluid button-container">
                  <div class="row">
                    <div class="flex1">
                      <div class="col-sm-6 no-space">
                        <button type="button" class="user-type-button" (click)="bankRef.click()">
                          <img class="user-type-img" src="../../../../assets/Utils/archivo.png" />
                          Ref. Bancaria
                          <input type="file" id="bank-reference" #bankRef (change)="
                              onUploadDocument({
                                name: 'Referencia-bancaria',
                                event: $event
                              })
                            " />
                        </button>
                        <a class="doc-preview" *ngIf="bankRefPreview !== ''" [href]="bankRefPreview | safe"
                          target="_blank">Ver referencia bancaria</a>
                      </div>
                      <div class="col-sm-6">
                        <button type="button" class="user-type-button" (click)="rut.click()">
                          <img class="user-type-img rut" src="../../../assets/Utils/archivo.png" />
                          RUT
                          <input type="file" id="rut" #rut (change)="
                              onUploadDocument({ name: 'RUT', event: $event })
                            " />
                        </button>
                        <a class="doc-preview" *ngIf="rutPreview !== ''" [href]="rutPreview | safe" target="_blank">Ver
                          RUT</a>
                      </div>
                    </div>
                  </div>
                </div>
              </mat-panel-description>
            </mat-expansion-panel>
            <!--CREDIT CARDS -->
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <div class="form-subtitle">Tarjetas</div>
              </mat-expansion-panel-header>
              <mat-panel-description>
                <div class="fluid-container-credit-card">
                  <div class="container-information">
                    <div class="empty" *ngIf="cards.length === 0">
                      <div class="box--empty">
                        <fa-icon [icon]="faBoxOpen" class="icon-caja"></fa-icon>
                      </div>
                      <p class="nothing">Nada por aquí</p>
                    </div>
                    <div class="flex--tarjetas" *ngFor="let card of cards; index as i">
                      <div class="flex--tarjetas--main">
                        <div class="trash">
                          <fa-icon (click)="onDelete(i)" [icon]="faTrash"></fa-icon>
                        </div>
                        <div class="flex--tarjetas--main-square" (click)="onChooseTarjeta(i)">{{ card.franquicia }}
                        </div>
                        <div class="tarjetas--main--info " (click)="onChooseTarjeta(i)">
                          <label for="">**** **** **** {{ card.ultimos }}</label>
                          <label for="">{{ card.nombreTarjeta }}</label>
                        </div>
                      </div>
                      <div class="flex-tarjeta--checked">
                        <fa-icon *ngIf="card.preferidad" class="icon--card" (click)="onChooseTarjeta(i)"
                          [icon]="faCheckCircle" class="green--circle"></fa-icon>
                        <fa-icon *ngIf="!card.preferidad" class="icon--card" (click)="onChooseTarjeta(i)"
                          [icon]="faCheckCircle" class="red--circle"></fa-icon>
                      </div>
                    </div>
                  </div>
                  <div class="add-card-container">
                    <div class="add-card--flex">
                      <fa-icon [icon]="faPlus" class="orange-plus"></fa-icon>
                      <a class="add--button" (click)="onOpenCreditCardModal()">Agrega una tarjeta</a>
                    </div>
                  </div>
                </div>
              </mat-panel-description>
            </mat-expansion-panel>
            <!--Submit button -->
            <div class="row buttons">
              <div class="flex1 botones">
                <button id="register-button" type="submit">
                  Aceptar y continuar
                </button>
                <button class="changePassword--button" (click)="onOpenPasswordModal({ content: changePassword })">
                  Cambiar Contraseña
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <!-- GENERAL PROFILE -->
    <div *ngIf="edit === false" class="account--right--header">
      <div class="account-right--header--title">
        <h1>Así se está moviendo tu cuenta</h1>
      </div>
      <div class="account--right--header--options" *ngIf="scrWidth > 756">
        <a (click)="goToReservas()" class="hover">Ver reservas</a>
        <button class="create hover" (click)="goToCreateWarehouse()">
          Crear bodega
        </button>
      </div>
    </div>
    <div *ngIf="edit === false" class="account--right--main">
      <div class="account--right--main--boxes">
        <div class="account--right--main--boxes--box hover" (click)="onGoToStats()">
          <div class="account--right--main--icon">
            <fa-icon [icon]="faBox"></fa-icon>
          </div>
          <div class="account-right--main--info">
            <h1>{{ pendingMovements.length }}</h1>
            <p>Movimientos pendientes</p>
          </div>
        </div>
        <div class="line"></div>
        <div class="account--right--main--boxes--box hover" (click)="onGoToRerservations({ type: 'Tenant' })">
          <div class="account--right--main--icon">
            <fa-icon [icon]="faCalendarAlt"></fa-icon>
          </div>
          <div class="account-right--main--info">
            <h1>{{ reservationsTenant.length }}</h1>
            <p>Reservas a alguien más</p>
          </div>
        </div>
      </div>
      <div class="account--right--main--boxes right">
        <div class="account--right--main--boxes--box hover" (click)="onGoToWarehouses()">
          <div class="account--right--main--icon">
            <fa-icon [icon]="faWarehouse"></fa-icon>
          </div>
          <div class="account-right--main--info">
            <h1>{{ numberWarehouses }}</h1>
            <p>Bodegas creadas</p>
          </div>
        </div>
        <div class="line"></div>
        <div class="account--right--main--boxes--box hover" (click)="onGoToRerservations({ type: 'Owner' })">
          <div class="account--right--main--icon">
            <fa-icon [icon]="faConciergeBell"></fa-icon>
          </div>
          <div class="account-right--main--info">
            <h1>{{ reservations.length }}</h1>
            <p>Reservas a mis espacios</p>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="edit === false" class="box-bottom">
      <div class="small-box">
        <div class="account--right--main--icon icon--size">
          <fa-icon class="money" [icon]="faDollarSign"></fa-icon>
        </div>
        <div class="account-right--main--info">
          <h1 class="total">{{ total | currency: "$ ":"code" }}</h1>
        </div>
      </div>
      <p>Ingresos de este mes</p>
    </div>
    <div *ngIf="edit === false" class="box-bottom second">
      <div class="account--right--main--boxes--box">
        <div class="account--right--main--icon icon--size">
          <fa-icon class="money" [icon]="faDollarSign"></fa-icon>
        </div>
        <div class="account-right--main--info">
          <h1 class="total">{{ total | currency: "$ ":"code" }}</h1>
        </div>
      </div>
      <p>Total ganado</p>
    </div>
  </div>
</div>

<!-- Modal change password  -->
<ng-template #changePassword let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Cambiar contraseña</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross exit')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form class="main-form" [formGroup]="passwordForm">
      <div class="form-div">
        <p>Contraseña actual</p>
        <div class="password-input" [ngClass]="{ 'red-border': fieldIsInvalid({ field: 'oldPassword' }) }">
          <input class="label" [type]="oldType" id="password" placeholder="Contraseña actual"
            formControlName="oldPassword" (ngModelChange)="checkOldPassword()" />
          <fa-icon [icon]="oldType === 'text' ? faEye : faEyeSlash" class="eye-icon"
            (click)="onShowPassword({ passwordInputType: 'old' })"></fa-icon>
        </div>
        <span *ngIf="fieldIsInvalid({ field: 'oldPassword' })" class="invalid-warning">Campo obligatorio</span>
      </div>
      <div class="form-div">
        <p>Contraseña nueva</p>
        <div class="password-input" [ngClass]="{ 'red-border': fieldIsInvalid({ field: 'newPassword' }) }">
          <input class="label" [type]="newType" id="password" placeholder="Nueva Contraseña"
            formControlName="newPassword" />
          <fa-icon [icon]="newType === 'text' ? faEye : faEyeSlash" class="eye-icon"
            (click)="onShowPassword({ passwordInputType: 'new' })"></fa-icon>
        </div>
        <span *ngIf="fieldIsInvalid({ field: 'newPassword' })" class="invalid-warning">Campo obligatorio</span>
      </div>
      <div class="form-div">
        <p>Confirmar contraseña nueva</p>
        <div class="password-input" [ngClass]="{
            'red-border': fieldIsInvalid({ field: 'newPasswordConfirm' })
          }">
          <input class="label" [type]="newConfirmType" id="password" placeholder="Confirmar nueva contraseña"
            formControlName="newPasswordConfirm" />
          <fa-icon [icon]="newConfirmType === 'text' ? faEye : faEyeSlash" class="eye-icon"
            (click)="onShowPassword({ passwordInputType: 'newConfirm' })"></fa-icon>
        </div>
        <span *ngIf="fieldIsInvalid({ field: 'newPasswordConfirm' })" class="invalid-warning">Campo obligatorio</span>
      </div>
      <span *ngIf="!checkNewPassword()" class="invalid-warning">Las contraseñas no coinciden</span>
      <span *ngIf="!passwordIsValid" class="invalid-warning">La contraseña actual no coincide con la registrada</span>
    </form>
    <div class="modal--body-options">
      <button (click)="modal.dismiss('Cross cancel')" class="cancel">
        Cancelar
      </button>
      <button (click)="onChangePassword({ modal: modal })" class="yes">
        Cambiar contraseña
      </button>
    </div>
  </div>
</ng-template>
<!-- Añade tarjeta modal -->
<div class="pago__modal">
  <ng-template #checkout let-modal>
    <div class="modal-header">
      <h1 class="title" for="">Agrega tu tarjeta</h1>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
      <p>Cerrar</p>
    </div>
    <div class="modal-body">
      <div class="checkout--section--form">
        <form [formGroup]="paymentInformationForm" (ngSubmit)="onSubmitClick()">
          <div class="sub-form--payment" formGroupName="informacionTarjeta">
            <strong>
              <label id="card-info">Informacion de la tarjeta</label>
            </strong>
            <div class="sub-form--payment--name">
              <label>Nombre propietario de la tarjeta</label>
              <input class="input--payment--full" id="fullCardName" type="text" formControlName="fullCardName"
                placeholder="Nombre propietario de la tarjeta" />
            </div>
            <div class="sub--form--payment--cc--number">
              <label>Número de la tarjeta</label>
              <input class="input--payment--full" id="cc-number" minlength="15" maxlength="19" name="cc-number"
                type="tel" #ccNumber="ccNumber" formControlName="cardNumber" autocomplete="cc-number"
                placeholder="Número de la tarjeta" ccNumber />
            </div>
            <div class="scheme">
              Medio de pago
              <strong><span #creditCardFranchise>{{
                  ccNumber.resolvedScheme$ | async | uppercase
                  }}</span></strong>
            </div>
            <div class="sub--form--extra--info">
              <div class="sub-form--exp--date">
                <label for="expDate"> Fecha de expiración </label>
                <input class="input--payment--small" id="expDate" type="tel" formControlName="expDate"
                  placeholder="05 / 2022" autocomplete="cc-exp" ccExp />
              </div>
              <div class="sub--form--extra--info">
                <div class="sub-form--cvc">
                  <label for="cc-cvc"> CVC </label>
                  <input class="input--payment--small" id="cc-cvc" type="tel" autocomplete="off" formControlName="cvc"
                    ccCVC />
                </div>
              </div>
            </div>
          </div>
          <strong>
            <label>Información propietario</label>
          </strong>
          <div class="sub-form" formGroupName="informacionPersonal">
            <div class="payment--user--info--flex">
              <div class="sub-form--name">
                <input class="input--payment--medium" id="name" type="text" formControlName="nombreCompleto"
                  placeholder="Nombre" />
              </div>
              <div class="sub-form--email">
                <input class="input--payment--medium space" id="email" type="email" formControlName="email"
                  placeholder="E-mail" />
              </div>
            </div>
            <div class="sub-form--id">
              <input class="input--payment--medium" id="id-number" type="text" formControlName="numeroDeIdentificacion"
                placeholder="Número de documento" />
            </div>
          </div>
          <div class="bottom--payment--section">
            <button [disabled]="paymentInformationForm.invalid" id="submit-button" type="submit">
              Agregar tarjeta
            </button>
          </div>
        </form>
      </div>
    </div>
  </ng-template>
</div>

<!--IN GODS  MODAL -->
<ng-template #tarjetaModal let-modal>
  <div class="add--parameter--modal">
    <div class="modal-header">
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal--flex--add">
      <div class="modal--body--circle--outside">
        <div class="modal__body--circle--inside">
          <fa-icon [icon]="faQuestion"></fa-icon>
        </div>
      </div>
      <div class="modal--body-question">
        <h1 class="action">¿Deseas escoger esta tarjeta como tu preferida?</h1>
        <p class="action">Esta acción no tiene vuelta atrás</p>
      </div>
      <button (click)="onChangeCardStatus()" class="submit--button-add">
        Escoger
      </button>
    </div>
  </div>
</ng-template>
<!-- Substract modal -->