<div class="main-container">
  <div class="main-container__header">
    <h4 class="header__title">Olvidé mi contraseña</h4>
    <fa-icon [icon]="faTimes" class="header__close-icon" (click)="onClose()"></fa-icon>
  </div>
  <hr />
  <div class="main-container__body">
    <p class="body__main-text">Enviaremos instrucciones para que puedas recuperar tu contraseña e ingresar a Flexpot sin problema</p>
    <input placeholder="Correo electrónico" class="body__input form-input" id="email" type="email" autocomplete="email" [(ngModel)]="email" />
  </div>
  <hr />
  <div class="main-container__footer">
    <button class="footer__back-button" (click)="onBackClicked()">
      <img src="../../../../assets/Utils/flecha.png" class="image" />
      Volver a iniciar sesion
    </button>
    <button class="footer__send-button" (click)="onSendPassword()">Enviar</button>
  </div>
</div>
