<div>
  <canvas
    baseChart
    width="{{width}}"
    height="{{height}}"
    [datasets]="barChartData"
    [labels]="barChartLabels"
    [options]="barChartOptions"
    [legend]="barChartLegend"
    [chartType]="barChartType"
  >
  </canvas>
</div>
