<mat-card class="mat-card mat-focus-indicator border--right--main height">
    <div class="reservas--main--left--reservas--title">
        <mat-card-header class="mat-card-header">
            <div class="mat-card-header-text"></div>
            <div>
                <mat-card-title class="mat-card-title">
                    <div class="reservas--main--left--reservas--title--space">
                        <p>Espacio: {{ reservaInfo.bodega.nombre }}</p>
                        <div class="reservas--main--left--reservas--title--space--status positions">
                            <div class="reservas--main--left--reservas--title--space--status--number__positions">
                                <p>{{ reservaInfo.espacios[0].cantidad }} posiciones</p>
                            </div>
                            <div class="reservas--main--left--reservas--title--space--status--movimientos"><img
                                    src="../../../assets/Utils/movimiento.png" class="upload-image">
                                <p>0 movimientos</p>
                            </div>
                        </div>
                    </div>
                </mat-card-title>
            </div>
        </mat-card-header>
    </div>
    <div class="reservas--main--left--reservas--content--mine medium">
        <mat-card-content class="mat-card-content">
            <div class="reservas--main--left--reservas--content--finaliza">
                <div class="reservas--main--left--reservas--content--fechas">
                    <p class="title">Finaliza:</p>
                    <p class="sub-title">{{reservaInfo.fechaFinal}}</p>
                </div>
                <div class="reservas--main--left--reservas--content--fechas">
                    <p class="title">3</p>
                    <p class="sub-title">semanas</p>
                </div>
            </div>
        </mat-card-content>
    </div>
    <div class="reservas_main--info--solicitud">
        <div class="reservas_main--info--solicitud--circle">
            <p>ND</p>
        </div>
        <div class="reservas_main--info--solicitud--info">
            <p>Alquila</p>
            <p class="name">{{ reservaInfo.arrendatario.nombre }}</p>
        </div>
    </div>
</mat-card>