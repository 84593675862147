<div class="principal">
    <div class="info-header">
        <img class="profile-img" src="../../../../assets/Utils/servicios.png">
        <div class="container-info-msg">
            <span class="fecha">{{fecha}}</span>
            <div class="name-container">
                <span>{{nombre}}</span>
                <img class="open-message-img" src="../../../../assets/Utils/flecha.png">
            </div>
        </div>
    </div>
    <span class="last-message">{{ultimoMensaje}}</span>
</div>