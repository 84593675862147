import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { Router } from "@angular/router";
import { ActivatedRoute } from "@angular/router";
import { faChevronLeft, faArrowCircleDown, faEdit } from "@fortawesome/free-solid-svg-icons";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import { faBoxOpen, faSearch } from "@fortawesome/free-solid-svg-icons";
import { faCalendarAlt, faMapMarker } from "@fortawesome/free-solid-svg-icons";
import { faClock, faTimes, faPlus, faMinus, faFile, faSignInAlt, faUserTag, faQuestion } from "@fortawesome/free-solid-svg-icons";
import { ModalDismissReasons, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { NbIconLibraries, NbToastrService, NbDialogService } from "@nebular/theme";
import * as moment from "moment";
import { ToastrService } from "ngx-toastr";
import { MyWarehousesService } from "../public/services/my-warehouses.service";
import { ApiService } from "../public/services/api.service";
import { FormArray, FormBuilder, FormGroup, FormControl, Validators } from "@angular/forms";
import { Location } from "@angular/common";
import { MapsService } from "../public/services/maps.service";
import { S3Service } from "../public/services/s3.service";
import { MatDialog } from "@angular/material/dialog";
import { SuccessComponent } from "../public/shared/success/success.component";
import { NavigationService } from "../public/services/navigation.service";
import { AuthService } from "../public/services/auth.service";
@Component({
  selector: "app-reservas-history",
  templateUrl: "./reservas-history.component.html",
  styleUrls: ["./reservas-history.component.scss"]
})
export class ReservasHistoryComponent implements OnInit {
  reservas: any = [];
  selectedDateObject: any = {};
  viewReservas: any = [];
  searchBar = "";
  faBoxOpen = faBoxOpen;
  faSearch = faSearch
  /**Font awesome icons */
  faChevronLeft = faChevronLeft;

  constructor(
    private router: Router,
    private myWarehousesService: MyWarehousesService,
    private activatedRoute: ActivatedRoute,
    private modalService: NgbModal,
    private apiService: ApiService,
    private iconLibraries: NbIconLibraries,
    private toastr: ToastrService,
    private location: Location,
    private formBuilder: FormBuilder,
    private mapsService: MapsService,
    private s3Service: S3Service,
    private dialog: MatDialog,
    private navigationService: NavigationService,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    let id = this.activatedRoute.snapshot.params["id"];
    this.getWarehouseByUserId();
  }

  public async getWarehouseByUserId() {
    const user = this.authService.getUser();
    const id = user._id;
    let reservations = await this.myWarehousesService.getReservationByUserId({ id: id });
    this.reservas = [...reservations];
    this.viewReservas = [...reservations];
  }

  /**
   *  Go to other reservation view
   * @param param0 id of reserve
   */
  public onReservation({ id }: { id: string }) {
    this.router.navigate(["mis-reservas/" + id]);
  }

  /**
   * Binds the change of the date.
   * @param event
   */
  public onChangeDate(event) {
    this.selectedDateObject = event;
    this.onFilterDateStatus();
  }
  /**
   * Calculate the number of reservas pendientes
   */
  /**Binds the date from the event */
  public onFilterDateStatus() {
    if (this.selectedDateObject && this.selectedDateObject.startDate !== null && this.selectedDateObject.endDate !== null) {
      this.viewReservas = this.reservas.filter((reserva) =>
          this.validateDates({date: reserva.fechaInicio, rangeStart: this.selectedDateObject.startDate, rangeEnd: this.selectedDateObject.endDate}) ||
          this.validateDates({ date: reserva.fechaFinal, rangeStart: this.selectedDateObject.startDate, rangeEnd: this.selectedDateObject.endDate} )
      );  
    } else if (this.selectedDateObject && this.selectedDateObject.startDate !== null && this.selectedDateObject.endDate !== null) {
      this.viewReservas = this.reservas.filter((reserva) =>
          this.validateDates({date: reserva.fechaInicio, rangeStart: this.selectedDateObject.startDate, rangeEnd: this.selectedDateObject.endDate}) ||
          this.validateDates({date: reserva.fechaFinal, rangeStart: this.selectedDateObject.startDate, rangeEnd: this.selectedDateObject.endDate})
      );  
    }   
    else {
     this.viewReservas = this.reservas;
    }
  }
   /**
   *
  * @param param0 Dateof the reserva
  * @returns
  */
  public validateDates({date, rangeStart, rangeEnd}) {
   let dateMoment = moment(date);
    if (dateMoment.isSameOrAfter(rangeStart) && dateMoment.isSameOrBefore(rangeEnd)) {
      return true;
    } else {
      return false;
    }
  }
  
  /**
 * To go to the previous view
   */
   onBackClicked() {
    this.router.navigate(["reservas/"]);

  }
}
