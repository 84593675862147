import { ApiService } from "./../services/api.service";
import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { SuccessComponent } from "../shared/success/success.component";
import { NavigationService } from "../services/navigation.service";

@Component({
  selector: "app-contact",
  templateUrl: "./contact.component.html",
  styleUrls: ["./contact.component.scss"],
})
export class ContactComponent implements OnInit {
  /** CONSTANTS */
  readonly CONTACT_API = "email/send-contact-email";

  /** VARIABLES */

  /** Variable that holds the contact form  */
  contactForm: FormGroup;
  /** Variable that signals if the component is loading */
  loading = false;

  constructor(
    private formBuilder: FormBuilder,
    private apiService: ApiService,
    private dialog: MatDialog,
    private navigationService: NavigationService
  ) {}

  ngOnInit(): void {
    this.contactForm = this.formBuilder.group({
      nombre: ["", Validators.required],
      apellido: ["", Validators.required],
      telefono: ["", Validators.required],
      email: ["", Validators.required],
      mensaje: ["", Validators.required],
    });
  }

  /**
   * Handles the submission of the contact form
   */
  onSubmitContact(): void {
    const contact = this.contactForm.value;
    this.loading = true;
    this.apiService.post({ api: this.CONTACT_API, data: contact }).subscribe(
      (response) => {
        if (response.success) {
          this.loading = false;
          this.dialog.open(SuccessComponent, {
            data: {
              title: "Mensaje enviado",
              message: "Te contactaremos lo más pronto posible",
              onOk: () => {
                this.navigationService.navigateTo({ path: "home" });
              },
            },
            disableClose: true,
          });
        }
      },
      (err) => {
        alert(`Ha ocurrido un error, por favor vuelve a intentarlo más tarde.`);
        this.loading = false;
      }
    );
  }
}
