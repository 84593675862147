import { Component,Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { faStopwatch } from '@fortawesome/free-solid-svg-icons';
import {ActivatedRoute, Router, NavigationEnd} from "@angular/router";
@Component({
  selector: 'app-success-reserva',
  templateUrl: './success-reserva.component.html',
  styleUrls: ['./success-reserva.component.scss']
})
export class SuccessReservaComponent implements OnInit {
  faStopwatch=faStopwatch;
  constructor(public dialogRef: MatDialogRef<SuccessReservaComponent>,private router: Router,
    @Inject(MAT_DIALOG_DATA) public data,) { }

  ngOnInit(): void {
  }
  /**
   * Handles the click on the ok button
   */
   onOkClick(): void {
    this.dialogRef.close();
    this.routerNavigateTo({
      path: "/reservas"
    });
  }
     /**
   * Handles navigation to the specified URL
   * @param {string} path
   * @return void
   */
      public routerNavigateTo({path}: {path: string}): void {
        this.router.navigate([path]).then((fulfilled) => {
          if (!fulfilled) {
            alert("Invalid URL")
          }
        });
      }

}
