import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterService',
  pure: false,
})
export class FilterServicePipe implements PipeTransform {

  /**
   * Services list
   * @param services list of services
   * @returns
   */
  transform(services: Array<any>): Array<any> {
    return services.filter((service) => service.selected);
  }

}
