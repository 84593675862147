import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  constructor(private router: Router) { }

  navigateTo({ path, params }: { path: string, params?: any }) {
    return this.router.navigate([path], { queryParams: params });
  }
}
