import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-delete-confirm',
  templateUrl: './delete-confirm.component.html',
  styleUrls: ['./delete-confirm.component.scss']
})
export class DeleteConfirmComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<DeleteConfirmComponent>,
              @Inject(MAT_DIALOG_DATA) public data) { }

  ngOnInit(): void {
  }

  /**
   * Handles clicks on the cancel button
   */
  onCancelClick(): void {
    this.dialogRef.close();
  }

  /**
   * Handles the click on the confirm button
   */
  onConfirmClick(): void {
    this.data.callback();
    this.dialogRef.close();
  }

}
