<div class="reservation-div">
  <div class="reservation-info-left">
    <button class="back-button" (click)="onBackClicked()">
      <img src="./assets/Utils/flecha.png" class="image" /> Volver a reservas
    </button>
    <div class="reservation-info-left-body">
      <h4>{{ reserve.bodega?.nombre }}</h4>
      <div class="reservation-info-dates">
        <p>
          Desde:
          <br />
          {{ reserve.fechaInicio }}
        </p>
        <div class="vertical-line"></div>
        <p>
          Hasta:
          <br />
          {{ reserve.fechaFinal }}
        </p>
        <!-- <p>Fecha</p> -->
      </div>
      <hr />
      <div class="reservation-info-owner">
        <div class="reservation-info-owner-circle">
          <p>{{ ownerNameCircle }}</p>
        </div>
        <div class="reservation-info-owner-name">
          <p>Alquilado a:</p>
          <p>{{ ownerName }}</p>
        </div>
        <button class="messages-button" (click)="goToMessages()">
          <span class="material-icons"> question_answer </span>
          Mensaje
        </button>
      </div>
      <hr />
      <div class="reservation-info-product">
        <p>Sobre el Producto Almacenado</p>
        <!-- <button class="reservation-button">Ver mi reserva</button> -->
      </div>
      <p>
        {{ reserve.comentario }}
      </p>
    </div>
  </div>
  <div class="reservation-info-right">
    <div class="reservation-info-right-header">
      <p>Estado y Movimiento</p>
      <!-- <button (click)="onDetailPago(reserve._id)" class="history-button">Historial de pagos</button> -->
      <!-- <button class="rate-button">Calificar Servicio</button> -->
    </div>
    <div class="reservation-info-right-status">
      <p>{{ capitalizeFirstLetter(reserve?.estado) }}</p>
    </div>
    <hr />
    <div class="reservation-info-right-flows">
      <p>Movimientos</p>
      <div class="reservation-info-right-flows-buttons">
        <!-- <button class="all-button">Ver todos</button> -->
        <button class="flows-button" (click)="onFlows({id: reserve._id})">
          <img src="./assets/Utils/movimiento_white.png" class="image" />
          Crear orden de movimiento
        </button>
      </div>
    </div>

    <!-- Tabla temporal -->
    <div class="table">
      <ng2-smart-table [settings]="settingsFlows" [source]="sourceFlows" (custom)="onCustom($event)"> </ng2-smart-table>
    </div>
  </div>

  <h1 class="subtitle">Inventario de esta reserva</h1>

  <div class="reservation-inventory">
    <!-- Tabla temporal -->
    <div class="table">
      <ng2-smart-table [settings]="settingsInventory" [source]="sourceInventory" (custom)="onCustom($event)">
      </ng2-smart-table>
    </div>
  </div>
</div>