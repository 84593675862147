import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SafePipe } from "./pipes/safe.pipe";
import { FilterServicePipe } from "./pipes/filter-service.pipe";
import { SearchPipe } from "./pipes/search-pipe.pipe";

@NgModule({
  declarations: [SafePipe, SearchPipe, FilterServicePipe],
  imports: [CommonModule],
  exports: [SafePipe, SearchPipe, FilterServicePipe],
})
export class SharedModule {}
