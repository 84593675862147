<div class="crear-unidad__container">
  <div class="crear-unidad__container--header">
    <h1>Calcula el espacio de tu mercancía</h1>
    <fa-icon [icon]="faTimes" class="close-icon" (click)="onClose()"></fa-icon>
  </div>
  <p class="our__unidades">Nuestras unidades</p>
  <div class="crear-unidad__container--main">
    <ng-container class="container--logos" *ngFor="let logo of logos">
      <div class="crear-unidad__container--main--unidades {{logo.style}}">
        <fa-icon class="logo" [icon]="logo.icono"></fa-icon>
      </div>
    </ng-container>
    <div class="container__unidades">
      <div class="crear-unidad__container--main--unidades--info" *ngFor="let unidad of unidades">
        <div class="crear-unidad__container--main--unidades--unidad">
          <p class="crear-unidad__container--main--unidades--unidad__name">{{ unidad.nombre }}</p>
          <div class="crear-unidad__container--main--unidades--info--info">
            <p>{{ unidad.ancho }}</p>
            <p>x</p>
            <p>{{ unidad.alto }}</p>
            <p>x</p>
            <p>{{ unidad.profundidad }}</p>
            <p>cm</p>
          </div>
        </div>
      </div>
    </div>
    <div class="crear-unidad__container--main--options">
      <select [(ngModel)]="name" (change)="OnChangeSelect($event)" class="search-input">
        <option [ngValue]="null">Selecciona la unidad</option>
        <option *ngFor="let unidad of unidades; let i = index">{{ unidad.nombre }}</option>
      </select>
      <div class="cantidad">
        <input class="search-input" [(ngModel)]="cantidad" type="number" max="1000" placeholder="Indica cantidad" />
      </div>
    </div>
    <div class="crear-unidad__container--main--options">
      <input class="search-input" [(ngModel)]="peso" type="number" placeholder="Peso por unidad" />
      <div class="calculate--flex">
        <button class="checkout--button" [disabled]="info === false" (click)="OncalculateSpace()">Calcular</button>
        <button class="clean--button" (click)="OnlimpiarResult()">Limpiar</button>
      </div>
    </div>
    <div *ngIf="result" class="crear-unidad__container--main--result">
      <p>Tu mercancía ocupa</p>
      <p>{{ resultadoString | slice: 0:5 }} m3</p>
      <p>{{ resultPositions }} Palets aprox.</p>
    </div>
  </div>
</div>