<div class="delete-confirm">
  <p>¿Está seguro de querer eliminar este elemento?</p>
  <div class="row">
    <div class="col-sm-6">
      <button id="confirm-button" (click)="onConfirmClick()">Confirmar</button>
    </div>
    <div class="col-sm-6">
      <button id="cancel-button" (click)="onCancelClick()">Cancelar</button>
    </div>
  </div>
</div>
