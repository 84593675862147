import { Component, OnInit, ElementRef, ViewChild } from "@angular/core";
import { ApiService } from "src/app/public/services/api.service";
import { DatePipe } from "@angular/common";
import { AuthService } from "../services/auth.service";
import {
  faCircle,
  faCreditCard,
  faInfoCircle,
  faCheckCircle,
  faFilm,
  faSignInAlt,
  faWarehouse,
  faSearch,
  faChevronLeft,
  faChevronRight,
  faBoxOpen,
} from "@fortawesome/free-solid-svg-icons";
import { SuccessComponent } from "../../public/shared/success/success.component";
import {
  NgbModal,
  ModalDismissReasons,
  NgbDatepickerI18n,
} from "@ng-bootstrap/ng-bootstrap";
import * as moment from "moment";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { CreditCardValidators } from "angular-cc-library";
import { Router } from "@angular/router";
import { ActivatedRoute } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { NavigationService } from "../services/navigation.service";
import { PagosService } from "../services/pagos.service";
import { UserService } from "../services/user.service";

@Component({
  selector: "app-reservas",
  templateUrl: "./reservas.component.html",
  styleUrls: ["./reservas.component.scss"],
})
export class ReservasComponent implements OnInit {
  /**Api variables */
  RESERVAS_API = "api/reserva";
  modalReference: any;
  reservasOwnerPending: any = {};
  RESERVASOWNER_API = "api/reserva/propietario";
  RESERVASPENDINGOWNER_API = "api/reserva/propietario/pendiente";
  RESERVASTENANT_API = "api/reserva/arrendatario";
  PAGO_GENERAR_API = "api/pago/divided";
  PAGO_API = "api/pago/reserva";
  dates: any = [];
  content: any = "";
  /**Objects that store the reservas */
  faBoxOpen = faBoxOpen;
  reservas = [];
  reservas2 = [];
  faChevronLeft = faChevronLeft;
  selectedDate = "";
  /**Font awesome icons */
  faCircle = faCircle;
  faChevronRight = faChevronRight;
  faCreditCard = faCreditCard;
  faFilm = faFilm;
  faSignInAlt = faSignInAlt;
  faInfoCircle = faInfoCircle;
  faCheckCircle = faCheckCircle;
  faWarehouse = faWarehouse;
  faSearch = faSearch;
  counterActive = 0;
  pagos = [];
  reservasTenat = [];
  reservasOwner = [];
  viewReservasOwner = [];
  viewReservasTenant = [];
  selectedEstado = "";
  selectedBodega = "";
  servicesCost = "";
  closeResult = "";
  searchBar = "";
  primerPago: any = "";
  selectedDateObject: any = {};
  choosenReservaTenat: any = {};
  tarjeta: any = "";
  // pago:boolean = true;
  cotizaNada = true;
  cotizaMes = false;
  cotizaSemana = false;
  loading = false;
  creditCard = false;
  pse = false;
  paymentOption: any = "";
  positionsCost = "";
  finalPrice: any = "";
  servicesSelected = [];
  /**Varibles for changing the screen that is gonna be shown */
  otros: boolean = true;
  mios: boolean = false;
  pendingReservas: any = 0;
  /** Variable that holds the currently selected credit card
   * If the value is -1 the user is adding a new credit card
   */
  selectedCreditCard = -1;
  /**Variables for the payment form */
  /** variable that holds the payment information form */
  paymentInformationForm: FormGroup;
  /** Reference to credit card franchise element */

  constructor(
    private apiService: ApiService,
    public datepipe: DatePipe,
    private authService: AuthService,
    private modalService: NgbModal,
    private activatedRoute: ActivatedRoute,
    public router: Router,
    private dialog: MatDialog,
    private fb: FormBuilder,
    private navigationService: NavigationService,
    private pagosService: PagosService,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    //Get params value in order to validate the reservations tabs preferences
    let initialType = this.activatedRoute.snapshot?.queryParams?.type;
    this.getReservas();
    this.getReservasByTenant();
    this.getReservasByOwner();
    this.getReservasPendingByTenant();
    if (initialType) {
      this.mios = initialType == "owner" ? true : false;
      this.otros = initialType == "tenant" ? true : false;
    }
    // this.getTarjetaFavourite();
    /**Informacion personal form */
    this.paymentInformationForm = this.fb.group({
      informacionPersonal: this.fb.group({
        nombreCompleto: ["", Validators.required],
        email: ["", [Validators.required, Validators.email]],
        // tipoDeDocumento: ['', Validators.required],
        numeroDeIdentificacion: ["", Validators.required],
      }),
      informacionTarjeta: this.fb.group({
        fullCardName: ["", Validators.required],
        // idNumber: ['', Validators.required],
        cardNumber: [
          "",
          [Validators.required, CreditCardValidators.validateCCNumber],
        ],
        expDate: [
          "",
          [Validators.required, CreditCardValidators.validateExpDate],
        ],
        cvc: [
          "",
          [
            Validators.required,
            Validators.minLength(3),
            Validators.maxLength(4),
          ],
        ],
      }),
    });
  }

  @ViewChild("checkout", { static: false }) checkout: ElementRef;
  @ViewChild("checkoutTarjeta", { static: false }) checkoutTarjeta: ElementRef;

  /**
   * Adds a new form control to insert credit card information
   */
  // onAddCreditCard(): void {
  //   // if (this.paymentInformationForm.get('informacionTarjeta') === null) {
  //   const informacionTarjeta = this.fb.group({
  //     fullCardName: ["", Validators.required],
  //     idNumber: ["", Validators.required],
  //     cardNumber: ["", [Validators.required, CreditCardValidators.validateCCNumber]],
  //     expDate: ["", [Validators.required, CreditCardValidators.validateExpDate]]
  //   });
  //   this.paymentInformationForm.addControl("informacionTarjeta", informacionTarjeta);
  //   // }
  //   this.selectedCreditCard = -1;
  // }

  /**Submit the form */
  onSubmitClick(): void {
    const informacionPersonal =
      this.paymentInformationForm.value.informacionPersonal;
    const informacionTarjeta =
      this.paymentInformationForm.value.informacionTarjeta;
    /**Fecha de expiracion tarjeta */
    let expDate = informacionTarjeta.expDate;
    let momentDate = moment(expDate, "MM/YYYY");
    /**Mes y año de expiracion */
    let expMonth = momentDate.format("MM");
    let expYear = momentDate.format("YY");
    /**Numero de tarjeta */
    let cc = informacionTarjeta.cardNumber;
    // cc=cc.replace(/^\s+|\s+$|\s+(?=\s)/g, "");
    cc = cc.replace(/\s+/g, "");
    /**cvc */
    let cvc = informacionTarjeta.cvc;
    /**Dueño tarjeta */
    let cardHolder = informacionTarjeta.fullCardName;

    // const franchise = this.franchiseElements.nativeElement.innerText;
    //Creting the payload form
    /**Construyedo el objeto a enviar */
    const tarjeta = {
      number: cc,
      cvc: cvc,
      exp_month: expMonth,
      exp_year: expYear,
      card_holder: cardHolder,
      preferidad: true,
    };

    // Creando tarjeta en el back
    this.pagosService
      .postTarjeta({ data: tarjeta })
      .then((response) => {
        alert("Tarjeta creada con éxito");
        this.createTransaction();
        // this.choosenReservaTenat.tarjeta = tarjeta;
      })
      .catch((err) => {
        alert(err.error.mensaje);
        if (err.error.mensaje === "La tarjeta ya existe") {
          this.createTransaction();
        }
      });

    /**Modificando el objeto a enviar */
  }

  public async getTarjetaFavourite() {
    const id = this.authService.getUser()._id;
    this.tarjeta = await this.userService.getTarjetaFavourite({ id: id });
  }

  reloadCurrentRoute() {
    let currentUrl = this.router.url;
    this.router.navigateByUrl("/", { skipLocationChange: true }).then(() => {
      this.router.navigate([currentUrl]);
    });
  }

  private createTransaction() {
    this.modalService.dismissAll();
    this.apiService
      .post({ api: this.PAGO_GENERAR_API, data: this.choosenReservaTenat })
      .subscribe(
        (response) => {
          this.dialog.open(SuccessComponent, {
            data: {
              title: "Pago exitoso",
              message: "Gracias por confiar en Flexpot",
              onOk: () => {
                this.navigationService.navigateTo({ path: "reservas" });
                this.reloadCurrentRoute();
              },
            },
            disableClose: true,
          });
        },
        (err) => {
          alert("Hubo un error en tu pago por favor comunicate con soporte");
          this.dialog.open(SuccessComponent, {
            data: {
              title: "Hubo un error",
              message: "Por favor comunicate con soporte",
              onOk: () => {
                this.navigationService.navigateTo({ path: "reservas" });
              },
            },
            disableClose: true,
          });
        }
      );
  }

  public onPse() {
    if (this.creditCard == true) {
      alert("Solo puedes seleccionar un método de pagp");
    } else {
      this.pse = true;
    }
  }
  public onCreditCard() {
    if (this.pse == true) {
      alert("Solo puedes seleccionar un método de pagp");
    } else {
      this.creditCard = true;
    }
  }
  public onDeactivateCreditCard() {
    this.creditCard = false;
  }
  public onDeactivatePse() {
    this.pse = false;
  }

  /**
   * Handles getting all the spaces from the api
   */
  public getReservas() {
    this.apiService.get({ api: this.RESERVAS_API }).subscribe((reserva) => {
      this.reservas = reserva;
    });
    //
  }

  /**
   * Get the reservas of the tenant from the API.
   */
  public getReservasByTenant() {
    const user = this.authService.getUser();
    const id = user._id;
    this.apiService
      .get({ api: `${this.RESERVASTENANT_API}/${id}` })
      .subscribe((reserva) => {
        let counterActive = 0;
        this.reservasTenat = reserva;
        this.viewReservasTenant = reserva;
        for (let reservas in reserva) {
          if (reserva[reservas].estado === "aceptado") {
            counterActive++;
          }
        }
        this.counterActive = counterActive;
      });
  }

  /**
   * Get the reservas of the tenant from the API.
   */
  public getReservasPendingByTenant() {
    const user = this.authService.getUser();
    const id = user._id;
    this.apiService
      .get({ api: `${this.RESERVASPENDINGOWNER_API}/${id}` })
      .subscribe((reserva) => {
        this.reservasOwnerPending = reserva;
      });
  }
  /**
   * Get the reservas of the owner from the API.
   */
  public getReservasByOwner() {
    const user = this.authService.getUser();
    const id = user._id;
    this.apiService
      .get({ api: `${this.RESERVASOWNER_API}/${id}` })
      .subscribe((reserva) => {
        this.reservasOwner = reserva;
        this.viewReservasOwner = reserva;
      });
  }

  /**
   * Get on hold reservations  of the owner from the API.
   */
  public getPendingReservasByOwner() {
    const user = this.authService.getUser();
    const id = user._id;
    this.apiService
      .get({ api: `${this.RESERVASOWNER_API}/${id}` })
      .subscribe((reserva) => {
        this.reservasOwner = reserva;
        this.viewReservasOwner = reserva;
      });
  }

  /**Changes to the mis espacios screen */
  public onClickMios() {
    this.navigationService.navigateTo({ path: "reservas", params: { type: "owner"} });
    this.mios = true;
    this.otros = false;
  }
  /**Changes to the otros espacios screen */
  public onClickOtros() {
    this.navigationService.navigateTo({ path: "reservas", params: { type: "tenant"} });
    this.mios = false;
    this.otros = true;
  }
  /**
   * Binds the change of the date.
   * @param event
   */
  public onChangeDate(event) {
    this.selectedDateObject = event;
    this.onFilterDateStatus();
  }
  /**
   * Binds the change of the date.
   * @param event
   */
  public onChangeDateOwner(event) {
    this.selectedDateObject = event;
    this.onFilterDateStatusOwner();
  }
  /**Binds the date from the event */
  public onFilterDateStatusOwner() {
    if (
      this.selectedDateObject &&
      this.selectedEstado &&
      this.selectedDateObject.startDate !== null &&
      this.selectedDateObject.endDate !== null
    ) {
      this.viewReservasOwner = this.reservasOwner.filter(
        (item) => item.estado === this.selectedEstado
      );
      this.viewReservasOwner = this.viewReservasOwner.filter(
        (reserva) =>
          this.validateDates({
            date: reserva.fechaInicio,
            rangeStart: this.selectedDateObject.startDate,
            rangeEnd: this.selectedDateObject.endDate,
          }) ||
          this.validateDates({
            date: reserva.fechaFinal,
            rangeStart: this.selectedDateObject.startDate,
            rangeEnd: this.selectedDateObject.endDate,
          })
      );
    } else if (
      this.selectedDateObject &&
      this.selectedDateObject.startDate !== null &&
      this.selectedDateObject.endDate !== null
    ) {
      this.viewReservasOwner = this.reservasOwner.filter(
        (reserva) =>
          this.validateDates({
            date: reserva.fechaInicio,
            rangeStart: this.selectedDateObject.startDate,
            rangeEnd: this.selectedDateObject.endDate,
          }) ||
          this.validateDates({
            date: reserva.fechaFinal,
            rangeStart: this.selectedDateObject.startDate,
            rangeEnd: this.selectedDateObject.endDate,
          })
      );
    } else if (this.selectedEstado) {
      this.viewReservasOwner = this.reservasOwner.filter(
        (item) => item.estado === this.selectedEstado
      );
    } else {
      this.viewReservasOwner = this.reservasOwner;
    }
  }
  /**
   * Calculate the number of reservas pendientes
   */
  /**Binds the date from the event */
  public onFilterDateStatus() {
    if (
      this.selectedDateObject &&
      this.selectedEstado &&
      this.selectedDateObject.startDate !== null &&
      this.selectedDateObject.endDate !== null
    ) {
      this.viewReservasTenant = this.reservasTenat.filter(
        (item) => item.estado === this.selectedEstado
      );
      this.viewReservasTenant = this.viewReservasTenant.filter(
        (reserva) =>
          this.validateDates({
            date: reserva.fechaInicio,
            rangeStart: this.selectedDateObject.startDate,
            rangeEnd: this.selectedDateObject.endDate,
          }) ||
          this.validateDates({
            date: reserva.fechaFinal,
            rangeStart: this.selectedDateObject.startDate,
            rangeEnd: this.selectedDateObject.endDate,
          })
      );
    } else if (
      this.selectedDateObject &&
      this.selectedDateObject.startDate !== null &&
      this.selectedDateObject.endDate !== null
    ) {
      this.viewReservasTenant = this.reservasTenat.filter(
        (reserva) =>
          this.validateDates({
            date: reserva.fechaInicio,
            rangeStart: this.selectedDateObject.startDate,
            rangeEnd: this.selectedDateObject.endDate,
          }) ||
          this.validateDates({
            date: reserva.fechaFinal,
            rangeStart: this.selectedDateObject.startDate,
            rangeEnd: this.selectedDateObject.endDate,
          })
      );
    } else if (this.selectedEstado) {
      this.viewReservasTenant = this.reservasTenat.filter(
        (item) => item.estado === this.selectedEstado
      );
    } else {
      this.viewReservasTenant = this.reservasTenat;
    }
  }

  /**
   *  Go to other reservation view
   * @param param0 id of reserve
   */
  public onReservation({ id }: { id: string }) {
    this.router.navigate(["otras-reservas/" + id]);
  }
  /**
   *  Go my reservas view
   * @param param0 id of reserve
   */
  public onMine(id: string) {
    this.router.navigate(["mis-reservas/" + id]);
  }

  /**
   *
   * @param param0 Dateof the reserva
   * @returns
   */
  public validateDates({ date, rangeStart, rangeEnd }) {
    let dateMoment = moment(date);
    if (dateMoment.isAfter(rangeStart) && dateMoment.isBefore(rangeEnd)) {
      return true;
    } else {
      return false;
    }
  }
  /**
   * Binds the value from the value changed.
   * @param event chnages according to the select
   */
  public onEstadoSelected(event) {
    this.selectedEstado = event.target.value;
    this.onFilterDateStatus();
  }
  /**Binds the value from the select of the bodega */
  public onBodegaSelected(event) {
    this.selectedBodega = event.target.value;
    this.viewReservasOwner = this.reservasOwner.filter(
      (item) => item.bodega.nombre === this.selectedBodega
    );
  }
  /**
   * Handles closing the modals with different options
   * @param reason how I am closing the modals
   */
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }
  /**
   * Calculates the price of a single payment for the reserva
   * @param array recievies the espacio array to calculate the final price
   */
  public onCotizaMesPositions(array) {
    this.positionsCost = array.espacios.reduce((accumulator, element) => {
      const positionCost =
        element.espacio.costoPosicion * element.cantidad * array.duracion * 7;
      return accumulator + positionCost;
    }, 0);
  }

  /**
   * Calculates the price of a service
   * @param array recievies the espacio array to calculate the final price
   */
  public onCotizaMesServicio(array) {
    let servicesSelected = [];
    array.otros.forEach((servicio) => {
      array.bodega.otros.forEach((element) => {
        if (element.otro === servicio.otro._id) {
          servicesSelected.push(element);
        }
      });
    });
    this.servicesSelected = servicesSelected;
    this.servicesCost = array.otros.reduce((accumulator, element) => {
      let i = 0;
      const serviceCost = servicesSelected[i].costo * element.cantidad;
      i++;
      return accumulator + serviceCost;
    }, 0);
  }

  /**Calculate the final price of everything */
  public totalServicesSpaces() {
    this.finalPrice = this.servicesCost + this.positionsCost;
    this.primerPago = this.finalPrice * 0.1;
  }

  /**
   * Function to navigate a to pagos page,
   * also this function sent the reservation id in order to get all information
   * of reservation in the other page
   * @param content
   * @param index index to get position of reservation in reservation (tenant) array
   */
  public onOpenModalPago(content, index) {
    this.navigationService.navigateTo({
      path: "pago-reserva",
      params: { reservation: this.reservasTenat[index]._id },
    });
    //   this.modalService.open(content, { windowClass: "gr-modal-full", ariaLabelledBy: "modal-basic-title" }).result.then(
    //     (result) => {
    //       this.closeResult = `Closed with: ${result}`;
    //     },
    //     (reason) => {
    //       this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    //     }
    //   );
    //   this.choosenReservaTenat = this.reservasTenat[index];
    // this.onCotizaMesPositions(this.choosenReservaTenat);
    //   this.onCotizaMesServicio(this.choosenReservaTenat);

    //   this.totalServicesSpaces();
    //   this.choosenReservaTenat.totalPago = this.finalPrice;
  }

  /**Handles opening modals */
  openModal(content) {
    this.modalService
      .open(content, {
        windowClass: "gr-modal-full",
        ariaLabelledBy: "modal-basic-title",
      })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }
  /**Binds the value of the payment option selected */
  public onCotiza(event) {
    this.paymentOption = event.target.value;
    if (this.paymentOption == "fullPago") {
      this.cotizaNada = false;
      this.cotizaMes = true;
      this.cotizaSemana = false;
    }
    if (this.paymentOption == "week") {
      this.cotizaNada = false;
      this.cotizaMes = false;
      this.cotizaSemana = true;
    }
  }
  /**Get the id of the route */
  public onDetailSpaces(id) {
    this.router.navigate(["reservas/" + id]);
  }
  public onReservas() {
    let user = this.authService.getUser();
    let id = user._id;
    this.router.navigate(["historial-reservas/" + id]);
  }
  public onNextStep() {
    if (this.tarjeta.length > 0) {
      this.openModal(this.checkoutTarjeta);
    } else {
      this.openModal(this.checkout);
    }
  }
}
