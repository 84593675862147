<div class="reservas">
  <div class="reservas__header">
    <div class="reservas__header--volver">
      <fa-icon routerLink="/reservas" [icon]="faChevronLeft"></fa-icon>
      <a routerLink="/reservas">Volver a reservas</a>
    </div>
  </div>
  <!-- Main Section -->
  <div class="reservas_main">
    <div class="reservas--main--left--left">
      <div class="reservas_main--left">
        <div class="reservas_main--left__header">
          <p class="title">Espacio reservado {{ reserva?.bodega?.nombre }}</p>
          <a (click)="onGoToBodega(reserva.bodega._id)" class="bodegas">Ir a la bodega</a>
        </div>
        <div class="reservas_main--left__description">
          <p class="description">
            A lo largo de la reserva el usuario puede ir sumando servicios
            adicionales a su alquiler según cómo mueva inventario en los
            espacios y el valor del estos servicios se irán adicionando a cada
            corte.
          </p>
        </div>
        <div class="reservas_main--left--info">
          <div class="reservas--main--left--reservas--content--fecha">
            <div class="reservas--main--left--reservas--content--fecha--inicio">
              <p class="title">Desde:</p>
              <p>{{ reserva?.fechaInicio }}</p>
            </div>
            <div class="reservas--main--left--reservas--content--fecha--line"></div>
            <div class="reservas--main--left--reservas--content--fecha--inicio">
              <p class="title">Hasta:</p>
              <p>{{ reserva?.fechaFinal }}</p>
            </div>
          </div>
          <div class="reservas_main--left--info--time">
            <div class="reservas_main--left--info--time--square">
              <fa-icon class="calendar" [icon]="faCalendarAlt"></fa-icon>
            </div>
            <div class="reservas_main--left--info--time--info">
              <p>Tiempo por cortes:</p>
              <p class="duration">{{ reserva?.duracion }} semanas</p>
            </div>
          </div>
        </div>
        <div class="reservas_main--info--solicitud">
          <div class="reservas_main--info--solicitud--circle">
            <p>JM</p>
          </div>
          <div class="reservas_main--info--solicitud--info">
            <p>Alquila</p>
            <p class="name">{{ reserva?.arrendatario?.nombre }}</p>
          </div>
        </div>
        <div class="reservas_main--product">
          <div class="reservas_main--product--title">
            <p>Sobre el producto a almacenar</p>
            <!-- <a (click)="open(espacios)" class="spaces">Ver espacios y servicios reservados</a> -->
          </div>
        </div>
        <div class="reserva_main--left--details">
          Detalles: {{ reserva?.comentario }}
        </div>
      </div>

      <!-- RIGHT SECTION -------------------------------------------------- -->
      <div class="reservas_main--right">
        <div class="reservas_main--right--title">
          <p class="title--main">Ganancias</p>
          <div class="reservas_main--right--spaces--profit">
            <p>Reserva de {{ reserva?.espacios?.length }} espacios</p>
            <p>{{ espacesCost | currency: "COP ":"code" }}</p>
          </div>
          <div class="reservas_main--right--spaces--profit">
            <p>Servicios adicionales ({{ servicesQuantity }})</p>
            <p>{{ servicesCost | currency: "COP ":"code" }}</p>
          </div>
          <div class="reservas_main--right--spaces--profit--fee">
            <p class="fee">- 25% comisión Flexpot</p>
            <p class="fee">{{ fee | currency: "COP ":"code" }}</p>
          </div>
          <div class="reservas_main--right--spaces--profit--total">
            <strong>
              <p class="total">Total</p>
            </strong>
            <strong>
              <p class="total">{{ profit | currency: "COP ":"code" }}</p>
            </strong>
          </div>
        </div>
        <div class="reservas_main--right--main">
          <div class="reservas_main--right--main--info">
            <p>Cobros de cortes cerrados</p>
            <p>
              Estos montos incluyen los servicios adicionales que el usuario
              agregó durante la reserva.
            </p>
          </div>
          <div class="reservas_main--right--main--header">
            <p>Fechas</p>
            <p class="sub-title">Total</p>
          </div>
          <ng-container *ngIf="pagos.length > 0 && pagos[0]">
            <div class="reservas_main--right--main--main" *ngFor="let pago of pagos">
              <p>{{ pago.createdAt | slice: 0:10 }}</p>
              <p>{{ pago.amount / 100 | currency: "COP ":"code" }}</p>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
    <div class="reserva_main--left--servicios--adicionales--title">
      <h1 *ngIf="inventario === false" class="table--title">
        Movimientos de inventario
      </h1>
      <h1 *ngIf="inventario" class="table--title">
        Inventario de esta reserva
      </h1>
      <div *ngIf="inventario" (click)="onMovement()" class="reserva_main--left--servicios--adicionales--title--change">
        <fa-icon [icon]="faEye"></fa-icon>
        <a>Ver movimientos</a>
      </div>
      <div *ngIf="inventario == false" (click)="onStock()"
        class="reserva_main--left--servicios--adicionales--title--change">
        <fa-icon [icon]="faEye"></fa-icon>
        <a>Ver inventario</a>
      </div>
    </div>
    <!-- inventory table
           -->
    <div *ngIf="inventario" class="reserva_main--left--servicios--adicionales--table">
      <ng2-smart-table [settings]="settingsInventory" [source]="sourceInventory" (custom)="onCustom($event)">
      </ng2-smart-table>
    </div>
    <div *ngIf="inventario == false" class="reserva_main--left--servicios--adicionales--table">
      <ng2-smart-table [settings]="settingsFlows" [source]="sourceFlows" (custom)="onCustom($event)">
      </ng2-smart-table>
    </div>
  </div>
</div>
<!-- Ver espacios modal ----------------------------------------------------------------------------------------------
     -->
<div class="ver-espacios--modal">
  <ng-template #espacios let-modal>
    <div class="modal-header">
      <h1 class="spaces--title">Espacios y servicios de la reserva</h1>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="reservas_--espacios--table">
        <h1 class="table--title">Espacios</h1>
        <div class="table-container-spaces">
          <table class="spaces">
            <tr>
              <th>Nombre</th>
              <th>Tipo</th>
              <th>Techo</th>
              <th>Posiciones</th>
              <th>Agregar # de posiciones</th>
              <th>Disminuir # de posiciones</th>
            </tr>
            <tr class="content-rows" *ngFor="let space of reserva.espacios; index as i">
              <td>{{ space.espacio.nombre }}</td>
              <td>{{ space.espacio.tipo }}</td>
              <td>{{ space.espacio.techo }}</td>
              <td>{{ space.cantidad }}</td>
              <td>
                <fa-icon class="table-icon" (click)="onAdd({ index: i })" [icon]="faPlusCircle"></fa-icon>
              </td>
              <td>
                <fa-icon class="table-icon" (click)="onSubstract({ index: i })" [icon]="faMinusCircle"></fa-icon>
              </td>
            </tr>
          </table>
        </div>
      </div>
      <div class="reservas_--espacios--table">
        <h1 class="table--title">Servicios</h1>
        <div class="table-container-spaces">
          <table class="spaces">
            <tr>
              <th>Servicio</th>
              <th>Precio/und</th>
              <th>Unidades compradas</th>
            </tr>
            <tr class="content-rows" *ngFor="let service of reserva.otros; index as i">
              <td>{{ service.otro.nombre }}</td>
              <td>{{ servicesSelected[i].costo }}</td>
              <td>{{ service.cantidad }}</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </ng-template>
</div>
<!-- Modal of movements -->
<ng-template #movementOut let-modal>
  <div class="modal-header">
    <h1>Salida de mercancía</h1>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="modal-body--title">
      <p>ID movimiento: {{ movementID }}</p>
    </div>
    <ng2-smart-table [settings]="settingsMovements" [source]="products" (custom)="onCustom($event)">
    </ng2-smart-table>
  </div>
</ng-template>
<ng-template #movementIn let-modal>
  <div class="modal-header">
    <h1>Entrada de mercancía</h1>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="modal-body--title">
      <p>ID movimiento: {{ movementID }}</p>
    </div>
    <ng2-smart-table [settings]="settingsMovements" [source]="products" (custom)="onCustom($event)">
    </ng2-smart-table>
  </div>
</ng-template>
<!--IN GODS  MODAL -->
<ng-template #docummentsIn let-modal>
  <div class="add--parameter--modal">
    <div class="modal-header">
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <!-- <div class="modal--body--circle--outside">
      <div class="modal__body--circle--inside">
        <fa-icon [icon]="faQuestion"></fa-icon>
      </div>
    </div> -->
    <div class="modal--body-question">
      <h1 class="action">¿Deseas reportar el ingreso de esta mercancía?</h1>
      <p class="action">Esta acción no tiene vuelta atrás</p>
    </div>
    <div class="documents--flex">
      <button class="upload-freedom-certificate" type="button" (click)="freedomCertificateUpload.click()">
        <img src="./assets/Utils/archivo.png" class="upload-image" />
        Comprobante de llegada de la mercancía
        <input type="file" #freedomCertificateUpload class="hidden-input" id="freedom-certificate-input"
          (change)="onUploadDocument({ event: $event,type:'entrada'})" />
      </button>
      <a *ngIf="documentsForm.get('document').value" [href]="documentsForm.get('document').value | safe" target="_blank"
        class="aref">
        Ver documento
      </a>
    </div>
    <div class="quantity-flex">
      <div class="quantity-flex--item--separated">
        <label for="">Nombre del producto</label>
        <label for="">Cantidad de productos ingresados</label>
      </div>
      <div *ngFor="let producto of productaOrder" class="quantity-flex--item--separated">
        <label for="">{{ producto.producto.nombre }}</label>
        <input class="warehouse-input" placeholder="Cantidad" [(ngModel)]="producto.cantidadIngresada" min="1"
          type="number" />
      </div>
    </div>
    <div class="d-flex justify-content-end">
      <button (click)="onAddStock()" class="submit--button-add">
        Adicionar al inventario
      </button>
    </div>
  </div>
</ng-template>
<!-- Substract modal -->
<ng-template #substract let-modal>
  <div class="add--parameter--modal">
    <div class="modal-header">
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal--body--circle--outside">
      <div class="modal__body--circle--inside">
        <fa-icon [icon]="faQuestion"></fa-icon>
      </div>
    </div>
    <div class="modal--body-question">
      <h1 class="action">
        ¿Estás seguro de que deseas restar 1 posición a este espacio?
      </h1>
      <p class="action">Esta acción no tiene vuelta atrás</p>
    </div>
    <button (click)="onSubmit()" class="submit--button-add">Restar</button>
  </div>
</ng-template>
<!--IN GODS  MODAL -->
<ng-template #out let-modal>
  <div class="add--parameter--modal">
    <div class="modal-header">
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <!-- <div class="modal--body--circle--outside">
      <div class="modal__body--circle--inside">
        <fa-icon [icon]="faQuestion"></fa-icon>
      </div>
    </div> -->
    <div class="modal--body-question">
      <h1 class="action">¿Deseas reportar el despacho de esta mercancía?</h1>
      <p class="action">Esta acción no tiene vuelta atrás</p>
    </div>
    <div class="documents--flex">
      <button class="upload-freedom-certificate" type="button" (click)="freedomCertificateUpload.click()">
        <img src="./assets/Utils/archivo.png" class="upload-image" />
        Comprobante de salida de la mercancía
        <input type="file" #freedomCertificateUpload class="hidden-input" id="freedom-certificate-input"
          (change)="onUploadDocument({event: $event,type:'salida'})" />
      </button>
      <a *ngIf="documentsForm.get('document').value" [href]="documentsForm.get('document').value | safe" target="_blank"
        class="aref">
        Ver documento
      </a>
    </div>
    <div class="quantity-flex">
      <div class="quantity-flex--item--separated">
        <label for="">Nombre del producto</label>
        <label for="">Cantidad de productos despachados</label>
      </div>
      <div *ngFor="let producto of productaOrder" class="quantity-flex--item--separated">
        <label for="">{{ producto.producto.nombre }}</label>
        <input class="warehouse-input" placeholder="Cantidad" [(ngModel)]="producto.cantidadDespachada" min="1"
          type="number" />
      </div>
    </div>
    <button (click)="onDespachar()" class="submit--button-add" id="submit--button-add">
      Despachar mercancía
    </button>
  </div>
</ng-template>