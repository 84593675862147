import { Component, OnInit } from "@angular/core";
import { Location } from "@angular/common";
import { NbIconLibraries } from "@nebular/theme";
import { ReservationAcceptedService } from "../services/reservation-accepted.service";
import { ActivatedRoute, Router } from "@angular/router";
import { NavigationService } from "../services/navigation.service";

@Component({
  selector: "app-reservation-accepted",
  templateUrl: "./reservation-accepted.component.html",
  styleUrls: ["./reservation-accepted.component.scss"],
})
export class ReservationAcceptedComponent implements OnInit {
  public settingsInventory: any = {};
  public settingsFlows: any = {};
  public sourceInventory: any;
  public sourceFlows: any;
  public reserve: any = {};
  public reserveId: string = "";
  public ownerName: string = "";
  public ownerNameCircle: string = "";

  constructor(
    private location: Location,
    private iconLibraries: NbIconLibraries,
    private reservationService: ReservationAcceptedService,
    private navigationService: NavigationService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {
    this.reserveId = this.activatedRoute.snapshot.params["id"];
  }

  async ngOnInit(): Promise<void> {
    this.iconLibraries.registerFontPack("font-awesome", {
      iconClassPrefix: "fa",
    });

    this.configInvetorySmartTable();
    this.configFlowsSmartTable();
    // Init principal resources
    await this.initReserve({ id: this.reserveId });
    await this.initInventoryAndFlows({ id: this.reserveId });
  }

  private async initReserve({ id }: { id: string }): Promise<void> {
    this.reserve = await this.reservationService.getReserve({ id: id });
    console.log(this.reserve.propietario.juridica)
    if(this.reserve.propietario.juridica === true) {
      this.ownerName = this.reserve.propietario?.nombreEmpresa;
      this.ownerNameCircle = this.reserve.propietario?.nombreEmpresa.charAt(0);
    } else {
      this.ownerName = this.reserve.propietario.nombre + this.reserve.propietario.apellido;
      this.ownerNameCircle = this.reserve.propietario?.nombre.charAt(0) + this.reserve.propietario?.apellido.charAt(0);
    }
  }

  /**
   * Method that configures the LocalDataSource
   */
  private async initInventoryAndFlows({ id }: { id: string }): Promise<void> {
    // Change struct for inventory of back to show in table
    let inv = await this.reservationService.getInventory({ id: id });
    let inventaryTable: any = [];
    let flowsTable: any = [];

    for (let index = 0; index < inv.length; index++) {
      const element = inv[index];

      inventaryTable.push({
        producto:
          element.producto != undefined ? element.producto.nombre : "None",
        unidad:
          element.producto != undefined ? element.producto.unidad : "None",
        cantidad: element.producto != undefined ? element.cantidad : "None",
        sku: element.producto != undefined ? element.producto.sku : "None",
        ubicacionNombre:
          element.espacio != undefined ? element.espacio.nombre : "None",
      });
    }

    let addMovements = await this.reservationService.getMovementsAdd({
      id: id,
    });
    for (let index = 0; index < addMovements.length; index++) {
      const element = addMovements[index];

      flowsTable.push({
        id: element._id,
        estado: element.estado,
        tipoMovimiento: "Ingreso",
        // fechaProgramada: element.producto != undefined ? element.cantidad : "None",
      });
    }

    let removeMovements = await this.reservationService.getMovementsRemove({
      id: id,
    });
    for (let index = 0; index < removeMovements.length; index++) {
      const element = removeMovements[index];

      flowsTable.push({
        id: element._id,
        estado: element.estado,
        tipoMovimiento: "Retiro",
        // fechaProgramada: element.producto != undefined ? element.cantidad : "None",
      });
    }

    this.sourceInventory = inventaryTable;
    this.sourceFlows = flowsTable;
  }

  /**
   * Method that configures the smart table for the inventory
   */
  private configInvetorySmartTable(): void {
    this.settingsInventory = {
      mode: "inline",
      pager: {
        display: true,
        perPage: 10,
      },
      hideSubHeader: false,
      actions: false,
      columns: {
        producto: {
          title: "Producto",
        },
        unidad: {
          title: "Tipo de Unidad",
        },
        cantidad: {
          title: "Cantidad",
        },
        sku: {
          title: "SKU Producto",
        },
        ubicacionNombre: {
          title: "Ubicación",
        },
      },
      attr: {
        class: "table table-bordered",
      },
      noDataMessage: "El inventario esta vacío",
    };
  }
  public onDetailPago(id) {
    this.router.navigate(["pagos/" + id]);
  }

  /**
   * Method that configures the smart table for the flows
   */
  private configFlowsSmartTable(): void {
    this.settingsFlows = {
      mode: "inline",
      pager: {
        display: true,
        perPage: 3,
      },
      hideSubHeader: false,
      actions: {
        // columnWidth: "50px",
        // columnTitle: "Acción",
        add: false,
        filter: false,
        edit: false,
        delete: false,
        // custom: [
        // {
        //   name: "watch",
        //   title: '<i class="fa fa-eye"></i>'
        // }
        // ]
      },
      columns: {
        id: {
          title: "ID. movimiento",
        },
        estado: {
          title: "Estado",
        },
        tipoMovimiento: {
          title: "Tipo de mov.",
        },
        // fechaProgramada: {
        //   title: "Fecha programada"
        // }
      },
      rowClassFunction: (row) => {
        if (row.data.estado == "aprobada") {
          //aprobado
          return "active";
        }
      },
      attr: {
        class: "table table-bordered",
      },
      noDataMessage: "No hay movimientos",
    };
  }

  /**
   *
   * @param event
   */
  public onCustom(event: any): void {
    //  TO DO
  }

  /**
   * To go to the previous view
   */
  onBackClicked() {
    this.router.navigate(["reservas/"]);
  }

  /**
   * Navigates to my messages
   */
  public goToMessages() {
    this.navigationService.navigateTo({ path: "mis-mensajes" });
  }

  /**
   * Go to inventory and flows view
   * @param param0 id of reserve
   */
  public onFlows({ id }: { id: string }): void {
    this.router.navigate(["inventario/" + id]);
  }

  /**
   *
   */
  public capitalizeFirstLetter(string) {
    return string ? string.charAt(0).toUpperCase() + string.slice(1) : string;
  }
}
