import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { Chart } from "chart.js";
import { ApiService } from "../services/api.service";
import { AuthService } from "../services/auth.service";
import * as moment from "moment";

@Component({
  selector: "app-stats",
  templateUrl: "./stats.component.html",
  styleUrls: ["./stats.component.scss"],
})
export class StatsComponent implements OnInit {
  /**Api variables type string */
  MOVIMIENTO_AGREGAR_API = "api/movimientoAgregar/user";
  MOVIMIENTO_RETIRO_API = "api/ordenRetiro/user";
  /**Variables to configure a view query */
  @ViewChild("monthlyChart", { static: false })
  monthlyChart: ElementRef;
  @ViewChild("dailyChart", { static: false })
  dailyChart: ElementRef;
  /**nxg loading state variable type bool*/
  public loading = false;
  /**Reference variables of charts month and day*/
  public dailayChartRef: any;
  public monthlyChartRef: any;
  /**Variables type array to save the information obtained in backend */
  public bodegas = [];
  public movimientosPendientes = [];
  public movimientosAprobados = [];
  public movimientosRetiroPendientes = [];
  public movimientosAgregarPendientes = [];
  /**Date reference variables */
  public months = [
    { name: "Enero", index: 1 },
    { name: "Febrero", index: 2 },
    { name: "Marzo", index: 3 },
    { name: "Abril", index: 4 },
    { name: "Mayo", index: 5 },
    { name: "Junio", index: 6 },
    { name: "Julio", index: 7 },
    { name: "Agosto", index: 8 },
    { name: "Septiembre", index: 9 },
    { name: "Octubre", index: 10 },
    { name: "Noviembre", index: 11 },
    { name: "Diciembre", index: 12 },
  ];
  public years = ["2022", "2021", "2020"];
  public monthSelected = Number(moment().format("MM"));
  public yearSelected = Number(moment().format("YYYY"));
  public daySelected;
  public warehouseDaySelected = "Todas";
  public warehouseMonthSelected = "Todas";
  constructor(
    private apiService: ApiService,
    private authService: AuthService
  ) {
    this.loading = true;
    const user = this.authService.getUser();
    this.getMovimientosAgregar({ id: user._id });
  }

  ngOnInit(): void {}

  /**
   * Chart of 'ordendes de retiro' and 'ordenes de entrada' by month
   * @param movementIn ordendes de entrara
   * @param movementOut ordenes de retiro
   */
  public monthlyReservations({ movementIn, movementOut, date }) {
    const labels = Array.from(
      Array(
        moment(
          `${this.yearSelected}-${this.monthSelected}`,
          "YYYY-MM"
        ).daysInMonth()
      ),
      (_, i) => i + 1
    );
    let monthlyChartRef = this.monthlyChart.nativeElement.getContext("2d");
    const templateIn = {
      label: "Órdenes de entrada",
      data: movementIn,
      fill: false,
      borderColor: "#3BAFFF",
      backgroundColor: "#3BAFFF",
      borderWidth: 1,
    };
    const templateOut = {
      label: "Órdenes de retiro",
      data: movementOut,
      fill: false,
      borderColor: "#FF3B50",
      backgroundColor: "#FF3B50",
      borderWidth: 1,
    };
    // Config bar charts
    this.monthlyChartRef = new Chart(monthlyChartRef, {
      type: "bar",
      data: {
        labels: labels,
        datasets: [templateIn, templateOut],
      },
      options: {
        scales: {
          yAxes: [{ display: true, ticks: { beginAtZero: true, stepSize: 1 } }],
          xAxes: [{ display: true, ticks: { beginAtZero: true, stepSize: 1 } }],
        },
      },
    });
    this.monthlyChartRef.update();
  }

  /**
   * Chart of 'ordendes de retiro' and 'ordenes de entrada' by day
   * @param movementIn ordendes de entrara
   * @param movementOut ordenes de retiro
   */
  public dailyReservations({ movementIn, movementOut }) {
    this.loading = true;
    const labels = [...Array(24).keys()];
    let dailyChartRef = this.dailyChart.nativeElement.getContext("2d");
    const templateIn = {
      label: "Órdenes de entrada",
      data: movementIn,
      fill: false,
      borderColor: "#3BAFFF", //azul
      backgroundColor: "#3BAFFF",
      borderWidth: 1,
    };
    const templateOut = {
      label: "Órdenes de retiro",
      data: movementOut,
      fill: false,
      borderColor: "#FF3B50", //azul
      backgroundColor: "#FF3B50",
      borderWidth: 1,
    };
    // Config bar charts
    this.dailayChartRef = new Chart(dailyChartRef, {
      type: "line",
      data: {
        labels: labels,
        datasets: [templateIn, templateOut],
      },
      options: {
        elements: { line: { tension: 0 } },
        scales: {
          yAxes: [{ display: true, ticks: { beginAtZero: true, stepSize: 1 } }],
          xAxes: [{ display: true }],
        },
      },
    });
    this.dailayChartRef.update();
    this.loading = false;
  }
  public onUpdatedMonthCharts() {
    let fechaCompleta = moment(
      `${this.yearSelected}-${this.monthSelected}`,
      "YYYY-MM"
    ).format("YYYY-MM-DD");
    this.onChangeMonth({ date: fechaCompleta });
  }

  /**
   * Function to get 'ordenes de entrada' approved and pending by reservas in bodegas
   * @param id
   */
  public getMovimientosAgregar({ id }) {
    this.apiService
      .get({ api: `${this.MOVIMIENTO_AGREGAR_API}/${id}` })
      .subscribe((ingreso) => {
        this.bodegas = ingreso.bodegas;
        this.movimientosAprobados = ingreso.movimientosAgregarAprobados;
        this.movimientosAgregarPendientes =
          ingreso.movimientosAgregarPendientes;
        this.getMovimientosRetiros({ id });
      });
  }

  /**
   * Function to get 'ordenes de retiro' approved and pending by reservas in bodegas
   * @param id
   */
  public getMovimientosRetiros({ id }) {
    this.apiService
      .get({ api: `${this.MOVIMIENTO_RETIRO_API}/${id}` })
      .subscribe((retiro) => {
        this.movimientosAprobados = this.movimientosAprobados.concat(
          retiro.ordenesRetiroAprobados
        );
        this.movimientosRetiroPendientes = retiro.ordenesRetiroPendientes;
        this.movimientosPendientes = this.movimientosRetiroPendientes.concat(
          this.movimientosAgregarPendientes
        );
        //Set current date in daily chart
        this.onChangeDay({ date: moment().format("YYYY-MM-DD") });
        //Set current date in monthly chart
        this.onChangeMonth({
          date: moment(
            `${this.yearSelected}-${this.monthSelected}`,
            "YYYY-MM"
          ).format("YYYY-MM-DD"),
        });
      });
  }

  /**
   * Function to update daily chart by selected date in datepicker
   * @param date
   */
  public async onChangeDay({ date }) {
    let currentMovementsAdd = this.movimientosAgregarPendientes.filter(
      (movement) => {
        if (this.warehouseDaySelected != "Todas") {
          if (
            moment(movement.createdAt).format("YYYY-MM-DD") ==
              moment(date).format("YYYY-MM-DD") &&
            movement.reserva.bodega == this.warehouseDaySelected
          )
            return true;
          else return false;
        } else {
          return moment(movement.createdAt).format("YYYY-MM-DD") ==
            moment(date).format("YYYY-MM-DD")
            ? true
            : false;
        }
      }
    );
    let currentMovementsRemove = this.movimientosRetiroPendientes.filter(
      (movement) => {
        if (this.warehouseDaySelected != "Todas") {
          if (
            moment(movement.createdAt).format("YYYY-MM-DD") ==
              moment(date).format("YYYY-MM-DD") &&
            movement.reserva.bodega == this.warehouseDaySelected
          )
            return true;
          else return false;
        } else {
          return moment(movement.createdAt).format("YYYY-MM-DD") ==
            moment(date).format("YYYY-MM-DD")
            ? true
            : false;
        }
      }
    );
    let movementIn = await this.createDataSetDays({
      movement: currentMovementsAdd,
    });
    let movementOut = await this.createDataSetDays({
      movement: currentMovementsRemove,
    });
    //Send params 'ordenes de entrada' and 'ordener de retiro' to daily chart
    this.dailyReservations({ movementIn, movementOut });
  }

  /**
   * Function to update daily chart by selected date in date picker
   * @param date
   */
  public createDataSetDays({ movement }) {
    return new Promise((resolve) => {
      let dataSet = new Array(24).fill(0);
      movement.forEach(
        (e) =>
          (dataSet[moment(e.createdAt).hour()] =
            dataSet[moment(e.createdAt).hour()] + 1)
      );
      resolve(dataSet);
    });
  }

  /**
   * Function to update monthly chart by selected date in month selecter
   * @param date
   */
  public async onChangeMonth({ date }) {
    let currentMovementsAdd = this.movimientosAgregarPendientes.filter(
      (movement) => {
        if (this.warehouseMonthSelected != "Todas") {
          if (
            moment(movement.createdAt).format("YYYY-MMMM") ==
              moment(date).format("YYYY-MMMM") &&
            movement.reserva.bodega == this.warehouseMonthSelected
          )
            return true;
          else return false;
        } else {
          return moment(movement.createdAt).format("YYYY-MMMM") ==
            moment(date).format("YYYY-MMMM")
            ? true
            : false;
        }
      }
    );
    let currentMovementsRemove = this.movimientosRetiroPendientes.filter(
      (movement) => {
        if (this.warehouseMonthSelected != "Todas") {
          if (
            moment(movement.createdAt).format("YYYY-MMMM") ==
              moment(date).format("YYYY-MMMM") &&
            movement.reserva.bodega == this.warehouseMonthSelected
          )
            return true;
          else return false;
        } else {
          return moment(movement.createdAt).format("YYYY-MMMM") ==
            moment(date).format("YYYY-MMMM")
            ? true
            : false;
        }
      }
    );
    let movementIn = await this.createDataSetMonths({
      movement: currentMovementsAdd,
      date,
    });
    let movementOut = await this.createDataSetMonths({
      movement: currentMovementsRemove,
      date,
    });
    //Send params 'ordenes de entrada', 'ordener de retiro' and date to monthly chart
    this.monthlyReservations({ movementIn, movementOut, date });
  }

  /**
   * Function to set data of month in days
   * @param movement
   * @param date
   * @returns
   */
  public createDataSetMonths({ movement, date }) {
    return new Promise((resolve) => {
      let dataSet = new Array(moment(date).daysInMonth()).fill(0);
      movement.forEach(
        (e) =>
          (dataSet[Number(moment(e.createdAt).format("DD")) - 1] =
            dataSet[Number(moment(e.createdAt).format("DD")) - 1] + 1)
      );
      resolve(dataSet);
    });
  }
}
