<div class="reservas">
  <div class="reservas__header">
    <div class="reservas__header--volver">
      <fa-icon routerLink="/reservas" [icon]="faChevronLeft"></fa-icon>
      <a routerLink="/reservas">Volver a reservas</a>
    </div>
  </div>
  <!-- Main Section -->
  <div class="reservas_main">
    <div class="reservas--main--left--left">
      <div class="reservas_main--left">
        <div class="reservas_main--left__header">
          <p class="title">Espacio reservado {{ reserva?.bodega?.nombre }}</p>
          <a href="" (click)="onGoToBodega(reserva.bodega._id)" class="bodegas">Ir a la bodega</a>
        </div>
        <div class="reservas_main--left__description">
          <p class="description">
            A lo largo de la reserva el usuario puede ir sumando servicios adicionales a su alquiler según cómo mueva
            inventario en los espacios y el
            valor del estos servicios se irán adicionando a cada corte.
          </p>
        </div>
        <div class="reservas_main--left--info">
          <div class="reservas--main--left--reservas--content--fecha">
            <div class="reservas--main--left--reservas--content--fecha--inicio">
              <p class="title">Desde:</p>
              <p>{{ reserva.fechaInicio }}</p>
            </div>
            <div class="reservas--main--left--reservas--content--fecha--line"></div>
            <div class="reservas--main--left--reservas--content--fecha--inicio">
              <p class="title">Hasta:</p>
              <p>{{ reserva.fechaFinal }}</p>
            </div>
          </div>
          <div class="reservas_main--left--info--time">
            <div class="reservas_main--left--info--time--square">
              <fa-icon class="calendar" [icon]="faCalendarAlt"></fa-icon>
            </div>
            <div class="reservas_main--left--info--time--info">
              <p>Tiempo por cortes:</p>
              <p class="duration">{{ reserva.duracion }} semanas</p>
            </div>
          </div>
        </div>
        <div class="reservas_main--info--solicitud">
          <div class="reservas_main--info--solicitud--circle">
            <p>JM</p>
          </div>
          <div class="reservas_main--info--solicitud--info">
            <p>Solicita</p>
            <p class="name">{{ reserva?.arrendatario?.nombre }}</p>
          </div>
        </div>
        <div class="reservas_main--product">
          <div class="reservas_main--product--title">
            <p>Sobre el producto a almacenar</p>
          </div>
        </div>
        <div class="reserva_main--left--details">Detalles: {{ reserva.comentario }}</div>
      </div>
      <div class="reservas_main--left--espacios--table">
        <h1 class="table--title">Espacios solicitados</h1>
        <div class="table-container">
          <table class="spaces">
            <tr>
              <th>Nombre</th>
              <th>Tipo</th>
              <th>Techo</th>
              <th>Posiciones</th>
              <th>Precio/Día</th>
              <th>Altura max</th>
              <th>Peso max</th>
              <th>Temp</th>
            </tr>
            <tr class="content-rows" *ngFor="let space of reserva.espacios; index as i">
              <td>{{ space.espacio.nombre }}</td>
              <td>{{ space.espacio.tipo }}</td>
              <td>{{ space.espacio.cubierta ? "Si" : "No" }}</td>
              <td>{{ space.espacio.cantidad }}</td>
              <td>{{ space.espacio.costo | currency: "COP ":"code" }}</td>
              <td>{{ space.espacio.alto }} m</td>
              <td>{{ space.espacio.peso }} Ton</td>
              <td>{{ space.espacio.temperatura }} °C</td>
            </tr>
          </table>
        </div>
      </div>
      <div class="reserva_main--left--servicios--adicionales">
        <h1 *ngIf="reserva?.otros?.length > 0 " class="table--title">Servicios adicionales</h1>
        <div class="reserva_main--left--servicios--adicionales--servicios" *ngFor="let services of reserva.otros">
          {{ services.otro.nombre }}
        </div>
      </div>
    </div>
    <!-- RIGHT SECTION -------------------------------------------------- -->
    <div class="reservas_main--right">
      <div class="reservas_main--right--title">
        <p class="title--main">Ganancias</p>
        <div class="reservas_main--right--spaces--profit">
          <p>Reserva de {{ reserva?.espacios?.length }} espacios</p>
          <p>{{ espacesCost | currency: "COP ":"code" }}</p>
        </div>
        <div class="reservas_main--right--spaces--profit">
          <p>Servicios adicionales ({{ servicesQuantity }})</p>
          <p>{{ servicesCost | currency: "COP ":"code" }}</p>
        </div>
        <div class="reservas_main--right--spaces--profit--fee">
          <p class="fee">- 25% comisión Flexpot</p>
          <p class="fee">{{ fee | currency: "COP ":"code" }}</p>
        </div>
        <div class="reservas_main--right--spaces--profit--total">
          <strong>
            <p class="total">Total</p>
          </strong>
          <strong>
            <p class="total">{{ profit | currency: "COP ":"code" }}</p>
          </strong>
        </div>
      </div>
      <div class="reservas_main--right--main">
        <p class="title--main">Responde a la solicitud</p>
        <p class="conditions">
          Una vez apruebes la solicitud, el usuario deberá cancelar la cuota inicial para que la reserva quede activa.
          Cuando pase esto, serás
          notificado por correo y verás la reserva activa en la plataforma.
        </p>
        <div class="reservas_main--right--options">
          <div class="reservas_main--right--options--deny">
            <fa-icon [icon]="faTimes"></fa-icon>
            <button class="deny">Rechazar</button>
          </div>
          <div class="reservas_main--right--options--approve">
            <fa-icon [icon]="faCheck"></fa-icon>
            <button class="approve" (click)="open(checkout)">Aceptar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Modal approve -------------------------------------------------- -->
<div class="modal--approve">
  <ng-template #checkout let-modal>
    <div class="modal-header">
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="modal--body--circle--outside">
        <div class="modal__body--circle--inside">
          <fa-icon [icon]="faQuestion"></fa-icon>
        </div>
      </div>
      <div class="modal--body-question">
        <h1>¿Estás seguro de aceptar la solicitud?</h1>
        <p>Esta acción no tiene vuelta atrás el cliente podrá pagar la reserva y quedará activa</p>
      </div>
      <div class="modal--body-options">
        <button (click)="modal.dismiss('Cross click')" class="cancel">No,cancelar</button>
        <button (click)="onApprove(reserva._id); modal.dismiss('Cross click')" class="yes">Si, seguro</button>
      </div>
    </div>
  </ng-template>
</div>