<div class="navbar-container">
  <div class="flex-center">
    <div class="navbar">
      <div class="principal-img">
        <img src="../../../../assets/Logos/logo-blanco.png" id="home-logo" routerLink="/home" />
        <img src="../../../../assets/Utils/menu_white_24dp.svg" id="hambur-menu" (click)="showMenuAction()" *ngIf="scrWidth < 621">
      </div>
      <div class="options" *ngIf="showMenu || scrWidth > 620">
        <button id="contact-button" class="navbar-button" routerLink="/contacto" (click)="showMenuAction()">Contacto</button>
        <button class="navbar-button" (click)="goToSearch()">Todos los espacios</button>
        <button class="navbar-button" (click)="goToReservas()" *ngIf="auth">Reservas</button>
        <button class="navbar-button" (click)="goToMessages()" *ngIf="auth">Mensajes</button>
        <button class="navbar-button" (click)="goToMyWarehouses()" *ngIf="auth">Mis bodegas</button>
        <button class="navbar-button" (click)="onLoginClick()" *ngIf="!auth">Ingresa</button>
        <button class="navbar-button" (click)="goToMyAccount()" *ngIf="auth">Cuenta</button>
      </div>
    </div>
  </div>
</div>
