<!-- Custom Modal -->
<div class="modal-background">
    <div class="contenerdor-mensaje">
        <div class="header">
            <h2>Preguntas frecuentes</h2>
        </div>
        <br>
        <div class="body">
            <div *ngFor="let x of arrayTest">
                <label class="question-title">
                    <strong>Pregunta no.1?</strong>
                </label>
                <p class="description-answer">
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Et doloribus
                    quisquam, officia harum ad
                    delectus, amet omnis fugit iure atque deleniti libero autem illum reiciendis, tenetur itaque totam
                    corporis saepe.
                </p>
            </div>

            
        </div>
        <div class="footer">
            <div class="button-custom pointer" (click)="onClose()">
                <div size="12" class="label-container">
                    <label class="label-button">
                        Entendido
                    </label>
                </div>
            </div>
        </div>
    </div>
</div>