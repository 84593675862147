<app-add-credit-card *ngIf="addCardModal" (close)="onCloseCreditCardModal()" (creationSuccess)="onCreationSuccess()">
</app-add-credit-card>
<ngx-loading [show]="loader" [config]="loaderConfig"></ngx-loading>
<div class="pago__modal">
  <div class="container-card" *ngIf="!payNextStep">
    <div class="back-button" (click)="onGoBack()">
      <span aria-hidden="true">&times;</span> Volver atrás
    </div>
    <div class="pago-card">
      <div class="title-card">
        <img src="./assets/Utils/info-basica.png" alt="" />
        <label class="title-wharehouse-card">{{
          reservation?.bodega?.nombre
          }}</label>
      </div>
      <div class="date-positions-card">
        <div class="date-card">
          <div class="date-card-container">
            <p class="date-container-title">Desde:</p>
            <p class="date-container-date">{{ reservation?.fechaInicio }}</p>
          </div>
          <div class="date-card-separator"></div>
          <div class="date-card-container">
            <p class="date-container-title">Hasta:</p>
            <p class="date-container-date">{{ reservation?.fechaFinal }}</p>
          </div>
        </div>
        <div class="positions-card" *ngIf="!loader && positionsReservated">
          <label>{{ positionsReservated }} Posiciones</label>
        </div>
        <div class="positions-card" *ngIf="!loader && mtsReservated">
          <label>{{ mtsReservated }} Mts</label>
        </div>
        <div class="positions-card" *ngIf="!loader && mtsReservated == 0 && positionsReservated == 0">
          <label>- - -</label>
        </div>
      </div>
      <div class="pay-detail-card">
        <label class="sub-title-pay-detail-card">Resumen de pago</label>
        <div class="pay-detail-pays">
          <div class="pays-detail-price-card" *ngFor="let space of spacesReservated">
            <label class="pay-detail-price-title">
              {{ space?.espacio?.nombre }} x {{ space?.cantidad }}
              {{
              space?.espacio?.tipo == "Estanteria" ? "posiciones" : "metros"
              }}
              ({{ reservation?.duracion }} sem)
            </label>
            <label class="pay-detail-price-price">{{
              space?.totalCost | currency: "COP ":"code"
              }}</label>
          </div>
          <div class="pays-detail-price-card" *ngFor="let service of servicesSelected">
            <label class="pay-detail-price-title">
              {{ service?.otro?.nombre }} x {{ service?.cantidad }} ({{
              service?.otro?.valor | currency: "COP ":"code"
              }})
            </label>
            <label class="pay-detail-price-price">{{
              service?.totalCost | currency: "COP ":"code"
              }}</label>
          </div>
        </div>
        <div class="pay-detail-total-card">
          <label class="pay-detail-total-title">Total:</label>
          <label class="pay-detail-total-price">{{
            totalInvoce | currency: "COP ":"code"
            }}</label>
        </div>
      </div>
      <hr />
      <div class="politics-card">
        <label>Políticas de cancelación</label>
        <img src="./assets/Utils/info.png" alt="" />
      </div>
      <div class="pay-method-card">
        <label class="sub-tilte-pay-method">Medio de pago</label>
        <div class="pays-method-container">
          <div class="pay-method" (click)="onSeletedPayMethod({ methodSelected: 'Credit Card' })"
            [ngClass]="{ 'pay-method-selected': payMethod == 'Credit Card' }">
            <img _ngcontent-serverapp-c455="" src="./assets/Logos/tarjeta.png" alt="" class="card-method" />
            <label class="method-title">Tarjeta de crédito</label>
          </div>
          <div class="pay-method mrg-l" (click)="onSeletedPayMethod({ methodSelected: 'PSE' })"
            [ngClass]="{ 'pay-method-selected': payMethod == 'PSE' }">
            <img _ngcontent-serverapp-c455="" src="./assets/Logos/pse2.png" alt="" class="pse-method" />
            <label class="method-title">PSE</label>
          </div>
        </div>
      </div>
      <hr />
      <div class="continue-button-card">
        <button class="continue-button" (click)="onNextStep()">
          Continuar
        </button>
      </div>
    </div>
  </div>

  <div class="container-card" *ngIf="payNextStep">
    <div class="pago-card">
      <div class="card-pay-total-pay">
        <label class="card-pay-sub-title">Tu pago</label>
        <label class="card-total-pay">{{
          totalInvoce | currency: "COP ":"code"
          }}</label>
      </div>
      <div class="card-form-credit-card">
        <label class="card-sub-title">Tus Tarjetas</label>
        <!--CREDIT CARDS -->
        <div class="fluid-container-credit-card">
          <div class="container-information">
            <!-- No cards -->
            <div class="empty" *ngIf="cards.length === 0">
              <p class="nothing">Nada por aquí</p>
            </div>
            <!-- For of cards -->
            <div class="flex-tarjetas" *ngFor="let card of cards; index as i">
              <div class="flex-tarjetas-main">
                <div class="trash">
                  <fa-icon (click)="onDelete({ cardIndex: i })" [icon]="faTrash"></fa-icon>
                </div>
                <div class="flex-tarjetas-main-square">
                  {{ card.franquicia }}
                </div>
                <div class="tarjetas-main-info">
                  <label class="mr-btm-unset">**** **** **** {{ card.ultimos }}</label>
                  <label class="mr-btm-unset">{{ card.nombreTarjeta }}</label>
                </div>
              </div>
              <div class="col-checked">
                <fa-icon [ngClass]="{
                    'green-circle': card.preferidad,
                    'red-circle': !card.preferidad
                  }" class="icon-card" (click)="onSetCurrentCard({ cardIndex: i })" [icon]="faCheckCircle">
                </fa-icon>
              </div>
            </div>
          </div>
          <br />
          <div class="add-card" (click)="onOpenCreditCardModal()">
            <fa-icon [icon]="faPlus"></fa-icon>
            <label>Agrega una tarjeta</label>
          </div>
        </div>
      </div>
      <hr />
      <div class="pay-button-card">
        <label class="card-button-return" (click)="payNextStep = false">
          <fa-icon [icon]="faChevronLeft"></fa-icon>
          Paso anterior
        </label>
        <button class="continue-button" (click)="onCreateCardTransaction()">
          Continuar
        </button>
      </div>
    </div>
  </div>
</div>