import { Component, OnInit, NgZone } from "@angular/core";
import { MapsService } from "../services/maps.service";
import { WarehouseSearchModule } from "../../warehouse-search/warehouse-search.module";
import {
  faStar,
  faSortDown,
  faSortUp,
  faBoxOpen,
} from "@fortawesome/free-solid-svg-icons";
import { ApiService } from "../services/api.service";
import { Router } from "@angular/router";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import { NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";
import { ActivatedRoute } from "@angular/router";
import { Options } from "@angular-slider/ngx-slider";
import { MyWarehousesService } from "../services/my-warehouses.service";
import { ngxLoadingAnimationTypes, NgxLoadingComponent } from "ngx-loading";
@Component({
  selector: "app-search",
  templateUrl: "./search.component.html",
  styleUrls: ["./search.component.scss"],
})
export class SearchComponent implements OnInit {
  primaryColour = "#ffffff";
  secondaryColour = "#ccc";
  faStar = faStar;
  faBoxOpen = faBoxOpen;
  checkboxes: boolean = false;
  checkboxes1: boolean = false;
  checkboxes2: boolean = false;
  checkboxes3: boolean = false;
  faSortDown = faSortDown;
  faSortUp = faSortUp;
  faMapMarkerAlt = faMapMarkerAlt;
  ciudadFiltro = "";

  protected icon = {
    url: "./assets/Logos/pin-azul.png",
    scaledSize: {
      width: 50,
      height: 50,
    },
  };

  loaing;
  /** Variable that holds the latitude of the address */
  lat = 4.614108;
  /** Variable that holds the longitude of the address */
  long = -74.090294;
  /**  Testing places while I call the places from the database */
  WAREHOUSE_API = "api/bodega";
  WAREHOUSE_API_APROBADAS = "api/bodega/aprobadas";
  SPACE_API = "api/espacio";
  OTRO_API = "api/otro";
  buscarProducto: any = "";
  loading = false;

  /**
   * Variables that storages the mat-checkbuttoms
   */
  servicios: any = [];
  otros: any = [];
  cantidadEspacios: any = "";
  unidad: any = "";
  warehouses = []; //all warehouses
  viewWarehouses = [];
  closeResult = "";
  currentRate = 3;
  tipoDeProductoSeleccionado: any = "";
  /**Boolean variables for some conditios */
  metros: boolean = false;
  estanteria: boolean = false;
  estanteriaFilter: boolean = false;
  /**Creating the label fot the markers in the map */
  labelOptions = {
    color: "#ee4646",
    fontFamily: "",
    fontSize: "10px",
    fontWeight: "bold",
    letterSpacing: "0.5px",
    text: "Plan Pagado/No pagado",
  };
  /**Variables for handling the change of the checkboxes */
  changeValue: any = 1000;
  changeHighValue: any = 600000;

  /**
   * Checkboxes
   */
  productos: any = [];
  certificados: any = [];
  productoCarga: boolean = false;
  productoQuimicos: boolean = false;
  productoAlimentos: boolean = false;
  productoFarmacos: boolean = false;
  CertificadoAmbiental: boolean = false;
  piso: boolean = false;
  refrigerado: boolean = false;
  noRefrigerados: boolean = false;
  cubierta: boolean = false;
  descubierto: boolean = false;
  value: number = 1000;
  productoChecked: any = [];
  certificadoChecked: any = [];
  etiquetado: boolean = false;
  picking: boolean = false;
  packing: boolean = false;
  estibado: boolean = false;
  BASC: boolean = false;
  ISO9001: boolean = false;
  kits: boolean = false;
  INVIMA: boolean = false;
  estantesModel: any = "";
  BPM: boolean = false;
  metrosModel: any = "";
  productoStatus = [];
  highValue: number = 600000;
  options: Options = {
    floor: 1000,
    ceil: 600000,
  };
  /**Variables for the query */
  tamanoMinimo: any = "";
  tamanoMaximo: any = "";
  cantidadMinima: any = "";
  cantidadMaxima: any = "";
  /**Cities and countries for the search bar */
  ciudades = [];
  paises = [];
  indexPais: any = "";

  showRangeValues = {
    value: 0,
    highValue: 0,
  };
  constructor(
    private mapsService: MapsService,
    private apiService: ApiService,
    public router: Router,
    private modalService: NgbModal,
    private activatedRoute: ActivatedRoute,
    private myWarehousesService: MyWarehousesService,
    private ngZone: NgZone
  ) {
    this.showRangeValues.value = this.currencyFormatCOP(1000);
    this.showRangeValues.highValue = this.currencyFormatCOP(600000);
    this.getOtros();
    this.getLocations();
  }
  ngOnInit(): void {
    var ciudad = this.activatedRoute.snapshot.queryParams["ciudad"];
    var metrosModel = this.activatedRoute.snapshot.queryParams["metrosModel"];
    var estantesModel =
      this.activatedRoute.snapshot.queryParams["estantesModel"];
    var buscarProducto =
      this.activatedRoute.snapshot.queryParams["buscarProducto"];
    this.indexPais = this.activatedRoute.snapshot.queryParams["pais"];
    if (
      ciudad == "" &&
      metrosModel == "" &&
      estantesModel == "" &&
      buscarProducto == ""
    ) {
      this.getWarehouses();
    } else {
      this.onFiltroInit(ciudad, metrosModel, estantesModel, buscarProducto);
      if (estantesModel == "") {
        this.metros = true;
        this.unidad = "metros";
      } else if (metrosModel == "") {
        this.estanteria = true;
        this.unidad = "estanteria";
      }
    }
  }
  public async getLocations() {
    let locations = await this.myWarehousesService.getLocations();
    for (let i = 0; i < locations.length; i++) {
      let element = locations[i];
      for (let j = 0; j < element.ciudades.length; j++) {
        let filterData = {
          ciudad: element.ciudades[j],
          cantidadMinima: "",
          cantidadMaxima: "",
          tamanoMinimo: "",
          tamanoMaximo: "",
          otros: [],
        };
        this.apiService
          .post({ api: `${this.WAREHOUSE_API}/query`, data: filterData })
          .subscribe(
            (res) => {
              //if (res.length > 0) {
              this.ciudades.push(element.ciudades[j]);
              //}
            },
            (err) => {
              console.error(err);
            }
          );
      }
      this.paises.push(element.pais);
    }
  }
  /**
   * Filter calling the query from the API.
   */
  public onFiltroInit(
    ciudad: string,
    metrosModel: any,
    estantesModel: any,
    buscarProducto: any
  ) {
    this.ciudadFiltro = ciudad;
    this.metrosModel = metrosModel;
    this.estantesModel = estantesModel;
    this.buscarProducto = buscarProducto;
    this.onFiltro();
  }
  /**
   * Get warehouses from the API
   */
  public getWarehouses() {
    this.loading = true;
    this.apiService
      .get({ api: this.WAREHOUSE_API_APROBADAS })
      .subscribe((warehouse) => {
        warehouse.forEach((e) => (e.shouldBounce = false));
        this.warehouses = warehouse;
        this.viewWarehouses = warehouse;
        this.loading = false;
      });
  }
  /**
   * Get otros from the API
   */
  public getOtros() {
    this.apiService.get({ api: this.OTRO_API }).subscribe((otro) => {
      this.otros = otro;
      this.productos = this.otros.filter((otro) => {
        if (otro.tipo === "producto") {
          return otro;
        }
      });
      this.servicios = this.otros.filter((otro) => {
        if (otro.tipo === "servicio") {
          return otro;
        }
      });
      this.certificados = this.otros.filter((otro) => {
        if (otro.tipo === "certificado") {
          return otro;
        }
      });
    });
  }

  /**
   * Get a space using a specific id
   */
  public onDetailSpaces(id) {
    this.router.navigate(["bodegas/bodega/" + id]);
  }
  /**
   * Filter the product based on an event
   */
  public onFilterSearchProduct(event) {
    this.tipoDeProductoSeleccionado = event.target.value;
  }
  /**Checks the option selected by the users in meters to check properly */
  public onCheckMeters() {
    if (this.metrosModel == 1) {
      (this.tamanoMinimo = 1), (this.tamanoMaximo = 100);
    } else if (this.metrosModel == 2) {
      (this.tamanoMinimo = 101), (this.tamanoMaximo = 501);
    } else if (this.metrosModel == 3) {
      (this.tamanoMinimo = 502), (this.tamanoMaximo = 1001);
    } else if (this.metrosModel == 4) {
      (this.tamanoMinimo = 1002), (this.tamanoMaximo = 50000);
    }
  }
  /**Checks the option selected by the user to filter propertly */
  public onCheckEstantes() {
    if (this.estantesModel == 1) {
      (this.cantidadMinima = 1), (this.cantidadMaxima = 50);
    } else if (this.estantesModel == 2) {
      (this.cantidadMinima = 50), (this.cantidadMaxima = 100);
    } else if (this.estantesModel == 3) {
      (this.cantidadMinima = 100), (this.cantidadMaxima = 500);
    } else if (this.estantesModel == 4) {
      (this.cantidadMinima = 500), (this.cantidadMaxima = 1000);
    } else if (this.estantesModel == 5) {
      (this.cantidadMinima = 1000), (this.cantidadMaxima = 100000);
    }
  }
  /**
   * Filter calling the query from the API.
   */
  public onFiltro() {
    this.loading = true;
    this.onCheckMeters();
    this.onCheckEstantes();
    if (
      this.estantesModel != "" ||
      this.metrosModel != "" ||
      this.ciudadFiltro != ""
    ) {
      let othersFilter = [];
      if (this.buscarProducto) othersFilter.push(this.buscarProducto);
      let filterData = {
        ciudad: this.ciudadFiltro,
        cantidadMinima: this.cantidadMinima,
        cantidadMaxima: this.cantidadMaxima,
        tamanoMinimo: this.tamanoMinimo,
        tamanoMaximo: this.tamanoMaximo,
        otros: othersFilter,
      };
      this.apiService
        .post({ api: `${this.WAREHOUSE_API}/query`, data: filterData })
        .subscribe(
          (res) => {
            res.forEach((e) => (e.shouldBounce = false));
            let firstWarehouse = res[0];
            if (firstWarehouse) {
              this.lat = parseFloat(firstWarehouse.latitud);
              this.long = parseFloat(firstWarehouse.longitud);
            }
            this.viewWarehouses = res;
            this.loading = false;
          },
          (err) => {
            console.error(err);
          }
        );

      this.myMethodChangingQueryParams();
    } else {
      this.viewWarehouses = this.warehouses;
      this.getWarehouses();
      this.loading = false;
    }
  }

  /**
   * Función para animar los iconos de las bodegas en el mapa cuando se hace hover sobre su tarjeta de información
   * @param warehouse la bodega sobre la que se está haciendo hover
   * @param f valor booleano que indica si debe animarse o no
   */
  onWarehouseHover(warehouse, f) {
    this.ngZone.run(() => {
      warehouse.shouldBounce = f;
    });
  }

  /**
   * Filter that calls the query from the API
   */
  public onFiltroModal() {
    if (
      this.changeValue != 0 ||
      this.changeHighValue != 0 ||
      this.productoChecked.length > 0
    ) {
      this.loading = true;
      let filterData = {
        presupuestoMinimo: this.changeValue,
        presupuestoMaximo: this.highValue,
        otros: this.certificadoChecked,
        cubierta: this.cubierta,
        descubierto: this.descubierto,
        piso: this.piso,
        estanteria: this.estanteria,
      };
      this.apiService
        .post({ api: `${this.WAREHOUSE_API}/query`, data: filterData })
        .subscribe(
          (res) => {
            this.viewWarehouses = res;
            this.loading = false;
            this.modalService.dismissAll();
          },
          (err) => {
            this.loading = false;
            alert("Hubo un error con la busqueda");
          }
        );
    } else {
      this.viewWarehouses = this.warehouses;
    }
  }
  /**
   * Filter that calls the query from the API
   */
  public onCleanFilter() {
    this.loading = true;
    this.buscarProducto = "";
    this.cantidadEspacios = "";
    // this.viewWarehouses = this.warehouses;
    this.ciudadFiltro = "";
    this.metros = false;
    this.estanteria = false;
    this.unidad = "";
    this.metrosModel = "";
    this.tamanoMinimo = "";
    this.tamanoMaximo = "";
    this.estantesModel = "";
    this.cantidadMinima = "";
    this.cantidadMaxima = "";
    this.loading = false;
  }

  /**
   * Método para abrir un modal
   * @param content TemplateRef al html donde se encuentra el modal
   */
  open(content) {
    this.modalService
      .open(content, { ariaLabelledBy: "modal-basic-title" })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }
  /**
   * Handles closing the modals with different options
   * @param reason how I am closing the modals
   */
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }
  /**
   * Function for adding the k to the slider
   */
  formatLabel(value: number) {
    if (value >= 1000) {
      return Math.round(value / 1000) + "k";
    }

    return value;
  }
  /**
   * Open the divs of the checkboxes
   */
  onClickCheckboxes() {
    this.checkboxes = true;
  }
  /**
   * Close the divs of the checkboxes
   */
  onClickCheckboxesClose() {
    this.checkboxes = false;
  }
  /**
   * Open the divs of the checkboxes
   */
  onClickCheckboxes1() {
    this.checkboxes1 = true;
  }
  /**
   * Close the divs of the checkboxes
   */
  onClickCheckboxesClose1() {
    this.checkboxes1 = false;
  }
  /**
   * Open the divs of the checkboxes
   */
  onClickCheckboxes2() {
    this.checkboxes2 = true;
  }
  /**
   * Close the divs of the checkboxes
   */
  onClickCheckboxesClose2() {
    this.checkboxes2 = false;
  }
  /**
   * Open the divs of the checkboxes
   */
  onClickCheckboxes3() {
    this.checkboxes3 = true;
  }
  /**
   * Close the divs of the checkboxes
   */
  onClickCheckboxesClose3() {
    this.checkboxes3 = false;
  }
  /**
   * Gets if the checkbox is checcked or unchecked
   * @param event Defines the event of checked or unchecked
   */
  public changeCertificado(event) {
    this.CertificadoAmbiental = event.checked;
  }
  /**
   * Gets if the checkbox is checcked or unchecked
   * @param event Defines the event of checked or unchecked
   */
  public changeProductoCarga(event) {
    this.productoCarga = event.checked;
  }
  /**
   * Gets if the checkbox is checcked or unchecked
   * @param event Defines the event of checked or unchecked
   */

  public onChangeProductos(event, id) {
    if (event.checked) {
      this.productoChecked.push(id);
    } else {
      let indexOfProducto = this.productoChecked.findIndex(
        (idProducto) => idProducto == id
      );
      if (indexOfProducto >= 0) {
        this.productoChecked.splice(indexOfProducto, 1);
      }
    }
  }

  public changeBASC(event, id) {
    if (event.checked) {
      this.certificadoChecked.push(id);
    } else {
      let indexOfCertificado = this.certificadoChecked.findIndex(
        (idCertifiacado) => idCertifiacado == id
      );
      if (indexOfCertificado >= 0) {
        this.certificadoChecked.splice(indexOfCertificado, 1);
      }
    }
  }

  public changeCubierta(event) {
    this.cubierta = event.checked;
  }

  public changeDescubierto(event) {
    this.descubierto = event.checked;
  }

  public changeEstanteria(event) {
    this.estanteria = event.checked;
  }

  public changePiso(event) {
    this.piso = event.checked;
  }

  /**
   * Function that handles cleaning the aditional filters
   */

  public onCleanFilterCheckboxes() {
    this.productoStatus = [false, false];
    this.piso = false;
    this.estanteriaFilter = false;
    this.refrigerado = false;
    this.noRefrigerados = false;
    this.currentRate = 1;
    this.changeValue = 1000;
    this.highValue = 600000;
    this.value = 1000;
    this.showRangeValues.value = this.currencyFormatCOP(1000);
    this.showRangeValues.highValue = this.currencyFormatCOP(600000);
    this.descubierto = false;
    this.etiquetado = false;
    this.kits = false;
    this.cubierta = false;
    this.BASC = false;
    this.picking = false;
    this.packing = false;
    this.estibado = false;
    this.packing = false;
    this.ISO9001 = false;
    this.INVIMA = false;
    this.BPM = false;
  }

  /**
   * Función para formatear la moneda a pesos
   * @param currencyWithOutFormat Valor sin formatear i.e 1200
   * @returns Valor formateado i.e 1.200,00
   */
  public currencyFormatCOP(currencyWithOutFormat: any) {
    return currencyWithOutFormat.toLocaleString("en-US", {
      style: "currency",
      currency: "COP",
    });
  }
  /**
   * Handles biding the change of value for the slider
   * @param event Binds the change of values for the slider
   */
  public onValueChange(event) {
    this.changeValue = event.value;
    this.highValue = event.highValue;
    this.showRangeValues.value = this.currencyFormatCOP(event.value);
    this.showRangeValues.highValue = this.currencyFormatCOP(event.highValue);
  }
  /**Function that handles the change depending on the measurement taht the user selects */
  public onChangeUnidad(event) {
    if (event.target.value == "metros") {
      this.metros = true;
      this.estanteria = false;
      if (this.estantesModel != "") {
        this.estantesModel = "";
        this.cantidadMinima = "";
        this.cantidadMaxima = "";
      }
    } else if (event.target.value == "estanteria") {
      this.metros = false;
      this.estanteria = true;
      if (this.metrosModel != "") {
        this.metrosModel = "";
        this.tamanoMinimo = "";
        this.tamanoMaximo = "";
      }
    }
  }

  /**
   * Función para actualizar la URL cuando se actualiza el filtro de forma que los campos de filtrado se reflejen en la misma y así el usuario pueda recargar la página sin perder sus filtros
   */
  public myMethodChangingQueryParams() {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: {
        ciudad: this.ciudadFiltro,
        metrosModel: this.metrosModel,
        estantesModel: this.estantesModel,
        buscarProducto: this.buscarProducto,
      },
      queryParamsHandling: "merge", // remove to replace all query params by provided
    });
  }

  public onConvertToNumber(dataToConvert) {
    return Number(dataToConvert);
  }
}
