import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-terms-conditions',
  templateUrl: './terms-conditions.component.html',
  styleUrls: ['./terms-conditions.component.scss']
})
export class TermsConditionsComponent implements OnInit {
/** This var create the close output function of modal when the user click close button */
@Output() close = new EventEmitter<Boolean>();

arrayTest = [1, 2, 3, 4, 5, 67, 8];
constructor() {}

ngOnInit(): void {}

/**
 * Function to close a modal
 */
public onClose() {
  this.close.emit(false);
}
}
