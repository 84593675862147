<div class="login container-fluid">
  <fa-icon [icon]="faTimes" class="close-icon" (click)="onClose()"></fa-icon>
  <div class="login-title">Inicia sesión</div>

  <form (ngSubmit)="onSubmit()" [formGroup]="loginForm">
    <div class="form">
      <input placeholder="Correo electrónico" class="form-input" id="email" type="email" autocomplete="email"
        formControlName="email" />
      <div class="passport-input">
        <input class="passport-hidden" id="password" placeholder="Contraseña" formControlName="password"
          [type]="passwordInputType" autocomplete="current-password" />
        <fa-icon [icon]="faEye" class="eye-icon" (click)="onChangeInputType()"></fa-icon>
      </div>
      <button id="login-button" type="submit">Entrar</button>
    </div>
  </form>

  <div class="recover-button">
    <a (click)="onForgotPassword()" class="transparent-button">Olvidé mi contraseña</a>
  </div>

  <!-- Go to register section-->
  <div class="register-text">¿No tienes una cuenta?
    <a (click)="onGoToSignUp()" class="transparent-button">
      Regístrate
    </a>
  </div>
</div>