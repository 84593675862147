<!-- Custom Modal -->
<div class="modal-background">
    <ngx-loading [show]="loading" [config]="loaderConfig"></ngx-loading>
    <div class="contenerdor-mensaje">
        <div class="header">
            <label class="card-button-return" (click)="onClose()">
                <span aria-hidden="true">&#x2715;</span> Cerrar
            </label>
        </div>
        <br>
        <form [formGroup]="cardForm" (ngSubmit)="onSubmitClick()">
            <div class="container-card">
                <div class="pago-card">
                    <div class="sub-form--payment" formGroupName="newCard">
                        <strong>
                            <h3>Agregar Tarjeta</h3>
                        </strong>
                        <div class="sub-form--payment--name">
                            <label>Nombre propietario de la tarjeta</label>
                            <input class="input--payment--full" id="fullCardName" type="text"
                                formControlName="fullCardName" placeholder="Nombre propietario de la tarjeta" />
                        </div>
                        <div class="sub--form--payment--cc--number">
                            <label>Número de la tarjeta</label>
                            <input class="input--payment--full" id="cc-number" minlength="15" maxlength="19"
                                name="cc-number" type="tel" formControlName="cardNumber" autocomplete="cc-number"
                                placeholder="Número de la tarjeta" />
                        </div>
                        <div class="row">
                            <div class="col-sm-6">
                                <label for="expDate"> Fecha de expiración </label>
                                <input class="input--payment--small" id="expDate" type="tel" formControlName="expDate"
                                    placeholder="05 / 2022" autocomplete="cc-exp" ccExp />
                            </div>
                            <div class="col-sm-6">
                                <label for="cc-cvc"> CVC </label>
                                <input class="input--payment--small" id="cc-cvc" type="tel" autocomplete="off"
                                    placeholder="CVC" formControlName="cvc" ccCVC />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <hr>
            <div class="pay-button-card">
                <label class="card-button-return">
                </label>
                <button class="continue-button">
                    Continuar
                </button>
            </div>
        </form>
    </div>
</div>