import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { Router } from "@angular/router";
import { ActivatedRoute } from "@angular/router";
import {
  faChevronLeft,
  faArrowCircleDown,
} from "@fortawesome/free-solid-svg-icons";
import { MyWarehousesService } from "../services/my-warehouses.service";
import { ReservationAcceptedService } from "../services/reservation-accepted.service";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import { faBoxOpen } from "@fortawesome/free-solid-svg-icons";
import { faCalendarAlt, faMapMarker } from "@fortawesome/free-solid-svg-icons";
import {
  faClock,
  faTimes,
  faPlus,
  faMinus,
  faFile,
  faSignInAlt,
  faUserTag,
  faQuestion,
} from "@fortawesome/free-solid-svg-icons";
import { ModalDismissReasons, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ApiService } from "../services/api.service";
import { UserService } from "../services/user.service";
import {
  NbIconLibraries,
  NbToastrService,
  NbDialogService,
} from "@nebular/theme";
import { ToastrService } from "ngx-toastr";
import { NavigationService } from "../services/navigation.service";
@Component({
  selector: "app-mis-bodegas-detail",
  templateUrl: "./mis-bodegas-detail.component.html",
  styleUrls: ["./mis-bodegas-detail.component.scss"],
})
export class MisBodegasDetailComponent implements OnInit {
  /**API variables */
  SPACES_API = "api/espacio";
  /**Font awesome variables */
  faStar = faStar;
  faQuestion = faQuestion;
  faSignInAlt = faSignInAlt;
  faMapMarker = faMapMarker;
  faUserTag = faUserTag;
  faMapMarkerAlt = faMapMarkerAlt;
  faBoxOpen = faBoxOpen;
  faCalendarAlt = faCalendarAlt;
  faClock = faClock;
  faTimes = faTimes;
  faPlus = faPlus;
  faMinus = faMinus;
  itemsMoved: boolean = true;
  gestionActive: boolean = false;
  products = [];
  faFile = faFile;
  faChevronLeft = faChevronLeft;
  warehouse: any = [];
  warehouseName: any = "";
  stockElements: any = [];
  stockElementTable: any = [];
  movementsArray: any = [];
  faArrowCircleDown = faArrowCircleDown;
  stock: boolean = true;
  movements: boolean = false;
  spaces: boolean = false;
  fecha: any = "";
  cliente: any = "";
  stadistics: boolean = false;
  titleMovement: any = "";
  idSpace: any = "";
  information: boolean = false;
  active: boolean = false;
  movementID = "";
  numberOfPostions: any = "";
  SPACE_API = "api/espacio";
  public spacesArray: any = {};
  public reserve: any = {};
  public settingsInventory: any = {};
  public settingsSpaces: any = {};
  public settingsMovementsModal: any = {};
  public sourceInventory: any;
  public settingsMovements: any = {};
  public movementsSource: any = [];
  public address: any = "";
  ingresos: any = 0;
  retiros: any = 0;
  closeResult = "";
  percentage: any = "";
  public sourceFlows: any;

  constructor(
    private router: Router,
    private myWarehousesService: MyWarehousesService,
    private activatedRoute: ActivatedRoute,
    private modalService: NgbModal,
    private apiService: ApiService,
    private UserService: UserService,
    private reservationService: ReservationAcceptedService,
    private iconLibraries: NbIconLibraries,
    private toastr: ToastrService,
    public navigationService: NavigationService
  ) {
    this.iconLibraries.registerFontPack("font-awesome", {
      iconClassPrefix: "fa",
      packClass: "fas",
    });
    let id = this.activatedRoute.snapshot.params["id"];
    this.getWarehouses({ id: id });
    // this.getSpace({id:id})
    this.initReserve({ id: id });
    this.getSpacesByWarehouseId({ id: id });
    // this.initInventoryAndFlows({id: id});
    this.getStockByWarehouseId({ id: id });
    this.getMovementsByWarehouseId({ id: id });
    this.configInvetorySmartTable();
    this.countNumberOfPositions({ id: id });
    this.configInvetorySmartTableSpaces();
    this.configMovementsSmartTable();
    this.configMovements();
  }

  ngOnInit(): void {}
  @ViewChild("movement", { static: false }) movement: ElementRef;
  private async initReserve({ id }: { id: string }): Promise<void> {
    this.reserve = await this.reservationService.getReserve({ id: id });
  }
  @ViewChild("approve", { static: false }) approve: ElementRef;
  @ViewChild("deactivate", { static: false }) deactivate: ElementRef;
  /**
   * Handles closing the modals with different options
   * @param reason how I am closing the modals
   */
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }
  /**
   * Handles  oppening a modal
   * @param content Modal
   */
  open(content) {
    this.modalService
      .open(content, { size: "lg", ariaLabelledBy: "modal-basic-title" })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }
  async countNumberOfPositions({ id: id }) {
    let response = await this.myWarehousesService.getNumberOfPositions({ id });
    let numberOfPostions = response.numberOfPositions;
    let totalPositionsWarehouse = this.warehouse.cantidadTotalPosiciones;
    this.percentage =
      totalPositionsWarehouse === 0
        ? 0
        : Math.round((numberOfPostions / totalPositionsWarehouse) * 100);
  }
  /**
   * Handles going back to a specific route
   */
  public onGoingBack() {
    this.router.navigate(["mis-bodegas"]);
  }
  /**Gets warehouses */
  async getWarehouses({ id }: { id: string }) {
    this.warehouse = await this.myWarehousesService.getWarehousesById({
      id: id,
    });
  }
  /**Gets stock by warehouse id */
  async getStockByWarehouseId({ id }: { id: string }) {
    this.stockElements = await this.myWarehousesService.getStockByWarehouseId({
      id: id,
    });
    let stock: any = [];
    for (let i = 0; i < this.stockElements.length; i++) {
      const element = this.stockElements[i];
      stock.push({
        producto:
          element.producto != undefined ? element.producto.nombre : "None",
        unidad:
          element.producto != undefined ? element.producto.unidad : "None",
        cantidad: element.producto != undefined ? element.cantidad : "None",
        sku: element.producto != undefined ? element.producto.sku : "None",
        cliente:
          element.producto.usuario.nombre != undefined
            ? element.producto.usuario.nombre
            : "None",
        ubicacionNombre:
          element.espacio != undefined ? element.espacio.nombre : "None",
      });
    }
    this.stockElementTable = stock;
  }
  /**Handles the click on movimientos */
  public OnMovements() {
    this.stock = false;
    this.movements = true;
    this.stadistics = false;
    this.information = false;
    this.spaces = false;
  }

  /**Handles getting the object from the backend and preparing it for the smart-table config */
  async getSpacesByWarehouseId({ id }: { id }) {
    this.spacesArray = await this.reservationService.getSpacesByWarehouseId({
      id: id,
    });
    let stock: any = [];
    for (let i = 0; i < this.spacesArray.length; i++) {
      const element = this.spacesArray[i];
      stock.push({
        id: element._id,
        nombre: element != undefined ? element.nombre : "None",
        tipo: element != undefined ? element.tipo : "None",
        techo: element.cubierta ? "Cubierta" : "Descubierta",
        cantidad: element.cantidad,
        costo: element != undefined ? `$${element.costo}` : "None",
        alto: element != undefined ? `${element.alto} mt` : "None",
        peso: `${element.peso} Ton`,
        estado: element.estado != undefined ? element.estado : "NA",
      });
    }
    this.spacesArray = stock;
  }
  /**
   * This method gets all the movements in a warehouse with its id
   * @param param0 warehouse id
   */
  async getMovementsByWarehouseId({ id }: { id: string }) {
    this.movementsArray = await this.UserService.getMovementsByWarehouseId({
      id: id,
    });
    let movements: any = [];
    for (let i = 0; i < this.movementsArray.length; i++) {
      const element = this.movementsArray[i];
      movements.push({
        id: element._id != undefined ? element._id : "None",
        estado:
          element.estado != undefined
            ? element.estado.charAt(0).toUpperCase() + element.estado.slice(1)
            : "None",
        fechaCreacion:
          element.createdAt != undefined
            ? element.createdAt.substr(0, 10)
            : "None",
        fechaProgramada:
          element.createdAt != undefined
            ? element.createdAt.substr(0, 10)
            : "None",
        cantidadDeProductos:
          element.productos.length > 0 ? element.productos.length : "0",
        cliente:
          element.reserva != undefined
            ? element.reserva.propietario.nombre
            : "None",
        reserva: element.reserva != undefined ? element.reserva._id : "None",
        tipo: element.direccionDestino
          ? "Orden de Retiro"
          : "Movimiento agregar",
        direccionDestino: element.direccionDestino
          ? element.direccionDestino
          : "NA",
      });
      if (element.direccionDestino) {
        this.ingresos++;
      } else {
        this.retiros++;
      }
    }
    this.movementsSource = movements;
  }
  /**Requests both orden de retiro and movimiento agregar  */
  public async requestMovement({ id, event }) {
    let movementPromise;
    if (event.data.tipo == "Movimiento agregar") {
      this.address = "";
      this.titleMovement = "Entrada de mercancía";
      movementPromise = await this.reservationService
        .getMovement({ id: id })
        .catch((err) => {
          return err;
        });
    } else {
      this.titleMovement = "Salida de mercancía";
      this.address = event.data.direccionDestino;
      movementPromise = await this.reservationService
        .getRemovebyId({ id: id })
        .catch((err) => {
          return err;
        });
    }
    if (movementPromise && movementPromise.stack && movementPromise.message) {
      console.error("Error");
    }
    for (let i = 0; i < movementPromise.productos.length; i++) {
      this.products.push(movementPromise.productos[i].producto);
      this.products[i].cantidad = movementPromise.productos[i].cantidad;
      this.products[i].ubicacion = movementPromise.productos[i].espacio.nombre;
    }
  }
  /**Handles opening the details of the movements */
  public async onCustom(event: any): Promise<void> {
    if (event.action === "watch") {
      this.movementID = event.data.id;
      this.cliente = event.data.cliente;
      this.fecha = event.data.fechaCreacion;
      this.products = [];
      await this.requestMovement({ id: event.data.id, event: event });
      this.open(this.movement);
    }
  }

  /**Configures the table for the movements table */
  private configMovementsSmartTable(): void {
    this.settingsMovements = {
      mode: "inline",
      pager: {
        display: true,
        perPage: 10,
      },
      hideSubHeader: false,
      actions: {
        columnWidth: "50px",
        columnTitle: "Acciones",
        add: false,
        edit: false,
        delete: false,
        custom: [
          {
            name: "watch",
            title: '<i class="fa fa-eye"></i>',
          },
        ],
      },
      columns: {
        // id: {
        //   title: "Id del movimiento"
        // },
        estado: {
          title: "Estado",
        },
        fechaCreacion: {
          title: "Fecha de creación",
        },
        fechaProgramada: {
          title: "Fecha programada",
        },
        cantidadDeProductos: {
          title: "Cantidad de productos",
        },
        cliente: {
          title: "Cliente",
        },
        tipo: {
          title: "Tipo de movimiento",
        },
      },
      rowClassFunction: (row) => {
        if (row.data.estado == "aprobado") {
          //aprobado
          return "active";
        }
      },
      noDataMessage: "No hay movimientos para mostrar",
    };
  }
  /**Handles the click on movimientos */
  public OnStock() {
    this.stock = true;
    this.movements = false;
    this.stadistics = false;
    this.information = false;
    this.spaces = false;
  }
  /**Handles the click on movimientos */
  public OnSpaces() {
    this.stock = false;
    this.movements = false;
    this.stadistics = false;
    this.information = false;
    this.spaces = true;
  }
  /**Handles the click on movimientos */
  public OnStadistics() {
    this.stock = false;
    this.movements = false;
    this.stadistics = true;
    this.information = false;
    this.spaces = false;
  }
  /**Handles the click on movimientos */
  public OnInformation() {
    this.stock = false;
    this.movements = false;
    this.stadistics = false;
    this.spaces = false;
    this.information = true;
  }
  private configMovements(): void {
    this.settingsMovementsModal = {
      mode: "inline",
      pager: {
        display: true,
        perPage: 10,
      },
      hideSubHeader: false,
      actions: false,
      columns: {
        nombre: {
          title: "Producto",
        },
        cantidad: {
          title: "Items a mover",
        },
        sku: {
          title: "sku",
        },
        ubicacion: {
          title: "Ubicación",
        },
      },
      // rowClassFunction: (row) => {
      //   if (row.data.estado == "aprobado") {
      //     //aprobado
      //     return "active";
      //   }
      // },
      noDataMessage: "No hay movimientos",
    };
  }

  /**Handles setting up the stock and the smart tableqq */
  private configInvetorySmartTable(): void {
    this.settingsInventory = {
      mode: "inline",
      pager: {
        display: true,
        perPage: 10,
      },
      hideSubHeader: false,
      actions: false,
      columns: {
        producto: {
          title: "Producto",
        },
        unidad: {
          title: "Tipo de Unidad",
        },
        cantidad: {
          title: "Cantidad",
        },
        sku: {
          title: "SKU Producto",
        },
        ubicacionNombre: {
          title: "Ubicación",
        },
        cliente: {
          title: "Cliente",
        },
      },
      rowClassFunction: (row) => {
        if (row.data.estado == "aprobado") {
          //aprobado
          return "active";
        }
      },
      noDataMessage: "El inventario está vacío",
    };
  }
  /**Handles setting up the stock and the smart table */
  private configInvetorySmartTableSpaces(): void {
    this.settingsSpaces = {
      mode: "inline",
      pager: {
        display: true,
        perPage: 10,
      },
      hideSubHeader: false,
      actions: {
        columnWidth: "50px",
        columnTitle: "Acción",
        add: false,
        filter: false,
        edit: false,
        delete: false,
        position: "left",
        custom: [
          {
            name: "activate",
            title: '<i class="fa fa-toggle-off"></i>',
          },
        ],
      },
      columns: {
        nombre: {
          title: "Nombre",
        },
        tipo: {
          title: "Tipo",
        },
        techo: {
          title: "Techo",
        },
        cantidad: {
          title: "Posiciones/m2",
        },
        costo: {
          title: "Precio/día",
        },
        alto: {
          title: "Altura max",
        },
        peso: {
          title: "Peso máximo",
        },
        estado: {
          title: "Estado",
        },
      },
      rowClassFunction: (row) => {
        if (row.data.estado == "activo") {
          //activo
          this.active = true;
          return "active";
        }
      },
      noDataMessage: "Esta bodega no tiene espacios",
    };
  }
  /**Activate otr deactivate a space */
  public onCustomSpaces(event: any) {
    this.idSpace = event.data.id;
    if (event.action === "activate" && event.data.estado != "activo") {
      this.onApproveSpace();
    } else {
      this.onDeactivate();
    }
  }
  public onDeactivate() {
    this.open(this.deactivate);
  }
  public onApproveSpace() {
    this.open(this.approve);
  }
  public onItems() {
    this.itemsMoved = true;
    this.gestionActive = false;
  }
  public onGestion() {
    this.itemsMoved = false;
    this.gestionActive = true;
  }
  /**
   * Method to uptdate the space status
   * @param event Event generated by the tyable
   */
  onDeactivateSpace(): void {
    const space: any = {
      estado: "inactivo",
    };
    this.apiService
      .put({
        api: `${this.SPACES_API}/${this.idSpace}`,
        data: space,
      })
      .subscribe(
        (response) => {
          this.modalService.dismissAll();
          this.reloadCurrentRoute();
          this.showToastr({
            title: "Se ha desactivado el espacio de manera exitosa",
          });
        },
        (error) =>
          this.showToastr({
            title: "Un error ha ocurrido contactanos",
          })
      );
  }
  /**
   * Method to uptdate the space status
   * @param event Event generated by the tyable
   */
  onActivateSpace(): void {
    const space: any = {
      estado: "activo",
    };
    this.apiService
      .put({
        api: `${this.SPACES_API}/${this.idSpace}`,
        data: space,
      })
      .subscribe(
        (response) => {
          this.modalService.dismissAll();
          this.reloadCurrentRoute();
          this.showToastr({
            title: "Se ha activado el espacio de manera exitosa",
          });
        },
        (error) =>
          this.showToastr({
            title: "Un error ha ocurrido contactanos",
          })
      );
  }
  public showToastr({
    position,
    title,
    message,
  }: {
    position?: any;
    title: string;
    message?: string;
  }): void {
    let realPosition = position ? position : "top-end";
    let realMessage = message ? message : "";
    let duractionMilisec = 4500;
    this.toastr.show(`${realMessage}`, `${title}`, {
      positionClass: "toast-bottom-right",
      timeOut: duractionMilisec,
    });
  }
  public reloadCurrentRoute() {
    let currentUrl = this.router.url;
    this.router.navigateByUrl("/", { skipLocationChange: true }).then(() => {
      this.router.navigate([currentUrl]);
    });
  }
  public onEdit() {
    let id = this.activatedRoute.snapshot.params["id"];
    this.navigationService.navigateTo({ path: `editar-bodega/${id}` });
  }
}
