import { Component, OnInit } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import {
  faEye,
  faTimes,
  faPallet,
  faBox,
  faParachuteBox,
  faTruckLoading,
  faPeopleCarry,
} from "@fortawesome/free-solid-svg-icons";
import { ApiService } from "src/app/public/services/api.service";

@Component({
  selector: "app-calcula-espacio",
  templateUrl: "./calcula-espacio.component.html",
  styleUrls: ["./calcula-espacio.component.scss"],
})
export class CalculaEspacioComponent implements OnInit {
  UNIDAD_API = "api/unidad";
  unidades: any = [];
  /**
   * Font awesome variables
   */
  faPallet = faPallet;
  faBox = faBox;
  faTruckLoading = faTruckLoading;
  faParachuteBox = faParachuteBox;
  faPeopleCarry = faPeopleCarry;
  logos = [
    { icono: faPallet, style: "pallet" },
    { icono: faParachuteBox, style: "pequena" },
    { icono: faBox, style: "mediana" },
    { icono: faTruckLoading, style: "grande" },
  ];
  faEye = faEye;
  faTimes = faTimes;
  cantidad: any = "";
  peso: any = "";
  name = null;
  namePrinted: any = "";
  volumen: any = {};
  info: boolean = false;
  result: boolean = false;
  resultado: any = "";
  resultPositions: any = "";
  resultadoString: string = "";
  constructor(
    public dialogRef: MatDialogRef<CalculaEspacioComponent>,
    private apiService: ApiService
  ) {
    this.getUnidades();
  }

  ngOnInit(): void {}
  /**
   * Handles the closing of the dialog   */
  onClose(): void {
    this.dialogRef.close();
  }
  /**
   * Get unidades from the API
   */
  public getUnidades() {
    this.apiService.get({ api: this.UNIDAD_API }).subscribe((unidad) => {
      this.unidades = unidad;
    });
  }
  /**On change select */
  public OnChangeSelect(event) {
    this.info = true;
  }
  /**
   * Calculates the space of an object
   */
  public OncalculateSpace() {
    if ((this.cantidad || this.peso) > 4000) {
      alert("Los valores ingresados de cantidad o peso no son validos");
    } else {
      this.namePrinted = this.name;
      this.volumen = this.getVolumen(this.namePrinted);
      this.resultado = this.cantidad * this.volumen.volumen;
      this.resultadoString = this.resultado.toFixed(2).toString();
      this.resultPositions = this.onCalculatePositions({
        volumen: this.resultado,
      });
      this.result = true;
    }
  }
  /**Calculate the # of pallets that the element occupies */
  public onCalculatePositions({ volumen }) {
    let palet = this.getVolumen("Palet");
    let volumentPalet = palet.volumen;
    let result = volumen / volumentPalet;
    let resultAprox = Math.round(result) === 0 ? 1 : Math.round(result);
    return resultAprox;
  }
  /**
   *
   *
   * @param nombre get the volume according  to the name
   * @returns
   */
  public getVolumen(nombre) {
    return this.unidades.find((item) => nombre === item.nombre);
  }
  /**
   * Clean
   */
  public OnlimpiarResult() {
    this.peso = "";
    this.cantidad = "";
    this.result = false;
  }
  public onQuantity(event) {
    if (event.target.value > 4000) {
      alert("Se está superando el número maxímo");
    }
  }
}
