<div class="date-button" (click)="onClickDatePicker(d, $event)">
    <div class="label-content">
        <label class="label-date-button">
            
        </label>
        <input class="date-input" placeholder={{dateInput}} [(ngModel)]="date" (dateSelect)="dateSelected($event)" ngbDatepicker #d="ngbDatepicker">
    </div>
    <div class="icon-content">
        <div class="img-active icon-calendar"></div>
    </div>
</div>