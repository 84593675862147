import { Component, OnInit, ViewChild, ElementRef, NgZone } from "@angular/core";
import { Router } from "@angular/router";
import { ActivatedRoute } from "@angular/router";
import { faChevronLeft, faEdit } from "@fortawesome/free-solid-svg-icons";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { MyWarehousesService } from "../public/services/my-warehouses.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Location } from "@angular/common";
import { MapsService } from "../public/services/maps.service";
import { S3Service } from "../public/services/s3.service";
import { MatDialog } from "@angular/material/dialog";
import { SuccessComponent } from "../public/shared/success/success.component";
import { NavigationService } from "../public/services/navigation.service";
import { ApiService } from "../public/services/api.service";
import * as moment from 'moment';
@Component({
  selector: "app-edit-warehouse",
  templateUrl: "./edit-warehouse.component.html",
  styleUrls: ["./edit-warehouse.component.scss"]
})
export class EditWarehouseComponent implements OnInit {
  /** FONT AWESOME icons */
  faTimes = faTimes;
  faChevronLeft = faChevronLeft;
  faEdit = faEdit;
  /** Constant that holds the titles of the steps */
  readonly STEPS_TITLES = [
    "Información básica",
    "Información Genaral",
  ];
  /** Constant that holds the total number of steps */
  readonly TOTAL_STEPS = 2;

  /** Variable that holds the current step */
  currentStep = 1;
  /** Variable that holds the warehouse form (step 1)*/
  wareHouseForm: FormGroup;

  readonly SPACE_API = "api/espacio";
  public spaces = [];
  public today: any;

  /** Variable that holds the schedule form (step 3) */
  scheduleForm: FormGroup;

  /** Variables for the specifications form (step 4) */
  /** List for the accepted products */
  acceptedProducts = [];
  /** List for the security specifications*/
  securityItems = [];
  /* List for the certificates of the warehouse */
  certificates = [];
  /** Signals the current progress of the submission */
  currentProgress = 0;
  /** Variables for the photos form (step 8) */
  photos = [];

  /**  Variables for the services form (step 5) */
  /** List of services that the warehouse provides */
  serviceList = [];
  /**Regular variables */
  warehouse: any = [];
  /** Signals if a submission is in progress */
  loading = false;
  kilogramHandling: any = "";
  horario1: any = "";
  horario2: any = "";
  readonly WAREHOUSE_API = "api/bodega/";

  constructor(
    private myWarehousesService: MyWarehousesService,
    private activatedRoute: ActivatedRoute,
    private apiService: ApiService,
    private location: Location,
    private formBuilder: FormBuilder,
    private s3Service: S3Service,
    private dialog: MatDialog,
    private navigationService: NavigationService,
    private ngZone: NgZone
  ) {
    this.today = moment().format('YYYY-MM-DD');
  }

  async ngOnInit() {
    let id = this.activatedRoute.snapshot.params["id"];
    this.initializeWarehouseForm();

    this.apiService.get({ api: `${this.WAREHOUSE_API}${id}` }).subscribe(async res => {
      this.warehouse = res;
      await this.getWarehouses();
      this.wareHouseForm = this.formBuilder.group({
        nombre: [this.warehouse.nombre, Validators.required],
        descripcion: [this.warehouse.descripcion, Validators.required],
        kilogramHandling: [this.warehouse.costoKilogramo, Validators.required],
        contacto: this.formBuilder.group({
          nombre: [this.warehouse.contacto.nombre, Validators.required],
          apellido: [this.warehouse.contacto.apellido, Validators.required],
          telefono: [this.warehouse.contacto.telefono, Validators.required],
          correo: [this.warehouse.contacto.correo, Validators.required]
        })
      });
      // Form for getting schedule related data
      this.scheduleForm = this.formBuilder.group({
        horaInicio: [this.horario1, Validators.required],
        horaFin: [this.horario2, Validators.required]
      });
      this.getOthers();
      this.getSpaces();
    });
  }

  initializeWarehouseForm() {
    this.wareHouseForm = this.formBuilder.group({
      nombre: ['', Validators.required],
      descripcion: ['', Validators.required],
      kilogramHandling: ['', Validators.required],
      contacto: this.formBuilder.group({
        nombre: ['', Validators.required],
        apellido: ['', Validators.required],
        telefono: ['', Validators.required],
        correo: ['', Validators.required]
      })
    });
  }

  /**
   * Handles the delete event of a photo in the
   * @param index index of the photo to delete
   */
  onDeletePhoto({ index }: { index: number }): void {
    this.photos.splice(index, 1);
  }

  /**Gets warehouses */
  async getWarehouses() {
    for (let i = 0; i < this.warehouse.fotos.length; i++) {
      let element = this.warehouse.fotos[i];
      let photo = {
        file: undefined,
        preview: element
      };
      this.photos.push(photo);

    }
    let horas = this.warehouse.horario.split("-");
    this.horario1 = horas[0];
    this.horario2 = horas[1];
  }

  /**
   * Handles the click on the cancel button
   */
  onCancel(): void {
    this.location.back();
  }

  /**
   * Retrieves the 'others' elements and sets them up in the form
   */
  getOthers(): void {
    if (this.warehouse) {
      this.warehouse.otros.forEach(e => {
        if (e?.fechaVencimiento) {
          // e.fechaVencimiento = moment(e.fechaVencimiento).format('YYYY-MM-DD');
          this.certificates.push(e)
        };
      });
    }
  }

  /**
   * Handles getting all the spaces from the api
   */
  public getSpaces() {
    this.apiService.get({ api: `${this.SPACE_API}/bodega/${this.warehouse._id}` }).subscribe((spaces) => {
      this.spaces = spaces;
    });
  }

  /**
   * Handles the click on the previous step button
   */
  onPreviousStep(): void {
    if (this.currentStep - 1 >= 1) {
      this.currentStep--;
    }
  }
  /**
   * Handles the click on the previous step button
   */
  onNextStep(): void {
    if (this.currentStep >= 1) {
      this.currentStep++;
    }
  }


  /**
   * Handles the upload of documents
   */
  onUploadDocument({ type, event, name }: { type: string; event: any; name?: string }): void {
    if (type == "Photo") {
      let photo = {
        file: undefined,
        preview: undefined
      };
      const reader = new FileReader();
      // Read the photo, store it and create a preview
      if (event.target.files && event.target.files.length) {
        photo.file = event.target.files[0];
        reader.readAsDataURL(photo.file);
        reader.onload = () => {
          photo.preview = reader.result;
          const arrayCopy = this.photos.slice();
          arrayCopy.push(photo);
          this.photos = arrayCopy;
        };
      }
    }
    // If the uploaded element is a photo add it to the photos array
  }

  async onSaveClick({ transactionType }: { transactionType: string }): Promise<any> {
    this.loading = true;
    let id = this.activatedRoute.snapshot.params["id"];
    this.currentProgress += 33;
    const uploads = [];
    const photoList = [];
    let randomKey = this.s3Service.generateKey();
    const warehouseData = this.wareHouseForm.value;
    const scheduleData = this.scheduleForm.value;

    /**Uploading de fotos */
    for (const photo of this.photos) {
      randomKey = this.s3Service.generateKey();
      if (photo.file) {
        uploads.push({
          file: photo.file,
          key: `warehouse/photos/${photo.file.name}/${randomKey}`
        });
      } else {
        photoList.push(photo.preview);
      }
    }
    let uploadResults;
    if (uploads.length > 0) {
      uploadResults = await this.s3Service.uploadBatch({ list: uploads });
      for (const result of uploadResults) {
        if (result.key.includes("photos")) {
          photoList.push(result.Location);
        }
      }
    }
    let warehouse = {
      nombre: warehouseData.nombre,
      descripcion: warehouseData.descripcion,
      horario: scheduleData.horaInicio + "-" + scheduleData.horaFin,
      contacto: warehouseData.contacto,
      costoKilogramo: warehouseData.kilogramHandling,
      fotos: photoList,
      otros: []
    };
    warehouse.otros = await this.setNewCertificates();
    await this.updateSpaces();
    this.currentProgress += 33;

    this.myWarehousesService.putWarehouse({ id: id, data: warehouse }).then(() => {
      this.currentProgress += 33;
      this.dialog.open(SuccessComponent, {
        data: {
          title: "¡Genial!",
          message: "Tu bodega se ha actualizado exitosamente",
          onOk: () => {
            this.currentProgress += 33;
            this.navigationService.navigateTo({ path: "mis-bodegas" });
          }
        },
        disableClose: true
      });
    }).catch((err) => {
      alert(err.error.mensaje);
      this.loading = false;
    });
  }

  public setNewCertificates() {
    return new Promise<any[]>(resolve => {
      let otros = [];
      if (this.certificates.length > 0) {
        this.certificates.forEach(c => {
          let i = this.warehouse.otros.findIndex(e => e.otro._id == c._id)
          c.fechaVencimiento = moment(c.otro.fechaVencimiento);
          this.warehouse.otros[i] = c;
        })
      }
      otros = this.warehouse.otros;
      resolve(otros);
    });
  }

  public updateSpaces() {
    return new Promise(success => {
      this.apiService.post({ api: `${this.SPACE_API}/update_cost`, data: this.spaces }).subscribe((spaces) => {
        success(spaces);
      });
    })
  }
}