import { HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";
import { AuthService } from "./auth.service";

@Injectable({
  providedIn: "root"
})
export class MyWarehousesService {
  readonly WAREHOUSE_API = "api/bodega/";
  readonly WAREHOUSE_BY_USER_API = "api/bodega/user/";
  readonly STOCK_WAREHOUSE_ID_API = "api/inventario/bodega/";
  readonly RESERVAS_API = "api/reserva/";
  readonly OTROS_API = "api/otro/";
  readonly LOCATION_API = "api/localizacion/";
  readonly ORDERS_API = "api/ordenRetiro";
  readonly USER_ID = "";
  readonly API_RESERVAS_PAST = "api/reserva/propietarioPagadas/";
  readonly API_COUNT_POSITIONS = "api/bodega/positions/";
  readonly API_COUNT_POSITIONS_WAREHOUSES = "api/bodega/positionsByUser/";
  // Variables to pass data into the components.
  // This is a warehouse to complate progress
  public warehouseInProgress: any;

  constructor(private apiService: ApiService, private authService: AuthService) {
    let user = authService.getUser();
    if (user) {
      this.USER_ID = user._id;
    }
  }

  /**
   * It is in charge of calling the api service to make the get request with parameters to obtain the warehouses.
   * @param param0 Receives a string that can be approved, rejected, pending or in progress, which is the status of the warehouse.
   * @returns The warehouses obtained from the back
   */
  public getWarehouses({ value, page, size, all }: { value: string; page: number; size: number; all: string }): Promise<any> {
    let params = new HttpParams();
    params = params.append("estado", value);
    params = params.append("page", page + "");
    params = params.append("size", size + "");
    params = params.append("all", all);
    let user = this.authService.getUser();
    let id;
    if (user) {
      id = user._id;
    }
    return this.apiService.getWithParams({ api: `${this.WAREHOUSE_BY_USER_API}${id}`, queryParams: params }).toPromise();
  }

  /**
   * It is in charge of calling the api service to make the get request with parameters to obtain the warehouses.
   * @param param0 Receives a string that can be approved, rejected, pending or in progress, which is the status of the warehouse.
   * @returns The warehouses obtained from the back
   */
  public deleteWarehouse({ id }: { id: string }): Promise<any> {
    return this.apiService.deleteAsPromise({ api: `${this.WAREHOUSE_API}${id}` });
  }
  public deleteLocation({ id }: { id: string }): Promise<any> {
    return this.apiService.deleteAsPromise({ api: `${this.LOCATION_API}${id}` });
  }
  public getWarehousesById({ id }: { id: string }): Promise<any> {
    return this.apiService.getAsPromise({ api: `${this.WAREHOUSE_API}${id}` });
  }
  public getNumberOfPositionsByUserId({ id }: { id: string }): Promise<any> {
    return this.apiService.getAsPromise({ api: `${this.API_COUNT_POSITIONS_WAREHOUSES}${id}` });
  }
  public getReservationByUserId({ id }: { id: string }): Promise<any> {
    return this.apiService.getAsPromise({ api: `${this.API_RESERVAS_PAST}${id}` });
  }
  public getStockByWarehouseId({ id }: { id: string }): Promise<any> {
    return this.apiService.getAsPromise({ api: `${this.STOCK_WAREHOUSE_ID_API}${id}` });
  }
  public getReservations(): Promise<any> {
    return this.apiService.getAsPromise({ api: `${this.RESERVAS_API}` });
  }
  public getReservationById({ id }: { id: string }): Promise<any> {
    return this.apiService.getAsPromise({ api: `${this.RESERVAS_API}${id}` });
  }
  public getOtros(): Promise<any> {
    return this.apiService.getAsPromise({ api: `${this.OTROS_API}` });
  }
  public getOtrosByTipo({ tipo }: { tipo: string }): Promise<any> {
    return this.apiService.getAsPromise({ api: `${this.OTROS_API}tipo/${tipo}` });
  }
  public getLocations(): Promise<any> {
    return this.apiService.getAsPromise({ api: `${this.LOCATION_API}` });
  }
  public getOrders(): Promise<any> {
    return this.apiService.getAsPromise({ api: `${this.ORDERS_API}` });
  }
  public getNumberOfPositions({ id }: { id: string }): Promise<any> {
    return this.apiService.getAsPromise({ api: `${this.API_COUNT_POSITIONS}${id}` });
  }

  public postWarehouse({ data }: { data: any }): Promise<any> {
    return this.apiService.postPromise({ api: `${this.WAREHOUSE_API}`, data: data });
  }
  public putWarehouse({ id, data }: { id: any, data: any }): Promise<any> {
    return this.apiService.putPromise({ api: `${this.WAREHOUSE_API}${id}`, data: data });
  }
  public putRetiro({ id, data }: { id: any, data: any }): Promise<any> {
    return this.apiService.putPromise({ api: `${this.WAREHOUSE_API}${id}`, data: data });
  }
  public putAgregar({ id, data }: { id: any, data: any }): Promise<any> {
    return this.apiService.putPromise({ api: `${this.WAREHOUSE_API}${id}`, data: data });
  }
  public postLocation({ data }: { data: any }): Promise<any> {
    return this.apiService.postPromise({ api: `${this.LOCATION_API}`, data: data });
  }
}
