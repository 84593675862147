<div class="main">
  <div class="main--header">
    <div class="main--header--arrow--back">
      <button (click)="onGoingBack()" id="back-button">
        <fa-icon (click)="onGoingBack()" [icon]="faChevronLeft"></fa-icon>
        Volver atrás
      </button>
    </div>
  </div>
  <div class="main--main">
    <div class="main--main--warehouse--name">
      <h1 class="title-main">Espacio reservado {{ warehouse.nombre }}</h1>
    </div>
    <div class="main--main--warehouse--statistics">
      <div class="main--main--warehouse--statistics--pie--chart">
        <circle-progress
          [percent]="percentage > 100 ? 100 : percentage"
          class="percentage-circle-progress"
        ></circle-progress>
      </div>
      <div class="main--main--warehouse--statistics--entries">
        <div class="main--main--square">
          <img
            src="../../../assets/Utils/flecha-abajo.png"
            class="upload-image"
          />
        </div>
        <div class="main-main--info">
          <p class="title-main">{{ ingresos }}</p>
          <p>Ingresos de mercancía</p>
        </div>
      </div>
      <div class="main--main--warehouse--statistics--exits">
        <div class="main--main--square">
          <img
            src="../../../assets/Utils/flecha-diagonal.png"
            class="upload-image"
          />
        </div>
        <div class="main-main--info">
          <p class="title-main">{{ retiros }}</p>
          <p>Retiros de mercancía</p>
        </div>
      </div>
    </div>
  </div>
  <div class="main--tables">
    <div class="main--tables--navbar">
      <button [ngClass]="{ enabled: stock }" (click)="OnStock()" [class.active]>
        Inventario
      </button>
      <button
        [ngClass]="{ enabled: movements }"
        (click)="OnMovements()"
        [class.active]
      >
        Movimientos
      </button>
      <button
        [ngClass]="{ enabled: spaces }"
        (click)="OnSpaces()"
        [class.active]
      >
        Espacios
      </button>
      <!-- <button [ngClass]="{enabled: stadistics}" (click)="OnStadistics()" [class.active]>Estadísticas</button> -->
      <button
        [ngClass]="{ enabled: information }"
        (click)="OnInformation()"
        [class.active]
      >
        Info. general
      </button>
      <button
        class="checkout--button space-left-button"
        (click)="onEdit()"
        *ngIf="information"
      >
        Editar bodega
      </button>
    </div>
    <div
      *ngIf="stock"
      class="reserva_main--left--servicios--adicionales--table"
    >
      <ng2-smart-table
        [settings]="settingsInventory"
        [source]="stockElementTable"
        (custom)="onCustom($event)"
      >
      </ng2-smart-table>
    </div>
    <div
      *ngIf="spaces"
      class="reserva_main--left--servicios--adicionales--table"
    >
      <ng2-smart-table
        [settings]="settingsSpaces"
        [source]="spacesArray"
        (custom)="onCustomSpaces($event)"
      >
      </ng2-smart-table>
    </div>
    <div
      *ngIf="movements"
      class="reserva_main--left--servicios--adicionales--table"
    >
      <ng2-smart-table
        [settings]="settingsMovements"
        [source]="movementsSource"
        (custom)="onCustom($event)"
      >
      </ng2-smart-table>
    </div>
    <!-- Informacion de la bodega -->
    <div class="main--tables--information" *ngIf="information">
      <div class="main--tables--information--left">
        <div class="main__bodegas--left">
          <div class="main__bodegas--left--flex">
            <div class="main__bodegas--left--header">
              <h1 class="warehouse--title">{{ warehouse.nombre }}</h1>
              <div class="main__bodegas--left--header--subtitle">
                <div class="main__bodegas--left--header-subtitle--address">
                  <fa-icon [icon]="faMapMarker"></fa-icon>
                  <p>
                    {{
                      warehouse.direccion
                        ? warehouse.direccion.replace("undefined", "")
                        : "Calle 2b #36-26"
                    }}
                    - {{ warehouse.ciudad }},{{ warehouse.pais }}
                  </p>
                </div>
                <!-- <div class="main__bodegas--left--header-subtitle--rating">
                  <fa-icon [icon]="faStar"></fa-icon>
                  <p class="calificacion">{{ warehouse.calificacion }}</p>
                </div> -->
              </div>
              <p>{{ warehouse.descripcion }}</p>
            </div>
            <div class="main__bodegas--left--main">
              <div class="main__bodegas--left--main--info">
                <div class="main__bodegas--left--main--info--icon">
                  <div class="main__bodegas--left--main--info--icon--square">
                    <fa-icon class="icon-open" [icon]="faBoxOpen"></fa-icon>
                  </div>
                  <div class="main__bodegas--left--main--info--icon--info">
                    <h2>Tamaño:</h2>
                    <p>{{ warehouse.tamano }} m2</p>
                  </div>
                </div>
                <div class="main__bodegas--left--main--info--icon">
                  <div class="main__bodegas--left--main--info--icon--square">
                    <fa-icon [icon]="faCalendarAlt"></fa-icon>
                  </div>
                  <div class="main__bodegas--left--main--info--icon--info">
                    <h2>Días:</h2>
                    <p>{{ warehouse.dias }}</p>
                  </div>
                </div>
                <div class="main__bodegas--left--main--info--icon">
                  <div class="main__bodegas--left--main--info--icon--square">
                    <fa-icon [icon]="faClock"></fa-icon>
                  </div>
                  <div class="main__bodegas--left--main--info--icon--info">
                    <h2>Horario:</h2>
                    <p class="info">{{ warehouse.horario }} pm</p>
                  </div>
                </div>
              </div>
              <div class="main__bodegas--left--main--images">
                <div class="main__bodegas--left--main--images--title">
                  <p>Imágenes</p>
                  <button (click)="open(fotos)" href="#">Ver más</button>
                </div>
                <ng-template #fotos let-modal>
                  <div class="modal-header">
                    <button
                      type="button"
                      class="close"
                      aria-label="Close"
                      (click)="modal.dismiss('Cross click')"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div class="modal-body">
                    <ngb-carousel>
                      <ng-template
                        *ngFor="let foto of warehouse.fotos"
                        ngbSlide
                      >
                        <img
                          class="img-carousel"
                          src="{{ foto }}"
                          onError="src='https://flexpot-recursos.s3.amazonaws.com/warehouse/photos/warehouse.jpg/SPBUF65J9C6BkYZ'"
                        />
                      </ng-template>
                    </ngb-carousel>
                  </div>
                </ng-template>
                <div class="main__bodegas--left--main--images--carrousel">
                  <div
                    class="main__bodegas--left--main--images--carrousel--image"
                    *ngFor="let foto of warehouse.fotos"
                  >
                    <img
                      (click)="open(fotos)"
                      src="{{ foto }}"
                      onError="src='https://flexpot-recursos.s3.amazonaws.com/warehouse/photos/warehouse.jpg/SPBUF65J9C6BkYZ'"
                    />
                  </div>
                </div>
              </div>
            </div>
            <!-- Section of the bottom -->
            <div class="bottom">
              <div class="main__left__bottom--products">
                <div class="main__left__bottom--products--title">
                  <!-- <p class="title">Servicios adicionales de la bodega</p> -->
                </div>
                <div class="main__left__bottom--products--product--flex">
                  <ng-container *ngFor="let producto of warehouse.otros">
                    <ng-container
                      *ngIf="
                        producto.otro.tipo !== 'producto' &&
                        producto.otro.tipo !== 'certificado'
                      "
                    >
                      <div class="main__left__bottom--products--product">
                        <p>
                          {{
                            producto.otro.name
                              ? producto.otro.name
                              : "No hay
                          productos por mostrar"
                          }}
                        </p>
                        <ng-container *ngIf="producto.costo">
                          <p class="price">${{ producto.costo }}</p>
                        </ng-container>
                      </div>
                    </ng-container>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="main--tables--information--right">
        <p>Editar info de tu bodega</p>
        <p>
          Para cambiar la información de tu bodega que ya fue aprobada puedes
          editarla haciendo clic al botón de arriba, pero un encargado tendrá
          que hacer una nueva verificación desde el sistema o presencial para
          habilitar nuevamente la bodega.
        </p>
        <br />
        <br />
        <p>
          Si actualizas algún costo sobre uno de los servicios, aplicará para
          las próximas reservas, los clientes que tengan reservas por periodos
          largos conservarán el costo inicial del servicio en el momento en que
          hicieron la reserva
        </p>
      </div>
    </div>
  </div>
</div>

<!-- Modal of movements -->
<ng-template #movement let-modal>
  <div class="modal-header">
    <h1 class="movement">{{ titleMovement }}</h1>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="modal-body--title">
      <p>ID movimiento: {{ movementID }}</p>
    </div>
    <div class="modal-body-info">
      <div class="modal-body-info-info first">
        <img
          src="../../../assets/Utils/aporte-1.png"
          class="upload-image-small"
        />
        <div class="modal-body-info-info-flex">
          <p>Fecha</p>
          <p class="date">{{ fecha }}</p>
        </div>
      </div>
      <div class="modal-body-info-info">
        <img
          src="../../../assets/Utils/espacios.png"
          class="upload-image-small"
        />
        <div class="modal-body-info-info-flex">
          <p>Gestión</p>
          <p class="date">Flexpot</p>
        </div>
      </div>
      <div class="modal-body-info-info">
        <img
          src="../../../assets/Utils/aporte-6.png"
          class="upload-image-small"
        />
        <div class="modal-body-info-info-flex">
          <p>Cliente</p>
          <p class="date">{{ cliente }}</p>
        </div>
      </div>
    </div>
    <div class="modal-body-info-info-header">
      <button
        [class.active]
        (click)="onItems()"
        [ngClass]="{ enabled: itemsMoved }"
      >
        Items movidos
      </button>
      <button
        [class.active]
        (click)="onGestion()"
        [ngClass]="{ enabled: gestionActive }"
      >
        Gestión
      </button>
    </div>
    <ng2-smart-table
      *ngIf="itemsMoved"
      [settings]="settingsMovementsModal"
      [source]="products"
      (custom)="onCustom($event)"
    >
    </ng2-smart-table>
  </div>
  <div class="modal--gestion" *ngIf="gestionActive">
    <!-- Usar datos originales  -->
    <p>Dirección: {{ address == "" ? warehouse.nombre : address }}</p>
    <p class="charge">Encargado: {{ cliente }}</p>
  </div>
  <div class="modal--footer">
    <button
      class="modal__checkout--button"
      (click)="modal.dismiss('Cross click')"
    >
      Listo
    </button>
  </div>
</ng-template>
<!-- Modal of approve -->
<ng-template #approve let-modal>
  <div class="delete--modal">
    <div class="modal-header">
      <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="modal.dismiss('Cross click')"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal--body--circle--outside">
      <div class="modal__body--circle--inside">
        <fa-icon [icon]="faQuestion"></fa-icon>
      </div>
    </div>
    <div class="modal--body-question">
      <h1>¿Estás seguro de que deseas activar este espacio?</h1>
      <p>El espacio se mostrará como activo</p>
    </div>
    <button (click)="onActivateSpace()" class="submit--button-delete">
      Activar
    </button>
  </div>
</ng-template>
<!-- Modal of approve -->
<ng-template #deactivate let-modal>
  <div class="delete--modal">
    <div class="modal-header">
      <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="modal.dismiss('Cross click')"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal--body--circle--outside">
      <div class="modal__body--circle--inside">
        <fa-icon [icon]="faQuestion"></fa-icon>
      </div>
    </div>
    <div class="modal--body-question">
      <h1>¿Estás seguro de que deseas desactivar este espacio?</h1>
      <p>El espacio se mostrará como inactivo</p>
    </div>
    <button (click)="onDeactivateSpace()" class="submit--button-delete">
      Desactivar
    </button>
  </div>
</ng-template>
